/**
 * @fileoverview Root reducer for the SensaiAI module
 * @module sensaiAi/reducers
 */

import { combineReducers } from 'redux'
import { NAMESPACE } from '../constants/namespaces'
import coachReducer from './coachReducer'
import status from './statusReducer'
import processing from './processingReducer'
import credentials from './credentialsReducer'

export const initialState = {
  [NAMESPACE.ROOT]: {
    coach: {
      coaches: null,
      isFetching: false,
      isCreating: false,
      error: null,
    },
    status: {
      sensaiStatus: null,
      isFetching: false,
      error: null,
    },
    processing: {
      requestId: null,
      isProcessing: false,
      isComplete: false,
    },
    credentials: {
      appSecret: null,
      licenseKey: null,
    },
  },
}

const sensaiReducer = combineReducers({
  coach: coachReducer,
  status,
  processing,
  credentials,
})

const rootReducer = (state = initialState[NAMESPACE.ROOT], action) => {
  return sensaiReducer(state, action)
}

export default rootReducer
