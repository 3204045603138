import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/session/constants'
import api from 'api'

// Actions

export const fetchVideosFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_VIDEOS_FAILURE,
})

export const fetchVideosRequest = () => ({
  type: actionTypes.FETCH_VIDEOS_REQUEST,
})

export const fetchVideosSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_VIDEOS_SUCCESS,
})

// Thunks

export const fetchVideos = (options, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    if (!getState().assessment.isCompleted) {
      return Promise.resolve()
    }

    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchVideosRequest())

    const handleResponse = ({ videos }) => dispatch(fetchVideosSuccess({ videos }))

    const handleError = ({ error, isConnectionError }) => {
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'videoLoadError' })))
      }
      dispatch(fetchVideosFailure(error))

      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.videos.getVideos(
      {
        accountId,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_VIDEOS

  return thunk
}
