export const en = {
  btnTextChangePassword: 'Change Password',
  formChangePassword: {
    btnTextSubmit: 'Save',
    labelCurrentPassword: 'Current Password',
    labelNewPassword: 'New Password',
    labelPasswordConfirmation: 'Retype New Password',
  },
  headerAccountPreferences: 'Account Preferences',
  labelMyNumberOfNetworks: 'My Number Of Networks',
  linkedNetworks: {
    default: 'Default Network',
    expired: 'Expired',
    header: 'Linked Networks',
    labelTitle: 'Set as Default Network',
  },
  maskPassword: '************',
  sections: {
    headerEmailAddresses: 'Email Addresses',
    headerLoginInformation: 'Login Information',
    headerPersonalInformation: 'Personal Information',
  },
}

export const es = en
