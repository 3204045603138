/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { COLORS } from 'constants/colors'

const lightGray = COLORS.grey3.rgba
const borderColor = 'rgba(155, 155, 155, 0.5)'

const borderBottom = `1px solid ${borderColor}`

const height = 70

const useStyles = makeStyles({
  root: {
    minHeight: `${height}px`,
    '& .MuiFormLabel-root': {
      color: lightGray,
    },
    '& .MuiFormLabel-asterisk': {
      color: 'red',
    },
    '& .MuiInput-underline:before': {
      borderBottom,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom,
    },
  },
})

const StyledTextField = ({ ariaLabel, className, id, label: labelProp, value, maxLength, ...rest }) => {
  const classes = useStyles()

  const configureLabel = () => (!maxLength ? labelProp : `${labelProp} (${value.length} / ${maxLength})`)

  const [label, setLabel] = useState(configureLabel())

  useEffect(() => {
    setLabel(configureLabel())
  }, [value])

  return (
    <TextField
      className={classnames(className, classes.root)}
      fullWidth
      inputProps={{
        'aria-label': ariaLabel || label,
        id,
        maxLength,
      }}
      label={label}
      value={value}
      {...rest}
    />
  )
}

StyledTextField.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.string,
}

StyledTextField.defaultProps = {
  ariaLabel: '',
  className: null,
  id: '',
  label: null,
  maxLength: null,
  value: '',
}

export default StyledTextField
