import { pickHTMLProps } from 'pick-react-known-prop'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { PERSON } from 'constants/props'
import Avatar from 'generics/Avatar'
import ListItemActions from 'generics/ListItemActions'

import styles from './SharedProfileRequestsListItem.scss'

// TODO: create Bagde component as ListItemActions
const renderBadge = status => {
  if (!status) {
    return null
  }
  return (
    <div className={classnames(styles.badge, styles[status])}>
      <span title={status}>{status}</span>
    </div>
  )
}

const SharedProfileRequestsListItem = props => {
  const { actions, status, profile, ...rest } = props

  const { email, name } = profile

  const htmlProps = pickHTMLProps(rest)

  return (
    <li className={styles.item} {...htmlProps}>
      <Avatar className={styles.avatar} profile={profile} title={name} />

      <div className={styles.information}>
        <div className={classnames(styles['information-wrapper'], styles['information-wrapper-name'])}>
          <span className={styles.name} title={name}>
            {name}
          </span>
        </div>
        <div className={classnames(styles['information-wrapper'], styles['information-wrapper-email'])}>
          <span className={styles.email} title={email}>
            {email}
          </span>
        </div>
      </div>
      {actions && <ListItemActions actions={actions} className={styles.actions} />}
      {renderBadge(status)}
    </li>
  )
}

SharedProfileRequestsListItem.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  profile: PERSON.isRequired,
  status: PropTypes.string,
}

SharedProfileRequestsListItem.defaultProps = {
  actions: null,
  status: null,
}

export default SharedProfileRequestsListItem
