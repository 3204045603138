/* eslint-disable react-hooks/exhaustive-deps */
import { delay, find, isEmpty } from 'lodash'
import { MenuItem, Paper } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { I18N, PROFILES } from 'constants/props'
import { StyledTextField } from 'generics/StyledFormComponents'
import Avatar from 'generics/Avatar'
import Translation from 'generics/Translation'

import styles from './AutoComplete.scss'

const DELAY_CLOSE_TIME = 200

const AutoComplete = ({
  className,
  hasAvatar,
  i18n,
  labelText,
  onChange,
  onSearch,
  onSelectProfile,
  profiles,
  selectedProfile,
}) => {
  const [filter, setFilter] = useState('')
  const [initialProfiles, setInitialProfiles] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState(null)
  const [searchProfiles, setSearchProfiles] = useState([])

  useEffect(() => {
    onSearch('', () => {})
  }, [])

  useEffect(() => {
    if (profiles.length) {
      if (typeof selectedProfile === 'function') {
        if (initialProfiles === null) {
          setInitialProfiles([...profiles].filter(profile => profile.id !== selectedProfile()))
        }
        setSearchProfiles([...profiles].filter(profile => profile.id !== selectedProfile()))
      }
    }
  }, [profiles])

  const handleSearchProfiles = () =>
    setSearchProfiles(
      initialProfiles?.filter(
        profile => profile.name.toLowerCase().includes(filter.toLowerCase()) && profile.id !== selectedProfile(),
      ),
    )

  // Search
  useEffect(() => {
    if (typeof selectedProfile === 'function') {
      if (filter) {
        handleSearchProfiles()
      } else {
        setSearchProfiles(initialProfiles?.filter(profile => profile.id !== selectedProfile()) || [])
      }
    }
  }, [filter])

  const handleClose = () => {
    delay(() => setIsOpen(false), DELAY_CLOSE_TIME)
  }

  const handleChange = id => {
    const selectedProfileObj = find(profiles, { id })

    setFilter(selectedProfileObj.name)
    setIsOpen(false)
    setSelected(selectedProfileObj)

    onSelectProfile(id)
    onChange(id)
  }

  const handleFocus = () => {
    if (typeof selectedProfile === 'function') {
      if (filter) {
        handleSearchProfiles()
      } else {
        setSearchProfiles(initialProfiles?.filter(profile => profile.id !== selectedProfile()) || [])
      }
      setIsOpen(true)
    }
  }

  const handleSearch = e => {
    setSelected(null)
    onSelectProfile(null)

    const filterTerm = e.currentTarget.value
    setFilter(filterTerm)
  }

  const renderProfiles = () =>
    searchProfiles?.map(({ id, isVisible, name }) => (
      <MenuItem
        className={`sugestion-${id}`}
        disabled={!isVisible}
        key={`sugestion-${id}`}
        onMouseDown={() => handleChange(id)}
        value={id}
      >
        {name}
      </MenuItem>
    ))

  return (
    <form
      className={classnames(styles['auto-complete'], className)}
      onBlur={handleClose}
      onSubmit={event => {
        event.preventDefault()
      }}
    >
      {hasAvatar && <Avatar className={styles.avatar} default icon={{ name: 'user' }} profile={selected} />}
      <StyledTextField
        label={labelText || i18n.generics.typeSearchPerson}
        fullWidth
        onChange={handleSearch}
        onFocus={handleFocus}
        title={labelText || i18n.generics.typeSearchPerson}
        value={filter}
      />
      {!isEmpty(profiles) && isOpen && <Paper className={styles.options}>{renderProfiles()}</Paper>}
    </form>
  )
}

AutoComplete.propTypes = {
  className: PropTypes.string,
  hasAvatar: PropTypes.bool,
  i18n: I18N.isRequired,
  labelText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelectProfile: PropTypes.func,
  profiles: PROFILES.isRequired,
  selectedProfile: PropTypes.func,
}

AutoComplete.defaultProps = {
  className: null,
  hasAvatar: false,
  labelText: null,
  onSelectProfile: () => {},
  selectedProfile: null,
}

export default Translation(AutoComplete, ['generics'])
