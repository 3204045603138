/**
 * @description Settings for training videos controller
 * @module API controller / Training Videos
 */
module.exports = {
  trackVideo: {
    /**
     * @description Request dashboard video tracking
     * @param {number} accountId
     * @returns {object} request settings
     */
    settings: ({ accountId, videoName }) => {
      return {
        endpoint: '/ahoy/visits',
        body: {
          accountId,
          event: 'viewed_dashboard_video',
          videoName,
        },
        method: 'POST',
      }
    },
  },

  trackSpiderGraph: {
    /**
     * @description Request spider graph tracking
     * @param {number} accountId
     * @returns {object} request settings
     */
    settings: ({ accountId, teamId }) => ({
      endpoint: '/ahoy/visits',
      body: {
        accountId,
        event: 'generated_spider_graph',
        teamId,
      },
      method: 'POST',
    }),
  },
}
