/* eslint-disable react/jsx-one-expression-per-line */
import { Col, Container, Row } from 'reactstrap'
import DocumentTitle from 'react-document-title'
import React from 'react'
import htmlParser from 'html-react-parser'

import { I18N } from 'constants/props'
import Translation from 'generics/Translation'

import styles from './PageTermsOfUse.scss'

const PageTermsOfUse = ({ i18n }) => (
  <DocumentTitle title="Simpli5® - Terms Of Use Page">
    <Container className={styles.terms}>
      <Row>
        <Col>
          <h1 className={styles.terms__h1}>{i18n.pageTermsOfUse.header}</h1>
          <p className={styles.terms__updatedDate}>{i18n.pageTermsOfUse.updatedDate}</p>
          <p>{htmlParser(i18n.pageTermsOfUse.text[0])}</p>
          <p>
            <b>{i18n.pageTermsOfUse.headers[0]}.</b>&nbsp;
            {htmlParser(i18n.pageTermsOfUse.text[1])}
            <b>
              <span className={styles.upper}>{htmlParser(i18n.pageTermsOfUse.text[2])}</span>
              (g)
            </b>
            .
          </p>
          <ol className={styles['main-list']}>
            <li>
              <b>{i18n.pageTermsOfUse.headers[1]}.</b>
              <ol>
                <li>{htmlParser(i18n.pageTermsOfUse.text[3])}</li>
                <li>{htmlParser(i18n.pageTermsOfUse.text[4])}</li>
                <li>{htmlParser(i18n.pageTermsOfUse.text[5])}</li>
              </ol>
            </li>
            <li>
              <b>{i18n.pageTermsOfUse.headers[2]}.</b>&nbsp;
              {htmlParser(i18n.pageTermsOfUse.text[6])}
            </li>
            <li>
              <b>{i18n.pageTermsOfUse.headers[3]}.</b>&nbsp;
              {htmlParser(i18n.pageTermsOfUse.text[7])}
            </li>
            <li>
              <b>{i18n.pageTermsOfUse.headers[4]}.</b>&nbsp;
              {htmlParser(i18n.pageTermsOfUse.text[8])}
            </li>
            <li>
              <b>{i18n.pageTermsOfUse.headers[5]}.</b>
              <ol>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[6]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[9])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[7]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[10])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[8]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[11])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[9]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[12])}
                </li>
              </ol>
            </li>
            <li>
              <b>{i18n.pageTermsOfUse.headers[10]}.</b>
              <ol>
                <li>{htmlParser(i18n.pageTermsOfUse.text[13])}</li>
                <li>{htmlParser(i18n.pageTermsOfUse.text[14])}</li>
                <li className={styles.upper}>{htmlParser(i18n.pageTermsOfUse.text[15])}</li>
              </ol>
            </li>
            <li>
              <b>{i18n.pageTermsOfUse.headers[11]}.</b>
              <ol>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[12]}</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[16])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[13]}.</b>
                  {htmlParser(i18n.pageTermsOfUse.text[17])}
                  <a
                    href="https://www.simpli5.com/privacy-policy/"
                    rel="noopener noreferrer"
                    target="_blank"
                    title={i18n.pageTermsOfUse.privacyPolicy}
                    to="https://www.simpli5.com/privacy-policy/"
                  >
                    {i18n.pageTermsOfUse.privacyPolicy}
                  </a>
                  {htmlParser(i18n.pageTermsOfUse.text[18])}
                </li>
              </ol>
            </li>
            <li>
              <b>{i18n.pageTermsOfUse.headers[14]}</b>&nbsp;
              {htmlParser(i18n.pageTermsOfUse.text[19])}
              <span className={styles.upper}>{htmlParser(i18n.pageTermsOfUse.text[20])}</span>&nbsp;
              {htmlParser(i18n.pageTermsOfUse.text[21])}
            </li>
            <li>
              <b>{i18n.pageTermsOfUse.headers[15]}.</b>&nbsp;
              {htmlParser(i18n.pageTermsOfUse.text[22])}
            </li>
            <li>
              <span className={styles.upper}>
                <b>{i18n.pageTermsOfUse.headers[16]}.</b>&nbsp;
                {htmlParser(i18n.pageTermsOfUse.text[23])}
              </span>
              &nbsp;
              {htmlParser(i18n.pageTermsOfUse.text[24])}
            </li>
            <li className={styles.upper}>
              <b>{i18n.pageTermsOfUse.headers[17]}.</b>&nbsp;
              {htmlParser(i18n.pageTermsOfUse.text[25])}
            </li>
            <li>
              <b>{i18n.pageTermsOfUse.headers[18]}.</b>&nbsp;
              {htmlParser(i18n.pageTermsOfUse.text[26])}
            </li>
            <li>
              <b>{i18n.pageTermsOfUse.headers[19]}.</b>
              <ol>
                <li>{htmlParser(i18n.pageTermsOfUse.text[27])}</li>
                <li>{htmlParser(i18n.pageTermsOfUse.text[28])}</li>
                <li>{htmlParser(i18n.pageTermsOfUse.text[29])}</li>
              </ol>
            </li>
            <li>
              <b>{i18n.pageTermsOfUse.headers[20]}.</b>
              <ol>
                <li>{htmlParser(i18n.pageTermsOfUse.text[30])}</li>
                <li>{htmlParser(i18n.pageTermsOfUse.text[31])}</li>
                <li>{htmlParser(i18n.pageTermsOfUse.text[32])}</li>
                <li>{htmlParser(i18n.pageTermsOfUse.text[33])}</li>
                <li>{htmlParser(i18n.pageTermsOfUse.text[34])}</li>
              </ol>
            </li>
            <li>
              <b>General.</b>
              <ol>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[21]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[35])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[22]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[36])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[23]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[37])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[24]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[38])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[25]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[39])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[26]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[40])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[27]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[41])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[28]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[42])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[29]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[43])}
                </li>
                <li>
                  <b>{i18n.pageTermsOfUse.headers[30]}.</b>&nbsp;
                  {htmlParser(i18n.pageTermsOfUse.text[44])}
                </li>
              </ol>
            </li>
          </ol>
        </Col>
      </Row>
    </Container>
  </DocumentTitle>
)

PageTermsOfUse.propTypes = {
  i18n: I18N.isRequired,
}

export default Translation(PageTermsOfUse, ['pageTermsOfUse'])
