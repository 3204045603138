import { get } from 'lodash'

import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import accountsActionTypes from 'app_modules/accounts/constants'
import api from 'api'

// Actions

export const fetchMyMembersFailure = error => ({
  payload: error,
  type: accountsActionTypes.FETCH_MY_MEMBERS_FAILURE,
})

export const fetchMyMembersRequest = pageIndex => ({
  payload: pageIndex,
  type: accountsActionTypes.FETCH_MY_MEMBERS_REQUEST,
})

export const fetchMyMembersSuccess = response => ({
  payload: response,
  type: accountsActionTypes.FETCH_MY_MEMBERS_SUCCESS,
})

// Thunks

export const fetchMyMembers = ({ pageIndex = 1, search, workshopFilter }) => {
  const thunk = (dispatch, getState) => {
    const state = getState()

    const accountId = sessionSelectors.accountId(state)

    dispatch(fetchMyMembersRequest(pageIndex))

    const handleResponse = response => {
      // TODO: Fix the endpoint schema, also review the invitation endpoint
      const fixedResponse = {
        ...response,
        account: get(response, 'profiles[0].account'),
        updateCounters: !search,
      }

      dispatch(fetchMyMembersSuccess(fixedResponse))
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchMyMembersFailure(error))
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'getMyMembersError' })))
      }
    }

    return api.accounts.getMyMembers({ accountId, pageIndex, search, workshopFilter }, handleResponse, handleError)
  }

  thunk.type = accountsActionTypes.FETCH_MY_MEMBERS

  return thunk
}

export default fetchMyMembers
