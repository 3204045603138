import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { Card, Header, Main } from 'generics/Card'
import PropTypes from 'prop-types'
import Translation from 'generics/Translation'
import { I18N, PERSON } from 'constants/props'
import { useCoach, useProcessing } from 'app_modules/sensaiAi/hooks'
import { constructSensaiUrl } from 'app_modules/sensaiAi/utils/redirectionUtils'
import { CircularProgress } from '@material-ui/core'
import styles from './SensaiStarter.scss'

const SensaiStarter = ({ className, i18n, profile }) => {
  const { accountId } = profile
  const [isLoading, setIsLoading] = useState(false)

  const userToken = useSelector(state => {
    const userData = state.user || {};
    const { token, impersonateToken, ssoToken } = userData;
    if (token) return `token=${token || userData.accessToken || userData.authToken}`;
    if (impersonateToken) return `impersonate=${impersonateToken}`;
    if (ssoToken) return `sso=${ssoToken}`;
    return '';
  })
  const credentials = useSelector(state => state.sensaiAi?.credentials || {})
  const currentUrl = window.location.href.split('?')[0]

  const { isLoading: isFetchingCoaches, isCreating: isCreatingCoach, createCoach, fetchCoaches } = useCoach()

  const { requestId, processing: isProcessing, setRequestId, setProcessing, setComplete, fetchStatus } = useProcessing()

  // Status polling effect
  useEffect(() => {
    let pollInterval = null

    const checkStatus = async () => {
      if (requestId && isProcessing) {
        try {
          const statusResponse = await fetchStatus(requestId)
          const isStillProcessing = statusResponse?.processing || statusResponse?.data?.processing

          if (!isStillProcessing) {
            if (pollInterval) {
              clearInterval(pollInterval)
              pollInterval = null
            }

            setComplete(true)
            setProcessing(false)
            setIsLoading(false)

            
            const url = constructSensaiUrl({
              accountId,
              userToken,
              currentUrl,
              credentials,
              isReturningUser: true,
              userName: profile.name,
            })
            window.location.href = `${url}&requestId=${encodeURIComponent(requestId)}`
            return
          }
        } catch (error) {
          if (pollInterval) {
            clearInterval(pollInterval)
            pollInterval = null
          }
        }
      }
    }

    if (requestId && isProcessing) {
      checkStatus() // Initial check
      pollInterval = setInterval(checkStatus, 8000) // Poll every 8 seconds

      // Cleanup function
      return () => {
        if (pollInterval) {
          clearInterval(pollInterval)
          pollInterval = null
        }
      }
    }
  }, [
    requestId,
    isProcessing,
    fetchStatus,
    setComplete,
    setProcessing,
    accountId,
    userToken,
    currentUrl,
    credentials,
    profile.name,
  ])

  const handleSensaiAction = async () => {
    setIsLoading(true)
    try {
      const coachesResponse = await fetchCoaches()
      if (coachesResponse?._status === 200) {
        setIsLoading(false)
        const url = constructSensaiUrl({
          accountId,
          userToken,
          currentUrl,
          credentials,
          isReturningUser: false,
          userName: profile.name,
        })
        window.location.href = url
        return
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(true)
      try {
        const createResponse = await createCoach()

        if (createResponse?.requestId) {
          setRequestId(createResponse.requestId)
          setProcessing(true)
          // Polling will start automatically via the effect
        }
      } catch (createError) {
        console.error('Create coach failed:', createError)
        setIsLoading(false)
      }
    }
  }

  const isButtonDisabled = isFetchingCoaches || isCreatingCoach || isProcessing || isLoading

  return (
    <Card className={classnames(styles.starter, className)} barClassName={styles.card__bar}>
      <Header className={styles.titleHeader}>
        <div className={styles.header}>
          <div className={styles.avatarWrapper}>
            <img
              src="https://chatstorage.isometrik.ai/simpli5/Avatars/avatar-default.png"
              alt="SenSai"
              className={styles.avatar}
            />
          </div>
          <div className={styles.titleWrapper}>
            <p className={styles.starterTitle}>Meet SenSai</p>
          </div>
        </div>
      </Header>

      <Main className={styles.titleHeader}>
        <div>
          <div className={styles.videoContainer}>
            <video
              poster="https://sensai.simpli5.com/assets/images/into_video_thumbnail_play_icon.png"
              controls
              width="100%"
              height="100%"
            >
              <track kind="captions" src="/path/to/captions.vtt" label="English" srcLang="en" default />
              <source src="https://chatstorage.isometrik.ai/bot-assets/Videos/sensai-intro.mp4" type="video/mp4" />
            </video>
          </div>

          <p className={styles.description}>{i18n.sensai.description.part1}</p>
          <p className={styles.description}>{i18n.sensai.description.part2}</p>

          <div>
            <button
              className={styles.connectButton}
              onClick={handleSensaiAction}
              disabled={isButtonDisabled}
              type="button"
            >
              <div className={styles.talkLabel}>
                {isButtonDisabled ? (
                  <div className={styles.loadingContainer}>
                    <CircularProgress className={styles.loader} size={25} thickness={3} />
                    <p> {i18n.sensai.loadingMsg}</p>
                  </div>
                ) : (
                  i18n.sensai.talkLabel
                )}
              </div>
            </button>
          </div>
        </div>

        <div className={styles.poweredBy}>
          <a
            className={styles.link}
            href="https://supernomics.ai"
            target="_blank"
            rel="noopener noreferrer"
            title={i18n.sensai.poweredByAlt}
          >
            <img src="https://sensai.simpli5.com/assets/logos/supernomics_new.svg" alt={i18n.sensai.poweredByAlt} />
          </a>
        </div>
      </Main>
    </Card>
  )
}

SensaiStarter.propTypes = {
  className: PropTypes.string,
  i18n: I18N.isRequired,
  profile: PERSON.isRequired,
}

SensaiStarter.defaultProps = {
  className: '',
}

export default Translation(SensaiStarter, ['sensai'])
