import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { COLORS } from 'constants/colors'

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    margin: '0 auto',
    minWidth: '32px',
    '&.Mui-disabled': {
      background: COLORS.blueDisabled.rgba,
      color: COLORS.primaryWhite.rgba,
    },
    '&.MuiButton-text': {
      background: 'transparent',
    },
  },
})

const StyledButton = ({ className, color, variant, ...rest }) => {
  const classes = useStyles()
  return <Button className={classnames(classes.root, className)} color={color} variant={variant} {...rest} />
}

StyledButton.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
}

StyledButton.defaultProps = {
  className: null,
  color: 'primary',
  variant: 'contained',
}

export default StyledButton
