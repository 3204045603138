import { combineReducers } from 'redux'
import { get, isEmpty, uniqBy } from 'lodash'

import initialState from 'app_modules/store/initialState.json'
import sessionActionTypes from 'app_modules/session/constants'
import teamsActionTypes from '../constants'

export const currentTeam = combineReducers({
  details: (state = initialState.teams.currentTeam.details, action) => {
    switch (action.type) {
      case teamsActionTypes.FETCH_DELETE_TEAM_REQUEST:
      case teamsActionTypes.FETCH_EDIT_TEAM_REQUEST:
      case teamsActionTypes.FETCH_SET_PRIVACY_TEAM_REQUEST:
      case teamsActionTypes.FETCH_TEAM_DETAILS_REQUEST:
        return { ...state, isFetching: true }

      case teamsActionTypes.FETCH_DELETE_TEAM_FAILURE:
      case teamsActionTypes.FETCH_DELETE_TEAM_SUCCESS:
      case teamsActionTypes.FETCH_EDIT_TEAM_FAILURE:
      case teamsActionTypes.FETCH_SET_PRIVACY_TEAM_FAILURE:
        return { ...state, isFetching: false }

      case teamsActionTypes.FETCH_EDIT_TEAM_SUCCESS:
      case teamsActionTypes.FETCH_SET_PRIVACY_TEAM_SUCCESS:
      case teamsActionTypes.FETCH_TEAM_DETAILS_SUCCESS:
      case teamsActionTypes.FETCH_UPDATE_LOGO_SUCCESS:
        return { ...state, ...action.payload.team, isFetching: false }

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.currentTeam.details

      default:
        return state
    }
  },

  isFetchingDetails: (state = initialState.teams.currentTeam.isFetching, action) => {
    switch (action.type) {
      case teamsActionTypes.FETCH_DELETE_TEAM_REQUEST:
      case teamsActionTypes.FETCH_EDIT_TEAM_REQUEST:
      case teamsActionTypes.FETCH_SET_PRIVACY_TEAM_REQUEST:
      case teamsActionTypes.FETCH_TEAM_DETAILS_REQUEST:
        return true

      case teamsActionTypes.FETCH_DELETE_TEAM_FAILURE:
      case teamsActionTypes.FETCH_DELETE_TEAM_SUCCESS:
      case teamsActionTypes.FETCH_EDIT_TEAM_FAILURE:
      case teamsActionTypes.FETCH_SET_PRIVACY_TEAM_FAILURE:
      case teamsActionTypes.FETCH_EDIT_TEAM_SUCCESS:
      case teamsActionTypes.FETCH_SET_PRIVACY_TEAM_SUCCESS:
      case teamsActionTypes.FETCH_TEAM_DETAILS_SUCCESS:
      case teamsActionTypes.FETCH_UPDATE_LOGO_SUCCESS:
        return false

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.currentTeam.isFetching

      default:
        return state
    }
  },

  isFetchingMembers: (state = initialState.teams.currentTeam.isFetching, action) => {
    switch (action.type) {
      case teamsActionTypes.FETCH_TEAM_DETAILS_REQUEST:
      case teamsActionTypes.FETCH_TEAM_MEMBERS_REQUEST:
        return true

      case teamsActionTypes.FETCH_TEAM_DETAILS_FAILURE:
      case teamsActionTypes.FETCH_TEAM_DETAILS_SUCCESS:
      case teamsActionTypes.FETCH_TEAM_MEMBERS_FAILURE:
      case teamsActionTypes.FETCH_TEAM_MEMBERS_SUCCESS:
        return false

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.currentTeam.isFetching

      default:
        return state
    }
  },

  teamMembers: (state = initialState.teams.currentTeam.teamMembers, action) => {
    switch (action.type) {
      case teamsActionTypes.FETCH_EDIT_TEAM_SUCCESS:
      case teamsActionTypes.FETCH_TEAM_DETAILS_SUCCESS:
        return get(action.payload, 'team.teamMembers', [])

      case teamsActionTypes.FETCH_TEAM_MEMBERS_SUCCESS:
        return uniqBy([...state, ...get(action.payload, 'profiles', [])], 'id')

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.currentTeam.teamMembers

      default:
        return state
    }
  },

  membersPageIndex: (state = initialState.teams.currentTeam.membersPageIndex, action) => {
    switch (action.type) {
      case teamsActionTypes.FETCH_EDIT_TEAM_SUCCESS:
      case teamsActionTypes.FETCH_TEAM_DETAILS_SUCCESS:
        return 1

      case teamsActionTypes.FETCH_TEAM_MEMBERS_SUCCESS:
        return action.payload.pageIndex

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.currentTeam.membersPageIndex

      default:
        return state
    }
  },

  modules: (state = initialState.teams.currentTeam.modules, action) => {
    switch (action.type) {
      case teamsActionTypes.FETCH_TEAM_MODULES_REQUEST:
        return {
          ...state,
          isFetching: true,
          list: [],
        }

      case teamsActionTypes.FETCH_TEAM_MODULES_FAILURE:
        return {
          ...state,
          isFetching: false,
        }

      case teamsActionTypes.FETCH_TEAM_MODULES_SUCCESS:
        return {
          state,
          isFetching: false,
          list: [...get(action.payload, 'modules', {})].filter(module => module.categoryKey !== 'meetings_8'),
        }

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.currentTeam.modules

      default:
        return state
    }
  },

  meta: (state = initialState.teams.currentTeam.meta, action) => {
    switch (action.type) {
      case teamsActionTypes.FETCH_EDIT_TEAM_SUCCESS:
      case teamsActionTypes.FETCH_TEAM_DETAILS_SUCCESS:
      case teamsActionTypes.FETCH_TEAM_MEMBERS_SUCCESS:
        return {
          ...state,
          ...action.payload.meta,
        }

      case teamsActionTypes.FETCH_EDIT_TEAM_FAILURE:
      case teamsActionTypes.FETCH_TEAM_DETAILS_FAILURE:
      case teamsActionTypes.FETCH_TEAM_MEMBERS_FAILURE:
        return {
          ...state,
          morePages: false,
        }

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.currentTeam.meta

      default:
        return state
    }
  },
})

export const errors = (state = initialState.teams.errors, action) => {
  switch (action.type) {
    case teamsActionTypes.FETCH_CREATE_TEAM_FAILURE:
    case teamsActionTypes.FETCH_EDIT_TEAM_FAILURE:
    case teamsActionTypes.FETCH_MY_TEAMS_FAILURE:
    case teamsActionTypes.FETCH_TEAM_DASHBOARD_FAILURE:
    case teamsActionTypes.FETCH_TEAM_DETAILS_FAILURE:
    case teamsActionTypes.FETCH_TEAM_MEMBERS_FAILURE:
    case teamsActionTypes.FETCH_TEAM_MODULES_FAILURE:
      return [...state, action.payload]

    case sessionActionTypes.RESET_STATE:
      return initialState.teams.errors

    default:
      return state
  }
}

export const corporateTeams = combineReducers({
  isFetching: (state = initialState.teams.teamList.isFetching, { type }) => {
    switch (type) {
      case teamsActionTypes.FETCH_CORPORATE_TEAMS_REQUEST:
        return true

      case teamsActionTypes.FETCH_CORPORATE_TEAMS_FAILURE:
      case teamsActionTypes.FETCH_CORPORATE_TEAMS_SUCCESS:
        return false

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.isFetching

      default:
        return state
    }
  },

  meta: (state = initialState.teams.teamList.meta, { type, payload }) => {
    switch (type) {
      case teamsActionTypes.FETCH_CORPORATE_TEAMS_REQUEST:
        return { morePages: false }

      case teamsActionTypes.FETCH_CORPORATE_TEAMS_SUCCESS: {
        const meta = get(payload, 'meta', initialState.teams.teamList.meta)
        if (isEmpty(payload.teams)) {
          // BE is returning morePages = true when there aren't results
          meta.morePages = false
        }
        return meta
      }

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.meta

      default:
        return state
    }
  },

  list: (state = initialState.teams.teamList.list, { type, payload }) => {
    switch (type) {
      case teamsActionTypes.FETCH_CORPORATE_TEAMS_REQUEST:
        if (payload === 1) {
          return []
        }
        return state

      case teamsActionTypes.FETCH_CORPORATE_TEAMS_FAILURE:
        return []

      case teamsActionTypes.FETCH_CORPORATE_TEAMS_SUCCESS:
        return uniqBy([...state, ...get(payload, 'teams', [])], 'id')

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.list

      default:
        return state
    }
  },
})

export const othersTeams = combineReducers({
  isFetching: (state = initialState.teams.teamList.isFetching, { type }) => {
    switch (type) {
      case teamsActionTypes.FETCH_OTHERS_TEAMS_REQUEST:
        return true

      case teamsActionTypes.FETCH_OTHERS_TEAMS_FAILURE:
      case teamsActionTypes.FETCH_OTHERS_TEAMS_SUCCESS:
        return false

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.isFetching

      default:
        return state
    }
  },

  meta: (state = initialState.teams.teamList.meta, { type, payload }) => {
    switch (type) {
      case teamsActionTypes.FETCH_OTHERS_TEAMS_REQUEST:
        return { morePages: false }

      case teamsActionTypes.FETCH_OTHERS_TEAMS_SUCCESS: {
        const meta = get(payload, 'meta', initialState.teams.teamList.meta)
        if (isEmpty(payload.teams)) {
          // BE is returning morePages = true when there aren't results
          meta.morePages = false
        }
        return meta
      }

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.meta

      default:
        return state
    }
  },

  list: (state = initialState.teams.teamList.list, { type, payload }) => {
    switch (type) {
      case teamsActionTypes.FETCH_OTHERS_TEAMS_REQUEST:
        if (payload === 1) {
          return []
        }
        return state

      case teamsActionTypes.FETCH_OTHERS_TEAMS_FAILURE:
        return []

      case teamsActionTypes.FETCH_OTHERS_TEAMS_SUCCESS:
        return uniqBy([...state, ...get(payload, 'teams', [])], 'id')

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.list

      default:
        return state
    }
  },
})

export const privateTeams = combineReducers({
  isFetching: (state = initialState.teams.teamList.isFetching, { type }) => {
    switch (type) {
      case teamsActionTypes.FETCH_PRIVATE_TEAMS_REQUEST:
        return true

      case teamsActionTypes.FETCH_PRIVATE_TEAMS_FAILURE:
      case teamsActionTypes.FETCH_PRIVATE_TEAMS_SUCCESS:
        return false

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.isFetching

      default:
        return state
    }
  },

  meta: (state = initialState.teams.teamList.meta, { type, payload }) => {
    switch (type) {
      case teamsActionTypes.FETCH_PRIVATE_TEAMS_REQUEST:
        return { morePages: false }

      case teamsActionTypes.FETCH_PRIVATE_TEAMS_SUCCESS: {
        const meta = get(payload, 'meta', initialState.teams.teamList.meta)
        if (isEmpty(payload.teams)) {
          // BE is returning morePages = true when there aren't results
          meta.morePages = false
        }
        return meta
      }

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.meta

      default:
        return state
    }
  },

  list: (state = initialState.teams.teamList.list, { type, payload }) => {
    switch (type) {
      case teamsActionTypes.FETCH_PRIVATE_TEAMS_REQUEST:
        if (payload === 1) {
          return []
        }
        return state

      case teamsActionTypes.FETCH_PRIVATE_TEAMS_FAILURE:
        return []

      case teamsActionTypes.FETCH_PRIVATE_TEAMS_SUCCESS:
        return uniqBy([...state, ...get(payload, 'teams', [])], 'id')

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.list

      default:
        return state
    }
  },
})

export const publishedTeams = combineReducers({
  isFetching: (state = initialState.teams.teamList.isFetching, { type }) => {
    switch (type) {
      case teamsActionTypes.FETCH_PUBLISHED_TEAMS_REQUEST:
        return true

      case teamsActionTypes.FETCH_PUBLISHED_TEAMS_FAILURE:
      case teamsActionTypes.FETCH_PUBLISHED_TEAMS_SUCCESS:
        return false

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.isFetching

      default:
        return state
    }
  },

  meta: (state = initialState.teams.teamList.meta, { type, payload }) => {
    switch (type) {
      case teamsActionTypes.FETCH_PUBLISHED_TEAMS_REQUEST:
        return { morePages: false }

      case teamsActionTypes.FETCH_PUBLISHED_TEAMS_SUCCESS: {
        const meta = get(payload, 'meta', initialState.teams.teamList.meta)
        if (isEmpty(payload.teams)) {
          // BE is returning morePages = true when there aren't results
          meta.morePages = false
        }
        return meta
      }

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.meta

      default:
        return state
    }
  },

  list: (state = initialState.teams.teamList.list, { type, payload }) => {
    switch (type) {
      case teamsActionTypes.FETCH_PUBLISHED_TEAMS_REQUEST:
        if (payload === 1) {
          return []
        }
        return state

      case teamsActionTypes.FETCH_PUBLISHED_TEAMS_FAILURE:
        return []

      case teamsActionTypes.FETCH_PUBLISHED_TEAMS_SUCCESS:
        return uniqBy([...state, ...get(payload, 'teams', [])], 'id')

      case sessionActionTypes.RESET_STATE:
        return initialState.teams.teamList.list

      default:
        return state
    }
  },
})

export default combineReducers({
  corporateTeams,
  currentTeam,
  errors,
  othersTeams,
  privateTeams,
  publishedTeams,
})
