/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from 'reactstrap'
import { get, isEmpty, isEqual } from 'lodash'
import { MenuItem } from '@material-ui/core'
import { sprintf } from 'sprintf-js'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import { ACTIONS, I18N, LIST, PERSON } from 'constants/props'
import { BarError } from 'classes/errors'
import { StyledButton, StyledSelect } from 'generics/StyledFormComponents'
import { USER_ACTIVE_TOKENS } from 'constants/apiErrorCodes'
import ActionButton from 'generics/ActionButton'
import api from 'api'
import Icon from 'generics/Icon'
import InfiniteScrollList from 'generics/InfiniteScrollList'
import ModalConfirmation from 'generics/ModalConfirmation'
import SearchBar from 'generics/SearchBar'
import Translation from 'generics/Translation'

import ModalBulkDelete from '../ModalBulkDelete'
import ModalEditDepartment from '../ModalEditDepartment'
import PeopleListItem from '../PeopleListItem'
import styles from './Members.scss'

const WORKSHOP_FILTER_OPTIONS = ['all', 'in', 'notIn']

const Members = ({
  accountActions,
  currentProfile,
  i18n,
  isDepartmentEnabled,
  isMultipleSelection,
  isLicenseBuyer,
  myMembers,
  notificationActions,
  onLoadWorkshop,
  pageActions,
  profileActions,
  selectedMembers,
  selectedMembersLimit,
  setSelectedMembers,
}) => {
  const [deleteItem, setDeleteItem] = useState(undefined)
  const [filter, setFilter] = useState(null)
  const [isEditDepartmentOpen, setIsEditDepartment] = useState(false)
  const [isModalBulkDeleteOpen, setIsModalBulkDeleteOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFetching, setIsFetching] = useState(null)
  const [selectedDepartment, setSelectedDepartment] = useState({})
  const [workshopFilter, setWorkshopFilter] = useState(WORKSHOP_FILTER_OPTIONS[0])

  let infiniteScrollList = useRef(null)

  const handleOpenCloseBulkDeleteModal = () => {
    setIsModalBulkDeleteOpen(!isModalBulkDeleteOpen)
  }

  const handleFetchRequests = (pageIndex = 1) => {
    if (pageIndex === 1 && get(infiniteScrollList, 'infiniteScrollRef')) {
      infiniteScrollList.infiniteScrollRef.resetIndex()
    }

    return accountActions.fetchMyMembers({ pageIndex, search: filter, workshopFilter })
  }

  const handleDeleteMember = memberId => {
    setDeleteItem(memberId)
    setIsModalOpen(true)
  }

  const handleDeleteMemberCancel = () => {
    setDeleteItem(undefined)
    setIsModalOpen(false)
  }

  useEffect(() => {
    handleFetchRequests()
  }, [filter, workshopFilter])

  const handleDeleteMemberAccept = () => {
    setIsFetching(deleteItem)

    const onFinish = () => {
      setIsFetching(null)
      setFilter(null)
      handleFetchRequests()

      notificationActions.notifySuccess(i18n.pageAdministration.peopleList.deleteMemberSuccessMessage)
    }

    const onError = error => {
      setIsFetching(null)

      if (error.errorCode === USER_ACTIVE_TOKENS) {
        notificationActions.notifyError(
          new BarError({
            ...error,
            message: error?.message,
          }),
        )
      }
    }

    profileActions.fetchDeleteProfile(deleteItem, onFinish, onError)
    handleDeleteMemberCancel()
  }

  const handleExport = () => {
    const {
      account: { id: accountId },
    } = currentProfile

    api.profiles.exportMembers(accountId)
  }

  const handleSearch = searchInput => {
    if (isEqual(filter, searchInput)) {
      return
    }

    setFilter(searchInput)
  }

  const handleChangeFilter = workshopFilterParam => {
    setWorkshopFilter(workshopFilterParam)
  }

  const renderList = () => {
    const {
      account: { id: accountId },
      isSSOAccount,
    } = currentProfile

    return !isEmpty(myMembers.list) ? (
      myMembers.list.map(item => {
        const { assessmentStatus, id: memberId, lastWorkshop } = item
        const isDisabled = currentProfile.id === memberId
        Object.assign(item, { status: i18n.workshops.status[assessmentStatus] })

        return (
          <PeopleListItem
            accountId={accountId}
            actions={[
              isSSOAccount && lastWorkshop && (
                <Icon.Stroke7
                  className={styles['workshop-link']}
                  key={`members-workshop-${item.id}`}
                  name="share"
                  onClick={() => {
                    onLoadWorkshop(lastWorkshop.id)
                  }}
                  title={sprintf(i18n.pageAdministration.peopleList.latestWorkshop, { workshop: lastWorkshop.name })}
                />
              ),
              isSSOAccount && !lastWorkshop && currentProfile.can({ workshop: 'index' }) && (
                <span className={styles['workshop-link-space']} />
              ),
              !isDisabled && (
                <ActionButton
                  actionType="decline"
                  desktop={{
                    props: {
                      className: styles.delete__desktop,
                      label: i18n.generics.deleteLabel,
                      title: i18n.generics.deleteLabel,
                    },
                  }}
                  key={`delete-${item.id}`}
                  isSingleAction
                  onClick={() => handleDeleteMember(memberId)}
                />
              ),
            ]}
            assessmentStatus={assessmentStatus}
            currentProfile={currentProfile}
            isCheckboxDisabled={
              Object.values(selectedMembers).filter(value => value && value).length === selectedMembersLimit
            }
            isChecked={selectedMembers && !!selectedMembers[memberId]}
            isDepartmentEnabled={isDepartmentEnabled}
            isDisabled={isDisabled}
            isFetching={isFetching === memberId}
            isLicenseBuyer={isLicenseBuyer}
            isMultipleSelection={isMultipleSelection}
            item={item}
            key={`member-${memberId}`}
            listItemTestAttribute="members-list-item"
            listItemNameTestAttribute="members-list-item-name"
            onChange={() =>
              setSelectedMembers({
                ...selectedMembers,
                [memberId]: selectedMembers[memberId] ? false : item,
              })
            }
            setIsEditDepartment={setIsEditDepartment}
            setSelectedDepartment={setSelectedDepartment}
          />
        )
      })
    ) : (
      <div className={styles.empty}>
        <span>{i18n.pageAdministration.peopleList.noResultsToDisplayYetLabel}</span>
      </div>
    )
  }

  const {
    account: { id: accountId },
    isSSOAccount,
  } = currentProfile

  const displayWorkshopFilter = isSSOAccount && currentProfile.can({ workshop: 'index' })

  const infiniteScroll = {
    hasMorePages: myMembers.meta.morePages,
    listHeight: 600,
    onFetch: handleFetchRequests,
  }

  const workshopFilterItems = WORKSHOP_FILTER_OPTIONS.map(value => (
    <MenuItem key={`workshop-filter-option-${value}`} value={value}>
      {i18n.pageAdministration.members.workshopOptions[value]}
    </MenuItem>
  ))

  return (
    <>
      <div className={styles['members-menu']}>
        <StyledButton
          className={styles['menu-opt']}
          onClick={handleExport}
          title={i18n.pageAdministration.members.exportMembersTitle}
          variant="text"
        >
          <Icon.Stroke7 className={styles['menu-opt__icon']} name="download" />
          <span className={styles['menu-opt__text']}>{i18n.pageAdministration.members.exportMembers}</span>
        </StyledButton>
        <StyledButton
          className={styles['menu-opt']}
          onClick={handleOpenCloseBulkDeleteModal}
          title={i18n.pageAdministration.members.bulkDelete}
          variant="text"
        >
          <Icon.Stroke7 className={styles['menu-opt__icon']} name="delete-user" />
          <span className={styles['menu-opt__text']}>{i18n.pageAdministration.members.bulkDelete}</span>
        </StyledButton>
      </div>
      <Row>
        <Col xs="12" lg={displayWorkshopFilter ? 6 : 12}>
          <SearchBar
            className={styles.search}
            floatingLabelText={i18n.pageAdministration.members.search}
            isDepartmentEnabled={isDepartmentEnabled}
            onChange={handleSearch}
            onSearch={handleSearch}
          />
        </Col>
        {displayWorkshopFilter && (
          <Col xs="12" lg="6" className={styles['workshop-selector-container']}>
            <StyledSelect
              className={styles['workshop-selector']}
              fullWidth
              iconClassName={styles['workshop-selector__icon']}
              id="workshopFilter"
              onChange={e => {
                handleChangeFilter(e.target.value)
              }}
              value={workshopFilter}
            >
              {workshopFilterItems}
            </StyledSelect>
          </Col>
        )}
      </Row>
      <InfiniteScrollList
        className={styles['scroll-list-main']}
        infiniteScroll={infiniteScroll}
        listId="members-scroll-list"
        innerRef={component => {
          infiniteScrollList = component
        }}
      >
        {renderList()}
      </InfiniteScrollList>

      {isModalOpen && (
        <ModalConfirmation
          confirmationMessages={[i18n.pageAdministration.peopleList.deleteMemberConfirmationMessage]}
          barClassName={styles.bar}
          onAccept={handleDeleteMemberAccept}
          onCancel={handleDeleteMemberCancel}
        />
      )}
      {isModalBulkDeleteOpen && (
        <ModalBulkDelete
          accountId={accountId}
          onClose={() => {
            handleOpenCloseBulkDeleteModal()
          }}
          onComplete={() => {
            handleFetchRequests()
          }}
          pageActions={pageActions}
        />
      )}
      {isEditDepartmentOpen && (
        <ModalEditDepartment
          accountActions={accountActions}
          i18n={i18n}
          initialValues={{ ...selectedDepartment }}
          notificationActions={notificationActions}
          onClose={() => {
            setIsEditDepartment(false)
          }}
          selectedDepartment={selectedDepartment}
        />
      )}
    </>
  )
}

Members.propTypes = {
  accountActions: ACTIONS.isRequired,
  currentProfile: PERSON.isRequired,
  i18n: I18N.isRequired,
  isMultipleSelection: PropTypes.bool,
  isDepartmentEnabled: PropTypes.bool.isRequired,
  isLicenseBuyer: PropTypes.bool.isRequired,
  notificationActions: ACTIONS.isRequired,
  myMembers: LIST(PERSON).isRequired,
  onLoadWorkshop: PropTypes.func.isRequired,
  pageActions: ACTIONS.isRequired,
  profileActions: ACTIONS.isRequired,
  selectedMembers: PropTypes.shape({}),
  selectedMembersLimit: PropTypes.number,
  setSelectedMembers: PropTypes.func,
}

Members.defaultProps = {
  isMultipleSelection: false,
  selectedMembers: {},
  selectedMembersLimit: null,
  setSelectedMembers: () => {},
}

export default Translation(Members, ['pageAdministration', 'generics', 'workshops'])
