import { isNil, zipWith } from 'lodash'
import { scaleThreshold } from 'd3-scale'

import { DYNAMICS_LABELS, ENERGY_LABELS } from 'constants/'

export function toString() {
  if (this.length > 0) {
    return `(${this.join(', ')})`
  }

  return '(-, -, -, -)'
}

/**
 * Abstract class representing a profile
 * @class
 */
class Profile {
  constructor() {
    this._dynamics = DYNAMICS_LABELS

    this._energies = [
      {
        abbreviation: 'R',
        text: ENERGY_LABELS[0],
      },
      {
        abbreviation: 'R',
        text: ENERGY_LABELS[0],
        threshold: 0,
      },
      {
        abbreviation: 'D',
        text: ENERGY_LABELS[1],
        threshold: 2,
      },
      {
        abbreviation: 'D+',
        text: `${ENERGY_LABELS[1]}+`,
        threshold: 5,
      },
      {
        abbreviation: 'E',
        text: ENERGY_LABELS[2],
        threshold: 9,
      },
      {
        abbreviation: 'E+',
        text: `${ENERGY_LABELS[2]}+`,
        threshold: 12,
      },
      {
        abbreviation: 'A',
        text: ENERGY_LABELS[3],
        threshold: 14,
      },
    ]
  }

  /**
   * CLone
   * @param {any} options - The var to clone
   */
  clone(options) {
    return Object.assign(Object.create(this), this, options)
  }

  getEnergyRangeLabel(score) {
    if (isNil(score)) {
      return {
        abbreviation: null,
        text: null,
      }
    }

    return scaleThreshold()
      .domain(this._energies.map(({ threshold }) => threshold).filter(threshold => threshold !== undefined))
      .range(this._energies)(score)
  }

  get accountId() {
    return this.account.id
  }

  get dynamics() {
    return this._dynamics
  }

  get energies() {
    const { assessmentScores } = this

    const _energies = assessmentScores.map(score => this.getEnergyRangeLabel(score))

    const energies = _energies.map(({ text }) => text)

    const isNull = energies.every(score => score === null)

    energies.isNull = isNull

    energies.toString = isNull ? () => 'This information is not available' : toString

    Object.defineProperty(energies, 'abbreviations', {
      get() {
        const abbreviations = _energies.map(({ abbreviation }) => abbreviation)

        abbreviations.toString = toString

        return abbreviations
      },
    })

    Object.defineProperty(energies, 'byWidth', {
      get() {
        let byWidth

        if (window.innerWidth < 480) {
          byWidth = _energies.map(({ abbreviation }) => abbreviation)
        } else {
          byWidth = _energies.map(({ text }) => text)
        }

        byWidth.toString = toString

        return byWidth
      },
    })

    return energies
  }

  get scores() {
    return zipWith(this.assessmentScores, this._dynamics, this.energies, (value, dynamic, energy) => ({
      dynamic,
      energy,
      value,
    }))
  }
}

export default Profile
