import { sprintf } from 'sprintf-js'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import { StyledButton } from 'generics/StyledFormComponents'
import Invite from 'classes/Invite'
import Translation from 'generics/Translation'

import { ACCEPTED } from 'constants/invitationStatus'

import styles from './Result.scss'

const Result = ({ invite, onChangeEmail, onCopyToken, onResend, ssoToken, isSSO, i18n }) => {
  const { createdAt, email, status } = invite
  const originalDate = createdAt ? moment.utc(createdAt) : moment.utc(moment.now())
  const now = moment.utc(moment.now())

  return (
    <>
      {
        // Don't allow to send more invitations if previous invitation is already accepted
        status !== ACCEPTED && (
          <div className={styles.result}>
            <p>
              <strong>{i18n.news.success.title}</strong>
            </p>
            <p className={styles.result__small}>{sprintf(i18n.news.success.message, now.to(originalDate), email)}</p>
            <StyledButton
              aria-label={`${i18n.news.success.resend} invitation`}
              className={styles.btn}
              color="primary"
              id="main"
              title={i18n.news.success.resend}
              onClick={onResend}
            >
              {i18n.news.success.resend}
            </StyledButton>
            <StyledButton
              aria-label={i18n.news.success.changeEmail}
              className={styles.btn}
              color="primary"
              title={i18n.news.success.changeEmail}
              onClick={onChangeEmail}
            >
              {i18n.news.success.changeEmail}
            </StyledButton>
          </div>
        )
      }
      {isSSO && (
        <div className={styles['token-info']}>
          <p>
            <strong>{i18n.news.ssoToken.title}</strong>
          </p>
          <p>{i18n.news.ssoToken.message}</p>
          <div className={styles['token-info__token']}>
            <label htmlFor="token">{i18n.news.ssoToken.label}</label>
            <input disabled type="text" id="token" name="token" value={ssoToken} />
            <StyledButton
              className={styles['token-info__copy']}
              id="main"
              onClick={onCopyToken}
              title={i18n.news.ssoToken.copy}
              variant="text"
            >
              {i18n.news.ssoToken.copy}
            </StyledButton>
          </div>
        </div>
      )}
    </>
  )
}

Result.propTypes = {
  i18n: I18N.isRequired,
  invite: PropTypes.instanceOf(Invite).isRequired,
  isSSO: PropTypes.bool,
  onChangeEmail: PropTypes.func.isRequired,
  onCopyToken: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  ssoToken: PropTypes.number,
}

Result.defaultProps = {
  isSSO: false,
  ssoToken: null,
}

export default Translation(Result, ['news'])
