import { pickHTMLProps } from 'pick-react-known-prop'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from '../Card/card.scss'

const Separator = props => {
  const { className, ...rest } = props

  return <div className={classnames(styles.card__separator, className)} {...pickHTMLProps(rest)} />
}

Separator.propTypes = {
  className: PropTypes.string,
}

Separator.defaultProps = {
  className: null,
}

export default Separator
