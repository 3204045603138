import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/training/constants'
import api from 'api'

// Actions

export const fetchVideosFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_VIDEOS_FAILURE,
})

export const fetchVideosRequest = () => ({
  type: actionTypes.FETCH_VIDEOS_REQUEST,
})

export const fetchVideosSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_VIDEOS_SUCCESS,
})

// Thunks

export const fetchVideos = (onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchVideosRequest())

    const handleResponse = response => {
      dispatch(fetchVideosSuccess(response))

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchVideosFailure(error))

      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.training.getAll(accountId, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_VIDEOS

  return thunk
}

export default fetchVideos
