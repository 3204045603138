import { BarError } from 'classes/errors'
import { DECLINED, EXPIRED } from 'constants/invitationStatus'
import { INVITES_RENEW_INVITE, INVITES_RESEND_INVITE } from 'constants/apiErrorCodes'
import { notifyError } from 'app_modules/notifications/actions'
import * as accountActions from 'app_modules/accounts/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/groups/constants'
import api from 'api'

// Actions

export const fetchResendInvitationFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_RESEND_INVITATION_FAILURE,
})

export const fetchResendInvitationRequest = () => ({
  type: actionTypes.FETCH_RESEND_INVITATION_REQUEST,
})

export const fetchResendInvitationSuccess = (invitationId, invite) => ({
  payload: {
    invitationId,
    invite,
  },
  type: actionTypes.FETCH_RESEND_INVITATION_SUCCESS,
})

export const clearGroup = () => ({
  type: actionTypes.CLEAR_GROUP,
})

// Thunks

export const fetchResendInvitation = ({ invitationId, invitationStatus }, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchResendInvitationRequest())

    const handleResponse = response => {
      dispatch(fetchResendInvitationSuccess(invitationId, response.invite))
      // it sends a new invitation and we need to update counters
      if (invitationStatus === DECLINED || invitationStatus === EXPIRED) {
        dispatch(accountActions.fetchAccount(accountId))
      }
      if (onSuccess) {
        return onSuccess(response)
      }
      return response
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchResendInvitationFailure(error))
      if (error.errorCode === INVITES_RENEW_INVITE) {
        dispatch(notifyError(new BarError({ ...error, label: 'licenseLimitExceeds' })))
      } else if (error.errorCode === INVITES_RESEND_INVITE) {
        dispatch(
          notifyError(
            new BarError({
              message: error?.details[0]?.errorMessage[0],
            }),
          ),
        )
      } else if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'fetchResendInvitation' })))
      }

      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.invites.resendInvitation(
      {
        accountId,
        invitationId,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_RESEND_INVITATION

  return thunk
}

export default fetchResendInvitation
