import { mapKeys, merge } from 'lodash'

import { hasScores } from 'helpers'
import Person from './Person'
import Profile, { toString } from './Profile'

export default class Team extends Profile {
  constructor(options) {
    super(options)

    const defaults = {
      _isTeam: true,
      _type: 'team',
      _logo: {
        url: null,
      },
      account: {
        accountType: null,
        id: null,
        isPersonal: false,
        logo: {
          url: null,
        },
        name: null,
        nameAbbreviation: null,
        remainingLicensesCount: null,
        siteUrl: null,
        totalTeamsQuantity: 0,
      },
      description: null,
      id: null,
      isManager: false,
      isPublic: false,
      isPublished: false,
      name: null,
      teamStatistics: {
        averageScores: [null, null, null, null],
        stdevScores: [null, null, null, null],
      },
      owner: {
        id: null,
        firstName: null,
        lastName: null,
        avatar: null,
        email: null,
        public: false,
        connected: false,
        jobTitle: null,
        mobilePhone: null,
        workPhone: null,
        otherPhone: null,
        address: null,
        country: {
          id: null,
          name: null,
          abbreviation: null,
          createdAt: null,
          updatedAt: null,
        },
        state: null,
        city: null,
        postalCode: null,
        genderPronounPreference: null,
        agreedToTermsOfService: true,
        division: null,
        permissions: null,
        createdAt: null,
      },
      teamMembers: [],
    }

    const { owner = {}, teamMembers = [], ...rest } = options || {}

    const optionsKeysMap = {
      logo: '_logo',
    }

    const mappedOptions = mapKeys(rest, (value, key) => (key in optionsKeysMap ? optionsKeysMap[key] : key))

    merge(
      this,
      defaults,
      rest,
      {
        owner: new Person(owner),
        teamMembers: teamMembers.map(profile => new Person(profile)),
      },
      mappedOptions,
    )
  }

  get type() {
    return this._type
  }

  get logo() {
    return this._logo.url
  }

  get assessmentScores() {
    const assessmentScores = this.teamStatistics.averageScores
    assessmentScores.toString = toString
    assessmentScores.isNull = !hasScores(assessmentScores)
    return assessmentScores
  }

  get stdevScores() {
    return this.teamStatistics.stdevScores
  }
}
