import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/training/constants'
import api from 'api'

// Actions

export const fetchCompleteVideoFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_COMPLETE_VIDEO_FAILURE,
})

export const fetchCompleteVideoRequest = () => ({
  type: actionTypes.FETCH_COMPLETE_VIDEO_REQUEST,
})

export const fetchCompleteVideoSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_COMPLETE_VIDEO_SUCCESS,
})

// Thunks

export const fetchCompleteVideo = ({ trainingName, videoName }, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchCompleteVideoRequest())

    const handleResponse = response => {
      dispatch(fetchCompleteVideoSuccess({ trainingName, videoName }))
      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchCompleteVideoFailure(error))
      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.training.completeVideo({ accountId, videoName }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_COMPLETE_VIDEO

  return thunk
}

export default fetchCompleteVideo
