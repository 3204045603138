/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import _ from 'lodash'

import getElementGeometry from './getElementGeometry'

const initGeometry = () => ({
  safety: {
    top: 0,
    right: 2,
    bottom: 0,
    left: 2,
  },

  bandSideRatios: {
    Reserved: 0.3,
    Deliberate: 0.57,
    Effortless: 0.79,
    Abundant: 0.99,
  },

  // central area
  offsetRatio: 0.02,
  dotRadiusOffsetRatio: 0.5,

  // quadrants labels
  labels: {
    dynamicNameFontSize: 12,
    energyNameFontSize: 13,
    energyNameLineHeight: 14,
    margin: {
      top: 6.5,
      bottom: 5.1,
    },
  },

  // legends
  legend: {
    fontSize: 12,
    marginBottomFromReference: 65,
    balloon: {
      margin: {
        right: 7.3,
      },
    },
  },

  // rounded corners
  cornerRadiusRatio: 0.35,

  // balloons
  plusRadiusRatio: 0.25,

  // quadrants
  quadrantsAngle: [
    90, // 1. Explore
    180, // 2. Excite
    270, // 3. Examine
    0, // 4. Execute
  ], // TODO calc this with aTan2 from quadrantsSigns?

  quadrantsSigns: [
    { x: -1, y: 1 }, // 1. Explore
    { x: -1, y: -1 }, // 2. Excite
    { x: 1, y: -1 }, // 3. Examine
    { x: 1, y: 1 }, // 4. Execute
  ],
})

const updateContainersGeometry = (elements, geometry) => {
  const { center, height, width } = geometry

  const { canvas, g_main, svg } = elements

  if (canvas) {
    canvas.attr('width', width).attr('height', height)
  }

  if (svg) {
    svg.attr('viewBox', `0 0 ${width} ${height}`)
  }

  if (g_main) {
    g_main.attr('transform', `translate(${center.x}, ${center.y})`)
  }

  return {
    ...elements,
    canvas,
    g_main,
    svg,
  }
}

const updateGeometry = (elements, geometrySettings, isPair) => {
  const geometry = { ...geometrySettings }
  const { svg } = elements

  // container
  geometry.container = getElementGeometry(svg?.node().parentNode, [
    'fontSize',
    'paddingBottom',
    'paddingLeft',
    'paddingRight',
    'paddingTop',
  ])

  // x
  geometry.width = geometry.container.width - geometry.container.paddingLeft - geometry.container.paddingRight

  geometry.widthHalf = 0.5 * geometry.width

  geometry.innerWidth = geometry.width - geometry.safety.left - geometry.safety.right

  geometry.innerWidthHalf = 0.5 * geometry.innerWidth

  const legendHeight = geometry.legend.marginBottomFromReference

  const labelsHeight = geometry.labels.dynamicNameFontSize + geometry.labels.energyNameLineHeight + 4

  let aspectRatio

  if (!isPair) {
    aspectRatio =
      (2 * labelsHeight +
        geometry.safety.top +
        geometry.labels.margin.top +
        geometry.innerWidth +
        geometry.labels.margin.bottom +
        geometry.safety.bottom) /
      geometry.innerWidth
  } else {
    aspectRatio =
      (labelsHeight +
        geometry.safety.top +
        geometry.labels.margin.top +
        geometry.innerWidth +
        geometry.labels.margin.bottom +
        legendHeight +
        geometry.safety.bottom) /
      geometry.innerWidth
  }

  // y
  geometry.height = aspectRatio * geometry.width

  geometry.heightHalf = 0.5 * geometry.height

  geometry.innerHeight = geometry.height - geometry.safety.top - geometry.safety.bottom

  geometry.innerHeightHalf = 0.5 * geometry.innerHeight

  // center
  if (!isPair) {
    geometry.center = {
      x: geometry.widthHalf,
      y: geometry.heightHalf - 4,
    }
  } else {
    geometry.center = {
      x: geometry.widthHalf,
      y: geometry.heightHalf - 2 - 0.5 * (legendHeight - labelsHeight),
    }
  }

  // side
  geometry.side = 0.5 * geometry.innerWidth

  // central area
  geometry.offset = geometry.offsetRatio * geometry.side
  geometry.dotRadius = geometry.dotRadiusOffsetRatio * geometry.offset

  // balloons
  geometry.maxBalloonSide = geometry.side - geometry.offset

  // legend
  geometry.legendBalloonRadius = geometry.legend.fontSize / (1 + Math.SQRT1_2)

  // bands
  geometry.bandHalfSide = _.mapValues(
    geometry.bandSideRatios,
    ratio => geometry.offset + ratio * geometry.maxBalloonSide,
  )

  return geometry
}

export const initGraph = (isPair, elements, geometryObj) => {
  let geometrySettings
  if (!geometryObj) {
    geometrySettings = initGeometry()
  } else {
    geometrySettings = geometryObj
  }
  const geometry = updateGeometry(elements, geometrySettings, isPair)
  let newElements
  if (!Number.isNaN(geometry?.width) && !Number.isNaN(geometry?.height)) {
    newElements = updateContainersGeometry(elements, geometry)
  }

  return {
    geometry,
    elements: newElements,
  }
}
