/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React from 'react'

import { ACTIONS } from 'constants/props'
import Modal from 'generics/Modal'
import Translation from 'generics/Translation'

import FormToggleRenewalEmailNotifications from '../FormToggleRenewalEmailNotifications/FormToggleRenewalEmailNotifications'
import styles from './ModalToggleRenewalEmailNotifications.scss'

const ModalToggleRenewalEmailNotifications = ({
  accountActions,
  hasSubscriptionNotifications,
  isFetching,
  onClose,
  onFinish,
  title,
  renewalDate,
}) => {
  const handleSubmit = subscriptionNotifications => {
    if (hasSubscriptionNotifications === subscriptionNotifications) {
      onFinish()
      return
    }

    accountActions.editAccount({ subscriptionNotifications }, onFinish)
  }

  return (
    <Modal
      card={{
        barClassName: styles.bar,
        isFetching,
      }}
      modalCardClassName={styles.modal}
      onClose={onClose}
      switchModalView={false}
      title={title}
    >
      <FormToggleRenewalEmailNotifications
        hasSubscriptionNotifications={hasSubscriptionNotifications}
        onClose={onClose}
        onSubmit={handleSubmit}
        renewalDate={renewalDate}
      />
    </Modal>
  )
}

ModalToggleRenewalEmailNotifications.propTypes = {
  accountActions: ACTIONS.isRequired,
  hasSubscriptionNotifications: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  renewalDate: PropTypes.string.isRequired,
}

export default Translation(ModalToggleRenewalEmailNotifications, ['subscriptions'])
