import { Elements } from '@stripe/react-stripe-js'
import propTypes from 'prop-types'
import React from 'react'

import { ACTIONS, I18N } from 'constants/props'
import { loadStripePlugin } from 'helpers'

import CheckoutFormWrapper from './components/CheckoutFormWrapper/CheckoutFormWrapper'

const stripePromise = loadStripePlugin()

const Payment = ({
  accountId,
  amount,
  i18n,
  notificationActions,
  onFinish,
  onFetchNetworkDetails,
  orderQuantity,
  profileId,
  stripeEmail,
}) => (
  <Elements stripe={stripePromise}>
    <CheckoutFormWrapper
      accountId={accountId}
      amount={amount}
      i18n={i18n}
      notificationActions={notificationActions}
      onFinish={onFinish}
      onFetchNetworkDetails={onFetchNetworkDetails}
      orderQuantity={orderQuantity}
      profileId={profileId}
      stripeEmail={stripeEmail}
    />
  </Elements>
)

Payment.propTypes = {
  accountId: propTypes.number.isRequired,
  amount: propTypes.number,
  i18n: I18N.isRequired,
  notificationActions: ACTIONS.isRequired,
  onFinish: propTypes.func,
  onFetchNetworkDetails: propTypes.func.isRequired,
  orderQuantity: propTypes.string,
  profileId: propTypes.number.isRequired,
  stripeEmail: propTypes.string,
}

Payment.defaultProps = {
  amount: 0,
  onFinish: null,
  orderQuantity: '',
  stripeEmail: '',
}

export default Payment
