/* eslint-disable react-hooks/exhaustive-deps */
import { get, isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import { ACTIONS, I18N, LIST, PERSON, TEAM } from 'constants/props'
import { Card, Header } from 'generics/Card'
import { PRIVATE_TEAMS } from 'constants/teamSections'
import { StyledButton } from 'generics/StyledFormComponents'
import ActionButton from 'generics/ActionButton'
import InfiniteScrollList from 'generics/InfiniteScrollList'
import ModalConfirmation from 'generics/ModalConfirmation'
import ModalCreateTeam from 'generics/ModalCreateTeam'
import SearchBar from 'generics/SearchBar'
import Translation from 'generics/Translation'

import styles from './TeamList.scss'
import TeamListItem from '../TeamListItem'

const TeamList = ({ accountId, actions, className, i18n, onFetch, profile, teams, sectionId, sharedIcon }) => {
  const [isCreateTeamModalOpen, setIsCreateTeamModalOpen] = useState(false)
  const [isDeleteTeamModalOpen, setIsDeleteTeamModalOpen] = useState(false)
  const [oldSectionId, setOldSectionId] = useState(null)
  const [pageIndex, setPageIndex] = useState(1)
  const [search, setSearch] = useState(null)
  const [selectedTeam, setSelectedTeam] = useState(null)

  let searchRef = useRef(null)
  let infiniteScrollList = useRef(null)

  const resetInfiniteScroll = () => {
    if (get(infiniteScrollList, 'infiniteScrollRef')) {
      infiniteScrollList.infiniteScrollRef.resetIndex()
    }
  }

  const handleFetchTeams = (newPageIndex = 1) => onFetch(newPageIndex, search)

  useEffect(() => {
    if (sectionId !== oldSectionId) {
      if (search) {
        searchRef.reset()
      } else {
        resetInfiniteScroll()
        handleFetchTeams()
      }
      setOldSectionId(sectionId)
    }
  }, [sectionId])

  const handleCreateNewTeam = () => {
    setIsCreateTeamModalOpen(true)
  }

  const handleDeleteTeam = teamId => {
    setIsDeleteTeamModalOpen(true)
    setPageIndex(1)
    setSelectedTeam(teamId)
  }

  const handleDeleteTeamCancel = () => {
    setIsDeleteTeamModalOpen(false)
    setSelectedTeam(undefined)
  }

  const handleDeleteTeamAccept = () => {
    const onSuccess = () => {
      resetInfiniteScroll()
      handleFetchTeams()
    }

    actions.fetchDeleteTeam(selectedTeam, onSuccess)

    handleDeleteTeamCancel()
  }

  const handleFinishCreateTeam = () => {
    if (sectionId === PRIVATE_TEAMS) {
      resetInfiniteScroll()
      handleFetchTeams()
    }
  }

  const handleCloseTeamModal = () => {
    setIsCreateTeamModalOpen(false)
  }

  const handleSearch = filterInput => {
    if (isEqual(search, filterInput)) {
      return
    }

    resetInfiniteScroll()

    setSearch(filterInput)
  }

  useEffect(() => {
    handleFetchTeams()
  }, [search])

  const renderList = () => {
    const { list: teamList } = teams

    if (teamList.length === 0) {
      return (
        <div className={styles.empty}>
          <span>{i18n.generics.list.noResults}</span>
        </div>
      )
    }

    return teamList.map(team => (
      <TeamListItem
        accountId={accountId}
        actions={[
          team.owner.id === profile.id && (
            <ActionButton
              actionType="decline"
              className={styles.decline}
              desktop={{
                props: {
                  label: i18n.pageTeams.teamList.btnDeleteTeam,
                  title: i18n.pageTeams.teamList.btnDeleteTeam,
                },
              }}
              key="decline"
              onClick={() => {
                handleDeleteTeam(team.id)
              }}
            />
          ),
        ]}
        key={team.id}
        profile={profile}
        sharedIcon={sharedIcon}
        team={team}
      />
    ))
  }

  const { isFetching, meta } = teams

  const { morePages } = meta

  const infiniteScroll = {
    hasMorePages: morePages,
    listHeight: 600,
    onFetch: handleFetchTeams,
  }

  const newActions =
    sectionId === 'private' || sectionId === 'published'
      ? [
          <StyledButton
            disabled={isCreateTeamModalOpen}
            id="team-list-new-team-btn"
            onClick={handleCreateNewTeam}
            color="primary"
            title={i18n.pageTeams.teamList.btnCreateTeam}
          >
            {i18n.pageTeams.teamList.btnCreateTeam}
          </StyledButton>,
        ]
      : []

  return (
    <Card barClassName={styles.bar} className={className} isFetching={pageIndex === 1 && isFetching}>
      <Header title={i18n.pageTeams.sections[sectionId].title} actions={newActions} />

      <p className={styles.description}>{i18n.pageTeams.sections[sectionId].description}</p>

      <SearchBar
        className={styles.search}
        floatingLabelText={i18n.pageTeams.teamList.lblSearch}
        onChange={handleSearch}
        onSearch={handleSearch}
        innerRef={component => {
          searchRef = component
        }}
        isTeamSearch
      />

      <InfiniteScrollList
        infiniteScroll={infiniteScroll}
        listId="team-list-scroll-list"
        innerRef={component => {
          infiniteScrollList = component
        }}
      >
        {renderList()}
      </InfiniteScrollList>

      {isCreateTeamModalOpen && (
        <ModalCreateTeam barClassName={styles.bar} onClose={handleCloseTeamModal} onSuccess={handleFinishCreateTeam} />
      )}
      {isDeleteTeamModalOpen && (
        <ModalConfirmation
          confirmationMessages={[i18n.pageTeams.teamList.deleteConfirmationMessage]}
          barClassName={styles.bar}
          onAccept={handleDeleteTeamAccept}
          onCancel={handleDeleteTeamCancel}
        />
      )}
    </Card>
  )
}

TeamList.propTypes = {
  accountId: PropTypes.number.isRequired,
  actions: ACTIONS.isRequired,
  className: PropTypes.string,
  i18n: I18N.isRequired,
  onFetch: PropTypes.func.isRequired,
  profile: PERSON.isRequired,
  sectionId: PropTypes.string.isRequired,
  sharedIcon: PropTypes.string.isRequired,
  teams: LIST(TEAM).isRequired,
}

TeamList.defaultProps = {
  className: null,
}

export default Translation(TeamList, ['pageTeams', 'generics'])
