import PropTypes from 'prop-types'
import React from 'react'

import { PROFILE, I18N } from 'props'
import Translation from 'generics/Translation'

const { SHOW_TESTING_SCORES } = process.env

/**
 * TestingScores Component
 */
const TestingScores = props => {
  const { className, i18n, profile } = props

  if (!profile || !SHOW_TESTING_SCORES) {
    return null
  }

  const { assessmentScores, energies } = profile

  const scores = assessmentScores.toStringWithoutValidate
    ? assessmentScores.toStringWithoutValidate()
    : assessmentScores.toString()

  return (
    <span className={className} title={i18n.generics.testingOnly}>
      {`${scores} ${energies.abbreviations.toString(true)}`}
    </span>
  )
}

TestingScores.propTypes = {
  className: PropTypes.string,
  i18n: I18N.isRequired,
  profile: PROFILE,
}

TestingScores.defaultProps = {
  className: null,
  profile: null,
}

export default Translation(TestingScores, ['generics'])
