import { merge } from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import shortid from 'shortid'

import { ACTION_BUTTON } from 'constants/props'
import { StyledButton } from 'generics/StyledFormComponents'
import Icon from 'generics/Icon'

import styles from './ActionButton.scss'

const HIDEN_SM_DOWN = styles['hidden-sm-down']
const DECLINE_CLASSES = classnames(styles['hidden-sm-down'], styles['button--decline'])

const desktopButtonConfiguration = {
  default: {
    component: StyledButton,
    props: {
      className: HIDEN_SM_DOWN,
      color: 'secondary',
      label: '',
      variant: 'text',
    },
  },

  accept: {
    component: StyledButton,
    props: {
      color: 'primary',
      className: HIDEN_SM_DOWN,
      label: 'ACCEPT',
      title: 'Accept',
      variant: 'contained',
    },
  },

  decline: {
    component: StyledButton,
    props: {
      className: DECLINE_CLASSES,
      label: 'Decline',
      title: 'Decline',
    },
  },
}

const mobileButtonConfiguration = {
  default: {
    component: StyledButton,
    props: {
      children: <span />,
      className: styles['hidden-md-up'],
      color: 'secondary',
      variant: 'text',
    },
  },

  accept: {
    props: {
      children: <Icon.Stroke7 className={classnames(styles.button__icon, styles['button--accept'])} name="check" />,
      className: styles['hidden-md-up'],
      color: 'primary',
      title: 'Accept',
      variant: 'text',
    },
  },

  decline: {
    props: {
      children: (
        <Icon.Stroke7 className={classnames(styles.button__icon, styles['button--decline'])} name="close-circle" />
      ),
      className: classnames(styles['hidden-md-up'], styles['button--decline']),
      title: 'Decline',
    },
  },
}

const ActionButton = props => {
  const { actionType, ariaLabel, className, desktop, isSingleAction, isVisible, mobile, onClick, id } = props

  const desktopButton = merge({}, desktopButtonConfiguration.default, desktopButtonConfiguration[actionType], desktop, {
    props: { id: `desktop-${id}` },
  })

  const mobileButton = merge({}, mobileButtonConfiguration.default, mobileButtonConfiguration[actionType], mobile, {
    props: { id: `mobile-${id}` },
  })

  const buttons = [mobileButton, desktopButton]

  return (
    <div
      className={classnames(
        styles.buttons,
        { [styles['buttons--is-not-visible']]: !isVisible },
        { [styles['buttons--single-button']]: isSingleAction },
      )}
    >
      {buttons.map(({ component: Component, props: buttonProps }) => {
        const { children, className: buttonClassName, id: buttonId, label, title, ...rest } = buttonProps

        return (
          <Component
            aria-label={ariaLabel}
            className={classnames(styles['action-button'], buttonClassName, className)}
            id={buttonId}
            key={buttonId}
            onClick={() => {
              onClick()
            }}
            title={title}
            {...rest}
          >
            {label}
            {children}
          </Component>
        )
      })}
    </div>
  )
}

ActionButton.propTypes = {
  actionType: PropTypes.oneOf(['accept', 'decline']).isRequired,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  desktop: ACTION_BUTTON,
  isSingleAction: PropTypes.bool,
  isVisible: PropTypes.bool,
  mobile: ACTION_BUTTON,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string,
}

ActionButton.defaultProps = {
  ariaLabel: '',
  className: null,
  desktop: {},
  isSingleAction: false,
  isVisible: true,
  mobile: {},
  id: shortid.generate(),
}

export default ActionButton
