import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N, PROFILE } from 'constants/props'
import Translation from 'generics/Translation'

import styles from './TeamGridRow.scss'
import TeamGridBalloon from '../TeamGridBalloon'

const TeamGridRow = ({ className, i18n, onClick, profile, selected }) => {
  const hasEvents = () => {
    const { _isPerson, isVisible } = profile

    return _isPerson ? isVisible && onClick && !selected : onClick && !selected
  }

  const handleClick = () => {
    if (!hasEvents()) {
      return
    }
    onClick(profile)
  }

  const handleKeyPress = event => {
    if (!hasEvents()) {
      return
    }

    switch (event.key) {
      case 'Enter': {
        onClick(profile)

        break
      }

      default:
    }
  }

  const { _isPerson, _isTeam, energies, firstName, lastName, name, scores } = profile

  const rowTitle = `${name}: ${energies.toString()}`

  const hasEventsResult = hasEvents()

  return (
    <div
      className={classnames(
        styles.row,
        { [styles['is-selectable']]: hasEventsResult },
        { [styles['is-selected']]: selected },
        className,
      )}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      role="button"
      tabIndex={hasEvents ? '0' : ''}
      title={rowTitle}
    >
      {_isTeam && (
        <div className={classnames(styles.cell, styles['cell--name'])}>
          <span title={i18n.pageTeamDashboard.teamEnergy.teamGrid.teamAverage}>
            {i18n.pageTeamDashboard.teamEnergy.teamGrid.teamAverage}
          </span>
        </div>
      )}

      {_isPerson && (
        <div className={classnames(styles.cell, styles['cell--name'])} title={name}>
          <span className={styles['cell__first-name']}>{firstName}</span>
          <span className={styles['cell__last-name']}>{lastName}</span>
        </div>
      )}

      <div className={styles['cell--balloons']}>
        {scores.map(({ dynamic, energy, value }, index) => (
          <div
            className={classnames(styles.cell, styles['cell--balloon'], styles[`cell--balloon-${index}`])}
            key={dynamic}
          >
            <TeamGridBalloon
              energy={energy}
              score={value}
              shape={{ className: styles[`row__balloon-${index}`] }}
              testAttribute="team-grid-row-cell-balloon"
            />
          </div>
        ))}
      </div>
    </div>
  )
}

TeamGridRow.propTypes = {
  className: PropTypes.string,
  i18n: I18N.isRequired,
  onClick: PropTypes.func,
  profile: PROFILE.isRequired,
  selected: PropTypes.bool,
}

TeamGridRow.defaultProps = {
  className: null,
  onClick: undefined,
  selected: false,
}

export default Translation(TeamGridRow, ['pageTeamDashboard'])
