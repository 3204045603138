import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/profile/constants'
import api from 'api'

// Actions

export const deleteProfileFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_DELETE_PROFILE_FAILURE,
})

export const deleteProfileRequest = () => ({
  type: actionTypes.FETCH_DELETE_PROFILE_REQUEST,
})

export const deleteProfileSuccess = response => ({
  payload: response.profile,
  type: actionTypes.FETCH_DELETE_PROFILE_SUCCESS,
})

// Thunks

export const fetchDeleteProfile = (profileId, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(deleteProfileRequest())

    const handleResponse = response => {
      dispatch(deleteProfileSuccess(response))

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(deleteProfileFailure(error))

      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.profiles.deleteProfile(
      {
        accountId,
        profileId,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_DELETE_PROFILE

  return thunk
}

export default fetchDeleteProfile
