import { formatRoute } from 'react-router-named-routes'
import { fromCallbacksToPromise } from 'helpers'
import { replace } from 'connected-react-router'

import { LOGIN } from 'urls'
import { resetState } from 'app_modules/session/actions/resetState'
import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import sessionActionTypes from 'app_modules/session/constants'
// Actions

export const fetchLogoutFailure = error => ({
  payload: error,
  type: sessionActionTypes.FETCH_LOGOUT_FAILURE,
})

export const fetchLogoutRequest = () => ({
  type: sessionActionTypes.FETCH_LOGOUT_REQUEST,
})

export const fetchLogoutSuccess = () => ({
  type: sessionActionTypes.FETCH_LOGOUT_SUCCESS,
})

// Thunks

export const logout = (isLoginIgnored = false) => {
  const thunk = (dispatch, getState) => {
    const state = getState()
    const accountId = sessionSelectors.accountId(state)
    const isAuthenticated = sessionSelectors.isAuthenticated(state)

    let promises = [dispatch(fetchLogoutRequest()), dispatch(fetchLogoutSuccess())]

    if (isAuthenticated && accountId) {
      promises = [
        ...promises,
        fromCallbacksToPromise(api.session.logout, accountId).catch(({ error }) => {
          dispatch(fetchLogoutFailure(error))
        }),
        dispatch(resetState()),
      ]
    } else {
      promises = [...promises, dispatch(resetState())]

      if (!isLoginIgnored) {
        promises = [...promises, dispatch(replace(formatRoute(LOGIN)))]
      }
    }

    return Promise.all(promises)
  }

  thunk.type = sessionActionTypes.FETCH_LOGOUT

  return thunk
}

export default logout
