/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core'
import DocumentTitle from 'react-document-title'
import React, { useEffect, useState } from 'react'

import { ACTIONS, MATCH } from 'constants/props'
import Mask from 'generics/Mask'
import styles from './PageImpersonate.scss'

const PageImpersonate = ({ match, sessionActions }) => {
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    const { token } = match.params

    sessionActions
      .impersonate(token)
      .then(() => {
        setIsFetching(false)
      })
      .catch(() => {
        setIsFetching(false)
      })

    setIsFetching(true)
  }, [])

  return (
    <DocumentTitle title="Simpli5® - Impersonate Page">
      <Mask className={styles.mask} open={isFetching}>
        <CircularProgress className={styles.loader} size={60} thickness={7} />
      </Mask>
    </DocumentTitle>
  )
}

PageImpersonate.propTypes = {
  match: MATCH.isRequired,
  sessionActions: ACTIONS.isRequired,
}

export default PageImpersonate
