/* eslint-disable react-hooks/exhaustive-deps */
import { formatRoute } from 'react-router-named-routes'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import DocumentTitle from 'react-document-title'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { ACTIONS, HISTORY, I18N } from 'constants/props'
import { Card, Header, Main } from 'generics/Card'
import { LOGIN_FORGOT_PASSWORD, LOGIN_SSO_PROVIDERS, MY_5_DYNAMICS, WELCOME } from 'urls'
import FormLogin from 'components/FormLogin'
import Translation from 'generics/Translation'

import styles from './PageLogin.scss'

const PageLogin = ({ accountId, history, i18n, isAssessmentCompleted, isAuthenticated, sessionActions }) => {
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (isAuthenticated) {
      if (isAssessmentCompleted) {
        history.replace(formatRoute(MY_5_DYNAMICS, { accountId }))
      } else {
        history.replace(formatRoute(WELCOME, { accountId }))
      }
    }
  }, [])

  const handleSubmit = credentials => {
    setIsFetching(true)

    sessionActions.login(credentials, () => setIsFetching(false))
  }

  return (
    <DocumentTitle title="Simpli5® - Login Page">
      <section className={styles.page}>
        <Card center isFetching={isFetching}>
          <Header className={styles.header} title={i18n.login.title} subtitle={i18n.login.subtitle} />
          <Main className={styles.main}>
            <FormLogin className={styles.form} onSubmit={handleSubmit} />
            <Link
              className={styles.link}
              href={LOGIN_FORGOT_PASSWORD}
              title={i18n.login.link.forgot}
              to={LOGIN_FORGOT_PASSWORD}
            >
              {i18n.login.link.forgot}
            </Link>
            <Link
              className={classnames(styles.link, styles.sso)}
              href={LOGIN_SSO_PROVIDERS}
              title={i18n.login.link.signInWithSSO}
              to={LOGIN_SSO_PROVIDERS}
            >
              {i18n.login.link.signInWithSSO}
            </Link>
          </Main>
        </Card>
      </section>
    </DocumentTitle>
  )
}

PageLogin.propTypes = {
  accountId: PropTypes.number,
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  isAssessmentCompleted: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  sessionActions: ACTIONS.isRequired,
}

PageLogin.defaultProps = {
  accountId: null,
}

export default Translation(PageLogin, ['login'])
