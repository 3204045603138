/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap'
import { isEmpty, reject, size, some, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import { ACTIONS, I18N, WORKSHOP, WORKSHOP_MEMBERS } from 'constants/props'
import { Card, Footer, Header, Main } from 'generics/Card'
import { COLORS } from 'constants/colors'
import { StyledButton } from 'generics/StyledFormComponents'
import Icon from 'generics/Icon'
import InfiniteScrollList from 'generics/InfiniteScrollList'
import Translation from 'generics/Translation'

import BadgeResults from '../BadgeResults'
import FormWorkshop from '../FormWorkshop'
import ModalErrors from '../ModalErrors'
import PersonForm from '../PersonForm'
import SelectedRecords from '../SelectedRecords'
import styles from './ModalWorkshop.scss'

const STATUS = {
  ERROR: 'error',
  INITIAL: 'initial',
  SUCCESS: 'success',
}

const DATE_FORMAT = 'MM/DD/YYYY'

const ModalWorkshop = ({
  hasMoreMembers,
  i18n,
  members,
  membersPageIndex,
  onClose,
  onComplete,
  onFetchMembers,
  pageActions,
  workshop,
  workshopActions,
}) => {
  const [editedMembers, setEditedMembers] = useState([])
  const [date, setDate] = useState(new Date(workshop.establishedAt))
  const [deletedMembers, setDeletedMembers] = useState([])
  const [failedMembersList, setFailedMembersList] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [membersList, setMembersList] = useState(members)
  const [messages, setMessages] = useState(null)
  const [name, setName] = useState(workshop.name)
  const [newMembers, setNewMembers] = useState([])
  const [prevPageIndex, setPrevPageIndex] = useState(null)
  const [removedMembersList, setRemovedMembersList] = useState(null)
  const [status, setStatus] = useState(STATUS.INITIAL)
  const [successMembersList, setSuccessMembersList] = useState(null)

  let infiniteScrollList = useRef(null)

  useEffect(() => {
    if (!isEmpty(members) && !isEmpty(membersList)) {
      const allMembers = uniqBy([...membersList, ...members], 'id')

      setMembersList(reject(allMembers, ({ id }) => deletedMembers.includes(id)))
    }
  }, [members])

  useEffect(() => {
    pageActions.switchModalView()

    return () => {
      pageActions.switchModalView()
    }
  }, [])

  useEffect(() => {
    if (membersPageIndex !== prevPageIndex) {
      if (infiniteScrollList?.infiniteScrollRef?.resetIndex) {
        infiniteScrollList.infiniteScrollRef.resetIndex()
      }
      setPrevPageIndex(membersPageIndex)
    }
  }, [membersPageIndex])

  /**
   * @description Checks if the user is using it's own email
   * or if the email already exist in the list
   */

  const checkDuplicatedEmail = ({ email }) => {
    const lowerCaseEmail = email.toLowerCase()

    if (some(membersList, ({ email: listEmail }) => listEmail.toLowerCase() === lowerCaseEmail)) {
      return {
        email: i18n.generics.errors.email,
      }
    }
    return {}
  }

  const handleChangeFields = ({ date: newDate, name: newName }, newIsValid) => {
    setName(newName)
    setDate(newDate)
    setIsValid(newIsValid)
  }

  /**
   * @description Checks if the user added or deleted members
   * @returns {bool} True: members were updated | false: Members didn't change
   */

  // const hasChangedMembers = () => size(newMembers) > 0 || size(deletedMembers) > 0

  /**
   * @description closes the modal
   */

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  /**
   * @description changes the component state according the members sent
   */

  const successResults = (newSuccessMembersList = [], newFailedMembersList = [], newRemovedMembersList = []) => {
    setFailedMembersList(newFailedMembersList)
    setIsFetching(false)
    setRemovedMembersList(newRemovedMembersList)
    setStatus(STATUS.success)
    setSuccessMembersList(newSuccessMembersList)
  }

  /**
   * @description Includes all update Workshop actions
   * (update workshop, updates workshop members, updates members emails)
   */

  const handleUpdateWorkshop = () => {
    setIsFetching(true)

    const onFail = error => {
      let newMessages = []

      if (size(error.message) > 0) {
        newMessages = [error.message[0]]
      } else if (error.label) {
        newMessages = [i18n.notifications[error.label]]
      } else if (size(error.details) > 0) {
        newMessages = [
          i18n.workshops.createWorkshop.fieldErrors,
          ...error.details.map(({ field, errorMessage }) => `${field}: ${errorMessage}`),
        ]
      }

      if (isEmpty(newMessages)) {
        handleClose()
      } else {
        setIsFetching(false)
        setMessages(newMessages)
        setStatus(STATUS.ERROR)
      }
    }

    const onSuccess = ({ meta }) => {
      const { failedMembers = [], removedMembers = [], successfulMembers = [] } = meta

      if (!isEmpty(failedMembers) || !isEmpty(removedMembers) || !isEmpty(successfulMembers)) {
        successResults(successfulMembers, failedMembers, removedMembers)
      } else {
        handleClose()
      }

      if (onComplete) {
        onComplete()
      }

      setIsFetching(false)
    }

    workshopActions.fetchUpdateWorkshop(
      { establishedDate: date, name, members: newMembers, removeMembers: deletedMembers },
      onSuccess,
      onFail,
    )
  }

  /**
   * @description changes the state when a member is added
   */

  const handleAdd = member => {
    Object.assign(member, { workshopId: workshop.id })

    setMembersList([...membersList, member])
    setNewMembers([...newMembers, member])
  }

  /**
   * @description changes the state when removing a member
   */

  const handleRemoveMember = memberId => {
    const oldDeletedMembers = [...deletedMembers]
    const oldMemberList = [...membersList]

    const newMembersList = reject(oldMemberList, { id: memberId })
    const newDeletedMembers = [...oldDeletedMembers, memberId]

    if (some(newMembers, { id: memberId })) {
      setMembersList(newMembersList)
      setNewMembers(reject(newMembers, { id: memberId }))
    }

    if (some(editedMembers, { id: memberId })) {
      setMembersList(newMembersList)
      setDeletedMembers(newDeletedMembers)
      setEditedMembers(reject(editedMembers, { id: memberId }))
    }

    setDeletedMembers(newDeletedMembers)
    setMembersList(newMembersList)
  }

  /**
   * @description renders the cancel button
   */

  const renderCancelButton = statusParam => {
    if (statusParam !== STATUS.INITIAL) {
      return null
    }

    return (
      <StyledButton
        className={styles.cancel}
        color="default"
        onClick={handleClose}
        title={i18n.generics.cancelLabel}
        variant="text"
      >
        {i18n.generics.cancelLabel}
      </StyledButton>
    )
  }

  /**
   * @description renders the done button
   */

  const renderDoneButton = () => {
    let isDisabled
    let onClick

    if (status === STATUS.INITIAL) {
      isDisabled = !isValid
      onClick = handleUpdateWorkshop
    } else {
      isDisabled = false
      onClick = handleClose
    }

    return (
      <StyledButton
        className={styles['done-button']}
        disabled={isDisabled}
        onClick={onClick}
        color="primary"
        title={i18n.generics.doneLabel}
      >
        {i18n.generics.doneLabel}
      </StyledButton>
    )
  }

  /**
   * @description renders the title
   */
  const renderTitle = () => {
    switch (status) {
      case STATUS.ERROR:
        return i18n.generics.error
      case STATUS.SUCCESS:
        return isEmpty(successMembersList) && isEmpty(removedMembersList) ? i18n.generics.error : i18n.generics.success
      default:
        return i18n.workshops.createWorkshop.editLabel
    }
  }

  const membersInfiniteScroll = {
    hasMorePages: hasMoreMembers,
    hideFullLink: true,
    listHeight: 600,
    onFetch: onFetchMembers,
    pageStart: membersPageIndex + 1,
  }

  /**
   * @description main render
   */

  return (
    <div className={styles.modal}>
      <Card barBackgroundColor={COLORS.primaryBlue.rgba} className={styles.card} isLoading={isFetching}>
        <Header className={styles.header}>
          <h1>{renderTitle()}</h1>
        </Header>
        <Icon.Stroke7 className={styles.close} name="close" onClick={handleClose} title={i18n.generics.closeLabel} />
        <Main className={styles.main}>
          {status === STATUS.SUCCESS && (
            <BadgeResults
              failed={failedMembersList}
              failedTitle={i18n.workshops.createWorkshop.resultMessage.failed}
              removed={removedMembersList}
              removedTitle={i18n.workshops.createWorkshop.resultMessage.removed}
              success={successMembersList}
              successTitle={i18n.workshops.createWorkshop.resultMessage.success}
            />
          )}
          {status === STATUS.INITIAL && (
            <Container className={styles.informationFileReader}>
              <FormWorkshop
                date={moment(date).format(DATE_FORMAT)}
                message={i18n.workshops.editWorkshop.formDescription}
                name={name}
                onChange={handleChangeFields}
              />
              <PersonForm
                message={i18n.workshops.editWorkshop.enterMemberMessage}
                onSubmit={handleAdd}
                onValidate={checkDuplicatedEmail}
                resetOnSubmit
              />
              {!isEmpty(membersList) && (
                <InfiniteScrollList
                  className={styles['scroll-list-main']}
                  infiniteScroll={membersInfiniteScroll}
                  listId="workshop-members-scroll-list"
                  innerRef={component => {
                    infiniteScrollList = component
                  }}
                >
                  <SelectedRecords list={membersList} onRemove={handleRemoveMember} />
                </InfiniteScrollList>
              )}
            </Container>
          )}
          {status === STATUS.ERROR && <ModalErrors errors={messages} />}
        </Main>

        <Footer>
          <Row>
            <Col xs="6">{renderCancelButton(status)}</Col>
            <Col xs="6">{renderDoneButton(status)}</Col>
          </Row>
        </Footer>
      </Card>
    </div>
  )
}

ModalWorkshop.propTypes = {
  hasMoreMembers: PropTypes.bool,
  i18n: I18N.isRequired,
  members: WORKSHOP_MEMBERS,
  membersPageIndex: PropTypes.number,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  onFetchMembers: PropTypes.func.isRequired,
  pageActions: ACTIONS.isRequired,
  workshop: WORKSHOP,
  workshopActions: ACTIONS.isRequired,
}

ModalWorkshop.defaultProps = {
  hasMoreMembers: false,
  members: [],
  membersPageIndex: 1,
  onClose: undefined,
  onComplete: undefined,
  workshop: undefined,
}

export default Translation(ModalWorkshop, ['generics', 'workshops'])
