/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'

import { ACTIONS, CUSTOMIZATIONS, I18N } from 'constants/props'
import { Card } from 'generics/Card'
import { OVERVIEW_REPORT_MODULE, VIDEO_MODULE, VIDEO_MODULE_SECOND } from 'constants/customizationModules'
import Translation from 'generics/Translation'

import CustomizationsList from '../CustomizationsList'
import styles from './CustomizationsCard.scss'
import FormEditOverviewReportCustomization from '../FormEditOverviewReportCustomization'
import FormEditVideoCustomization from '../FormEditVideoCustomization'
import FormEditVideoSecondCustomization from '../FormEditVideoSecondCustomization'
import ModalEditCustomization from '../ModalEditCustomization'

const CustomizationsCard = ({ accountActions, customizations, i18n }) => {
  const [customizationsList, setCustomizationsList] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [selectedCustomizationKey, setSelectedCustomizationKey] = useState(null)

  const handleManageCustomization = key => {
    setSelectedCustomizationKey(key)
  }

  const closeManageCustomization = () => {
    setSelectedCustomizationKey(null)
  }

  const handleSubmit = values => {
    setIsFetching(true)
    const onFinish = () => {
      setSelectedCustomizationKey(null)
      setIsFetching(false)
    }

    const newValues = {
      ...customizations[OVERVIEW_REPORT_MODULE],
      ...customizations[VIDEO_MODULE],
      ...values,
    }

    accountActions.editCustomizations(newValues, onFinish)
  }

  const orderCustomizationsList = () =>
    i18n.customizations.customizationsList.map(({ key, title }) => ({
      key,
      title,
      ...customizations[key],
    }))

  useEffect(() => {
    if (customizations) {
      setCustomizationsList(orderCustomizationsList())
    }
  }, [customizations])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Card barClassName={styles.bar} isFetching={isFetching}>
        <CustomizationsList
          customizations={customizationsList}
          onManageCustomization={key => handleManageCustomization(key)}
        />
      </Card>
      {selectedCustomizationKey && (
        <ModalEditCustomization
          isFetching={isFetching}
          onClose={() => closeManageCustomization()}
          title={i18n.customizations[selectedCustomizationKey].title}
        >
          {selectedCustomizationKey === OVERVIEW_REPORT_MODULE && (
            <FormEditOverviewReportCustomization
              initialValues={{ ...customizations[OVERVIEW_REPORT_MODULE] }}
              onClose={() => closeManageCustomization()}
              onSubmit={handleSubmit}
            />
          )}
          {selectedCustomizationKey === VIDEO_MODULE && (
            <FormEditVideoCustomization
              initialValues={{ ...customizations[VIDEO_MODULE] }}
              onClose={() => closeManageCustomization()}
              onSubmit={handleSubmit}
            />
          )}
          {selectedCustomizationKey === VIDEO_MODULE_SECOND && (
            <FormEditVideoSecondCustomization
              initialValues={{ ...customizations[VIDEO_MODULE_SECOND] }}
              onClose={() => closeManageCustomization()}
              onSubmit={handleSubmit}
            />
          )}
        </ModalEditCustomization>
      )}
    </>
  )
}

CustomizationsCard.propTypes = {
  customizations: CUSTOMIZATIONS.isRequired,
  accountActions: ACTIONS.isRequired,
  i18n: I18N.isRequired,
}

export default Translation(CustomizationsCard, ['customizations'])
