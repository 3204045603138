import { autobind } from 'core-decorators'
import { Col, Container, Row } from 'reactstrap'
import { isNil } from 'lodash'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import React from 'react'

import { FormValidator } from 'classes'
import { hasFormErrors } from 'helpers'
import { I18N } from 'constants/props'
import { EMAIL, REQUIRED } from 'constants/inputTypes'
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents'
import FormComponent from 'generics/FormComponent'
import Translation from 'generics/Translation'

import styles from './FormSendReportRequest.scss'

/**
 * @description: Send Report Request Form
 * @extends FormComponent
 */
export class FormSendReportRequest extends FormComponent {
  constructor(props) {
    super(props)

    this.state = {
      errors: {},
      values: {},
    }
  }

  @autobind
  validate() {
    const { i18n } = this.props
    const { values } = this.state

    const { email, firstName, lastName } = values

    const validator = new FormValidator(i18n)

    const errors = {
      email: validator.validate(REQUIRED, email) || validator.validate(EMAIL, email),
      firstName: validator.validate(REQUIRED, firstName),
      lastName: validator.validate(REQUIRED, lastName),
    }

    this.setState({
      errors,
      isValid: !hasFormErrors(errors),
    })
  }

  render() {
    const { className, i18n } = this.props

    const { errors, isValid, values } = this.state

    return (
      <form className={className} onSubmit={this.handleSubmit}>
        <Container className={styles.container} fluid>
          <Row>
            <Col xs="12" sm="6">
              <StyledTextField
                className={styles['text-field']}
                error={isNil(values.firstName) ? false : !!errors.firstName}
                helperText={isNil(values.firstName) ? ' ' : errors.firstName}
                label={i18n.generics.formFields.firstName}
                id="send-report-request-first-name"
                name="firstName"
                onChange={e => this.handleChange('firstName', e.currentTarget.value)}
                required
                title={i18n.generics.formFields.firstName}
                value={values.firstName}
              />
            </Col>

            <Col xs="12" sm="6">
              <StyledTextField
                className={styles['text-field']}
                error={isNil(values.lastName) ? false : !!errors.lastName}
                helperText={isNil(values.lastName) ? ' ' : errors.lastName}
                label={i18n.generics.formFields.lastName}
                id="send-report-request-last-name"
                name="lastName"
                onChange={e => this.handleChange('lastName', e.currentTarget.value)}
                required
                title={i18n.generics.formFields.lastName}
                value={values.lastName}
              />
            </Col>

            <Col xs="12">
              <StyledTextField
                className={styles['text-field']}
                error={isNil(values.email) ? false : !!errors.email}
                helperText={isNil(values.email) ? ' ' : errors.email}
                id="send-report-request-email"
                label={i18n.generics.formFields.email}
                name="email"
                onChange={e => this.handleChange('email', e.currentTarget.value)}
                required
                rows={2}
                title={i18n.generics.formFields.email}
                value={values.email}
              />
            </Col>
            <Col xs={12} md={{ size: 8, offset: 2 }}>
              <StyledButton
                color="primary"
                disabled={!isValid}
                fullWidth
                id="send-report-request-btn"
                title={i18n.pageTokenAdministration.useTokens.sendReportRequestSubmit}
                type="submit"
              >
                {i18n.pageTokenAdministration.useTokens.sendReportRequestSubmit}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </form>
    )
  }
}

FormSendReportRequest.propTypes = {
  className: PropTypes.string,
  i18n: I18N.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

FormSendReportRequest.defaultProps = {
  className: null,
}

export default Translation(withRouter(FormSendReportRequest), ['generics', 'pageTokenAdministration'])
