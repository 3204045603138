/**
 * @description Settings for controller
 * @module API controller / Roles
 */

module.exports = {
  get: {
    ignoreNotFound: true,

    /**
     * @description Get request settings (Get roles)
     * @param  {number} accountId - The account ID.
     * @return {object} Request Settings
     */
    settings(accountId) {
      return {
        endpoint: `/account/${accountId}/roles`,
        method: 'GET',
      }
    },
  },

  getProfileRoles: {
    ignoreNotFound: true,

    /**
     * @description Get request settings (Get Profile Roles)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.profileId - The profile ID.
     * @return {object} Request Settings
     */
    settings({ accountId, profileId }) {
      return {
        endpoint: `/account/${accountId}/roles/profile/${profileId}/roles_by_profile`,
        method: 'GET',
      }
    },
  },

  updateProfileRoles: {
    /**
     * @description Get request settings (Update Profile Roles)
     * @param  {object} options
     * @param  {number} options.accountId - The account ID.
     * @param  {number} options.profileId - The profile ID.
     * @param  {array} options.roles - Fields to edit.
     * @return {object} Request Settings
     */
    settings({ accountId, profileId, roles: rolesList }) {
      return {
        endpoint: `/account/${accountId}/roles/profile/${profileId}/update_profile_roles`,
        body: {
          roles: rolesList,
        },
        method: 'PATCH',
      }
    },
  },
}
