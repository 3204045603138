/**
 * @fileoverview Coach-related actions for the SensaiAI module
 * @module sensaiAi/actions/coachActions
 */

import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import { COACH } from '../constants/actionTypes'
import { ERROR_MESSAGES } from '../constants/errorMessages'
import { handleApiError, validateCredentials, createApiParams } from '../utils/apiUtils'
import { getSensaiCredentials } from '../selectors/credentialsSelectors'

/**
 * Action creator for coach fetch request
 * @returns {Object} Action object
 */
export const fetchCoachesRequest = () => ({
  type: COACH.FETCH_REQUEST,
})

/**
 * Action creator for successful coach fetch
 * @param {Object} response - API response data
 * @returns {Object} Action object
 */
export const fetchCoachesSuccess = response => ({
  type: COACH.FETCH_SUCCESS,
  payload: response,
})

/**
 * Action creator for failed coach fetch
 * @param {Error} error - Error object
 * @returns {Object} Action object
 */
export const fetchCoachesFailure = error => ({
  type: COACH.FETCH_FAILURE,
  payload: error,
})

/**
 * Action creator for coach creation request
 * @returns {Object} Action object
 */
export const createCoachRequest = () => ({
  type: COACH.CREATE_REQUEST,
})

/**
 * Action creator for successful coach creation
 * @param {Object} response - API response data
 * @returns {Object} Action object
 */
export const createCoachSuccess = response => ({
  type: COACH.CREATE_SUCCESS,
  payload: response,
})

/**
 * Action creator for failed coach creation
 * @param {Error} error - Error object
 * @returns {Object} Action object
 */
export const createCoachFailure = error => ({
  type: COACH.CREATE_FAILURE,
  payload: error,
})

/**
 * Thunk action creator for creating a coach
 * @param {string} userName - User name for the coach
 * @param {string} knowledgeBase - Knowledge base for the coach
 * @returns {Function} Thunk function
 */
export const createCoach = (userName, knowledgeBase) => (dispatch, getState) => {
  const state = getState()
  const accountId = sessionSelectors.accountId(state)
  const credentials = getSensaiCredentials(state)

  try {
    validateCredentials(accountId, credentials.appSecret, credentials.licenseKey)
  } catch (error) {
    return handleApiError(error, dispatch, createCoachFailure, ERROR_MESSAGES.CREATE_COACH_FAILED)
  }

  dispatch(createCoachRequest())

  const params = createApiParams(accountId, credentials, {
    userName,
    knowledgeBase,
  })

  return new Promise((resolve, reject) => {
    api.sensai.createCoach(
      params,
      response => {
        if (response) {
          dispatch(createCoachSuccess(response))
          resolve(response)
        } else {
          const error = new Error(ERROR_MESSAGES.INVALID_RESPONSE)
          handleApiError(error, dispatch, createCoachFailure, ERROR_MESSAGES.CREATE_COACH_FAILED)
          reject(error)
        }
      },
      error => {
        handleApiError(error, dispatch, createCoachFailure, ERROR_MESSAGES.CREATE_COACH_FAILED)
        reject(error)
      },
    )
  })
}

export const fetchCoaches = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState()
    const accountId = sessionSelectors.accountId(state)
    const { appSecret, licenseKey } = getSensaiCredentials(state)

    if (!accountId || !appSecret || !licenseKey) {
      const error = new Error('Missing credentials')
      console.error('Credential check failed:', error)
      dispatch(fetchCoachesFailure(error))
      reject(error)
      return
    }

    dispatch(fetchCoachesRequest())

    api.sensai.getCoaches(
      {
        userId: accountId.toString(),
        appSecret,
        licenseKey,
      },
      // Success callback
      response => {
        if (!response) {
          const error = new Error('Invalid response from server')
          dispatch(fetchCoachesFailure(error))
          reject(error)
          return
        }
        dispatch(fetchCoachesSuccess(response))
        resolve(response)
      },
      // Error callback
      error => {
        console.error('API Error Response:', error)
        const formattedError = {
          error: {
            errorCode: error.status || 1,
            message: error.message || 'Failed to fetch coaches',
            details: error,
          },
        }
        dispatch(fetchCoachesFailure(formattedError))
        reject(formattedError)
      },
    )
  })
}
