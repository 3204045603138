import { Col, Container, Row } from 'reactstrap'
import { isNil } from 'lodash'
import { Link } from 'react-router-dom'
import { Checkbox, MenuItem, FormControlLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import { StyledButton, StyledTextField, StyledSelect } from 'generics/StyledFormComponents'
import { TERMS_OF_USE } from 'urls'
import genderPronouns from 'constants/genderPronouns'
import Translation from 'generics/Translation'

import styles from './SetupSSOProfileForm.scss'
import useSetupSSOProfileForm from './useSetupSSoProfileForm'

const SetupSSOProfileForm = ({ i18n, initialValues, onSubmit }) => {
  const { errors, isValid, handleChange, handleSubmit, values } = useSetupSSOProfileForm({
    i18n,
    initialValues,
    onSubmit,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Container className={styles.container}>
        <Row>
          <Col sm="12">
            <StyledTextField
              className={styles['text-field']}
              disabled
              fullWidth
              label={i18n.generics.formFields.email}
              name="email"
              title={i18n.generics.formFields.email}
              value={values.email}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6">
            <StyledTextField
              className={styles['text-field']}
              error={isNil(values.firstName) ? false : !!errors.firstName}
              fullWidth
              helperText={isNil(values.firstName) ? ' ' : errors.firstName}
              label={i18n.generics.formFields.firstName}
              name="firstName"
              onChange={e => handleChange('firstName', e.currentTarget.value)}
              title={i18n.generics.formFields.firstName}
              value={values.firstName ? values.firstName : ''}
            />
          </Col>
          <Col xs="12" sm="6">
            <StyledTextField
              className={styles['text-field']}
              error={isNil(values.lastName) ? false : !!errors.lastName}
              fullWidth
              helperText={isNil(values.lastName) ? ' ' : errors.lastName}
              label={i18n.generics.formFields.lastName}
              name="lastName"
              onChange={e => handleChange('lastName', e.currentTarget.value)}
              title={i18n.generics.formFields.lastName}
              value={values.lastName ? values.lastName : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledSelect
              color="primary"
              error={isNil(values.genderPronounPreference) ? false : !!errors.genderPronounPreference}
              fullWidth
              helperText={isNil(values.genderPronounPreference) ? ' ' : errors.genderPronounPreference}
              label={i18n.generics.formFields.gender}
              name="genderPronounPreference"
              onChange={e => handleChange('genderPronounPreference', e.target.value)}
              title={i18n.generics.formFields.genderPronounPreference}
              value={values.genderPronounPreference ? values.genderPronounPreference : ''}
            >
              {genderPronouns.map(genderPronoun => (
                <MenuItem key={genderPronoun.value} value={genderPronoun.value}>
                  {genderPronoun.gender}
                </MenuItem>
              ))}
            </StyledSelect>
          </Col>
        </Row>
        <Row>
          <Col className={styles.agree}>
            <FormControlLabel
              classes={{ root: styles.agree__root, label: styles.agree__label }}
              control={
                <Checkbox
                  color="primary"
                  error={isNil(values.agreedToTermsOfService) ? false : !!errors.agreedToTermsOfService}
                  helperText={isNil(values.agreedToTermsOfService) ? ' ' : errors.agreedToTermsOfService}
                  onChange={() => handleChange('agreedToTermsOfService', !values.agreedToTermsOfService)}
                  name="agreedToTermsOfService"
                  title={i18n.generics.formFields.agreeTermsDescription}
                  value={values.agreedToTermsOfService ? values.agreedToTermsOfService : ''}
                />
              }
              label={i18n.generics.formFields.agreeTerms}
            />
            <Link
              className={styles.agree__link}
              href={TERMS_OF_USE}
              target="_blank"
              title={i18n.generics.termsOfUse}
              to={TERMS_OF_USE}
            >
              {i18n.generics.termsOfUse}
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm={{ size: 8, offset: 2 }}>
            <StyledButton
              className={styles.submit}
              color="primary"
              disabled={!isValid}
              fullWidth
              title={i18n.generics.save}
              type="submit"
            >
              {i18n.generics.save}
            </StyledButton>
          </Col>
        </Row>
      </Container>
    </form>
  )
}

SetupSSOProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}),
}

SetupSSOProfileForm.defaultProps = {
  initialValues: {},
}

export default Translation(SetupSSOProfileForm, ['generics'])
