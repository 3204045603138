/**
 * @fileoverview Coach selectors for the SensaiAI module
 * @module sensaiAi/selectors/coachSelectors
 */

import { NAMESPACE } from '../constants/namespaces'

/**
 * Initial state for coach
 */
const initialState = {
  coach: {
    coaches: null,
    isFetching: false,
    isCreating: false,
    error: null,
  },
}

/**
 * Base selector for coach state
 * @param {Object} state - Global state
 * @returns {Object} Coach state
 */
const getCoachState = state => state?.[NAMESPACE.ROOT]?.coach || initialState.coach

/**
 * Selector for retrieving coaches
 * @param {Object} state - Global state
 * @returns {Array|null} List of coaches
 */
export const getCoaches = state => state.sensaiAi.coach.coaches

/**
 * Selector for retrieving coach fetch error
 * @param {Object} state - Global state
 * @returns {Object|null} Error object
 */
export const getCoachesError = state => state.sensaiAi.coach.error

/**
 * Selector for checking if coaches are being fetched
 * @param {Object} state - Global state
 * @returns {boolean} Fetching state
 */
export const isFetchingCoaches = state => getCoachState(state).isFetching

/**
 * Selector for checking if a coach is being created
 * @param {Object} state - Global state
 * @returns {boolean} Creating state
 */
export const isCreatingCoach = state => getCoachState(state).isCreating

/**
 * Selector for retrieving coach request ID
 * @param {Object} state - Global state
 * @returns {string|null} Request ID
 */
export const getCoachRequestId = state => state.sensaiAi.coach.requestId

/**
 * Selector for checking if coach processing is in progress
 * @param {Object} state - Global state
 * @returns {boolean} Processing state
 */
export const getCoachProcessing = state => state.sensaiAi.coach.processing

/**
 * Selector for checking if coach processing is complete
 * @param {Object} state - Global state
 * @returns {boolean} Complete state
 */
export const getCoachComplete = state => state.sensaiAi.coach.complete
