import { Col, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import Icon from 'generics/Icon'
import Translation from 'generics/Translation'

import styles from './Header.scss'

const { PUBLIC_PATH } = process.env

const IMG_PATH = `${window.location.origin}${PUBLIC_PATH}training`

/**
 * @class Header
 * @description renders Learn Page Header
 */
const Header = ({ i18n, trainingName }) => (
  <div className={styles.header}>
    <Row>
      <Col xs="12" lg="6">
        <div className={styles.top}>
          <Icon.Stroke7 className={styles.icon} name="film" title={i18n.training.title} />
          <div className={styles.titles}>
            <h1 className={styles.title}>{i18n.training.trainings[trainingName].title}</h1>
            <h2 className={styles.subtitle}>{i18n.training.trainings[trainingName].subtitle}</h2>
          </div>
        </div>
        <p className={styles.description}>{i18n.training.trainings[trainingName].internalDescription}</p>
      </Col>
      <Col xs="12" lg="6">
        <img
          alt={i18n.training.trainings[trainingName].subtitle}
          className={styles.image}
          src={`${IMG_PATH}/${trainingName}/${trainingName}.png`}
        />
      </Col>
    </Row>
  </div>
)

Header.propTypes = {
  trainingName: PropTypes.string.isRequired,
  i18n: I18N.isRequired,
}

export default Translation(Header, ['training'])
