import { Col, Container, Row } from 'reactstrap'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { Footer, Header, Main } from 'generics/Card'
import { ACTIONS, I18N, PERSON } from 'constants/props'
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents'
import Translation from 'generics/Translation'

import styles from './FormValidateCode.scss'
import useFormValidateCode from './useFormValidateCode'

const getFieldTitle = (i18n, isRequired) => (
  <div className={styles['field-title']}>
    <span title={i18n.myProfile.accountInformation.codeLabel}>{i18n.myProfile.accountInformation.codeLabel}</span>
    {isRequired && (
      <span className={styles.required} title={i18n.generics.requiredField}>
        *
      </span>
    )}
  </div>
)

const FormValidateCode = ({ accountId, i18n, initialValues, newEmail, notificationActions, onFinish }) => {
  const { errors, isValid, handleChange, handleSubmit, values } = useFormValidateCode({
    accountId,
    i18n,
    initialValues,
    newEmail,
    notificationActions,
    onFinish,
  })

  const getFormFieldText = fieldName => (
    <StyledTextField
      className={styles['text-field']}
      name={fieldName}
      error={isNil(values[fieldName]) ? false : !!errors[fieldName]}
      helperText={isNil(values) ? ' ' : errors[fieldName]}
      onChange={e => handleChange(fieldName, e.currentTarget.value)}
      value={values[fieldName] || ''}
    />
  )

  return (
    <>
      <Header>
        <h1 className={styles.header} title={i18n.myProfile.accountInformation.validateCodeLabel}>
          {i18n.myProfile.accountInformation.validateCodeLabel}
        </h1>
        <p>{i18n.myProfile.accountInformation.confirmationCodeEmailLabel}</p>
      </Header>
      <Main>
        <Container fluid>
          <form className={styles.form} onSubmit={e => handleSubmit(e)}>
            <Container>
              <Row>
                <Col xs="12" sm="6">
                  {getFieldTitle(i18n, 'code', true)}
                  {getFormFieldText('code')}
                </Col>
              </Row>
            </Container>
          </form>
        </Container>
      </Main>
      <Footer>
        <Container>
          <Row>
            <Col xs={{ size: 4 }} md={{ size: 2, offset: 10 }}>
              <StyledButton
                disabled={!isValid}
                fullWidth
                onClick={e => handleSubmit(e)}
                title={i18n.myProfile.accountInformationForm.saveButtonLabel}
              >
                {i18n.myProfile.accountInformationForm.saveButtonLabel}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  )
}

FormValidateCode.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  initialValues: PERSON.isRequired,
  newEmail: PropTypes.string.isRequired,
  notificationActions: ACTIONS.isRequired,
  onFinish: PropTypes.func.isRequired,
}

export default Translation(FormValidateCode, ['myProfile', 'generics'])
