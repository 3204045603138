/**
 * @fileoverview Error message constants for the SensaiAI module
 * @module sensaiAi/constants/errorMessages
 */

/**
 * @constant {Object} ERROR_MESSAGES - Error message constants
 * @property {string} MISSING_CREDENTIALS - Error message for missing credentials
 * @property {string} CREATE_COACH_FAILED - Error message for coach creation failure
 * @property {string} STATUS_FAILED - Error message for status fetch failure
 * @property {string} INVALID_RESPONSE - Error message for invalid server response
 * @property {string} MISSING_ACCOUNT_ID - Error message for missing account ID
 */
const ERROR_MESSAGES = {
  MISSING_CREDENTIALS: 'sensaiCredentialsNotFound',
  CREATE_COACH_FAILED: 'createCoachFailed',
  STATUS_FAILED: 'sensaiStatusFailed',
  INVALID_RESPONSE: 'Invalid response from server',
  MISSING_ACCOUNT_ID: 'Missing accountId',
}

export { ERROR_MESSAGES }
export default ERROR_MESSAGES
