import PropTypes from 'prop-types'
import React from 'react'
import shortid from 'shortid'

import styles from './ModalErrors.scss'

const ModalErrors = ({ errors }) => {
  if (errors) {
    const errorList = errors.map(error => <div key={shortid.generate()}>{error}</div>)

    return <div className={styles.errors}>{errorList}</div>
  }
  return null
}

ModalErrors.propTypes = { errors: PropTypes.arrayOf(PropTypes.string) }

ModalErrors.defaultProps = { errors: undefined }

export default ModalErrors
