/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import Modal from 'generics/Modal'
import Translation from 'generics/Translation'

import styles from './ModalProfileNotes.scss'

const ModalProfileNotes = ({ children, i18n, isFetching, onClose }) => (
  <Modal
    card={{
      barClassName: styles.bar,
      isFetching,
    }}
    modalCardClassName={styles.modal}
    onClose={onClose}
    switchModalView={false}
    title={i18n.notes.noteLabel}
  >
    {children}
  </Modal>
)

ModalProfileNotes.propTypes = {
  children: PropTypes.node.isRequired,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Translation(ModalProfileNotes, ['notes'])
