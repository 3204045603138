import { bindActionCreators } from 'redux'
import { Col, Container, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { sprintf } from 'sprintf-js'
import { withRouter } from 'react-router-dom'
import htmlParser from 'html-react-parser'
import React from 'react'

import { I18N, INVITATION } from 'constants/props'
import { Card, Header, Main } from 'generics/Card'
import { DECLINED } from 'constants/invitationStatus'
import { StyledButton } from 'generics/StyledFormComponents'
import * as invitesActions from 'app_modules/invites/actions'
import * as invitesSelectors from 'app_modules/invites/selectors'
import * as pageActions from 'app_modules/page/actions'
import Mask from 'generics/Mask'
import Translation from 'generics/Translation'

import styles from './InvitationDialog.scss'
import useInvitationDialog from './useInvitationDialog'

const InvitationDialog = props => {
  const { i18n, invitation } = props

  const {
    error,
    handleAccept,
    handleAcceptError,
    handleConfirmDecline,
    handleDecline,
    isFetching,
  } = useInvitationDialog({ ...props })

  if (isEmpty(invitation)) return null

  const { account, invitationSender, status } = invitation
  const { name: accountName } = account

  const pendingInvitationText = <>{htmlParser(sprintf(i18n.invitationDialog.description, accountName))}</>

  return (
    <Mask className={styles.mask} open>
      {error && (
        <Card className={styles.mask__card} isLoading={isFetching}>
          <Header>
            <Container>
              <Row>
                <Col>{i18n.invitationDialog.invalidInvitation}</Col>
              </Row>
            </Container>
          </Header>
          <Main>
            <Container>
              <Row>
                <Col>
                  <p className={styles.mask__title}>{error.message}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <StyledButton
                    className={styles.mask__button}
                    color="primary"
                    onClick={handleAcceptError}
                    title={i18n.invitationDialog.accept}
                  >
                    {i18n.invitationDialog.accept}
                  </StyledButton>
                </Col>
              </Row>
            </Container>
          </Main>
        </Card>
      )}
      {!error && (
        <Card className={styles.mask__card} isLoading={isFetching}>
          <Header>
            <Container>
              <Row>
                <Col>
                  <h1>
                    {status !== DECLINED
                      ? i18n.invitationDialog.acceptInvitation
                      : i18n.invitationDialog.invitationDeclined}
                  </h1>
                </Col>
              </Row>
            </Container>
          </Header>
          <Main>
            <Container>
              <Row>
                <Col>
                  <p className={styles.mask__title}>
                    {status !== DECLINED
                      ? sprintf(i18n.invitationDialog.invitedBy, invitationSender, accountName)
                      : i18n.invitationDialog.youDeclinedInvitation}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className={styles.mask__message}>
                    {status !== DECLINED
                      ? pendingInvitationText
                      : sprintf(i18n.invitationDialog.invitationDeclinedDescription, accountName, accountName)}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="6">
                  {status !== DECLINED && (
                    <StyledButton
                      className={`${styles.mask__button} ${styles['mask__button-decline']}`}
                      color="default"
                      onClick={handleDecline}
                      title={i18n.invitationDialog.decline}
                      variant="text"
                    >
                      {i18n.invitationDialog.decline}
                    </StyledButton>
                  )}
                </Col>
                <Col xs="12" sm="6">
                  <StyledButton
                    className={styles.mask__button}
                    onClick={status !== DECLINED ? handleAccept : handleConfirmDecline}
                    title={status !== DECLINED ? i18n.invitationDialog.accept : i18n.invitationDialog.ok}
                  >
                    {status !== DECLINED ? i18n.invitationDialog.accept : i18n.invitationDialog.ok}
                  </StyledButton>
                </Col>
              </Row>
            </Container>
          </Main>
        </Card>
      )}
    </Mask>
  )
}

InvitationDialog.propTypes = {
  i18n: I18N.isRequired,
  invitation: INVITATION,
}

InvitationDialog.defaultProps = {
  invitation: {},
}

const mapStateToProps = state => ({
  invitation: invitesSelectors.invitation(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(invitesActions, dispatch),
  pageActionsProps: bindActionCreators(pageActions, dispatch),
})

export default withRouter(
  Translation(connect(mapStateToProps, mapDispatchToProps)(InvitationDialog), ['invitationDialog']),
)
