import _ from 'lodash'

export default function (elem, additionalProps) {
  let inspectedProps = ['width', 'height']
  if (!_.isUndefined(additionalProps)) {
    inspectedProps = inspectedProps.concat(additionalProps)
  }

  if (elem) {
    const cs = getComputedStyle(elem)
    const size = _.chain(cs)
      .pick(inspectedProps)
      .mapValues(pxValue => parseFloat(pxValue, 10))
      .value()

    return size
  }

  return {}
}

// FIXME move in some utils/ directory
