/**
 * @fileoverview Hook for managing processing-related operations
 * @module sensaiAi/hooks/useProcessing
 */

import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSensaiRequestId,
  setSensaiProcessing,
  setSensaiProcessingComplete,
  fetchCoachStatus,
} from '../actions/processingActions'
import { getSensaiRequestId, isProcessing, isProcessingComplete } from '../selectors/processingSelectors'

/**
 * Hook for managing processing operations
 * @returns {Object} Processing operations and state
 */
const useProcessing = () => {
  const dispatch = useDispatch()

  // Selectors
  const requestId = useSelector(getSensaiRequestId)
  const processing = useSelector(isProcessing)
  const complete = useSelector(isProcessingComplete)

  // Callbacks
  const handleSetRequestId = useCallback(id => dispatch(setSensaiRequestId(id)), [dispatch])
  const handleSetProcessing = useCallback(processingState => dispatch(setSensaiProcessing(processingState)), [dispatch])
  const handleSetComplete = useCallback(() => dispatch(setSensaiProcessingComplete()), [dispatch])

  // Rename parameter to avoid conflict
  const handleFetchCoachStatus = useCallback(statusRequestId => dispatch(fetchCoachStatus(statusRequestId)), [dispatch])

  return {
    // State
    requestId,
    processing,
    complete,
    // Actions
    setRequestId: handleSetRequestId,
    setProcessing: handleSetProcessing,
    setComplete: handleSetComplete,
    fetchStatus: handleFetchCoachStatus,
  }
}

export default useProcessing
