import * as accountActions from 'app_modules/accounts/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import invitesActionTypes from 'app_modules/invites/constants'

// Actions

export const fetchSentInvitationsFailure = error => ({
  payload: error,
  type: invitesActionTypes.FETCH_SENT_INVITATIONS_FAILURE,
})

export const fetchSentInvitationsRequest = pageIndex => ({
  payload: pageIndex,
  type: invitesActionTypes.FETCH_SENT_INVITATIONS_REQUEST,
})

export const fetchSentInvitationsSuccess = response => ({
  payload: response,
  type: invitesActionTypes.FETCH_SENT_INVITATIONS_SUCCESS,
})

export const fetchSentInvitationsSuccessWithNoResults = () => ({
  payload: { isEmptyList: true },
  type: invitesActionTypes.FETCH_SENT_INVITATIONS_SUCCESS_NO_RESULTS,
})

// Thunks

export const fetchSentInvitations = ({ pageIndex = 1, search, filters }) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchSentInvitationsRequest(pageIndex))

    const handleResponse = response => {
      const { invites } = response

      if (!invites) {
        dispatch(fetchSentInvitationsSuccessWithNoResults())
        dispatch(accountActions.fetchAccount(accountId))
        return response
      }

      const fixedResponse = {
        ...response,
        account: response.invites[0].account,
        updateCounters: !search,
      }

      dispatch(fetchSentInvitationsSuccess(fixedResponse))
      return response
    }

    const handleError = ({ error }) => {
      dispatch(fetchSentInvitationsFailure(error))
    }

    return api.invites.sentInvitations({ accountId, pageIndex, search, filters }, handleResponse, handleError)
  }

  thunk.type = invitesActionTypes.FETCH_SENT_INVITATIONS

  return thunk
}

export default fetchSentInvitations
