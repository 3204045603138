import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import * as accountSelectors from 'app_modules/accounts/selectors'
import * as proMemberActions from 'app_modules/proMember/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import * as teamsActions from 'app_modules/teams/actions'
import * as teamsSelectors from 'app_modules/teams/selectors'
import PageTeamsDashboard from 'components/PageTeamsDashboard'

const mapStateToProps = state => ({
  accountId: sessionSelectors.accountId(state),
  // Backend is returning null instead of true or false on edit team
  hasMoreMembers: teamsSelectors.teamMeta(state).morePages || false,
  isProMember: accountSelectors.isProMember(state),
  membersPageIndex: teamsSelectors.membersPageIndex(state),
  modules: teamsSelectors.modules(state),
  profile: sessionSelectors.currentProfile(state),
  team: teamsSelectors.team(state),
  teamMembers: teamsSelectors.teamMembers(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(teamsActions, dispatch),
  proMemberActionsProps: bindActionCreators(proMemberActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageTeamsDashboard))
