import { get } from 'lodash'
import { replace } from 'connected-react-router'

import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import actionTypes from 'app_modules/invites/constants'
import api from 'api'

// Actions

export const fetchDeclineInvitationFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_DECLINE_INVITATION_FAILURE,
})

export const fetchDeclineInvitationRequest = () => ({
  type: actionTypes.FETCH_DECLINE_INVITATION_REQUEST,
})

export const fetchDeclineInvitationSuccess = invitation => ({
  payload: invitation,
  type: actionTypes.FETCH_DECLINE_INVITATION_SUCCESS,
})

// Thunks

export const declineInvitation = (onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const { account, token } = getState().invites.invitation

    const accountId = get(account, 'id')

    dispatch(fetchDeclineInvitationRequest())

    const handleResponse = ({ invite }) => {
      dispatch(fetchDeclineInvitationSuccess(invite))
      if (onSuccess) {
        onSuccess()
      }
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchDeclineInvitationFailure(error.message))
      if (onError) {
        onError(error)
      }
      dispatch(replace('/login'))
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'fetchDeclineInvitation' })))
      }
    }

    return api.invites.decline({ accountId, token }, handleResponse, handleError)
  }

  thunk.type = actionTypes.TRIGGER_DECLINE_INVITATION

  return thunk
}

export default declineInvitation
