/**
 * @description Settings for charges controller
 * @module API controller / Charges
 */

module.exports = {
  getTokensPrice: {
    /**
     * @description Get request settings to tokens price.
     * @param {number} accountId - The account ID.
     * @param {number} quantity - Tokens quantity.
     * @returns {object} - Request settings.
     */
    settings({ accountId, quantity }) {
      return {
        endpoint: `/account/${accountId}/charges/tokens/amount?quantity=${quantity}`,
        method: 'GET',
      }
    },
  },
  sendTokensPayment: {
    /**
     * @description Post request settings (Send Stripe Report Payment)
     * @param  {object} options
     * @param  {string} options.stripeToken - Stripe transaction token
     * @param  {string} options.stripeEmail - Stripe transaction email
     * @return {object} Request Settings
     */
    settings({ accountId, countryId, fullName, postalCode, stripeToken, stripeEmail, quantity }) {
      return {
        endpoint: `/account/${accountId}/charges/tokens`,
        body: {
          countryId,
          fullName,
          postalCode,
          stripeToken,
          stripeEmail,
          quantity,
        },
        method: 'POST',
      }
    },
  },
}
