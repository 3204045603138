export const en = {
  header: 'End User Terms of Use',
  headers: [
    'Acceptance',
    'Your Account(s)',
    'Your Organization',
    'Provision of the Services',
    'Restrictions on Your Use of the Services',
    'Intellectual Property Rights; Ownership',
    'Rights in the Services',
    'Usage Data',
    'No Removal of Notices',
    'Ownership of and Use of Reports',
    'Content and Customer Data',
    'Confidentiality and Privacy',
    'Confidentiality',
    'Privacy Policy',
    'Other Third-Party Components and Software.',
    'Maintenance',
    'Warranty Disclaimer',
    'Limitation of Liability',
    'Indemnification',
    'Term and Termination',
    'Payment and Cancellation',
    'Electronic Signature(s)',
    'Modifications',
    'Waiver',
    'Severability',
    'Assignment',
    'Waiver of Jury Trial; Attorneys’ Fees',
    'Arbitration',
    'Governing Law',
    'Entire Agreement',
    'Contacting 5 Dynamics',
  ],
  privacyPolicy: 'Privacy Policy',
  text: [
    'These End User Terms of Use (these <b>&quot;End User Terms&quot;</b>) are a legally binding agreement between you, as an end user (<b>&quot;User&quot;</b> or <b>&quot;you&quot;</b>) and 5 Dynamics LLC (<b>&quot;5 Dynamics&quot;</b>, <b>&quot;we&quot;</b> or <b>&quot;us&quot;</b>) and govern your access to and use of Simpli5, of our proprietary workplace productivity, engagement, and leadership platform and associated services (the <b>&quot;Services&quot;</b>). As part of these End User Terms, you agree to comply with the most recent version of our <b>Acceptable Use Policy</b>, which is incorporated by reference into these End User Terms.',
    'Please read these End User Terms carefully and save them. If you do not agree to these End User Terms, you may not use the Services. By clicking to accept these End User Terms, or by otherwise using the Services, you agree to be bound by these End User Terms as of that date (the <b>&quot;Effective Date&quot;</b>), and you represent that you are 18 years of age or older and have the authority to enter into these End User Terms. ',
    'Your access to, and use of, the services and our obligations with respect thereto are expressly conditioned on your acceptance of these end user terms, including arbitration on an individual basis in section 15',
    'To use our Services, you will be asked to create an account. As part of the account creation process, you&lsquo;ll be asked to provide your email address, and create a password. Until you apply for an account, your access to our Services will be limited to what is available to the general public. When registering an account, you must provide true, accurate, current, and complete information about yourself as requested during the account creation process. You must also keep that information true, accurate, current, and complete after you create your account.',
    'You agree to abide by the license metrics, requirements and other restrictions and limitations concerning the Services as described when you create an account for the Services. 5 Dynamics will provide you with a unique set of credentials for each individual that will be granted access to the Services (&quot;ID Credentials&quot;). You may not share your ID Credentials, and you are responsible for any unauthorized access to your ID Credentials.',
    'You agree that all information you provide to us in connection with your account will be true, accurate, supportable, and complete. You shall: (a) notify 5 Dynamics immediately of any unauthorized use of any ID Credentials or any other known breach of security, (b) report to 5 Dynamics immediately and use reasonable efforts to stop any unauthorized use of the Services that is known or suspected by you or any user, and (c) not provide false identity information to gain access to or use the Services. You are solely responsible for all use (whether or not authorized) of our Services under your account(s). You agree to take all reasonable precautions to prevent unauthorized access to or use of our Services and will notify us promptly of any unauthorized access or use. We will not be liable for any loss or damage arising from unauthorized use of your account.',
    'These End User Terms apply to you as a user of the Services and are entered into between you and 5 Dynamics. Your company or organization (i.e., a unique domain where a group of users may access the Services) that has invited you to access the Services is referred to as the <b>&quot;Customer&quot;</b> in this Agreement. If you are joining your employer’s organization, for example, Customer is your employer. If you are joining a non-profit organization created by a friend using their personal email address, the friend is our Customer and they are authorizing you to join their organization. Each Customer has a separate written agreement with us (in either case, the <b>&quot;Customer Agreement&quot;</b>) that permitted Customer to create and configure an organization so that you and others could join (each invitee granted access to the Services, including you, is an <b>&quot;Authorized User&quot;</b>). You are an Authorized User of the Customer. The Customer Agreement contains our commitment to deliver the Services to Customer, who may then invite Authorized Users to join its organization. Each Authorized User requires a separate license. License reuse is prohibited.',
    'Subject to these End User Terms and the Customer Agreement, we will use commercially reasonable efforts to make the Services for which the Customer has subscribed available to you as an end user of the Customer. Depending upon the Services for which Customer has subscribed, different tools and features will be available.',
    'Except as expressly permitted herein, you may not, and may not permit anyone to: (i) copy or republish the Services, (ii) make the Services available to any other person, (iii) use or access the Services to provide service bureau, time-sharing or other computer hosting services to third parties, (iv) modify or create derivative works based upon the Services, (v) remove, modify or obscure any copyright, trademark or other proprietary notices contained in the Services, (vi) reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code of the Services, except and only to the extent such activity is expressly permitted by applicable law, (vii) access the Services in order to build a similar product or competitive product, (viii) create &quot;links&quot; to or from the Services, or &quot;frame&quot; or &quot;mirror&quot; any content forming part of the Services, (ix) integrate or combine the Services with any software, or (x) use the content for other purposes such as for training a machine learning AI model, except as expressly permitted by 5 Dynamics.',
    'Except for the limited rights granted herein during the term of these End User Terms, any and all rights in and to the Services, the Simpli5 website, and 5 Dynamics&lsquo;s online materials, including all intellectual property rights, including but not limited to our trademarks, copyrights, patents, and all related rights, in and to the foregoing, are and shall remain the exclusive property of 5 Dynamics. 5 Dynamics reserves all rights not expressly granted herein. Nothing in these End User Terms will transfer any such rights to, or to vest any such rights in, you. You will not take any action to copy, jeopardize, infringe, limit, or interfere with 5 Dynamics’ intellectual property rights. Any unauthorized use of the Services or our intellectual property rights is a violation of these End User Terms and may also constitute a violation of intellectual property laws and treaties, including without limitation copyright laws and trademark laws.',
    'We may collect usage data, event logs, performance data, error monitoring and other data related to how you use and interact with the Services (<b>&quot;Usage Data&quot;</b>). To the extent we collect or generate Usage Data, it will be owned by us, and we may use it for any lawful purpose.',
    'You agree that you will not remove, obscure, make illegible or alter any notices or indications of the intellectual property and/or 5 Dynamics’ rights and ownership thereof, whether such notice or indications are affixed on, contained in, or otherwise connected to any materials.',
    'The Services, as well as the assessments and assessment reports created through your use of the Services, are the sole property of 5 Dynamics. Your rights in the assessments and/or the assessment reports created through your use of the Services include and are expressly limited to the following: (i) the limited access rights to use the Services to complete the assessment; (ii) limited access rights to use the Services to view the assessment report related to the assessment; (iii) the opportunity to download certain reports and information as permitted by the Services; and (iv) the opportunity to use what you have learned from the process of using the Services and seeing the assessment reports, as one data point in assessing the significance of your indicated personal characteristics. You hereby grant 5 Dynamics the royalty free, irrevocable, perpetual right and license to use, copy, modify, distribute, aggregate, compile and/or publish in its original or modified form the information contained in assessment reports or derived from the assessment process, provided that in doing so 5 Dynamics does not provide your personal information to identify you to others as the subject of the assessment report.',
    'In connection with your use of the Services, you may input or upload text, images, photos, videos, sounds, links, works of authorship, or other materials (collectively, &quot;<b>Content</b>&quot;). We do not claim ownership of your Content. By posting or sharing the Content, you grant to us only the limited rights that are reasonably necessary for us to provide the Services to you and the Customer. You represent and warrant that: (a) you own your Content or otherwise have the right to grant the license set forth in this section, and (b) transmitting your Content on or through the Services does not violate the privacy rights, publicity rights, copyrights, trademark rights, contract rights or any other rights of any person or entity. User shall only collect, process, use, store, disclose and transfer Content in compliance with any applicable Third-Party Terms, privacy policies or policies issued by Customer. User acknowledges that 5 Dynamics does not have any control over the Content that passes through 5 Dynamics’s systems and networks through the Services. 5 Dynamics is not responsible for monitoring your Content and collects such Content automatically under your direction when providing the Services to you and the Customer. We may, but do not have any obligation to, remove any of the Content from the Services in our sole discretion, including if we determine that it may violate another person’s intellectual property rights, this Agreement, any applicable Third-Party Terms, or applicable law. User acknowledges that 5 Dynamics will have access to the Content as it is being transmitted via the Services to the applicable Third-Party System and may need to translate, reformat, re-tag or otherwise modify technical elements of the Content. User acknowledges and agrees that after termination of this Agreement, User may not have access to the Content via the Services.',
    'When an Authorized User (including, you) submits Content or information to the Services, such as profile data or picture, but excluding Usage Data (&quot;<b>Customer Data</b>&quot;), you acknowledge and agree that the Customer Data is owned by Customer and the Customer Agreement provides Customer with many choices and control over that Customer Data. For example, Customer may provision or de-provision access to the Services, manage permissions, share connections, and these choices and instructions may result in the access, use, disclosure, modification, or deletion of certain or all Customer Data.',
    'As between us and customer, you agree that it is solely customer’s responsibility to (a) inform you and any authorized users of any relevant customer policies and practices and any settings that may impact the processing of customer data; (b) obtain any rights, permissions or consents from you and any authorized users that are necessary for the lawful use of customer data and the operation of the services; (c) ensure that the transfer and processing of customer data under the customer agreement is lawful; and (d) respond to and resolve any dispute with you and any authorized user relating to or based on customer data, the services or customer’s failure to fulfill these obligations. 5 Dynamics makes no representations or warranties of any kind, whether express or implied, to you relating to the services, which are provided to you on an &quot;as is&quot; and &quot;as available&quot; basis.',
    'You agree that you will treat the Services, including but not limited to, the assessments, the assessment reports, any information downloaded by you or disclosed to you by 5 Dynamics through the Services, and your password and related access information to the Services as Confidential Information and the trade secrets of 5 Dynamics. You shall not provide access to the Services to any other person without the express written permission of 5 Dynamics. You will not disclose the Services contents, assessments, or assessment reports to any third party without the express written permission of 5 Dynamics.',
    ' Please review our ',
    ' for more information on how we collect, use, and share information.',
    'User acknowledges that (a) the Services may contain other software or components that are either owned by a third party or in the public domain, and (b) 5 Dynamics has no proprietary interest in such software or components (collectively and each, the <b>&quot;Third-Party Software&quot;</b>), and as such, cannot grant User a license to use such Third-Party Software. A listing of such Third-Party Software is available upon written request.  User’s rights in the Third-Party Software are governed by and subject to the terms and conditions set forth in the applicable third-party license(s) also set forth therein. User acknowledges and agrees to fully comply with such terms and conditions.',
    ' In addition to any disclaimers set forth in such terms and conditions, the disclaimers set forth and the limitations of liability set forth herein shall apply to 5 dynamics and its licensors with respect to such third-party software.',
    'In addition, certain components of the Services are, due to their interaction with third party software, required to be made available to you under terms and conditions other than this Agreement.',
    'Scheduled system maintenance will take place from time to time, and during such times, updates to the Services or elements thereof may be unavailable. Emergency maintenance may be required at other times in the event of system failure. In addition, you acknowledge that information found on the Services may contain errors or inaccuracies and may not be complete or current.',
    'To the maximum extent permitted by applicable law, the services are provided &quot;as is&quot; and &quot;as available&quot;, with all faults and without warranty of any kind, and we hereby disclaim all warranties and conditions with respect thereto, either express, implied, or statutory, including, but not limited to, the implied warranties and/or conditions of merchantability, of satisfactory quality, of fitness for a particular purpose, of accuracy, of quiet enjoyment, and of noninfringement of third-party rights. We do not warrant against interference with user’s enjoyment of the service, that the functions contained in or performed or provided by the service will meet user’s requirements, that the operation of the service will be uninterrupted or error-free, or that defects in the service will be corrected. No oral or written information or advice that we give shall create a warranty. some jurisdictions do not allow the exclusion of implied warranties or limitations on applicable statutory rights of a consumer, so the above exclusion and limitations may not apply.',
    'Without limiting the generality of the foregoing, 5 Dynamics makes no warranty, expressed or implied, regarding accuracy, adequacy, completeness, legality, readability, reliability, or usefulness of any Content that is transmitted via the Services. User is solely responsible for verifying the accuracy, completeness, and appropriate rendering of Content before and after transmission through the Services.',
    'In no event will you or we have any liability to the other for any lost profits or revenues or for any indirect, special, incidental, exemplary, punitive consequential, cover or punitive damages however caused, whether in customer agreement, tort or under any other theory of liability, and whether or not the party has been advised of the possibility of such damages. Unless you are also a customer (and without limitation to our rights and remedies under the customer agreement), you will have no financial liability to us for a breach of these end user terms. our maximum aggregate liability to you for any breach of the user terms is one hundred dollars ($100) in the aggregate. The foregoing disclaimers will not apply to the extent prohibited by applicable law and do not limit either party&lsquo;s right to seek and obtain equitable relief.',
    'You agree to defend, indemnify, and hold 5 Dynamics and our parent, subsidiary and other affiliated companies, and their employees, contractors, officers and directors harmless from all liabilities, claims and expenses, including attorney’s fees, that arise from: (i) your use or misuse of the Services or the content therein; and (ii) your Content, including without limitation claims, damages and liabilities arising from User’s failure to comply with applicable law, or with any privacy policy or other agreement under which such Content was obtained.  We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will cooperate with us in asserting any available defenses.',
    'These End User Terms will be effective as of the Effective Date and will remain effective until Customer’s subscription for you expires or terminates, or your access to the Services has been terminated by Customer or us.',
    'We may terminate these End User Terms with immediate effect at any time. Without limiting other remedies, 5 Dynamics may limit, suspend, or terminate these End User Terms and your use of the Services, with immediate effect, automatically and without recourse, if we reasonably believe that you are in breach of these End User Terms. 5 Dynamics shall effect such termination by providing notice to you at the email address you have provided, and/or by preventing your access to the Services.',
    'Upon termination of these End User Terms: (i) all licenses and rights to use the Services shall immediately terminate; and (ii) you will immediately cease any and all use of the Services.  Notwithstanding the foregoing, the following Sections will survive any termination of these End User Terms: Sections 4-7 and 10-15.',
    '<b>Credit Card Purchases.</b> For some customers and organization types, 5 Dynamics offers the ability to purchase Simpli5 licenses using a credit card. When using this payment method, you agree to the following terms.',
    '<b>Payment Terms.</b> You agree to pay all fees associated with the purchase of licenses as outlined. Fees are billed in advance and are non-refundable.',
    '<b>Billing and Renewal.</b> If you pay by credit card, you authorize 5 Dynamics to charge your credit card on a recurring basis for the license term. On the date of your renewal, licenses for all active members will automatically be renewed. For any members that you do not want to renew, you agree to remove them as members of your organization prior to the renewal date.',
    '<b>Refunds.</b> All license fees are non-refundable. Refunds will not be provided for any partial subscription period.',
    '<b>Cancellation.</b> You may cancel your license renewal at any time, and the cancellation will be effective at the end of the current subscription term.',
    'You hereby agree to the use of electronic communication and to the electronic delivery of notices. Furthermore, you hereby waive any rights or requirements under any laws or regulations in any jurisdiction which requires an original (non-electronic) signature or delivery or retention of non-electronic records, to the extent permitted under applicable mandatory law.',
    'We may modify these End User Terms from time to time. Any modification shall be effective when we notify you of the modification (via an update delivered through the Services or via email or other notification) and you continue to use the Services after such date.',
    'No failure or delay by either party in exercising any right under the End User Terms will constitute a waiver of that right. No waiver under the End User Terms will be effective unless made in writing and signed by an authorized representative of the party being deemed to have granted the waiver.',
    'If any provision of the End User Terms is held by a court of competent jurisdiction to be contrary to law, the provision will be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of the End User Terms will remain in effect.',
    'You may not assign any of your rights or delegate your obligations under these End User Terms, whether by operation of law or otherwise, without the prior written consent of us (not to be unreasonably withheld). We may assign these End User Terms in their entirety (including all terms and conditions incorporated herein by reference), without your consent, to a corporate affiliate or in connection with a merger, acquisition, corporate reorganization, or sale of all or substantially all of our assets.',
    'Each party also hereby waives any right to jury trial in connection with any action or litigation in any way arising out of or related to the End User Terms. In any action or proceeding to enforce rights under the End User Terms, the prevailing party will be entitled to recover its reasonable costs and attorney’s fees.',
    'Any dispute, controversy or claim arising out of these End User Terms will be settled by binding arbitration pursuant to the Commercial Rules (or Consumer Rules, to the extent applicable) of the American Arbitration Association (<b>&quot;Rules&quot;</b>) then in effect. Notwithstanding those Rules, the following provisions will apply to such arbitration: (a) Texas law shall apply, (b) the arbitration will be conducted by a single arbitrator; however, at the request of either party, a panel of three arbitrators will conduct the arbitration, with one arbitrator chosen by each of the parties and the third appointed by the other two arbitrators, (c) the fees of the arbitrator(s) shall be equally borne (50/50) by the parties, and (d) the proceedings shall be in the English language and shall take place in Austin, Texas, User’s home state or another location reasonably convenient to both parties. The arbitrator(s) shall reach a binding decision regarding the issues presented as it deems fair, reasonable, and appropriate, and such decision shall have the full force and effect of a binding judgment, which may be entered in any court having proper jurisdiction. Each party may seek injunctive relief in any court of competent jurisdiction. <b>You and 5 Dynamics agree to resolve any dispute in arbitration on an individual basis only, and not on a class or collective basis.</b> The arbitrator shall have no authority to consider or resolve any claim or issue any relief on any basis other than an individual basis. If at any point this provision is determined to be unenforceable, the parties agree that this provision shall not be severable, unless it is determined that the arbitration may still proceed on an individual basis only.',
    'These End User Terms shall, for all domestic and international purposes, be governed, interpreted, construed, and enforced solely and exclusively in accordance with the laws of the State of Texas, U.S.A., without regard to conflicts of law provisions.',
    'The End User Terms, including any terms incorporated by reference into the End User Terms, constitute the entire agreement between you and us and supersede all prior and contemporaneous agreements, proposals, or representations, written or oral, concerning its subject matter. To the extent of any conflict or inconsistency between the provisions in these End User Terms and any pages referenced in these End User Terms, the terms of these End User Terms will first prevail; provided, however, that if there is a conflict or inconsistency between the Customer Agreement and the End User Terms, the terms of the Customer Agreement will first prevail, followed by the provisions in these End User Terms, and then followed by the pages referenced in these End User Terms (e.g., the Privacy Policy). Customer will be responsible for notifying Authorized Users of those conflicts or inconsistencies and until such time the terms set forth herein will be binding.',
    'You may contact us at <a href="mailto:support@simpli5.com">support@simpli5.com</a> or at our mailing address below:<br/>5 Dynamics LLC<br/>2802 Flintrock Trace, Suite 259<br/>Austin, TX 78738<br/>USA<br/>',
  ],
  updatedDate: 'Last updated: February 12, 2024',
}

export const es = en
