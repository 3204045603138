import { Col, Row } from 'reactstrap'
import { isEmpty, merge, some, uniqBy } from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState, useRef } from 'react'

import { ADD, REMOVE } from 'constants/actions'
import { orderByFirstNameLastName } from 'helpers'
import { PROFILES, INFINITE_SCROLL } from 'constants/props'
import InfiniteScrollList from 'generics/InfiniteScrollList'
import ListDnd from 'generics/ListDnD'
import ListItem from 'generics/ListItem'

import styles from './ListSelection.scss'

const ITEM_TYPES = {
  RESULT: 'result',
  SELECTED: 'selected',
}

const LIST = PropTypes.shape({
  className: PropTypes.string,
  emptyMessage: PropTypes.string,
  infiniteScroll: INFINITE_SCROLL,
  profiles: PROFILES,
  title: PropTypes.string,
})

const DEFAULT_LIST_VALUES = {
  emptyMessage: 'No results',
  infiniteScroll: null,
  profiles: [],
}

const getDifference = (list1, list2) => list1.filter(({ id }) => !some(list2, { id }))

const ListSelection = ({
  className,
  componentRefProp,
  filter,
  isFetching,
  listA,
  listB,
  listItemTestAttribute,
  maximumSelectableListItems,
  onChange,
  pristine,
  showStatusLabel,
}) => {
  const listSelectionRef = useRef(null)
  const [list1, setList1] = useState(getDifference(listA.profiles, listB.profiles || []))

  useEffect(() => {
    if (componentRefProp) {
      componentRefProp(listSelectionRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRefProp])

  useEffect(() => {
    setList1(getDifference(listA.profiles, listB.profiles))
  }, [listA.profiles, listB.profiles])

  const handleAddProfile = itemToAdd => {
    if (!itemToAdd) {
      throw new Error('Item to add is undefined')
    }

    const profiles = itemToAdd.constructor === Array ? itemToAdd : [itemToAdd]

    const { profiles: listBProfiles = [] } = listB

    const list2 = uniqBy(listBProfiles.concat(profiles), 'id')

    setList1(getDifference(list1, profiles))

    onChange(list2, itemToAdd, ADD)
  }

  const handleRemoveProfile = itemToRemove => {
    if (!itemToRemove) {
      throw new Error('Item to remove is undefined')
    }

    const { profiles: profilesA = [] } = listA
    const { profiles: profilesB = [] } = listB

    const list2 = profilesB.filter(listItem => listItem.id !== itemToRemove.id)

    setList1(getDifference(profilesA, list2))

    onChange(list2, itemToRemove, REMOVE)
  }

  const listADefault = {
    ...DEFAULT_LIST_VALUES,
    title: 'Results',
  }

  const listBDefault = {
    ...DEFAULT_LIST_VALUES,
    title: 'Selected',
  }

  const { profiles: list2 = [] } = listB

  let counter

  if (maximumSelectableListItems) {
    counter = {
      children: (
        <>
          <span>{list2.length}</span>
          <span className={styles.list__separator}>/</span>
          <span>{maximumSelectableListItems}</span>
        </>
      ),
      title: `${list2.length}/${maximumSelectableListItems}`,
    }
  }

  const isDisabled = maximumSelectableListItems ? list2.length >= maximumSelectableListItems : false

  const mergeLists = [
    merge(
      {
        accepts: ITEM_TYPES.SELECTED,
        className: styles['list--1'],
        disabled: isDisabled,
        list: list1,
        listItem: {
          onRequestAdd: handleAddProfile,
          type: ITEM_TYPES.RESULT,
        },
        onDrop: handleRemoveProfile,
        title: 'Results',
      },
      listADefault,
      listA,
    ),
    merge(
      {
        accepts: ITEM_TYPES.RESULT,
        counter,
        list: list2,
        listItem: {
          onRequestRemove: handleRemoveProfile,
          type: ITEM_TYPES.SELECTED,
        },
        onDrop: handleAddProfile,
        title: 'Selected',
      },
      listBDefault,
      listB,
    ),
  ]

  const lists = mergeLists.map((l, index) => {
    const {
      accepts,
      counter: listCounter,
      emptyMessage: listEmptyMessage,
      id: listId,
      infiniteScroll,
      isFetching: listIsFetching,
      list,
      listItem,
      onDrop,
      title,
      ...rest
    } = l

    let emptyMessage

    if (index === 0) {
      emptyMessage = !isFetching && !pristine && filter ? listEmptyMessage : null
    } else {
      emptyMessage = listEmptyMessage
    }

    let listItems = []

    if (list && list.length > 0) {
      listItems = orderByFirstNameLastName(list).map(profile => {
        const { id, canDrag: canDragFromProfile } = profile

        const canDrag = index === 0 ? !isDisabled && canDragFromProfile : canDragFromProfile

        return (
          <li data-test={listItemTestAttribute || undefined} key={`list-${index + 1}-${id}`}>
            <ListItem
              {...listItem}
              canDrag={canDrag}
              componentRefProp={componentRefProp}
              listItemTestAttribute={listItemTestAttribute}
              profile={profile}
              showStatusLabel={showStatusLabel}
              tag={Fragment}
              type="b"
            />
          </li>
        )
      })
    }

    const infiniteScrollList =
      infiniteScroll && listItems ? (
        <InfiniteScrollList
          className={styles['scroll-list']}
          infiniteScroll={{
            ...infiniteScroll,
            hideFullLink: true,
          }}
          listId={listId}
          innerRef={component => {
            listSelectionRef.current.InfiniteScrollList = component
          }}
        >
          {listItems}
        </InfiniteScrollList>
      ) : (
        <ul className={styles['list-ul']} id={`${listId}-ul`}>
          {listItems}
        </ul>
      )

    const listDnd = (
      <ListDnd
        accepts={accepts}
        className={styles.list}
        componentRefProp={componentRefProp}
        emptyMessage={emptyMessage}
        isFetching={listIsFetching || (index === 0 && isFetching)}
        onDrop={onDrop}
        {...rest}
      >
        {!isEmpty(listItems) ? infiniteScrollList : []}
      </ListDnd>
    )

    return (
      <Col key={title} xs="12" md="6">
        <h2 className={styles.list__header}>
          <span className={styles.list__title} title={title}>
            {title}
          </span>

          <div {...listCounter} />
        </h2>
        {listDnd}
      </Col>
    )
  })

  return (
    <div className={classnames(styles.lists, className)} ref={listSelectionRef}>
      <Row>{lists}</Row>
    </div>
  )
}

ListSelection.propTypes = {
  className: PropTypes.string,
  componentRefProp: PropTypes.func,
  filter: PropTypes.string,
  isFetching: PropTypes.bool,
  listA: LIST,
  listB: LIST,
  listItemTestAttribute: PropTypes.string,
  maximumSelectableListItems: PropTypes.number,
  onChange: PropTypes.func,
  pristine: PropTypes.bool,
  showStatusLabel: PropTypes.bool,
}

ListSelection.defaultProps = {
  className: null,
  componentRefProp: undefined,
  filter: null,
  isFetching: false,
  listA: {
    ...DEFAULT_LIST_VALUES,
    title: 'Results',
  },
  listB: {
    ...DEFAULT_LIST_VALUES,
    title: 'Selected',
  },
  listItemTestAttribute: '',
  maximumSelectableListItems: null,
  onChange: undefined,
  pristine: false,
  showStatusLabel: false,
}

export default ListSelection
