/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap'
import { isEmpty, reject, size, some } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { ACTIONS, I18N, INVITATION_GROUP, INVITATIONS, PERSON } from 'constants/props'
import { Card, Footer, Header, Main } from 'generics/Card'
import { COLORS } from 'constants/colors'
import { fromCallbacksToPromise } from 'helpers'
import { StyledButton } from 'generics/StyledFormComponents'
import FormFileInvitation from 'components/FormFileInvitation'
import Icon from 'generics/Icon'
import Translation from 'generics/Translation'

import BadgeResults from '../BadgeResults'
import PersonForm from '../PersonForm'
import ModalErrors from '../ModalErrors'
import SelectedRecords from '../SelectedRecords'
import styles from './ModalGroup.scss'

const EMPTY_META = { meta: {} }

const STATUS = {
  ERROR: 'error',
  INITIAL: 'initial',
  SUCCESS: 'success',
}

const ModalGroup = ({ group, groupActions, i18n, invitations, onClose, onComplete, pageActions, profile }) => {
  const { frequency, frequencyType, name } = group

  const [deletedInvitations, setDeletedInvitations] = useState([])
  const [editedInvitations, setEditedInvitations] = useState([])
  const [failedInvitationList, setFailedInvitationList] = useState([])
  const [formValues, setFormValues] = useState({ name, frequency, frequencyType })
  const [invitationList, setInvitationList] = useState(invitations)
  const [isFetching, setIsFetching] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [messages, setMessages] = useState(null)
  const [newInvitations, setNewInvitations] = useState([])
  const [removedInvitationList, setRemovedInvitationList] = useState([])
  const [status, setStatus] = useState(STATUS.INITIAL)
  const [successInvitationList, setSuccessInvitationList] = useState([])

  useEffect(() => {
    pageActions.switchModalView()

    return () => {
      pageActions.switchModalView()
    }
  }, [])

  /**
   * @description Checks if the user is using it's own email
   * or if the email already exist in the list
   */

  const checkDuplicatedEmail = ({ email }) => {
    const lowerCaseEmail = email.toLowerCase()

    if (
      lowerCaseEmail === profile.email.toLowerCase() ||
      some(invitationList, ({ email: listEmail }) => listEmail.toLowerCase() === lowerCaseEmail)
    ) {
      return { email: i18n.generics.errors.email }
    }
    return {}
  }

  /**
   * @description Checks if the user added or deleted invitations
   * @returns {bool} True: invitations were updated | false: Invitations didn't change
   */

  const hasChangedInvitations = () => size(newInvitations) > 0 || size(deletedInvitations) > 0

  /**
   * @description Checks if the user edited invitations
   * @returns {bool} True: invitations were edited
   */

  const hasEditedInvitations = () => size(editedInvitations) > 0

  /**
   * @description closes the modal
   */

  const handleClose = () => {
    onClose()
  }

  /**
   * @description Excecutes the action to edit group invitation emails
   */

  const editGroupInvitationEmails = () =>
    fromCallbacksToPromise(groupActions.fetchUpdateGroupInvitationEmails, { inviteEmails: editedInvitations })

  /**
   * @description Excecutes the action to add or remove group invitations
   */

  const updateGroupInvitations = () =>
    fromCallbacksToPromise(groupActions.fetchUpdateGroupInvitations, {
      invites: newInvitations,
      removeInvites: deletedInvitations,
    })

  /**
   * @description changes the component state according the invitations sent
   */

  const inviteSuccessResults = (
    newSuccessInvitationList = [],
    newFailedInvitationList = [],
    newRemovedInvitationList = [],
  ) => {
    setFailedInvitationList(newFailedInvitationList)
    setRemovedInvitationList(newRemovedInvitationList)
    setSuccessInvitationList(newSuccessInvitationList)
    setIsFetching(false)
    setStatus(STATUS.SUCCESS)
  }

  /**
   * @description Includes all update Group actions
   * (update group, updates group invitations, updates invitations emails)
   */

  const handleUpdateGroup = () => {
    setIsFetching(true)

    let failedList = []
    let successfulList = []
    let removedList = []

    fromCallbacksToPromise(groupActions.fetchUpdateGroup, formValues)
      .then(() => {
        if (hasChangedInvitations()) {
          return updateGroupInvitations()
        }
        return EMPTY_META
      })
      .then(({ meta }) => {
        const { failedInvites = [], removedInvites = [], successfulInvites = [] } = meta

        failedList = failedInvites
        removedList = removedInvites
        successfulList = successfulInvites

        if (hasEditedInvitations()) {
          return editGroupInvitationEmails()
        }

        return EMPTY_META
      })
      .then(({ meta }) => {
        const { failedInvites = [], successfulInvites = [] } = meta

        failedList = [...failedList, ...failedInvites]
        successfulList = [...successfulList, ...successfulInvites]

        if (!isEmpty(failedList) || !isEmpty(removedList) || !isEmpty(successfulList)) {
          inviteSuccessResults(successfulList, failedList, removedList)
        } else {
          handleClose()
        }

        if (onComplete) {
          onComplete()
        }

        setIsFetching(false)
      })
      .catch(() => {
        setIsFetching(false)
        handleClose()
      })
  }

  /**
   * @description changes the state when an email is edited
   */

  const handleEditEmail = (invitationId, email) => {
    let newInvitation = null
    let newEditedInvitations = [...editedInvitations]
    let newNewInvitations = [...newInvitations]
    const oldInvitationList = [...invitationList]

    // update the invitationList with the new email
    const newInvitationList = oldInvitationList.map(invitation => {
      const { id } = invitation

      // invitation found, email replaced
      if (id === invitationId) {
        newInvitation = {
          ...invitation,
          email,
        }
        return newInvitation
      }

      return invitation
    })

    // if it is a new invitation, update the newInvitations List
    if (some(newInvitations, { id: invitationId })) {
      newNewInvitations = newInvitations.map(invitation => {
        if (invitation.id === invitationId) {
          return {
            ...invitation,
            email,
          }
        }
        return invitation
      })
      // Add the invitation edited to the editedInvitations list
    } else {
      newEditedInvitations = [...reject(editedInvitations, { id: invitationId }), newInvitation]
    }

    setEditedInvitations(newEditedInvitations)
    setInvitationList(newInvitationList)
    setNewInvitations(newNewInvitations)
  }

  /**
   * @description changes the state when an invitation is added
   */

  const handleAdd = invitation => {
    Object.assign(invitation, { groupId: group.id })

    setInvitationList([...invitationList, invitation])
    setNewInvitations([...newInvitations, invitation])
  }

  const handleOnValidateForm = (newFormValues, newIsValid) => {
    setFormValues(newFormValues)
    setIsValid(newIsValid)
  }

  /**
   * @description changes the state when removing an invitation
   */

  const handleRemoveInvitation = invitationId => {
    const oldDeletedInvitations = [...deletedInvitations]
    const oldInvitationList = [...invitationList]

    const newInvitationList = reject(oldInvitationList, { id: invitationId })
    const newDeletedInvitations = [...oldDeletedInvitations, invitationId]

    if (some(newInvitations, { id: invitationId })) {
      setInvitationList(newInvitationList)
      setNewInvitations(reject(newInvitations, { id: invitationId }))
    }

    if (some(editedInvitations, { id: invitationId })) {
      setInvitationList(newInvitationList)
      setDeletedInvitations(newDeletedInvitations)
      setEditedInvitations(reject(editedInvitations, { id: invitationId }))
    }

    setDeletedInvitations(newDeletedInvitations)
    setInvitationList(newInvitationList)
  }

  /**
   * @description renders the cancel button
   */

  const renderCancelButton = statusParam => {
    if (statusParam !== STATUS.INITIAL) {
      return null
    }

    return (
      <StyledButton
        className={styles.cancel}
        color="default"
        onClick={handleClose}
        title={i18n.generics.cancelLabel}
        variant="text"
      >
        {i18n.generics.cancelLabel}
      </StyledButton>
    )
  }

  /**
   * @description renders the done button
   */

  const renderDoneButton = () => {
    let isDisabled
    let onClick

    if (status === STATUS.INITIAL) {
      isDisabled = !isValid
      onClick = handleUpdateGroup
    } else {
      isDisabled = false
      onClick = handleClose
    }

    return (
      <StyledButton
        className={styles.invite}
        disabled={isDisabled}
        id="button-modal-people-invite"
        onClick={onClick}
        color="primary"
        title={i18n.generics.doneLabel}
      >
        {i18n.generics.doneLabel}
      </StyledButton>
    )
  }

  /**
   * @description renders the title
   */
  const renderTitle = () => {
    switch (status) {
      case STATUS.ERROR:
        return i18n.pageAdministration.modalPeople.title.error
      case STATUS.SUCCESS:
        return isEmpty(successInvitationList) && isEmpty(removedInvitationList)
          ? i18n.pageAdministration.modalPeople.title.error
          : i18n.pageAdministration.modalPeople.title.success
      default:
        return i18n.pageAdministration.modalPeople.title.editGroup
    }
  }

  /**
   * @description main render
   */
  return (
    <div className={styles.modal}>
      <Card barBackgroundColor={COLORS.primaryBlue.rgba} className={styles.card} isLoading={isFetching}>
        <Header className={styles.header}>
          <h1>{renderTitle()}</h1>
        </Header>
        <Icon.Stroke7
          className={styles.close}
          name="close"
          onClick={handleClose}
          title={i18n.pageAdministration.modalPeople.close}
        />
        <Main className={styles.main}>
          {status === STATUS.SUCCESS && (
            <BadgeResults
              failed={failedInvitationList}
              failedTitle={i18n.pageAdministration.modalPeople.resultMessage.failedEditGroup}
              removed={removedInvitationList}
              success={successInvitationList}
            />
          )}
          {status === STATUS.INITIAL && (
            <Container className={styles.informationFileReader}>
              {profile.can({ invite: 'sendInvitationViaCsv' }) && (
                <FormFileInvitation edit initialValues={group} onValidate={handleOnValidateForm} />
              )}
              {profile.can({ invite: 'sendInvitationManually' }) && (
                <>
                  <PersonForm
                    message={i18n.pageAdministration.modalPeople.formInvitation.editDescription}
                    onSubmit={handleAdd}
                    onValidate={checkDuplicatedEmail}
                    resetOnSubmit
                  />
                  {!isEmpty(invitationList) && (
                    <SelectedRecords
                      className={styles['selected-records']}
                      list={invitationList}
                      onEditEmail={handleEditEmail}
                      onRemove={handleRemoveInvitation}
                      onValidate={checkDuplicatedEmail}
                    />
                  )}
                </>
              )}
            </Container>
          )}
          {status === STATUS.ERROR && <ModalErrors errors={messages} />}
        </Main>

        <Footer>
          <Row>
            <Col xs="6">{renderCancelButton(status)}</Col>
            <Col xs="6">{renderDoneButton(status)}</Col>
          </Row>
        </Footer>
      </Card>
    </div>
  )
}

ModalGroup.propTypes = {
  group: INVITATION_GROUP,
  groupActions: ACTIONS.isRequired,
  i18n: I18N.isRequired,
  invitations: INVITATIONS,
  onClose: PropTypes.func,
  onComplete: PropTypes.func,
  pageActions: ACTIONS.isRequired,
  profile: PERSON.isRequired,
}

ModalGroup.defaultProps = {
  group: undefined,
  invitations: [],
  onClose: undefined,
  onComplete: undefined,
}

export default Translation(ModalGroup, ['pageAdministration', 'errors', 'generics'])
