import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import * as notificationActions from 'app_modules/notifications/actions'
import * as trainingActions from 'app_modules/training/actions'
import * as trainingSelectors from 'app_modules/training/selectors'
import PageTraining from 'components/PageTraining'

const mapStateToProps = state => ({
  trainings: trainingSelectors.trainings(state),
})

const mapDispatchToProps = dispatch => ({
  notificationActions: bindActionCreators(notificationActions, dispatch),
  trainingActions: bindActionCreators(trainingActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageTraining))
