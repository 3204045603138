import { Card, Main } from 'generics/Card'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { ACTIONS, PEOPLE, I18N } from 'constants/props'
import { isDateExpired } from 'helpers'
import Avatar from 'generics/Avatar'
import Icon from 'generics/Icon'
import Translation from 'generics/Translation'

import styles from './LinkedNetworks.scss'

const LinkedNetworks = ({ actions, className, defaultAccount, i18n, profiles }) => {
  const handleChangeDefaultAccount = accountId => {
    actions.setDefaultAccount(parseInt(accountId, 10))
  }

  /**
   * @description Renders Account Name
   * @param {boolean} isExpired - If the account has expired
   * @param {boolean} hasOneAccount - if it's the only account related to the current user
   * @returns Text or the radio to select the account as default
   */
  const renderName = (account, isExpired, hasOneAccount) =>
    isExpired || hasOneAccount ? (
      <span className={classNames(styles['text-only'], { [styles['text-only__expired']]: isExpired })}>
        {account.name}
      </span>
    ) : (
      <FormControlLabel
        classes={{ label: styles.label, disabled: styles['control-label__disabled'], root: styles['control-label'] }}
        value={account.id}
        control={<Radio color="primary" title={i18n.pageGeneralAccountInformation.linkedNetworks.labelTitle} />}
        label={account.name}
      />
    )

  /**
   * @description Renders Account Name
   * @param {boolean} isDefault - If it is the default account
   * @param {boolean} isExpired - If the account has expired
   * @returns Status render
   */
  const renderStatus = (isDefault, isExpired) => {
    let statusLabel = ''
    let iconName = ''

    if (isExpired) {
      statusLabel = i18n.pageGeneralAccountInformation.linkedNetworks.expired
      iconName = 'timer'
    } else if (isDefault) {
      statusLabel = i18n.pageGeneralAccountInformation.linkedNetworks.default
      iconName = 'check'
    }

    return (
      statusLabel && (
        <div className={styles.status}>
          <Icon.Stroke7 className={styles.status__icon} name={iconName} />
          <span className={styles.status__text}>{statusLabel}</span>
        </div>
      )
    )
  }

  const accountList = profiles.map(({ account }) => {
    const isDefault = account.id === defaultAccount.id
    const isExpired = isDateExpired(account.expiresAt)
    const hasOneAccount = profiles.length === 1

    return (
      <li data-test="linked-network-list" className={styles['network-item']} key={account.id}>
        <Avatar
          className={styles['network-icon']}
          icon={{
            name: account.isPersonal ? 'user-female' : 'global',
          }}
          src={account.logo.url}
        />
        <div data-test="linked-network-list-name" className={styles.text} >
          {renderName(account, isExpired, hasOneAccount)}
          {!hasOneAccount && renderStatus(isDefault, isExpired)}
        </div>
      </li>
    )
  })

  return (
    <Card className={className} isFetching={!profiles}>
      <header className={styles.header}>{i18n.pageGeneralAccountInformation.linkedNetworks.header}</header>
      <Main className={styles.main}>
        <RadioGroup
          name="defaultAccount"
          onChange={e => handleChangeDefaultAccount(e.currentTarget.value)}
          value={defaultAccount.id}
        >
          <ul className={styles['network-content']}>{accountList}</ul>
        </RadioGroup>
      </Main>
    </Card>
  )
}

LinkedNetworks.propTypes = {
  className: PropTypes.string,
  defaultAccount: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  i18n: I18N.isRequired,
  profiles: PEOPLE.isRequired,
  actions: ACTIONS.isRequired,
}

LinkedNetworks.defaultProps = {
  className: null,
}

export default Translation(LinkedNetworks, ['pageGeneralAccountInformation'])
