const NAME_SPACE = 'Profiles'

const constants = {
  FETCH_COMPLETE_SSO_PROVISION_FAILURE: `${NAME_SPACE}/FETCH_COMPLETE_SSO_PROVISION_FAILURE`,
  FETCH_COMPLETE_SSO_PROVISION_REQUEST: `${NAME_SPACE}/FETCH_COMPLETE_SSO_PROVISION_REQUEST`,
  FETCH_COMPLETE_SSO_PROVISION_SUCCESS: `${NAME_SPACE}/FETCH_COMPLETE_SSO_PROVISION_SUCCESS`,
  FETCH_COMPLETE_SSO_PROVISION: `${NAME_SPACE}/FETCH_COMPLETE_SSO_PROVISION`,
  FETCH_CREATE_USER_FAILURE: `${NAME_SPACE}/FETCH_CREATE_USER_FAILURE`,
  FETCH_CREATE_USER_REQUEST: `${NAME_SPACE}/FETCH_CREATE_USER_REQUEST`,
  FETCH_CREATE_USER_SUCCESS: `${NAME_SPACE}/FETCH_CREATE_USER_SUCCESS`,
  FETCH_CREATE_USER: `${NAME_SPACE}/FETCH_CREATE_USER`,
  FETCH_DELETE_PROFILE_FAILURE: `${NAME_SPACE}/FETCH_DELETE_PROFILE_FAILURE`,
  FETCH_DELETE_PROFILE_REQUEST: `${NAME_SPACE}/FETCH_DELETE_PROFILE_REQUEST`,
  FETCH_DELETE_PROFILE_SUCCESS: `${NAME_SPACE}/FETCH_DELETE_PROFILE_SUCCESS`,
  FETCH_DELETE_PROFILE: `${NAME_SPACE}/FETCH_DELETE_PROFILE`,
  EDIT_DEPARTMENT_FAILURE: `${NAME_SPACE}/EDIT_DEPARTMENT_FAILURE`,
  EDIT_DEPARTMENT_REQUEST: `${NAME_SPACE}/EDIT_DEPARTMENT_REQUEST`,
  EDIT_DEPARTMENT_SUCCESS: `${NAME_SPACE}/EDIT_DEPARTMENT_SUCCESS`,
  EDIT_DEPARTMENT: `${NAME_SPACE}/EDIT_DEPARTMENT`,
  FETCH_EDIT_PROFILE_FAILURE: `${NAME_SPACE}/FETCH_EDIT_PROFILE_FAILURE`,
  FETCH_EDIT_PROFILE_REQUEST: `${NAME_SPACE}/FETCH_EDIT_PROFILE_REQUEST`,
  FETCH_EDIT_PROFILE_SUCCESS: `${NAME_SPACE}/FETCH_EDIT_PROFILE_SUCCESS`,
  FETCH_EDIT_PROFILE: `${NAME_SPACE}/FETCH_EDIT_PROFILE`,
  FETCH_UPDATE_AVATAR_FAILURE: `${NAME_SPACE}/FETCH_UPDATE_AVATAR_FAILURE`,
  FETCH_UPDATE_AVATAR_REQUEST: `${NAME_SPACE}/FETCH_UPDATE_AVATAR_REQUEST`,
  FETCH_UPDATE_AVATAR_SUCCESS: `${NAME_SPACE}/FETCH_UPDATE_AVATAR_SUCCESS`,
  FETCH_UPDATE_AVATAR: `${NAME_SPACE}/FETCH_UPDATE_AVATAR`,
}

export default constants
