/**
 * @fileoverview Selectors for credentials state
 * @module sensaiAi/selectors/credentialsSelectors
 */

/**
 * Base selector for credentials state
 * @param {Object} state - Global state
 * @returns {Object} Credentials state
 */
const getCredentialsState = state => {
  return state?.sensaiAi?.credentials || { appSecret: null, licenseKey: null }
}

/**
 * Selector for retrieving sensai credentials
 * @param {Object} state - Global state
 * @returns {Object} Credentials object
 */
export const getSensaiCredentials = state => getCredentialsState(state)

/**
 * Selector for checking if credentials are available
 * @param {Object} state - Global state
 * @returns {boolean} Whether credentials are available
 */
export const hasCredentials = state => {
  const credentials = getCredentialsState(state)
  return Boolean(credentials.appSecret && credentials.licenseKey)
}

export default {
  getSensaiCredentials,
  hasCredentials,
}
