import { pickHTMLProps } from 'pick-react-known-prop'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { CHILDREN } from 'constants/props'

import styles from '../Card/card.scss'

const Footer = props => {
  const { children, className, ...rest } = props

  return (
    <footer className={classnames(styles.card__footer, className)} {...pickHTMLProps(rest)}>
      {children}
    </footer>
  )
}

Footer.propTypes = {
  children: CHILDREN,
  className: PropTypes.string,
}

Footer.defaultProps = {
  children: null,
  className: null,
}

export default Footer
