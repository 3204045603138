import { combineReducers } from 'redux'
import { uniqBy } from 'lodash'

import initialState from 'app_modules/store/initialState.json'
import proMemberActionTypes from 'app_modules/proMember/constants'
import sessionActionTypes from 'app_modules/session/constants'

export const modal = (state = initialState.proMember.modal, { payload, type }) => {
  switch (type) {
    case proMemberActionTypes.TOGGLE_PRO_MEMBER_MODAL:
      return payload

    default:
      return state
  }
}

export const memberUpgradeRequests = (state = initialState.proMember.memberUpgradeRequests, { payload, type }) => {
  switch (type) {
    case proMemberActionTypes.FETCH_UPGRADE_MEMBER_REQUESTS_REQUEST:
      if (payload === 1) {
        return {
          ...state,
          isFetching: true,
          list: [],
        }
      }
      return {
        ...state,
        isFetching: true,
      }

    case proMemberActionTypes.FETCH_UPGRADE_MEMBER_REQUESTS_SUCCESS:
      return {
        ...payload,
        isFetching: false,
        list: uniqBy(
          [
            ...state.list,
            ...payload.licenseRequests.map(item => ({
              ...item,
              name: `${item.profile.firstName} ${item.profile.lastName}`,
            })),
          ],
          'id',
        ),
        meta: { ...payload.meta },
      }

    case proMemberActionTypes.FETCH_UPGRADE_MEMBER_REQUESTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        list: [],
        meta: {
          morePages: false,
          totalObjects: payload?.isEmptyList ? 0 : state.meta.totalObjects,
        },
      }

    case sessionActionTypes.RESET_STATE:
      return initialState.proMember.memberUpgradeRequests

    default:
      return state
  }
}

export default combineReducers({
  memberUpgradeRequests,
  modal,
})
