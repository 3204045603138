/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'

import { REQUIRED } from 'constants/inputTypes'
import { I18N } from 'constants/props'
import useForm from 'custom_hooks/useForm'
import useFormValidation from 'custom_hooks/useFormValidation'

const useFormFilterOwners = ({ i18n, initialValues, onSubmit }) => {
  const { validator } = useFormValidation({ i18n })

  const handleValidate = values => {
    const { ownerFilter } = values

    return {
      ownerFilter: validator.validate(REQUIRED, ownerFilter),
    }
  }

  const { errors, isValid, handleChange, handleSubmit, values = { email: null, password: null } } = useForm({
    onValidate: handleValidate,
    initialValues,
    onSubmit,
  })

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  }
}

useFormFilterOwners.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
}

export default useFormFilterOwners
