import { Col, Container, Row } from 'reactstrap'
import { sprintf } from 'sprintf-js'
import Avatar from 'generics/Avatar'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { CHILDREN, I18N, PERSON } from 'constants/props'
import { Card, Main } from 'generics/Card'
import { StyledButton } from 'generics/StyledFormComponents'
import DefinitionList from 'generics/DefinitionList'
import PictureSelectorModal from 'generics/PictureSelectorModal'
import Translation from 'generics/Translation'

import styles from './AccountProfile.scss'

export const useAccountProfileElements = ({ onUpdateAvatar }) => {
  const [isPictureModalOpen, setIsPictureModalOpen] = useState(false)

  /**
   * Handles Open/Close Picture Selector Modal
   */
  const handleOpenClosePictureModal = () => {
    setIsPictureModalOpen(!isPictureModalOpen)
  }

  /**
   * Handles save picture event
   * @param {object} image
   */
  const handleSavePicture = image => {
    if (!onUpdateAvatar) {
      return
    }

    handleOpenClosePictureModal()
    onUpdateAvatar(image)
  }

  /**
   * Handles the remove picture event
   */
  const handleRemovePicture = () => {
    handleSavePicture(null)
  }

  return {
    editPictureBtn: {
      onClick: handleOpenClosePictureModal,
    },
    isPictureModalOpen,
    pictureSelectorModal: {
      onClose: handleOpenClosePictureModal,
      onRemovePicture: handleRemovePicture,
      onSavePicture: handleSavePicture,
    },
  }
}

/**
 * AccountProfile component
 * @extends Component
 */
export const AccountProfile = ({
  canEdit,
  children,
  className,
  i18n,
  isFetching,
  isVisiblePersonalInfo,
  onGetData,
  onUpdateAvatar,
  profile,
}) => {
  const { name } = profile
  const editPictureText = i18n.accountProfile.editPicture
  const avatarTitle = sprintf(i18n.accountProfile.avatarTitle, name)

  const { editPictureBtn, isPictureModalOpen, pictureSelectorModal } = useAccountProfileElements({ onUpdateAvatar })

  return (
    <Card barClassName={styles.bar} className={className} isFetching={isFetching}>
      {isVisiblePersonalInfo && (
        <header className={styles.header}>
          <Avatar
            className={styles.avatar}
            hasBorder
            icon={{ className: styles.avatar__icon }}
            profile={profile}
            title={avatarTitle}
          />

          <Container>
            <Row>
              <Col>
                <div className={styles['full-name']}>
                  <span title={name}>{name}</span>
                </div>

                {canEdit && (
                  <StyledButton
                    className={styles['edit-picture']}
                    color="secondary"
                    title={editPictureText}
                    variant="text"
                    {...editPictureBtn}
                  >
                    {editPictureText}
                  </StyledButton>
                )}
              </Col>
            </Row>
          </Container>
        </header>
      )}
      <Main className={styles.main}>
        <DefinitionList data={onGetData()} />
        {children}
      </Main>
      {isPictureModalOpen && (
        <PictureSelectorModal
          pictureUrl={profile.avatar}
          uploadMessage={i18n.accountProfile.uploadMessage}
          {...pictureSelectorModal}
        />
      )}
    </Card>
  )
}

AccountProfile.propTypes = {
  canEdit: PropTypes.bool,
  children: CHILDREN,
  className: PropTypes.string,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool,
  isVisiblePersonalInfo: PropTypes.bool,
  onGetData: PropTypes.func.isRequired,
  onUpdateAvatar: PropTypes.func,
  profile: PERSON.isRequired,
}

AccountProfile.defaultProps = {
  canEdit: false,
  children: null,
  className: null,
  isFetching: false,
  isVisiblePersonalInfo: true,
  onUpdateAvatar: null,
}

export default Translation(AccountProfile, ['accountProfile'])
