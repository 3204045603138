import React from 'react'
const PaymentSuccessIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 100 100" fill="none" className={props.className}>
            <path d="M34.5839 91.2509L28.1256 80.2092L15.2089 77.605C14.445 77.4662 13.8027 77.0495 13.2818 76.355C12.761 75.6606 12.57 74.9314 12.7089 74.1675L14.1672 61.6675L5.93807 52.0842C5.38251 51.5287 5.10474 50.8342 5.10474 50.0009C5.10474 49.1675 5.38251 48.4731 5.93807 47.9175L14.1672 38.4384L12.7089 25.9384C12.57 25.1745 12.761 24.4453 13.2818 23.7509C13.8027 23.0564 14.445 22.6398 15.2089 22.5009L28.1256 19.8967L34.5839 8.75087C35.0006 8.05643 35.5908 7.57032 36.3547 7.29254C37.1186 7.01476 37.8825 7.04948 38.6464 7.3967L50.0006 12.7092L61.3547 7.3967C62.1186 7.04948 62.8825 6.9974 63.6464 7.24045C64.4103 7.48351 65.0006 7.95226 65.4172 8.6467L71.9797 19.8967L84.7922 22.5009C85.5561 22.6398 86.1985 23.0564 86.7193 23.7509C87.2402 24.4453 87.4311 25.1745 87.2922 25.9384L85.8339 38.4384L94.0631 47.9175C94.6186 48.4731 94.8964 49.1675 94.8964 50.0009C94.8964 50.8342 94.6186 51.5287 94.0631 52.0842L85.8339 61.6675L87.2922 74.1675C87.4311 74.9314 87.2402 75.6606 86.7193 76.355C86.1985 77.0495 85.5561 77.4662 84.7922 77.605L71.9797 80.2092L65.4172 91.355C65.0006 92.0495 64.4103 92.5182 63.6464 92.7613C62.8825 93.0043 62.1186 92.9523 61.3547 92.605L50.0006 87.2925L38.6464 92.605C37.8825 92.9523 37.1186 92.987 36.3547 92.7092C35.5908 92.4314 35.0006 91.9453 34.5839 91.2509ZM45.5214 54.8967L37.9172 46.98C37.2922 46.2856 36.5283 45.9384 35.6256 45.9384C34.7228 45.9384 33.9242 46.2509 33.2297 46.8759C32.5353 47.5703 32.1881 48.3689 32.1881 49.2717C32.1881 50.1745 32.5353 50.9731 33.2297 51.6675L43.3339 61.6675C43.9589 62.2925 44.6881 62.605 45.5214 62.605C46.3547 62.605 47.0839 62.2925 47.7089 61.6675L66.7714 42.7092C67.3964 42.0842 67.7089 41.3203 67.7089 40.4175C67.7089 39.5148 67.3617 38.7509 66.6672 38.1259C66.0422 37.5703 65.2957 37.3099 64.4277 37.3446C63.5596 37.3793 62.8478 37.6745 62.2922 38.23L45.5214 54.8967Z" fill="#3C92C1" />
        </svg>
    )
}

export default PaymentSuccessIcon;
