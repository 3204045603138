/**
 * @description Configuration settings for the Sensai API
 * @module constants/sensai
 */
export const BASE_URL = process.env.SENSAI_API_BASE_URL

export const SENSAI_CONFIG = {
  DEFAULT_PARAMS: {
    skip: 0, // Default number of records to skip in paginated requests
    limit: 20, // Default number of records to return in paginated requests
  },
  HEADERS: {
    DEFAULT: {
      accept: '*/*', // Accept all content types
      'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8', // Default language preferences
      'Content-Type': 'application/json', // Default content type for requests
    },
  },
}

/**
 * @description Endpoints for the Sensai API
 * @module constants/sensai
 */
export const SENSAI_ENDPOINTS = {
  /**
   * @description Endpoint to get the status of a specific account
   * @param {number} accountId - Account identifier
   * @returns {string} Formatted endpoint URL
   */
  STATUS: accountId => `/sensai/${accountId}/status`,

  /**
   * @description Endpoint to access coaches data
   * @returns {string} Endpoint URL
   */
  COACHES: '/coaches',

  /**
   * @description Endpoint to create a coach
   * @param {number} accountId - Account identifier
   * @returns {string} Endpoint URL
   */
  CREATE_COACH: accountId => `/sensai/${accountId}/coach`,

  /**
   * @description Endpoint to get the status of a coach
   * @returns {string} Endpoint URL
   */
  COACH_STATUS: requestId => `/coaches/status?requestId=${requestId}`,
}
