/**
 * @fileoverview API utility functions for the SensaiAI module
 * @module sensaiAi/utils/apiUtils
 */

import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import { ERROR_MESSAGES } from '../constants/errorMessages'

/**
 * Validates the required credentials for API calls
 * @param {string} accountId - The account ID
 * @param {string} appSecret - The application secret
 * @param {string} licenseKey - The license key
 * @throws {Error} If any required credential is missing
 */
export const validateCredentials = (accountId, appSecret, licenseKey) => {
  if (!accountId || !appSecret || !licenseKey) {
    throw new Error(ERROR_MESSAGES.MISSING_CREDENTIALS)
  }
}

/**
 * Handles API errors consistently across the module
 * @param {Error} error - The error object from the API
 * @param {Function} dispatch - Redux dispatch function
 * @param {Function} errorAction - Action creator for the error
 * @param {string} errorLabel - Label for the error notification
 * @returns {Promise} Rejected promise with the error
 */
export const handleApiError = (error, dispatch, errorAction, errorLabel) => {
  dispatch(errorAction(error))
  if (!error.isConnectionError) {
    dispatch(notifyError(new BarError({ ...error, label: errorLabel })))
  }
  return Promise.reject(error)
}

/**
 * Creates standard API request parameters
 * @param {string} accountId - The account ID
 * @param {Object} credentials - The API credentials
 * @param {Object} additionalParams - Additional parameters to include
 * @returns {Object} Combined API parameters
 */
export const createApiParams = (accountId, credentials, additionalParams = {}) => ({
  userId: accountId.toString(),
  appSecret: credentials.appSecret,
  licenseKey: credentials.licenseKey,
  ...additionalParams,
})
