import { connect } from 'react-redux'

import * as sessionSelectors from 'app_modules/session/selectors'
import PageSSOLoginProviders from 'components/PageSSOLoginProviders'

const mapStateToProps = state => ({
  accountId: sessionSelectors.accountId(state),
  isAssessmentCompleted: state.assessment.isCompleted,
  isAuthenticated: state.session.isAuthenticated,
})

export default connect(mapStateToProps)(PageSSOLoginProviders)
