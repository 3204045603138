import { isNil } from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { balloonShape } from 'constants/charts'
import d3 from 'vendor/d3'

import styles from './TeamGridBalloon.scss'

const DELIBERATE_SIZE = 4
const EFFORTLESS_SIZE = 10
const ABUNDANT_SIZE = 15

const scaleWidth = d3.scaleLinear().domain([0, 15]).range([25.9, 48.13])

const scaleScore = d3.scaleLinear().domain([0, 15]).range([0.4, 0.5])

const getEnergySize = score => {
  // Reserved are 0
  let newScore = 0
  const roundedScore = parseInt(score, 10)

  // Deliberate and Deliberate +
  if (roundedScore > 1 && roundedScore <= 8) {
    newScore = DELIBERATE_SIZE
    // Effortless and Effortless +
  } else if (roundedScore > 8 && roundedScore <= 13) {
    newScore = EFFORTLESS_SIZE
    // Abundant
  } else if (roundedScore > 13) {
    newScore = ABUNDANT_SIZE
  }

  return newScore
}

const TeamGridBalloon = props => {
  const { className, energy, score, shape, testAttribute } = props

  if (isNil(score)) {
    return null
  }

  const energySize = getEnergySize(score)

  const scoreScaled = scaleScore(energySize)

  const { className: shapeClassName } = shape

  const hasPlus = energy.endsWith('+')

  const plusSize = 0.3 * scoreScaled

  const aspectRatio = 1.207

  return (
    <svg
      className={classnames(styles.svg, className)}
      data-test={testAttribute}
      height={aspectRatio * scaleWidth(energySize)}
      viewBox={`0 0 1 ${aspectRatio}`}
      width={scaleWidth(energySize)}
    >
      <g className={shapeClassName} transform={`translate(0.5,${aspectRatio}) rotate(-135)`}>
        <path d={balloonShape(scoreScaled)} />
        <title>{energy}</title>
      </g>
      {hasPlus && (
        <g className={styles.plus} transform={`translate(0.5,${0.85 * aspectRatio - scoreScaled})`}>
          <line x1={-plusSize} x2={plusSize} />
          <line y1={-plusSize} y2={plusSize} />
        </g>
      )}
    </svg>
  )
}

TeamGridBalloon.propTypes = {
  className: PropTypes.string,
  energy: PropTypes.string,
  score: PropTypes.number,
  shape: PropTypes.shape({
    className: PropTypes.string,
  }),
  testAttribute: PropTypes.string,
}

TeamGridBalloon.defaultProps = {
  className: null,
  energy: null,
  score: null,
  shape: {
    className: null,
  },
  testAttribute: null,
}

export default TeamGridBalloon
