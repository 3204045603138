export const en = {
  accountProfileData: {
    accountType: 'Account Type',
    numberOfConnections: 'Number of Connections',
    numberOfTeams: 'Number of Teams Created by %s',
    privacy: 'Privacy',
    profilePrivacy: {
      private: 'Private Profile',
      public: 'Public Profile',
    },
    role: 'Role',
  },
  breadcrumbs: ['Network', 'Account Information'],
  edit: 'Edit',
  modalAssignRole: {
    description: 'Select the Role or Roles you wish to add to this member',
    done: 'Done',
    header: 'Assign Role',
  },
}

export const es = en
