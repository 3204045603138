/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { sprintf } from 'sprintf-js'
import { useState, useEffect, useRef } from 'react'

import { API_NOT_FOUND, CAN_NOT_CONNECT } from 'constants/apiErrorCodes'
import { DOWNLOADED, SHARED } from 'constants/reportStatus'
import { BarError } from 'classes/errors'
import { getCSSFromSVGs } from 'helpers'
import { Person } from 'classes'
import api from 'api'

const useUseTokens = ({ accountId, i18n, notificationActions, onFetchNetworkDetails, profileId }) => {
  const [isCancelReportConfirmationOpen, setIsCancelReportConfirmationOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSendReportRequestModalOpen, setIsSendReportRequestModalOpen] = useState(false)
  const [reports, setReports] = useState([])
  const [reportsMeta, setReportsMeta] = useState({ morePages: false })
  const [searchParams, setSearchParams] = useState({})
  const [selectedReport, setSelectedReport] = useState(null)

  const energyMapRefs = useRef({})

  useEffect(() => {
    onFetchNetworkDetails(accountId, profileId)
  }, [])

  const handleCreateAPGIReport = (id, fullName) => {
    setIsLoading(true)

    const { svgs } = energyMapRefs.current[id] || {}

    const { svg } = svgs ? svgs[0] : {}

    const css = svgs ? getCSSFromSVGs(svgs) : null

    const onSuccess = () => {
      onFetchNetworkDetails(accountId, profileId)
      getBulkReportList({ ...searchParams })
      setIsLoading(false)
    }

    const onError = ({ error }) => {
      setIsLoading(false)
      if (error.errorCode === CAN_NOT_CONNECT || error.errorCode === API_NOT_FOUND) {
        notificationActions.notifyError(
          new BarError({
            ...error,
            label: 'invalidadOwnerAction',
          }),
        )
      }
    }

    api.files.createAPGIReportPDF(
      {
        accountId,
        css,
        profile: {
          fullName,
          id,
          svg,
        },
      },
      onSuccess,
      onError,
    )
  }

  const handleDownloadAPGIReport = (id, fullName) => {
    setIsLoading(true)

    const { svgs } = energyMapRefs.current[id] || {}

    const { svg } = svgs ? svgs[0] : {}

    const css = svgs ? getCSSFromSVGs(svgs) : null

    const onSuccess = () => {
      setIsLoading(false)
    }

    const onError = ({ error }) => {
      setIsLoading(false)
      if (error.errorCode === CAN_NOT_CONNECT || error.errorCode === API_NOT_FOUND) {
        notificationActions.notifyError(
          new BarError({
            ...error,
            label: 'invalidadOwnerAction',
          }),
        )
      }
    }

    api.files.downloadAPGIReportPDF(
      {
        accountId,
        css,
        profile: {
          fullName,
          id,
          svg,
        },
      },
      onSuccess,
      onError,
    )
  }

  const handleSendNewInvitation = id => {
    setIsLoading(true)

    const onSuccess = () => {
      onFetchNetworkDetails(accountId, profileId)
      getBulkReportList({ ...searchParams })
      setIsLoading(false)
      notificationActions.notifySuccess(i18n.pageTokenAdministration.useTokens.sendNewInvitationSuccessful)
    }

    const onError = ({ error }) => {
      setIsLoading(false)
      if (error.errorCode === CAN_NOT_CONNECT || error.errorCode === API_NOT_FOUND) {
        notificationActions.notifyError(
          new BarError({
            ...error,
            label: 'invalidadOwnerAction',
          }),
        )
      }
    }

    api.files.sendNewInvitation(
      {
        accountId,
        reportId: id,
      },
      onSuccess,
      onError,
    )
  }

  const handleCancelReportAccept = () => {
    setIsLoading(true)

    const onSuccess = () => {
      setIsLoading(false)
      setIsCancelReportConfirmationOpen(false)
      onFetchNetworkDetails(accountId, profileId)
      getBulkReportList({ ...searchParams })
    }

    const onError = ({ error }) => {
      setIsLoading(false)
      setIsCancelReportConfirmationOpen(false)
      if (error.errorCode === CAN_NOT_CONNECT || error.errorCode === API_NOT_FOUND) {
        notificationActions.notifyError(
          new BarError({
            ...error,
            label: 'invalidadOwnerAction',
          }),
        )
      } else {
        notificationActions.notifyError(
          new BarError({
            ...error,
            message: error?.message,
          }),
        )
      }
    }

    api.tokens.cancelReportRequest({ accountId, reportId: selectedReport }, onSuccess, onError)
  }

  const handleCancelReportCancel = () => {
    setIsCancelReportConfirmationOpen(false)
    setSelectedReport(undefined)
  }

  const handleCancelReportConfirmation = id => {
    setIsCancelReportConfirmationOpen(true)
    setSelectedReport(id)
  }

  const handleSendReportReminder = (reportId, name) => {
    setIsLoading(true)

    const onSuccess = () => {
      setIsLoading(false)
      const notificationMessage = sprintf(i18n.pageTokenAdministration.useTokens.sendReportReminderSuccessful, name)
      notificationActions.notifySuccess(notificationMessage)
      onFetchNetworkDetails(accountId, profileId)
    }

    const onError = ({ error }) => {
      setIsLoading(false)
      if (error.errorCode === CAN_NOT_CONNECT || error.errorCode === API_NOT_FOUND) {
        notificationActions.notifyError(
          new BarError({
            ...error,
            label: 'invalidadOwnerAction',
          }),
        )
      } else {
        notificationActions.notifyError(
          new BarError({
            ...error,
            message: error?.message,
          }),
        )
      }
    }

    api.tokens.sendReportReminder({ accountId, reportId }, onSuccess, onError)
  }

  const getBulkReportList = ({ pageIndex, search }) => {
    setSearchParams({ pageIndex, search })
    setIsLoading(true)

    const onSuccess = ({ externalReports, meta }) => {
      if (externalReports?.length) {
        const newReports = externalReports.map(report =>
          report.status === SHARED || report.status === DOWNLOADED
            ? {
                ...report,
                profile: new Person({ assessmentScores: report.personalProfileScore }),
              }
            : report,
        )
        if (pageIndex === 1) {
          setReports(newReports)
        } else {
          const oldReports = [...reports]
          for (let i = 0; i < newReports.length; i += 1) {
            const indexToModify = oldReports.findIndex(r => r.id === newReports[i].id)
            if (indexToModify !== -1) {
              if (oldReports[indexToModify].status !== newReports[i].status) {
                oldReports[indexToModify] = newReports[i]
              }
            } else {
              oldReports.push(newReports[i])
            }
          }
          setReports([...oldReports])
        }
      }
      setReportsMeta(meta)
      setIsLoading(false)
    }

    const onError = ({ error }) => {
      setIsLoading(false)
      if (error.errorCode === API_NOT_FOUND) {
        setReports([])
        setReportsMeta({ morePages: false })
      }
    }

    api.tokens.getBulkReportList({ accountId, pageIndex, search }, onSuccess, onError)
  }

  const sendReportRequest = values => {
    const onSuccess = () => {
      onFetchNetworkDetails(accountId, profileId)
      getBulkReportList({ ...searchParams })
      setIsSendReportRequestModalOpen(false)
      notificationActions.notifySuccess(i18n.pageTokenAdministration.useTokens.sendReportRequestSuccessful)
    }

    const onError = ({ error }) => {
      setIsSendReportRequestModalOpen(false)
      notificationActions.notifyError(
        new BarError({
          ...error,
          message: error?.message,
        }),
      )
    }

    api.tokens.sendReportRequest({ accountId, values }, onSuccess, onError)
  }

  return {
    energyMapRefs,
    handleCancelReportAccept,
    handleCancelReportCancel,
    handleCancelReportConfirmation,
    handleCreateAPGIReport,
    handleDownloadAPGIReport,
    handleSendNewInvitation,
    handleSendReportReminder,
    getBulkReportList,
    isCancelReportConfirmationOpen,
    isLoading,
    isSendReportRequestModalOpen,
    sendReportRequest,
    setIsSendReportRequestModalOpen,
    reportsMeta,
    reports,
  }
}

export default useUseTokens
