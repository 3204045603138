import {
  ACCOUNT_EXPIRED,
  INVALID_USER_OR_TOKEN,
  USERS_ACCOUNT_BLOCKED,
  RESET_PASSWORD_REQUIRED,
} from 'constants/apiErrorCodes'

import { BarError } from 'classes/errors'
import { isDateExpired } from 'helpers'
import { isEmpty } from 'lodash'
import { notifyError, notifyWarning } from 'app_modules/notifications/actions'
import { PENDING_PRO_MEMBER, PRE_ACCEPTED } from 'constants/invitationStatus'
import { resetState } from 'app_modules/session/actions/resetState'
import * as invitesSelectors from 'app_modules/invites/selectors'
import actionTypes from 'app_modules/session/constants'
import api from 'api'

import { setUser } from './setUser'

// Actions

export const fetchLoginFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_LOGIN_FAILURE,
})

export const fetchLoginRequest = () => ({
  type: actionTypes.FETCH_LOGIN_REQUEST,
})

export const fetchLoginSuccess = () => ({
  type: actionTypes.FETCH_LOGIN_SUCCESS,
})

// Thunks

export const login = (credentials, onError) => {
  const { token: invitationToken } = credentials

  const thunk = (dispatch, getState) => {
    dispatch(resetState({ omit: ['invites'] }))
    dispatch(fetchLoginRequest())

    const handleResponse = ({ user }) => {
      const { defaultAccount, profiles } = user

      const state = getState()

      const { account, status: invitationStatus } = invitesSelectors.invitation(state)

      const { id: invitationId } = account || {}

      const isExpired = defaultAccount && isDateExpired(defaultAccount.expiresAt)
      const isInvitationPending = invitationStatus === PENDING_PRO_MEMBER
      const isInvitationPreAccepted = invitationStatus === PRE_ACCEPTED
      let labelError = ''

      if (isEmpty(profiles)) {
        labelError = 'loginError'
      } else if (isExpired && !isInvitationPending && !isInvitationPreAccepted) {
        labelError = 'accountExpired'
      }

      if (!isEmpty(labelError)) {
        const error = new BarError({ label: labelError })
        dispatch(fetchLoginFailure(error))
        dispatch(notifyError(error))
        return onError()
      }

      const promises = [
        dispatch(setUser(user, isInvitationPending, isInvitationPreAccepted, invitationId)),
        dispatch(fetchLoginSuccess()),
      ]

      /* if (invitationStatus === PRE_ACCEPTED && !invitationToken) {
        promises.push(dispatch(acceptInvitation(true)));
      } */

      return Promise.all(promises)
    }

    const handleError = ({ error, isConnectionError }) => {
      let label = ''
      let notificationAction = ''

      switch (error.errorCode) {
        case INVALID_USER_OR_TOKEN:
          label = 'invalidEmailOrPassword'
          notificationAction = notifyError
          break
        case ACCOUNT_EXPIRED:
          label = 'accountExpired'
          notificationAction = notifyWarning
          break
        case USERS_ACCOUNT_BLOCKED:
          label = 'loginAccountBlocked'
          notificationAction = notifyWarning
          break
        case RESET_PASSWORD_REQUIRED:
          label = 'resetPasswordRequired'
          notificationAction = notifyWarning
          break
        default:
          label = 'loginError'
          notificationAction = notifyError
      }

      dispatch(fetchLoginFailure(error))

      if (!isConnectionError) {
        dispatch(notificationAction(new BarError({ ...error, label })))
      }
      onError()
    }

    return invitationToken
      ? api.session.loginLinkRelationshipsProfile(credentials, handleResponse, handleError)
      : api.session.login(credentials, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_LOGIN

  return thunk
}

export default login
