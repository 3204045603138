import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/teams/constants'
import api from 'api'

// Actions

export const fetchTeamMembersFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_TEAM_MEMBERS_FAILURE,
})

export const fetchTeamMembersRequest = () => ({
  type: actionTypes.FETCH_TEAM_MEMBERS_REQUEST,
})

export const fetchTeamMembersSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_TEAM_MEMBERS_SUCCESS,
})

// Thunks

export const fetchTeamMembers = ({ pageIndex, sort, teamId }, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchTeamMembersRequest())

    const handleResponse = response => {
      const newResponse = {
        ...response,
        pageIndex,
      }

      dispatch(fetchTeamMembersSuccess(newResponse))

      if (onSuccess) {
        onSuccess(newResponse)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchTeamMembersFailure(error))
      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.teams.getTeamMembers({ accountId, pageIndex, sort, teamId }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_TEAM_MEMBERS

  return thunk
}

export default fetchTeamMembers
