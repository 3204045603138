import { Col, Container, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { sprintf } from 'sprintf-js'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import DocumentTitle from 'react-document-title'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'

import { CUSTOMIZATIONS, I18N, INVITATION, MODULES, PERSON, VIDEOS, ACTIONS, NETWORK_DETAILS } from 'constants/props'
import { LEARNING_ACCOUNT_TYPE } from 'constants/accountTypes'
import { ACCEPTED, PRE_ACCEPTED } from 'constants/invitationStatus'
import { VIDEO_MODULE, VIDEO_MODULE_SECOND } from 'constants/customizationModules'
import * as accountSelectors from 'app_modules/accounts/selectors'
import * as invitesSelectors from 'app_modules/invites/selectors'
import * as networkSelectors from 'app_modules/network/selectors'
import * as sessionSelectors from 'app_modules/session/selectors'
import * as sessionActions from 'app_modules/session/actions'
import * as userSelectors from 'app_modules/user/selectors'
import InvitationDialog from 'generics/InvitationDialog'
import ModulesList from 'generics/ModulesList'
import Translation from 'generics/Translation'
import VideoCard from 'generics/VideoCard'
import { useStatus } from 'app_modules/sensaiAi/hooks'

import styles from './PagePersonalDashboard.scss'
import WelcomeBack from './components/WelcomeBack'
import SensaiStarter from '../generics/SensaiStarter'

const PagePersonalDashboard = props => {
  const {
    accountId,
    customizations,
    i18n,
    invitation,
    isStudentWaitingResults,
    modules,
    profile,
    networkDetails,
    videos,
    sessionActionsProps,
  } = props
  const { limitedMember: limitedMemberFromNetworkDetails } = networkDetails || {}

  const {
    account: { accountType, hideResultsUntil, enableSensai = false },
    limitedMember: limitedMemberFromProfile,
  } = profile || {}

  const { fetchStatus } = useStatus()
  const isLearningAccount = accountType === LEARNING_ACCOUNT_TYPE
  const firstVideo = videos?.length && videos?.find(video => video.index === 1)
  const secondVideo = videos?.length && videos?.find(video => video.index === 2)
  const isLimitedMember =
    limitedMemberFromNetworkDetails === undefined ? limitedMemberFromProfile : limitedMemberFromNetworkDetails

  const showSensaiStarter = enableSensai && !isLimitedMember

  useEffect(() => {
    if (showSensaiStarter) {
      fetchStatus()
    }
  }, [showSensaiStarter, fetchStatus])

  useEffect(() => {
    sessionActionsProps.fetchDashboard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLimitedMember])

  return (
    <DocumentTitle title="Simpli5® - My 5 Dynamics Dashboard">
      <article
        className={classnames(styles.page, { [styles['page--has-hidden-results-label']]: isStudentWaitingResults })}
      >
        <Container>
          <Row>
            {!isStudentWaitingResults ? (
              <>
                <Col xs={12} lg={showSensaiStarter ? 6 : 8}>
                  <WelcomeBack
                    isLearningAccount={isLearningAccount}
                    className={styles.card}
                    isLimitedMember={isLimitedMember}
                  />
                </Col>
                {showSensaiStarter && (
                  <Col xs={12} lg={6}>
                    <SensaiStarter profile={profile} modules={modules} />
                  </Col>
                )}
                <Col xs={12} lg={showSensaiStarter ? 12 : 4}>
                  <div
                    className={classnames(styles['about-5-dynamics'], {
                      [styles['about-5-dynamics--sensai']]: showSensaiStarter,
                    })}
                  >
                    <VideoCard
                      accountId={accountId}
                      category={
                        !isLearningAccount
                          ? i18n.pagePersonalDashboard.videos[0].category
                          : i18n.pagePersonalDashboard.videos[2].category
                      }
                      createdAt={firstVideo?.createdAt}
                      description={customizations[VIDEO_MODULE].bodyContent || firstVideo?.description}
                      embedCode={customizations[VIDEO_MODULE].embedCode || null}
                      src={firstVideo?.video.url}
                      title={customizations[VIDEO_MODULE].heading || firstVideo?.title}
                      videoTitle={firstVideo?.title || ''}
                    />
                    <VideoCard
                      accountId={accountId}
                      category={
                        !isLearningAccount
                          ? i18n.pagePersonalDashboard.videos[1].category
                          : i18n.pagePersonalDashboard.videos[3].category
                      }
                      createdAt={secondVideo?.createdAt}
                      description={customizations[VIDEO_MODULE_SECOND].bodyContentSecond || secondVideo?.description}
                      embedCode={customizations[VIDEO_MODULE_SECOND].embedCodeSecond || null}
                      src={secondVideo?.video.url}
                      title={customizations[VIDEO_MODULE_SECOND].headingSecond || secondVideo?.title}
                      videoTitle={secondVideo?.title || ''}
                    />
                  </div>
                </Col>
              </>
            ) : (
              <Col xs="12">
                <h2 className={styles.hiddenResults}>
                  {sprintf(
                    i18n.pagePersonalDashboard.hiddenResults,
                    moment(new Date(hideResultsUntil)).utc().format('MM/DD/YYYY'),
                  )}
                </h2>
              </Col>
            )}
          </Row>

          {!isStudentWaitingResults && (
            <Row>
              <Col>
                <ModulesList
                  accountId={accountId}
                  isLimitedMember={isLimitedMember}
                  col={{ xs: 12, md: 6, lg: 4 }}
                  goToUrl={{
                    menuItem: 'my-5-dynamics',
                    entity: profile,
                  }}
                  header={{
                    isUpperCase: false,
                    title: 'Apply 5 Dynamics',
                  }}
                  modules={modules}
                />
              </Col>
            </Row>
          )}
        </Container>

        {!isEmpty(invitation) && (invitation.status !== PRE_ACCEPTED || invitation.status !== ACCEPTED) && (
          <InvitationDialog clearInvitationOnAccept />
        )}
      </article>
    </DocumentTitle>
  )
}

PagePersonalDashboard.propTypes = {
  accountId: PropTypes.number.isRequired,
  customizations: CUSTOMIZATIONS.isRequired,
  i18n: I18N.isRequired,
  invitation: INVITATION,
  isStudentWaitingResults: PropTypes.bool.isRequired,
  modules: MODULES.isRequired,
  profile: PERSON.isRequired,
  networkDetails: NETWORK_DETAILS.isRequired,
  videos: VIDEOS.isRequired,
  sessionActionsProps: ACTIONS.isRequired,
}

PagePersonalDashboard.defaultProps = {
  invitation: null,
}

const mapStateToProps = state => {
  const profile = sessionSelectors.currentProfile(state)

  return {
    accountId: sessionSelectors.accountId(state),
    customizations: accountSelectors.customizations(state),
    invitation: invitesSelectors.invitation(state),
    isStudentWaitingResults: accountSelectors.isStudentWaitingResults(state),
    modules: sessionSelectors.modules(state),
    profile,
    networkDetails: networkSelectors.networkDetails.profileNetworkDetails(state, profile.id),
    videos: userSelectors.videos(state),
  }
}

const mapDispatchToProps = dispatch => ({
  sessionActionsProps: bindActionCreators(
    {
      fetchDashboard: sessionActions.fetchDashboard,
    },
    dispatch,
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Translation(PagePersonalDashboard, ['pagePersonalDashboard'])),
)
