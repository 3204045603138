import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as sessionActions from 'app_modules/session/actions'
import PageSSOLoginError from 'components/PageSSOLoginError'

const mapDispatchToProps = dispatch => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
})

export default connect(null, mapDispatchToProps)(PageSSOLoginError)
