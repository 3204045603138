import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Translation from 'generics/Translation'

import styles from './videoIframe.scss'

const VideoIframe = props => {
  const { className, embedCode } = props

  return (
    <div className={classnames(styles.wrapper, className)}>
      <iframe
        className={styles.video}
        src={`https://www.youtube.com/embed/${embedCode}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  )
}

VideoIframe.propTypes = {
  className: PropTypes.string,
  embedCode: PropTypes.string,
}

VideoIframe.defaultProps = {
  className: null,
  embedCode: '',
}

export default Translation(VideoIframe, ['generics'])
