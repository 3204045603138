export const en = {
  modal: {
    title: 'Upgrade to Pro Today',
    description: 'Unlock Your Full Potential with Pro Access.',
    list: [
      {
        title: 'Work Smarter, not Harder: ',
        description:
          'Dive deeper into what makes you, you.  Discover more about your preferred energy and work styles.',
      },
      {
        title: 'Collaboration Supercharged: ',
        description:
          'Foster stronger connections with those around you by better understanding their strengths and preferences.',
      },
      {
        title: 'Team Diagnostics: ',
        description: 'Remove the guesswork from team dynamics with detailed insights and suggestions on what to do.',
      },
    ],
    submitDescription: 'Your administrator needs to approve your account upgrade',
    purchaseDescription:
      'Purchase licenses directly to unlock Pro features instantly, or route to your manager to upgrade!',
    purchaseDirectly:
      'Purchase licenses directly to unlock Pro features instantly',
    seeMoreLink: 'See more',
    purchaseButton: 'Buy Now!',
    submitButton: 'Request an Upgrade',
    successMessage: 'Your request has been sent to your manager successfully',
  },
}

export const es = en
