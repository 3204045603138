import * as sessionSelectors from 'app_modules/session/selectors'
import * as workshopSelectors from 'app_modules/workshops/selectors'
import api from 'api'
import workshopsActionTypes from 'app_modules/workshops/constants'

// Actions

export const fetchMembersFailure = error => ({
  payload: error,
  type: workshopsActionTypes.FETCH_MEMBERS_FAILURE,
})

export const fetchMembersRequest = () => ({
  type: workshopsActionTypes.FETCH_MEMBERS_REQUEST,
})

export const fetchMembersSuccess = response => ({
  payload: response,
  type: workshopsActionTypes.FETCH_MEMBERS_SUCCESS,
})

export const fetchMembersSuccessWithNoResults = () => ({
  type: workshopsActionTypes.FETCH_MEMBERS_SUCCESS_NO_RESULTS,
})

// Thunks
export const fetchMembers = ({ pageIndex, search }, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const state = getState()
    const accountId = sessionSelectors.accountId(state)
    const workshopId = workshopSelectors.currentWorkshop(state).workshop?.id

    dispatch(fetchMembersRequest())

    const handleResponse = response => {
      dispatch(
        fetchMembersSuccess({
          ...response,
          pageIndex,
        }),
      )

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchMembersFailure(error))
      if (onError) {
        onError(error)
      }
    }

    return api.workshops.getMembers(
      {
        accountId,
        pageIndex,
        search,
        workshopId,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = workshopsActionTypes.FETCH_MEMBERS

  return thunk
}

export default fetchMembers
