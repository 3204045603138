/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core'
import DocumentTitle from 'react-document-title'
import React, { useEffect, useState } from 'react'

import { ACTIONS, MATCH } from '../../constants/props'
import styles from './PageInvitation.scss'

const PageInvitation = ({ actions, match }) => {
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    const { token } = match.params

    setIsFetching(true)

    const onError = () => {
      setIsFetching(false)
    }

    actions.getInvitation(token, onError)
  }, [])

  return (
    <DocumentTitle title="Simpli5® - Invitation Page">
      <div className={styles.invitation}>
        {isFetching && <CircularProgress className={styles.loader} size={60} thickness={7} />}
      </div>
    </DocumentTitle>
  )
}

PageInvitation.propTypes = {
  actions: ACTIONS.isRequired,
  match: MATCH.isRequired,
}

export default PageInvitation
