import TeamDistributionGraph from './teamDistributionGraph.inline.svg'
import TeamSpiderGraph from './teamSpiderGraph.inline.svg'
import TentCards from './tentCards.inline.svg'

const ICONS = {
  TeamDistributionGraph,
  TeamSpiderGraph,
  TentCards,
}

export default ICONS
