/**
 * @fileoverview Action type constants for the SensaiAI module
 * @module sensaiAi/constants/actionTypes
 */

import { NAMESPACE } from './namespaces'

/**
 * @constant {Object} COACH - Coach-related action types
 */
export const COACH = {
  FETCH_REQUEST: `${NAMESPACE.COACH}/FETCH_REQUEST`,
  FETCH_SUCCESS: `${NAMESPACE.COACH}/FETCH_SUCCESS`,
  FETCH_FAILURE: `${NAMESPACE.COACH}/FETCH_FAILURE`,
  CREATE_REQUEST: `${NAMESPACE.COACH}/CREATE_REQUEST`,
  CREATE_SUCCESS: `${NAMESPACE.COACH}/CREATE_SUCCESS`,
  CREATE_FAILURE: `${NAMESPACE.COACH}/CREATE_FAILURE`,
}

/**
 * @constant {Object} STATUS - Status-related action types
 */
export const STATUS = {
  FETCH_REQUEST: `${NAMESPACE.STATUS}/FETCH_REQUEST`,
  FETCH_SUCCESS: `${NAMESPACE.STATUS}/FETCH_SUCCESS`,
  FETCH_FAILURE: `${NAMESPACE.STATUS}/FETCH_FAILURE`,
}

/**
 * @constant {Object} PROCESSING - Processing-related action types
 */
export const PROCESSING = {
  SET_REQUEST_ID: `${NAMESPACE.PROCESSING}/SET_REQUEST_ID`,
  SET_STATUS: `${NAMESPACE.PROCESSING}/SET_STATUS`,
  SET_COMPLETE: `${NAMESPACE.PROCESSING}/SET_COMPLETE`,
}

export const CREDENTIALS = {
  SET_SENSAI_CREDENTIALS: `${NAMESPACE.ROOT}/SET_SENSAI_CREDENTIALS`,
}
