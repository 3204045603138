import { autobind } from 'core-decorators'
import { CircularProgress } from '@material-ui/core'
import { pickHTMLProps } from 'pick-react-known-prop'
import classnames from 'classnames'
import GeminiScrollbar from 'gemini-scrollbar'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ScrollMagic from 'scrollmagic'

import { CHILDREN } from 'constants/props'

import styles from './InfiniteScroll.scss'

// TODO: Add a message when results are empty (no children)
class InfiniteScroll extends Component {
  constructor(props) {
    super(props)
    const { pageStart } = props
    this.state = {
      isFetchingMoreData: false,
      pageIndex: pageStart,
    }
  }

  componentDidMount() {
    const {
      scrollbar: { autoShow, forceGemini, minThumbSize, onResize },
    } = this.props

    this.scrollbar = new GeminiScrollbar({
      autoShow,
      createElements: false,
      element: this.scrollbarElement,
      forceGemini,
      minThumbSize,
      onResize,
    }).create()

    this.scrollMagicController = new ScrollMagic.Controller({
      container: this.containerElement,
    })

    this.scrollMagicScene = new ScrollMagic.Scene({
      triggerElement: this.triggerElement,
      triggerHook: 'onEnter',
    })

    this.scrollMagicScene.addTo(this.scrollMagicController).on('enter', this.handleFetch)
  }

  componentDidUpdate(prevProps) {
    const { pageStart } = this.props
    const { pageIndex } = this.state
    if (prevProps.pageStart !== pageStart && pageStart !== pageIndex) {
      this.setState({
        pageIndex: pageStart,
      })
    }
    this.scrollMagicScene.update()
    this.scrollbar.update()
  }

  componentWillUnmount() {
    if (this.scrollMagicController) {
      this.scrollMagicController.destroy(true)
    }

    if (this.scrollbar) {
      this.scrollbar.destroy()
    }

    this.scrollMagicController = null
    this.scrollbar = null
  }

  @autobind
  resetIndex() {
    const { pageStart } = this.props
    this.setState({
      pageIndex: pageStart,
    })
  }

  @autobind
  async handleFetch() {
    const { hasMorePages, isLoading, onFetch } = this.props

    const { pageIndex, isFetchingMoreData } = this.state

    if (isFetchingMoreData || isLoading || !onFetch || !hasMorePages) {
      return
    }

    this.setState({
      isFetchingMoreData: true,
    })

    await onFetch(pageIndex)

    this.setState({
      isFetchingMoreData: false,
      pageIndex: pageIndex + 1,
    })
  }

  render() {
    const { children, className, hasMorePages, id, tag: Tag, scrollbar, ...rest } = this.props

    const { className: scrollbarClassName } = scrollbar
    const { isFetchingMoreData } = this.state

    return (
      <div
        className={classnames('gm-scrollbar-container', className)}
        ref={node => {
          this.scrollbarElement = node
        }}
        {...pickHTMLProps(rest)}
      >
        <div className="gm-scrollbar -vertical">
          <div className="thumb" />
        </div>

        <div className="gm-scrollbar -horizontal">
          <div className="thumb" />
        </div>

        <Tag
          id={id}
          ref={node => {
            this.containerElement = node
          }}
          className={classnames(
            styles.content,
            { [styles['has-more-pages']]: hasMorePages },
            { [styles['is-fetching-more-data']]: isFetchingMoreData },
            'gm-scroll-view',
            scrollbarClassName,
          )}
        >
          {children}
          {hasMorePages && (
            <div
              className={classnames(
                styles.loader,
                { [styles['has-more-pages']]: hasMorePages },
                { [styles['is-fetching-more-data']]: isFetchingMoreData },
              )}
            >
              <CircularProgress className={styles['circular-progress']} size={30} thickness={4} />
            </div>
          )}
          <div
            className={styles.trigger}
            ref={node => {
              this.triggerElement = node
            }}
          />
        </Tag>
      </div>
    )
  }
}

InfiniteScroll.propTypes = {
  children: CHILDREN,
  className: PropTypes.string,
  hasMorePages: PropTypes.bool,
  isLoading: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onFetch: PropTypes.func,
  pageStart: PropTypes.number,
  scrollbar: PropTypes.shape({
    autoShow: PropTypes.bool,
    className: PropTypes.string,
    forceGemini: PropTypes.bool,
    minThumbSize: PropTypes.number,
    onResize: PropTypes.func,
  }),
  tag: PropTypes.string,
}

InfiniteScroll.defaultProps = {
  children: null,
  className: null,
  hasMorePages: true,
  isLoading: false,
  onFetch: undefined,
  pageStart: 2,
  scrollbar: {
    autoShow: false,
    className: null,
    forceGemini: false,
    minThumbSize: 20,
  },
  tag: 'div',
}

export default InfiniteScroll
