import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import * as groupsSelectors from 'app_modules/groups/selectors'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/groups/constants'
import api from 'api'

// Actions

export const fetchUpdateGroupInvitationEmailsFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_UPDATE_GROUP_INVITATION_EMAILS_FAILURE,
})

export const fetchUpdateGroupInvitationEmailsRequest = () => ({
  type: actionTypes.FETCH_UPDATE_GROUP_INVITATION_EMAILS_REQUEST,
})

export const fetchUpdateGroupInvitationEmailsSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_UPDATE_GROUP_INVITATION_EMAILS_SUCCESS,
})

// Thunks
export const fetchUpdateGroupInvitationEmails = (data, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const state = getState()
    const accountId = sessionSelectors.accountId(state)
    const { id: groupId } = groupsSelectors.currentGroup(state).group

    dispatch(fetchUpdateGroupInvitationEmailsRequest())

    const handleResponse = response => {
      dispatch(fetchUpdateGroupInvitationEmailsSuccess(response))

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchUpdateGroupInvitationEmailsFailure(error))
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'fetchUpdateGroupInvitationEmails' })))
      }

      if (onError) {
        onError(error)
      }
    }

    return api.groups.updateGroupInvitationEmails(
      {
        accountId,
        groupId,
        ...data,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_UPDATE_GROUP_INVITATION_EMAILS

  return thunk
}

export default fetchUpdateGroupInvitationEmails
