import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/workshops/constants'
import api from 'api'

// Actions

export const fetchWorkshopDetailsFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_WORKSHOP_DETAILS_FAILURE,
})

export const fetchWorkshopDetailsRequest = () => ({
  type: actionTypes.FETCH_WORKSHOP_DETAILS_REQUEST,
})

export const fetchWorkshopDetailsSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_WORKSHOP_DETAILS_SUCCESS,
})

export const clearWorkshop = () => ({
  type: actionTypes.CLEAR_WORKSHOP,
})

// Thunks

export const fetchWorkshopDetails = (workshopId, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchWorkshopDetailsRequest())

    const handleResponse = response => {
      dispatch(fetchWorkshopDetailsSuccess(response))

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchWorkshopDetailsFailure(error))

      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.workshops.getWorkshopDetails(
      {
        accountId,
        workshopId,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_WORKSHOP_DETAILS

  return thunk
}

export default fetchWorkshopDetails
