import { pickHTMLProps } from 'pick-react-known-prop'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './Stroke7.scss'

const Icon = props => {
  const { border, className, fixedWidth, flip, inverse, li, name, rotate, size, spin, stack, va, ...rest } = props

  let localClassName = `${styles.icon} ${styles[name]} ${className || ''}`

  if (border) {
    localClassName += ` ${styles.border}`
  }

  if (fixedWidth) {
    localClassName += ` ${styles.fw}`
  }

  if (flip) {
    localClassName += ` ${styles[`flip-${flip}`]}`
  }

  if (inverse) {
    localClassName += ` ${styles.inverse}`
  }

  if (li) {
    localClassName += ` ${styles.li}`
  }

  if (rotate) {
    localClassName += ` ${styles[`pe-rotate-${rotate}`]}`
  }

  if (size) {
    localClassName += ` ${styles[`size-${size}`]}`
  }

  if (spin) {
    localClassName += ` ${styles.spin}`
  }

  if (stack) {
    localClassName += ` ${styles[`pe-stack-${stack}`]}`
  }

  if (va) {
    localClassName += ` ${styles.va}`
  }

  return <span className={localClassName} {...pickHTMLProps(rest)} />
}

Icon.propTypes = {
  border: PropTypes.bool,
  className: PropTypes.string,
  fixedWidth: PropTypes.bool,
  flip: PropTypes.oneOf([false, 'horizontal', 'vertical']),
  inverse: PropTypes.bool,
  li: PropTypes.bool,
  name: PropTypes.string,
  rotate: PropTypes.oneOf([0, 90, 180, 270]),
  size: PropTypes.oneOf(['lg', '2x', '3x', '4x', '5x']),
  spin: PropTypes.bool,
  stack: PropTypes.oneOf(['1x', '2x']),
  va: PropTypes.bool,
}

Icon.defaultProps = {
  border: false,
  className: '',
  fixedWidth: false,
  flip: false,
  inverse: false,
  li: false,
  name: null,
  rotate: 0,
  size: null,
  spin: false,
  stack: null,
  va: false,
}

export default Icon
