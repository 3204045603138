import { Container } from 'reactstrap'
import PropTypes from 'prop-types'
import React from 'react'

import { BUNDLES, I18N } from 'constants/props'
import { Header, Main } from 'generics/Card'
import ActionButton from 'generics/ActionButton'
import Icon from 'generics/Icon'
import Translation from 'generics/Translation'

import CustomizationsListItem from '../CustomizationsListItem/CustomizationsListItem'
import styles from './CustomizationsList.scss'

const CustomizationsList = ({ customizations, i18n, onManageCustomization }) => {
  const customizationsList = customizations.map(customization => (
    <CustomizationsListItem
      actions={[
        <ActionButton
          actionType="decline"
          className={styles.decline}
          desktop={{
            props: {
              label: i18n.generics.edit,
              title: i18n.generics.edit,
            },
          }}
          mobile={{
            props: {
              children: <Icon.Stroke7 className={styles.button__icon} name="pen" />,
            },
          }}
          key="manage"
          onClick={() => {
            onManageCustomization(customization.key)
          }}
        />,
      ]}
      customization={customization}
      key={customization.key}
      onManageCustomization={onManageCustomization}
    />
  ))

  const customizationsListContent = <ul>{customizationsList}</ul>

  return (
    <>
      <Header className={styles.header} title={i18n.customizations.title} />
      <Main className={styles.main}>
        <Container>{customizationsListContent}</Container>
      </Main>
    </>
  )
}

CustomizationsList.propTypes = {
  customizations: BUNDLES,
  i18n: I18N.isRequired,
  onManageCustomization: PropTypes.func.isRequired,
}

CustomizationsList.defaultProps = {
  customizations: [],
}

export default Translation(CustomizationsList, ['generics', 'customizations'])
