/* eslint-disable no-param-reassign */
import classnames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import { CANCELLED, DOWNLOADED, EXPIRED, PENDING, SHARED } from 'constants/reportStatus'
import { ENERGY_MAP_REF, I18N, REPORT } from 'constants/props'
import { StyledButton, StyledCheckbox } from 'generics/StyledFormComponents'
import PDFEnergyMaps from 'generics/PDFEnergyMaps'

import styles from './ReportListItem.scss'

const ReportListItem = ({
  energyMapRefs,
  handleSelectReport,
  i18n,
  isAssign,
  profileId,
  listItemTestAttribute,
  listItemFullnameTestAttribute,
  listItemEmailTestAttribute,
  report,
  selectedTokens,
}) => {
  const capitalize = string => string && string.charAt(0).toUpperCase() + string.slice(1)

  const getFullName = (firstName, lastName) => `${capitalize(firstName)} ${capitalize(lastName)}`

  const getReportInfo = reportInfo => {
    const {
      code,
      email,
      expiredAt,
      firstName,
      handleCancelReportConfirmation,
      handleCreateAPGIReport,
      handleDownloadAPGIReport,
      handleSendNewInvitation,
      handleSendReportReminder,
      id,
      lastName,
      profile,
      sender,
      status,
    } = reportInfo

    const { firstName: senderFirstName, lastName: senderLastName, id: senderId } = sender || {}

    const fullName = getFullName(firstName || '', lastName || '')
    const ownerName = `${senderFirstName || ''} ${senderLastName || ''}`

    const isChecked = selectedTokens && !!selectedTokens[id]
    const isMyReport = profileId === senderId

    return (
      <div
        aria-hidden="true"
        className={classnames(styles.item, { [styles['is-assign']]: isAssign })}
        data-test={listItemTestAttribute}
        onClick={() => isAssign && handleSelectReport(id, !isChecked)}
      >
        {isAssign && (
          <section className={styles['checkbox-container']}>
            <StyledCheckbox
              checked={isChecked}
              color="primary"
              onChange={e => handleSelectReport(id, e.target.checked)}
            />
          </section>
        )}
        <div data-test={listItemFullnameTestAttribute}>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.name}</p>
          <p>{fullName}</p>
        </div>
        <div data-test={listItemEmailTestAttribute}>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.email}</p>
          <p>{email}</p>
        </div>
        <div>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.code}</p>
          <p>{code}</p>
        </div>
        <div>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.status}</p>
          <p>{status && capitalize(status)}</p>
        </div>
        <div>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.owner}</p>
          <p>{ownerName}</p>
        </div>
        <div>
          <p>{i18n.pageTokenAdministration.useTokens.list.headers.expirationDate}</p>
          <p className={classnames({ [styles['is-expired']]: status === EXPIRED })}>
            {expiredAt && moment(expiredAt).format('MM/DD/YYYY')}
          </p>
        </div>
        {!isAssign && (
          <div>
            <p>{i18n.pageTokenAdministration.useTokens.list.headers.actions}</p>
            <div className={styles['action-buttons-container']}>
              {isMyReport && (
                <>
                  {status === PENDING && (
                    <>
                      <StyledButton
                        className={styles.edit}
                        color="secondary"
                        onClick={() => handleCancelReportConfirmation(id)}
                        title={i18n.generics.cancelLabel}
                        variant="text"
                      >
                        {i18n.generics.cancelLabel}
                      </StyledButton>
                      <StyledButton
                        className={styles.edit}
                        color="primary"
                        onClick={() => handleSendReportReminder(id, fullName)}
                        title={i18n.generics.resendLabel}
                        variant="text"
                      >
                        {i18n.generics.resendLabel}
                      </StyledButton>
                    </>
                  )}
                  {status === SHARED && (
                    <StyledButton
                      className={styles.edit}
                      color="primary"
                      onClick={() => handleCreateAPGIReport(id, fullName.replace(' ', '_'))}
                      title={i18n.pageTokenAdministration.useTokens.pdf.createLabel}
                      variant="text"
                    >
                      {i18n.pageTokenAdministration.useTokens.pdf.createLabel}
                    </StyledButton>
                  )}
                </>
              )}
              {status === DOWNLOADED && (
                <StyledButton
                  className={styles.edit}
                  color="primary"
                  onClick={() => handleDownloadAPGIReport(id, fullName.replace(' ', '_'))}
                  title={i18n.pageTokenAdministration.useTokens.pdf.downloadLabel}
                  variant="text"
                >
                  {i18n.pageTokenAdministration.useTokens.pdf.downloadLabel}
                </StyledButton>
              )}
              {(status === EXPIRED || status === CANCELLED) && (
                <StyledButton
                  className={styles.edit}
                  color="primary"
                  onClick={() => handleSendNewInvitation(id)}
                  title={i18n.pageTokenAdministration.useTokens.pdf.sendNewInvitationLabel}
                  variant="text"
                >
                  {i18n.pageTokenAdministration.useTokens.pdf.sendNewInvitationLabel}
                </StyledButton>
              )}
            </div>
          </div>
        )}
        {!isAssign && profile && energyMapRefs && (
          <PDFEnergyMaps
            profiles={[profile]}
            innerRef={nodeElement => {
              energyMapRefs.current[id] = nodeElement
            }}
          />
        )}
      </div>
    )
  }

  return <li className={styles['list-item']}>{report && getReportInfo(report)}</li>
}

ReportListItem.propTypes = {
  energyMapRefs: ENERGY_MAP_REF.isRequired,
  handleSelectReport: PropTypes.func,
  i18n: I18N.isRequired,
  isAssign: PropTypes.bool,
  profileId: PropTypes.number.isRequired,
  listItemTestAttribute: PropTypes.string,
  listItemFullnameTestAttribute: PropTypes.string,
  listItemEmailTestAttribute: PropTypes.string,
  report: REPORT.isRequired,
  selectedTokens: PropTypes.objectOf(PropTypes.bool),
}

ReportListItem.defaultProps = {
  handleSelectReport: () => {},
  isAssign: false,
  listItemTestAttribute: null,
  listItemFullnameTestAttribute: null,
  listItemEmailTestAttribute: null,
  selectedTokens: {},
}

export default ReportListItem
