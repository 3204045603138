/**
 * @description Settings for controller
 * @module API controller / Session
 */
module.exports = {
  login: {
    /**
     * @description Get request settings (Login)
     * @param  {object} options
     * @param  {string} options.email - The account ID.
     * @param  {string} options.password - The profile ID.
     * @return {object} Request Settings
     */
    settings({ email, password }) {
      return {
        endpoint: '/sessions/login',
        body: {
          email,
          password,
        },
        method: 'POST',
      }
    },
  },

  loginLinkRelationshipsProfile: {
    /**
     * @description Get request settings (Login)
     * @param  {object} options
     * @param  {string} options.email - The account ID.
     * @param  {string} options.password - The profile ID.
     * @return {object} Request Settings
     */
    settings({ email, password, token }) {
      return {
        endpoint: '/sessions/login_link_personal',
        body: {
          email,
          password,
          token,
        },
        method: 'POST',
      }
    },
  },

  ssoLogin: {
    /**
     * @description Get request settings (SSO Login)
     * @param  {string} token
     * @return {object} Request Settings
     */
    settings(token) {
      return {
        endpoint: '/sessions/sso_login',
        headers: {
          Authorization: `Token sso=${token}`,
        },
        method: 'GET',
      }
    },
  },

  impersonate: {
    /**
     * @description Get request settings (Impersonate)
     * @param  {string} token - The account ID.
     * @return {object} Request Settings
     */
    settings(token) {
      return {
        endpoint: '/sessions/impersonate_login',
        headers: {
          Authorization: `Token impersonate=${token}`,
        },
        method: 'POST',
      }
    },
  },

  logout: {
    clearTimeout: true,
    /**
     * @description Get request settings (Logout)
     * @param { number } accountId
     * @return {object} Request Settings
     */
    settings(accountId) {
      return {
        endpoint: `/account/${accountId}/sessions/logout`,
        method: 'DELETE',
      }
    },
  },
}
