import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/accounts/constants'
import api from 'api'

// Actions

export const editCustomizationsFailure = error => ({
  payload: error,
  type: actionTypes.EDIT_CUSTOMIZATIONS_FAILURE,
})

export const editCustomizationsRequest = () => ({
  type: actionTypes.EDIT_CUSTOMIZATIONS_REQUEST,
})

export const editCustomizationsSuccess = request => ({
  payload: request,
  type: actionTypes.EDIT_CUSTOMIZATIONS_SUCCESS,
})

// Thunks

export const editCustomizations = (values, onFinish) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    const handleResponse = response => {
      dispatch(editCustomizationsSuccess(response))
      onFinish()
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(editCustomizationsFailure(error))
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'getAccountInformationError' })))
      }
    }

    api.accounts.editCustomization({ accountId, values }, handleResponse, handleError)
  }

  thunk.type = actionTypes.EDIT_CUSTOMIZATIONS

  return thunk
}

export default editCustomizations
