/**
 * @fileoverview Hook for managing coach-related operations
 * @module sensaiAi/hooks/useCoach
 */

import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCoach, fetchCoaches } from '../actions/coachActions'
import {
  getCoaches,
  isFetchingCoaches,
  getCoachesError,
  getCoachRequestId,
  getCoachProcessing,
  getCoachComplete,
  isCreatingCoach,
} from '../selectors/coachSelectors'

/**
 * Hook for managing coach operations
 * @returns {Object} Coach operations and state
 */
const useCoach = () => {
  const dispatch = useDispatch()

  // Selectors with null checks
  const coachState = useSelector(getCoaches) || { data: [], status: null, msg: null }
  const isLoading = useSelector(isFetchingCoaches)
  const error = useSelector(getCoachesError)
  const requestId = useSelector(getCoachRequestId)
  const isProcessing = useSelector(getCoachProcessing)
  const isComplete = useSelector(getCoachComplete)
  const isCreating = useSelector(isCreatingCoach)

  // Callbacks that return the promises from the action creators
  const handleCreateCoach = useCallback(
    (userName, knowledgeBase) => {
      return dispatch(createCoach(userName, knowledgeBase))
    },
    [dispatch],
  )

  const handleFetchCoaches = useCallback(() => {
    return dispatch(fetchCoaches())
  }, [dispatch])

  return {
    // State
    coaches: coachState,
    error,
    isLoading,
    isCreating,
    requestId,
    isProcessing,
    isComplete,
    // Actions
    createCoach: handleCreateCoach,
    fetchCoaches: handleFetchCoaches,
  }
}

export default useCoach
