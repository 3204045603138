export const en = {
  avatarTitle: "%s's avatar",
  confirmation: {
    message:
      'By making this team public, the members and their profiles will be visible to the entire organization regardless of their privacy setting.',
    title: 'Make Team Public',
  },
  editPicture: 'Edit Picture',
  editTeamDetail: 'Edit Team Detail',
  members: 'Members %(membersCount)s ',
  privacy: {
    makeTeamPublic: 'Make Team Public',
    private: 'Private',
    public: 'Public',
    visibleInfo: '(Team will be visible to the entire network)',
  },
  publishInfo: 'Publish your team so its visible to the team members',
  publishTeam: 'Publish Team',
  uploadMessage: 'Upload a picture here (1 MB max file size)',
}

export const es = en
