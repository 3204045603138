/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { ACTIONS, BUNDLES } from 'constants/props'
import { Card } from 'generics/Card'

import BundleModules from '../BundleModules'
import BundlesList from '../BundlesList'
import styles from './BundlesCard.scss'

const BundlesCard = ({ accountId, bundlesActions, bundlesList }) => {
  const [isFetching, setIsFetching] = useState(true)
  const [selectedBundleKey, setSelectedBundleKey] = useState(null)
  const [status, setStatus] = useState('bundles')

  useEffect(() => {
    const onFinish = () => {
      setIsFetching(false)
    }

    bundlesActions.fetchBundles(onFinish)
  }, [])

  const handleManageBundle = bundleKey => {
    const onSuccess = () => {
      setIsFetching(false)
      setSelectedBundleKey(bundleKey)
      setStatus('bundleModules')
    }

    const onError = () => {
      setIsFetching(false)
    }

    bundlesActions.fetchBundleDetails(bundleKey, onSuccess, onError)

    setIsFetching(true)
  }

  const handleBack = () => {
    setSelectedBundleKey(null)
    setStatus('bundles')
  }

  const selectedBundle = selectedBundleKey && bundlesList.find(({ i18NBundle }) => i18NBundle.key === selectedBundleKey)

  return (
    <Card barClassName={styles.bar} isFetching={isFetching}>
      {status === 'bundles' && <BundlesList bundles={bundlesList} onManageBundle={handleManageBundle} />}
      {status === 'bundleModules' && (
        <BundleModules accountId={accountId} bundle={selectedBundle} onBack={handleBack} />
      )}
    </Card>
  )
}

BundlesCard.propTypes = {
  accountId: PropTypes.number.isRequired,
  bundlesList: BUNDLES.isRequired,
  bundlesActions: ACTIONS.isRequired,
}

export default BundlesCard
