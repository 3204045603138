/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { I18N } from 'constants/props'
import { StyledButton, StyledTextField, StyledSelect } from 'generics/StyledFormComponents'
import Icon from 'generics/Icon'
import Translation from 'generics/Translation'

import styles from './SearchBar.scss'
import useSearchBar from './useSearchBar'

const SearchBar = ({
  className,
  defaultFilterOption,
  error,
  filters,
  floatingLabelText,
  helperText,
  i18n,
  innerRef,
  isDepartmentEnabled,
  isTeamSearch,
  onBlur,
  onChange,
  onFocus,
  onSearch,
  value,
}) => {
  const {
    filter,
    filterOptions,
    handleChange,
    handleOtherFilterChange,
    handleSubmit,
    hasValue,
    isValid,
    otherFilters,
    reset,
    resetRef,
    searchRef,
    selectedValue,
  } = useSearchBar({ defaultFilterOption, filters, isDepartmentEnabled, isTeamSearch, onChange, onSearch, value })

  useEffect(() => {
    if (innerRef) {
      innerRef({ reset, resetRef })
    }
  }, [innerRef])

  const searchFilterList = filterOptions.map(({ id, label }) => (
    <MenuItem key={id} value={id}>
      {label}
    </MenuItem>
  ))

  return (
    <form className={classnames(styles.search, className)} onSubmit={handleSubmit}>
      <div
        className={classnames(
          styles['search-field-container'],
          { [styles['full-width']]: defaultFilterOption },
          { [styles['more-filters']]: filters },
        )}
      >
        <StyledTextField
          color="primary"
          className={styles['search-field']}
          name="value"
          error={error}
          helperText={helperText}
          label={floatingLabelText}
          fullWidth
          onBlur={onBlur}
          onChange={e => handleChange('value', e.target.value)}
          onFocus={onFocus}
          inputRef={searchRef}
          title={floatingLabelText}
          {...selectedValue}
        />
        <Icon.Stroke7
          className={classnames(styles.close, { [styles.hide]: !hasValue })}
          name="close"
          onClick={() => reset()}
        />
      </div>
      {!defaultFilterOption && (
        <StyledSelect
          ariaLabel={`${i18n.generics.search.filter.label} dropdown list. ${
            filterOptions.find(opt => opt.id === filter.filterBy).label
          } selected.`}
          color="primary"
          formControlClassName={classnames(styles.filter, { [styles['other-filter']]: filters })}
          fullWidth
          iconClassName={styles.icon}
          label={i18n.generics.search.filter.label}
          name="filterBy"
          onChange={e => handleChange('filterBy', e.target.value)}
          value={filter.filterBy}
        >
          {searchFilterList}
        </StyledSelect>
      )}
      {filters && (
        <>
          {filters.map(({ filterLabel, label, options }, i) => (
            <StyledSelect
              ariaLabel={`${label} dropdown list. ${
                options.find(opt => opt.id === otherFilters[i].filterBy).label
              } selected.`}
              color="primary"
              formControlClassName={styles['other-filter']}
              fullWidth
              iconClassName={styles.icon}
              label={label}
              name={filterLabel}
              onChange={e => handleOtherFilterChange(e.target.value, i)}
              value={otherFilters[i].filterBy}
            >
              {options.map(({ id, label: optionLabel }) => (
                <MenuItem key={id} value={id}>
                  {optionLabel}
                </MenuItem>
              ))}
            </StyledSelect>
          ))}
        </>
      )}
      <StyledButton
        aria-disabled={!isValid}
        classes={{ root: styles.submit }}
        color="primary"
        id="login-submit"
        title={i18n.generics.search.button.submit}
        type="submit"
      >
        {i18n.generics.search.button.submit}
      </StyledButton>
    </form>
  )
}

SearchBar.propTypes = {
  className: PropTypes.string,
  debounce: PropTypes.bool,
  defaultFilterOption: PropTypes.string,
  error: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      defaultOption: PropTypes.string,
      filterLabel: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
    }),
  ),
  floatingLabelText: PropTypes.string,
  helperText: PropTypes.string,
  i18n: I18N.isRequired,
  innerRef: () => {},
  isDepartmentEnabled: PropTypes.bool.isRequired,
  isTeamSearch: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  value: PropTypes.string,
}

SearchBar.defaultProps = {
  className: null,
  debounce: true,
  defaultFilterOption: null,
  error: null,
  filters: null,
  floatingLabelText: null,
  helperText: null,
  innerRef: null,
  isTeamSearch: false,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  onReset: undefined,
  onSearch: undefined,
  value: null,
}

export default Translation(SearchBar, ['generics'])
