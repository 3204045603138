/**
 * @fileoverview Coach reducer for the SensaiAI module
 * @module sensaiAi/reducers/coachReducer
 */

import { COACH } from '../constants/actionTypes'

/**
 * @typedef {Object} CoachState
 * @property {Array|null} coaches - List of coaches
 * @property {boolean} isFetching - Loading state for fetch operations
 * @property {boolean} isCreating - Loading state for create operations
 * @property {Object|null} error - Error state
 */

/**
 * @type {CoachState}
 */
const initialState = {
  coaches: {
    data: [],
    status: null,
    msg: null,
  },
  isLoading: false,
  isCreating: false,
  error: null,
  requestId: null,
  processing: false,
  complete: false,
}

/**
 * Coach reducer
 * @param {CoachState} state - Current state
 * @param {Object} action - Action object
 * @returns {CoachState} New state
 */
const coachReducer = (state = initialState, action) => {
  switch (action.type) {
    case COACH.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      }

    case COACH.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        coaches: {
          data: action.payload.coaches || [],
          status: action.payload.status || 200,
          msg: action.payload.msg || 'Coaches fetched successfully.',
        },
      }

    case COACH.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        coaches: initialState.coaches,
      }

    case COACH.CREATE_REQUEST:
      return {
        ...state,
        isCreating: true,
        error: null,
      }

    case COACH.CREATE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        requestId: action.payload.requestId,
        processing: true,
      }

    case COACH.CREATE_FAILURE:
      return {
        ...state,
        isCreating: false,
        error: action.payload,
        processing: false,
      }

    case COACH.SET_PROCESSING_COMPLETE:
      return {
        ...state,
        processing: false,
        complete: true,
      }

    case COACH.RESET_COACH_STATE:
      return initialState

    default:
      return state
  }
}

export default coachReducer
