/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React from 'react'

import { ACTIONS, I18N } from 'constants/props'
import { BarError } from 'classes/errors'
import Modal from 'generics/Modal'

import FormEditDepartment from '../FormEditDepartment/FormEditDepartment'
import styles from './ModalEditDepartment.scss'

const ModalEditDepartment = ({
  accountActions,
  selectedDepartment,
  i18n,
  isFetching,
  notificationActions,
  onClose,
  title,
}) => {
  const { department, profileId } = selectedDepartment || {}

  const handleSubmit = values => {
    const onSuccess = () => {
      onClose()
      notificationActions.notifySuccess(i18n.pageAdministration.peopleList.updateDepartment)
    }

    const onError = () => {
      notificationActions.notifyError(new BarError({ label: 'updateDepartmentError' }))
    }

    accountActions.editDepartment(
      {
        profileId,
        values,
      },
      onSuccess,
      onError,
    )
  }

  return (
    <Modal
      card={{
        barClassName: styles.bar,
        isFetching,
      }}
      modalCardClassName={styles.modal}
      onClose={onClose}
      switchModalView={false}
      title={title}
    >
      <FormEditDepartment initialValues={{ department }} onClose={onClose} onSubmit={handleSubmit} />
    </Modal>
  )
}

ModalEditDepartment.propTypes = {
  accountActions: ACTIONS.isRequired,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool.isRequired,
  notificationActions: ACTIONS.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedDepartment: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
}

export default ModalEditDepartment
