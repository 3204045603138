import d3 from 'vendor/d3'

export function drawTeamStdev() {
  const { data } = this

  const { dynamics } = this.props

  const { offset, quadrantsAngle } = this.geometry

  const stdevProfiles = dynamics.map((dynamicName, index) => {
    const level = data[0].assessmentScores[index]
    const stdev = data[0].stdev[index]
    const rangeMax = Math.min(level + stdev, 15)
    const rangeMin = Math.max(level - stdev, 0)
    const radiusMax = this.scales.balloonDiameter(rangeMax) / 2
    const radiusMin = this.scales.balloonDiameter(rangeMin) / 2

    return {
      dynamicName,
      level,
      radiusMin,
      radiusMax,
      angle: quadrantsAngle[index],
    }
  })

  this.stdevProfile = this.g_profiles.selectAll(`.${this.styles.range}`).data(stdevProfiles, d => d.dynamicName)

  this.stdevProfile.exit().remove()

  const stdevProfileEnter = this.stdevProfile
    .enter()
    .append('g')
    .attr('class', d => `${this.styles.range} ${this.styles[d.dynamicName]}`)

  stdevProfileEnter.append('path').attr('class', 'stdevArea').attr('fill-rule', 'evenodd')

  this.stdevProfile = this.stdevProfile
    .merge(stdevProfileEnter)
    .attr('transform', d => `rotate(${d.angle}) translate(${offset},${offset})`)

  this.stdevProfile.select('.stdevArea').attr('d', d => {
    const pathGenerator = d3.path()
    pathGenerator.moveTo(0, 0)
    pathGenerator.lineTo(d.radiusMax, 0)
    pathGenerator.arc(d.radiusMax, d.radiusMax, d.radiusMax, -0.5 * Math.PI, Math.PI)
    pathGenerator.lineTo(0, 0)
    pathGenerator.lineTo(d.radiusMin, 0)
    pathGenerator.arc(d.radiusMin, d.radiusMin, d.radiusMin, -0.5 * Math.PI, Math.PI)
    pathGenerator.closePath()
    return pathGenerator.toString()
  })
}
