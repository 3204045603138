/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { sprintf } from 'sprintf-js'

import { ACTIONS, I18N, LIST, PERSON } from 'constants/props'
import { BarError } from 'classes/errors'
import { Card, Footer, Header, Main } from 'generics/Card'
import { COLORS } from 'constants/colors'
import { StyledButton } from 'generics/StyledFormComponents'
import api from 'api'
import Icon from 'generics/Icon'
import Translation from 'generics/Translation'

import BadgeResults from '../BadgeResults'
import styles from './modalApproveRequests.scss'

const ModalApproveRequests = ({ accountId, i18n, notificationActions, onClose, onFinish, pageActions, profiles }) => {
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    pageActions.switchModalView()

    return () => {
      pageActions.switchModalView()
    }
  }, [])

  const handleClose = () => {
    onClose()
  }

  const handleUpgradeMembersAction = ({ accept = [], decline = [] }) => {
    setIsFetching(true)

    const handleResponse = ({ cantUpgrade, acceptedMembers, declinedMembers }) => {
      const hasCantUpgrade = cantUpgrade.length
      const hasAcceptedMembers = acceptedMembers.length
      const hasDeclinedMembers = declinedMembers.length
      const emails = cantUpgrade
        .map(({ email }, index) => `${email}${index + 1 < cantUpgrade.length ? `, ` : ''}`)
        .join(' ')

      const notify = hasCantUpgrade && !hasAcceptedMembers && !hasDeclinedMembers ? 'Error' : 'Success'
      let message = ''

      if (hasDeclinedMembers)
        message = i18n.pageAdministration.upgradeMemberRequests.modalApproveRequests.resultMessage.successRejected
      else if (hasCantUpgrade && hasAcceptedMembers) {
        message = sprintf(
          i18n.pageAdministration.upgradeMemberRequests.modalApproveRequests.resultMessage
            .successCantUpgradeWithAccepted,
          { emails, users: hasAcceptedMembers },
        )
      } else if (hasCantUpgrade)
        message = i18n.pageAdministration.upgradeMemberRequests.modalApproveRequests.resultMessage.successCantUpgrade
      else if (!hasCantUpgrade)
        message = i18n.pageAdministration.upgradeMemberRequests.modalApproveRequests.resultMessage.success

      setIsFetching(false)
      notificationActions[`notify${notify}`](notify === 'Error' ? new BarError({ message }) : message)
      if (onClose) handleClose()
      if (onFinish) onFinish()
    }

    const handleError = ({ error }) => {
      setIsFetching(false)
      notificationActions.notifyError(
        new BarError({
          ...error,
          message: error?.message,
        }),
      )
      if (onClose) {
        handleClose()
      }
    }

    api.proMember.upgradeMemberRequestsAction(
      {
        accountId,
        accept,
        decline,
      },
      handleResponse,
      handleError,
    )
  }

  const renderCancelButton = () => (
    <StyledButton
      aria-label={`${i18n.generics.cancelLabel}`}
      className={styles.cancel}
      color="default"
      onClick={handleClose}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          e.preventDefault()
          onClose()
        }
      }}
      title={i18n.generics.cancelLabel}
      variant="text"
    >
      {i18n.generics.cancelLabel}
    </StyledButton>
  )

  const renderRejectButton = () => {
    const label = i18n.pageAdministration.upgradeMemberRequests.modalApproveRequests.reject
    const onClick = () =>
      handleUpgradeMembersAction({
        decline: profiles.map(profile => profile.id),
      })

    return (
      <StyledButton
        aria-label="Send generated reports to your email"
        className={styles.reject}
        id="button-modal-people-invite"
        onClick={onClick}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            e.preventDefault()
            onClose()
          }
        }}
        color="primary"
        title={label}
      >
        {label}
      </StyledButton>
    )
  }

  const renderAcceptButton = () => {
    const label = i18n.pageAdministration.upgradeMemberRequests.modalApproveRequests.approve
    const onClick = () =>
      handleUpgradeMembersAction({
        accept: profiles.map(profile => profile.id),
      })

    return (
      <StyledButton
        aria-label="Send generated reports to your email"
        className={styles.approve}
        id="button-modal-people-invite"
        onClick={onClick}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            e.preventDefault()
            onClose()
          }
        }}
        color="primary"
        title={label}
      >
        {label}
      </StyledButton>
    )
  }

  return (
    <article className={styles.modal}>
      <Card barBackgroundColor={COLORS.primaryBlue.rgba} className={styles.card} isLoading={isFetching}>
        <Header
          ariaLabel={i18n.pageAdministration.upgradeMemberRequests.modalApproveRequests.title}
          className={styles.header}
        >
          <h1>{i18n.pageAdministration.upgradeMemberRequests.modalApproveRequests.title}</h1>
        </Header>
        <Icon.Stroke7
          className={styles.close}
          name="close"
          onClick={handleClose}
          title={i18n.pageAdministration.upgradeMemberRequests.modalApproveRequests.close}
        />
        <Main className={styles.main}>
          <BadgeResults
            success={profiles.map(profile => ({ ...profile.profile })) || []}
            successTitle={i18n.pageAdministration.upgradeMemberRequests.modalApproveRequests.confirmationMessage}
          />
        </Main>

        <Footer>
          <Row>
            <Col xs="6">{renderCancelButton()}</Col>
            <Col xs="6">
              {renderAcceptButton()}
              {renderRejectButton()}
            </Col>
          </Row>
        </Footer>
      </Card>
    </article>
  )
}

ModalApproveRequests.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  notificationActions: ACTIONS.isRequired,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  pageActions: ACTIONS.isRequired,
  profiles: LIST(PERSON).isRequired,
}

ModalApproveRequests.defaultProps = {
  onClose: undefined,
  onFinish: undefined,
}

export default Translation(ModalApproveRequests, ['pageAdministration', 'notifications', 'generics'])
