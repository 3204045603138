import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { ACTIONS, I18N, INVITATION } from 'constants/props'

const useInvitationDialog = ({ actions, clearInvitationOnAccept, onAccept, onDecline, onError, pageActionsProps }) => {
  const [error, setError] = useState(null)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (pageActionsProps?.switchModalView) {
      pageActionsProps.switchModalView()
    }

    return () => {
      pageActionsProps.switchModalView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAccept = () => {
    setIsFetching(true)

    const handleResponse = () => {
      setIsFetching(false)

      if (onAccept) {
        onAccept()
      }
    }

    const handleError = errorObj => {
      setError(errorObj)
      setIsFetching(false)

      if (onError) {
        onError(errorObj)
      }
    }

    actions.acceptInvitation(clearInvitationOnAccept, handleResponse, handleError)
  }

  const handleConfirmDecline = () => {
    if (onDecline) {
      onDecline()
    }

    actions.clearInvitation()
  }

  const handleDecline = () => {
    setIsFetching(true)

    const handleResponse = () => {
      setIsFetching(false)
    }

    const handleError = errorObj => {
      setError(errorObj)
      setIsFetching(false)

      if (onError) {
        onError(errorObj)
      }
    }

    actions.declineInvitation(handleResponse, handleError)
  }

  const handleAcceptError = () => {
    actions.clearInvitation()
  }

  return {
    error,
    handleAccept,
    handleAcceptError,
    handleConfirmDecline,
    handleDecline,
    isFetching,
  }
}

useInvitationDialog.propTypes = {
  actions: ACTIONS.isRequired,
  clearInvitationOnAccept: PropTypes.bool,
  i18n: I18N.isRequired,
  invitation: INVITATION.isRequired,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  onError: PropTypes.func,
  pageActionsProps: ACTIONS.isRequired,
}

useInvitationDialog.defaultProps = {
  clearInvitationOnAccept: false,
  onAccept: undefined,
  onDecline: undefined,
  onError: undefined,
}

export default useInvitationDialog
