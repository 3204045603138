import d3 from 'vendor/d3'

export const initContainers = elementRefs => {
  const { canvasRef, legendRef, mainGRef, profilesRef, referencesRef, svgRef, textsRef } = elementRefs

  const baseElements = {
    canvas: d3.select(canvasRef.current),
    g_legend: d3.select(legendRef.current),
    g_main: d3.select(mainGRef.current),
    g_profiles: d3.select(profilesRef.current),
    g_quadrantsText: d3.select(textsRef.current),
    g_ref: d3.select(referencesRef.current),
    svg: d3.select(svgRef.current),
  }

  return baseElements
}
