import { useState } from 'react'
import PropTypes from 'prop-types'

import { hasFormErrors } from 'helpers'

const useForm = ({ initialValues, onSubmit, onValidate, resetOnSubmit }) => {
  const [values, setValues] = useState(initialValues || {})
  const [errors, setErrors] = useState({})
  const [isValid, setIsValid] = useState(false)

  const handleChange = (fieldName, value, isArray = false, position = null) => {
    let newValues
    let newErrors

    if (!isArray) {
      newValues = { ...values, [fieldName]: value }
      newErrors = onValidate(newValues)
    } else {
      newValues = [...values]
      const newValue = { ...newValues[position] }

      newValues[position] = {
        ...newValue,
        [fieldName]: value,
      }
      newErrors = onValidate(newValues, fieldName, position, value)
    }

    setErrors(newErrors)
    setIsValid(!hasFormErrors(newErrors))
    setValues(newValues)
  }

  const handleSubmit = (e, isArray = false) => {
    if (e) {
      e.preventDefault()
    }

    if (isValid) {
      if (!isArray) {
        onSubmit({ ...values })
      } else {
        onSubmit([...values])
      }

      if (resetOnSubmit) {
        setValues(!isArray ? {} : [])
        setIsValid(false)
      }
    }
  }

  const reset = (isArray = false) => {
    const newValues = {}
    if (!isArray) {
      Object.keys(values).forEach(key => {
        newValues[key] = ''
      })
    }
    setIsValid(!hasFormErrors(onValidate(newValues)))
    setValues(!isArray ? newValues : [])
  }

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    reset,
    setIsValid,
    setValues,
    values,
  }
}

useForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  resetOnSubmit: PropTypes.bool,
}

useForm.defaultProps = {
  initialValues: {},
  resetOnSubmit: false,
}

export default useForm
