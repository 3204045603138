/* eslint-disable react/no-did-update-set-state */
import { autobind } from 'core-decorators'
import { CircularProgress, MenuItem } from '@material-ui/core'
import { isNil } from 'lodash'
import { sprintf } from 'sprintf-js'
import propTypes from 'prop-types'
import React from 'react'

import { COUNTRIES } from 'constants/props'
import { NAME, REQUIRED, ZIP_CODE } from 'constants/inputTypes'
import { FormValidator } from 'classes'
import { hasFormErrors } from 'helpers'
import { StyledButton, StyledTextField, StyledSelect } from 'generics/StyledFormComponents'
import FormComponent from 'generics/FormComponent'
import Mask from 'generics/Mask'
import Translation from 'generics/Translation'

import CardSection from '../CardSection/CardSection'
import styles from './CheckoutForm.scss'

export class CheckoutForm extends FormComponent {
  constructor(props) {
    super(props)
    this.state = {
      values: {},
      errors: {},
      error: '',
      isLoading: false,
      isValid: false,
    }
  }

  componentDidUpdate({ defaultCountryId, orderQuantity }) {
    if (
      defaultCountryId !== this.props.defaultCountryId ||
      (this.props.defaultCountryId && !this.state.values.countryId)
    ) {
      this.handleChange('countryId', this.props.defaultCountryId || '')
    }

    if (orderQuantity !== this.props.orderQuantity) {
      this.handleChange(
        'order',
        this.props.orderQuantity ? `x${this.props.orderQuantity} ($${this.props.amount})` : null,
      )
      this.handleChange('quantity', this.props.orderQuantity ? this.props.orderQuantity : '')
    }
  }

  @autobind
  validate() {
    const { i18n, defaultCountryId } = this.props
    const { values } = this.state

    const { countryId, fullName, order, postalCode } = values

    const validator = new FormValidator(i18n)

    const errors = {
      countryId: validator.validate(REQUIRED, countryId),
      fullName: validator.validate(NAME, fullName),
      order: validator.validate(REQUIRED, order),
      postalCode:
        defaultCountryId === countryId
          ? validator.validate(REQUIRED, postalCode) || validator.validate(ZIP_CODE, postalCode)
          : null,
    }

    this.setState({
      errors,
      isValid: !hasFormErrors(errors),
    })
  }

  render() {
    const { amount, countries, defaultCountryId, i18n, isLoading, stripe, stripeEmail, stripeError } = this.props

    const { errors, values, isValid } = this.state

    const payLabel = sprintf(i18n.pageTokenAdministration.tokenManagement.payLabel, { amount })

    return (
      <>
        <form onSubmit={this.handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <StyledTextField
              label={i18n.pageTokenAdministration.tokenManagement.checkout.orderLabel}
              name="order"
              disabled
              type="text"
              value={values.order || ''}
            />
            <StyledTextField
              label={i18n.pageTokenAdministration.tokenManagement.checkout.emailLabel}
              name="stripeEmail"
              disabled
              value={stripeEmail || ''}
            />
          </div>
          <div className={styles.formGroup}>
            <div className={styles.eyebrow}>Card information</div>
            <CardSection />
            {stripeError && <p className={styles.error}>{stripeError}</p>}
          </div>
          <div className={styles.formGroup}>
            <StyledTextField
              error={isNil(values.fullName) ? null : errors.fullName}
              helperText={isNil(values.fullName) ? ' ' : errors.fullName}
              label={i18n.pageTokenAdministration.tokenManagement.checkout.nameOnCardLabel}
              name="fullName"
              onChange={e => this.handleChange('fullName', e.currentTarget.value)}
              type="text"
              value={values.fullName || ''}
            />
            <StyledSelect
              error={isNil(values.countryId) ? null : errors.countryId}
              fullWidth
              helperText={isNil(values.countryId) ? ' ' : errors.countryId}
              iconClassName={styles['select-icon']}
              label={i18n.pageTokenAdministration.tokenManagement.checkout.countriesLabel}
              name="countryId"
              onChange={e => this.handleChange('countryId', e.target.value)}
              value={values.countryId || ''}
            >
              {countries &&
                countries.map(country => (
                  <MenuItem key={country.value} value={country.value}>
                    {country.text}
                  </MenuItem>
                ))}
            </StyledSelect>
            {defaultCountryId === values?.countryId && (
              <StyledTextField
                error={isNil(values.postalCode) ? null : errors.postalCode}
                helperText={isNil(values.postalCode) ? ' ' : errors.postalCode}
                label={i18n.pageTokenAdministration.tokenManagement.checkout.zipCodeLabel}
                name="postalCode"
                onChange={e => this.handleChange('postalCode', e.currentTarget.value)}
                type="text"
                value={values.postalCode || ''}
              />
            )}
          </div>
          {!!amount && (
            <StyledButton aria-disabled={!stripe || !isValid} isBlock type="submit">
              {payLabel}
            </StyledButton>
          )}
        </form>
        <Mask className={styles.mask} open={isLoading}>
          <CircularProgress className={styles.loader} size={25} thickness={2} />
        </Mask>
      </>
    )
  }
}

CheckoutForm.propTypes = {
  amount: propTypes.number,
  countries: COUNTRIES.isRequired,
  orderQuantity: propTypes.string,
  stripeEmail: propTypes.string,
  resetOnSubmit: propTypes.bool,
}

CheckoutForm.defaultProps = {
  amount: 0,
  orderQuantity: '',
  stripeEmail: '',
  resetOnSubmit: false,
}

export default Translation(CheckoutForm, ['pageTokenAdministration', 'generics'])
