/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from '@material-ui/core'
import { Col, Container, Row } from 'reactstrap'
import DocumentTitle from 'react-document-title'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { ACTIONS, I18N, META, PEOPLE, RECEIVED_REQUESTS, SENT_REQUESTS } from 'constants/props'
import Mask from 'generics/Mask'
import Translation from 'generics/Translation'

import ProfilesCard from './components/ProfilesCard'
import SharedProfileRequests from './components/SharedProfileRequests'
import styles from './PageNetwork.scss'

const PageNetwork = ({
  actions,
  accountId,
  i18n,
  isDepartmentEnabled,
  isFetchingMyConnections,
  isFetchingMyNetwork,
  isFetchingRequests,
  isPersonalAccount,
  myConnectionsMeta,
  myConnectionsProfiles,
  networkMeta,
  networkProfiles,
  receivedRequests,
  sentRequests,
}) => {
  const [connectionsFilter, setConnectionsFilter] = useState(null)
  const [filter, setFilter] = useState(null)
  const [list, setList] = useState('received')
  const [myConnectionsParams, setMyConnectionsParams] = useState({})
  const [myNetworkParams, setMyNetworkParams] = useState({})
  const [pageIndex, setPageIndex] = useState(1)
  const [rendered, setRendered] = useState(false)
  const [sharedRequestsPageIndex, setSharedRequestsPageIndex] = useState(1)
  const [isNetworkVisible, setIsNetworkVisible] = useState(false)

  const { totalObjects: networkTotal } = networkMeta || {}
  const { totalObjects: receivedRequestsTotal } = receivedRequests?.meta || {}
  const { totalObjects: sentRequestsTotal } = sentRequests?.meta || {}

  const handleFetchMyConnections = params => {
    setMyConnectionsParams(params)
    actions.fetchMyConnections(params)
  }

  const handleFetchMyNetwork = params => {
    setMyNetworkParams(params)
    actions.fetchMyNetwork(params)
  }

  const handleReply = () => {
    actions.fetchMyConnections(myConnectionsParams)
    actions.fetchMyNetwork(myNetworkParams)
    actions.fetchConnectionRequests({ list: 'received', pageIndex: 1 })
    actions.fetchConnectionRequests({ list: 'sent', pageIndex: 1 })
  }

  const handleFetchRequests = (newPageIndex = 1, manualValue = null) =>
    actions.fetchConnectionRequests({ list: manualValue || list, pageIndex: newPageIndex })

  useEffect(() => {
    handleFetchMyConnections({ pageIndex, search: filter })
    handleFetchMyNetwork({ pageIndex, search: filter })
    handleFetchRequests()
    handleFetchRequests(1, 'sent')
    setRendered(true)
  }, [])

  useEffect(() => {
    if (rendered) {
      handleFetchRequests()
    }
  }, [list, sharedRequestsPageIndex])

  useEffect(() => {
    if (connectionsFilter !== null) {
      handleFetchMyConnections({ pageIndex: 1, search: connectionsFilter })
    }
  }, [connectionsFilter])

  useEffect(() => {
    if (filter !== null) {
      handleFetchMyNetwork({ pageIndex: 1, search: filter })
    }
  }, [filter])

  useEffect(() => {
    setIsNetworkVisible(networkTotal > 0 || receivedRequestsTotal > 0 || sentRequestsTotal > 0)
  }, [networkTotal, receivedRequestsTotal, sentRequestsTotal])

  return (
    <DocumentTitle title="Simpli5® - Network Page">
      <article className={styles.page}>
        <Container>
          {(!isFetchingMyConnections || !isFetchingMyNetwork || !isFetchingRequests) && (
            <Row>
              <Col md="12" lg={{ size: 7, offset: isNetworkVisible > 0 ? 0 : 3 }}>
                <ProfilesCard
                  accountId={accountId}
                  className={styles.network}
                  description={i18n.network.myConnections.description}
                  filter={connectionsFilter}
                  hasShareProfile
                  isDepartmentEnabled={isDepartmentEnabled}
                  isFetching={isFetchingMyConnections}
                  isPersonalAccount={isPersonalAccount}
                  meta={myConnectionsMeta}
                  onFetch={handleFetchMyConnections}
                  pageIndex={pageIndex}
                  profiles={myConnectionsProfiles}
                  setFilter={setConnectionsFilter}
                  setPageIndex={setPageIndex}
                  title={i18n.network.myConnections.title}
                />
                {isNetworkVisible && (
                  <ProfilesCard
                    accountId={accountId}
                    className={styles.network}
                    description={i18n.network.networkMembers.description}
                    filter={filter}
                    isDepartmentEnabled={isDepartmentEnabled}
                    isFetching={isFetchingMyNetwork}
                    isPersonalAccount={isPersonalAccount}
                    meta={networkMeta}
                    onFetch={handleFetchMyNetwork}
                    pageIndex={pageIndex}
                    profiles={networkProfiles}
                    setFilter={setFilter}
                    setPageIndex={setPageIndex}
                    title={i18n.network.networkMembers.title}
                  />
                )}
              </Col>
              {isNetworkVisible && (
                <Col md="12" lg="5">
                  <SharedProfileRequests
                    list={list}
                    onFetch={handleFetchRequests}
                    onReply={handleReply}
                    pageIndex={sharedRequestsPageIndex}
                    setList={setList}
                    setPageIndex={setSharedRequestsPageIndex}
                    receivedRequests={receivedRequests}
                    sentRequests={sentRequests}
                  />
                </Col>
              )}
            </Row>
          )}
          <Mask
            className={styles.mask}
            open={
              (isFetchingMyConnections || isFetchingMyNetwork || isFetchingRequests) &&
              filter === null &&
              connectionsFilter === null
            }
          >
            <CircularProgress className={styles.loader} size={60} thickness={7} />
          </Mask>
        </Container>
      </article>
    </DocumentTitle>
  )
}

PageNetwork.propTypes = {
  accountId: PropTypes.number.isRequired,
  actions: ACTIONS.isRequired,
  i18n: I18N.isRequired,
  isDepartmentEnabled: PropTypes.bool.isRequired,
  isFetchingMyConnections: PropTypes.bool.isRequired,
  isFetchingMyNetwork: PropTypes.bool.isRequired,
  isFetchingRequests: PropTypes.bool.isRequired,
  isPersonalAccount: PropTypes.bool.isRequired,
  myConnectionsMeta: META.isRequired,
  myConnectionsProfiles: PEOPLE.isRequired,
  networkMeta: META.isRequired,
  networkProfiles: PEOPLE.isRequired,
  receivedRequests: RECEIVED_REQUESTS.isRequired,
  sentRequests: SENT_REQUESTS.isRequired,
}

export default Translation(PageNetwork, ['network'])
