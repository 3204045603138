import * as adminUpdateEmail from './adminUpdateEmail'
import * as fetchConnectionRequests from './fetchConnectionRequests'
import * as fetchMyNetwork from './fetchMyNetwork'
import * as fetchNetworkDetails from './fetchNetworkDetails'
import * as fetchNetworkLists from './fetchNetworkLists'
import * as fetchReceivedRequests from './fetchReceivedRequests'
import * as fetchReplyConnectionRequest from './fetchReplyConnectionRequest'
import * as fetchSendConnectionRequest from './fetchSendConnectionRequest'
import * as fetchSentRequests from './fetchSentRequests'
import * as fetchThirdPersonProfile from './fetchThirdPersonProfile'
import * as fetchThirdPersonRoles from './fetchThirdPersonRoles'
import * as fetchUpdateProfileRoles from './fetchUpdateProfileRoles'
import * as fetchMyConnections from './fetchMyConnections'

module.exports = {
  ...adminUpdateEmail,
  ...fetchConnectionRequests,
  ...fetchMyConnections,
  ...fetchMyNetwork,
  ...fetchNetworkDetails,
  ...fetchNetworkLists,
  ...fetchReceivedRequests,
  ...fetchReplyConnectionRequest,
  ...fetchSendConnectionRequest,
  ...fetchSentRequests,
  ...fetchThirdPersonProfile,
  ...fetchThirdPersonRoles,
  ...fetchUpdateProfileRoles,
}
