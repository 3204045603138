import PropTypes from 'prop-types'
import { useState } from 'react'

const useFormToggleRenewalEmailNotifications = ({ hasSubscriptionNotifications }) => {
  const [enableSubscriptionNotifications, setEnableSubscriptionNotifications] = useState(hasSubscriptionNotifications)

  const handleValueChange = value => setEnableSubscriptionNotifications(value)

  return {
    enableSubscriptionNotifications,
    onChange: handleValueChange,
  }
}

useFormToggleRenewalEmailNotifications.propTypes = {
  hasSubscriptionNotifications: PropTypes.bool.isRequired,
}

export default useFormToggleRenewalEmailNotifications
