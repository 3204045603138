import { isNil, size } from 'lodash'
import { sprintf } from 'sprintf-js'
import moment from 'moment'

import {
  CURRENT_PASSWORD,
  DATE,
  DATE_TO,
  EMAIL,
  FREQUENCY,
  FREQUENCY_TYPE,
  GROUP_NAME,
  NAME,
  PASSWORD,
  PASSWORD_CONFIRMATION,
  PHONE,
  REQUIRED,
  REQUIRED_ALLOW_ZERO,
  TEXT,
  ZIP_CODE,
} from 'constants/inputTypes'

import { FREQUENCIES } from 'constants/frequencies'

const DATE_FORMAT = 'MM/DD/YYYY'
const EMAIL_MAX_LEGTH = 120
const TEXT_MAX_LENGTH = 252
const TEXT_MIN_LENGTH = 2

const validateDigit = text => !/(?=.*\d)/.test(text)
const validateEmailFormat = email => !/^[A-Za-zÀ-ÖØ-öø-ÿ0-9.'‘’`´_%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email)
const validateEmailLength = email => size(email) > EMAIL_MAX_LEGTH
const validateLowerCase = string => !/(?=.*[a-z])/.test(string)
const validateMaxLength = (text, maxLength = TEXT_MAX_LENGTH) => size(text) > maxLength
const validateMinLength = (text, minLength = TEXT_MIN_LENGTH) => size(text) < minLength
// const validateOnlyDigits = text => !/^[0-9]+$/.test(text);
const validatePhoneFormat = text =>
  !/^(?:\+?\d{1,3}\s*[- .])?\(?(?:\d{1,4})?\)?[- .]?\d{1,4}[- ]?\d{1,4}\s*[a-z]*\d*$/.test(text)
const validateSpecialCharacters = text => !/[~!@#$%^&*()_+.']/.test(text)
const validateString = text => !/^[-a-zA-ZÀ-ÖØ-öø-ÿç¨'‘’`´ ]*$/.test(text)
const validateUpperCase = text => !/(?=.*[A-Z])/.test(text)
const validateZipCodeFormat = zipCode => !/^\d{5}(-\d{4})?$/.test(zipCode)

/**
 *  Validates current password field value
 *  @param {object} i18n translations
 *  @param {string} password
 *  @returns {string} error
 */
const validateCurrentPassword = (i18n, password) => (!password ? i18n.generics.errors.required : null)

/**
 *  Validates password field value
 *  @param {object} i18n translations
 *  @param {string} password
 *  @returns {string} error
 */
function validatePassword(i18n, password, oldPassword) {
  let error = null

  if (!password) {
    error = i18n.generics.errors.required
  } else if (password === oldPassword) {
    error = i18n.generics.errors.password.same
  } else if (password.length < 6) {
    error = i18n.generics.errors.password.length
  } else if (validateLowerCase(password)) {
    error = i18n.generics.errors.password.lowerCase
  } else if (validateUpperCase(password)) {
    error = i18n.generics.errors.password.upperCase
  } else if (validateDigit(password)) {
    error = i18n.generics.errors.password.digit
  } else if (validateSpecialCharacters(password)) {
    error = i18n.generics.errors.password.specialChars
  }

  return error
}

/**
 *  Validates password confirmation field
 *  @param {object} i18n translations
 *  @param {string} password
 *  @param {string} passwordConfirmation
 *  @returns {string} error
 */
function validatePasswordConfirmation(i18n, password, passwordConfirmation) {
  let error = null

  if (!passwordConfirmation) {
    error = i18n.generics.errors.required
  } else if (password !== passwordConfirmation) {
    error = i18n.generics.errors.password.confirmNoMatch
  }

  return error
}

/**
 *  Validates name field value
 *  @param {object} i18n translations
 *  @param {string} name
 *  @returns {string} error
 */
function validateName(i18n, name) {
  let error = null

  if (!name) {
    error = i18n.generics.errors.required
  } else if (validateMaxLength(name)) {
    error = i18n.generics.errors.maxLength
  } else if (validateString(name)) {
    error = i18n.generics.errors.noSpecialCharsOrNumbers
  }

  return error
}

/**
 *  Validates group name field value
 *  @param {object} i18n translations
 *  @param {string} groupName
 *  @returns {string} error
 */
function validateGroupName(i18n, groupName) {
  let error = null

  if (!groupName) {
    error = i18n.generics.errors.required
  } else if (validateMinLength(groupName)) {
    error = i18n.generics.errors.minLength
  } else if (validateMaxLength(groupName)) {
    error = i18n.generics.errors.maxLength
  }

  return error
}

/**
 *  Validates date field value
 *  @param {object} i18n translations
 *  @param {string} date
 *  @returns {string} error
 */
function validateDateFormat(i18n, strDate) {
  const date = moment(strDate, DATE_FORMAT)
  return date.isValid() ? null : i18n.generics.errors.invalidDate
}

/**
 *  Validates date field value
 *  @param {object} i18n translations
 *  @param {string} dateFrom
 *  @param {string} dateTo
 *  @returns {string} error
 */
function validateDateTo(i18n, dateFrom, dateTo) {
  return dateFrom && dateTo && dateTo < dateFrom ? i18n.generics.errors.dateFromMoreRecent : null
}

/**
 *  Validates email field value
 *  @param {object} i18n translations
 *  @param {string} email
 *  @returns {string} error
 */
function validateEmail(i18n, email) {
  let error = null
  if (validateEmailFormat(email)) {
    error = i18n.generics.errors.invalidEmail
  } else if (validateEmailLength(email)) {
    error = i18n.generics.errors.invalidEmailLength
  }

  return error
}

/**
 *  Validates phone field value
 *  @param {object} i18n translations
 *  @param {string} phone
 *  @returns {string} error
 */
function validatePhone(i18n, phone) {
  let error = null

  if (validateMaxLength(phone)) {
    error = i18n.generics.errors.maxLength
  } else if (phone && validatePhoneFormat(phone)) {
    error = i18n.generics.errors.invalidPhone
  }

  return error
}

/**
 *  Validates frequency
 *  @param {object} i18n translations
 *  @param {string} frequency
 *  @returns {string} error
 */
function validateFrequency(i18n, frequency) {
  let error = null

  if (isNil(frequency)) {
    error = i18n.generics.errors.required
  } else if (isNil(FREQUENCIES[frequency])) {
    error = i18n.generics.errors.invalidFrequency
  }
  return error
}

/**
 *  Validates frequencyType
 *  @param {object} i18n translations
 *  @param {string} frequency
 *  @param {string} frequencyType
 *  @returns {string} error
 */
function validateFrequencyType(i18n, frequency, frequencyType) {
  if (isNil(frequencyType)) {
    return i18n.generics.errors.required
  }

  return !FREQUENCIES[frequency].includes(frequencyType) ? i18n.generics.errors.invalidFrequencyType : null
}

/**
 *  Validates that a field is required
 *  @param {object} i18n translations
 *  @param {string} text
 *  @returns {string} error
 */
function validateRequired(i18n, value) {
  return !value ? i18n.generics.errors.required : null
}

function validateRequiredAllowZero(i18n, value) {
  return !value && value !== 0 ? i18n.generics.errors.required : null
}

/**
 *  Validates text
 *  @param {object} i18n translations
 *  @param {string} text
 *  @param {number} minLength
 *  @param {number} maxLength
 *  @returns {string} error
 */
function validateTextLength(i18n, text, minLength = TEXT_MIN_LENGTH, maxLength = TEXT_MAX_LENGTH) {
  let error = null

  if (text && validateMinLength(text, minLength)) {
    error = sprintf(i18n.generics.errors.customMinLength, minLength)
  } else if (validateMaxLength(text, maxLength)) {
    error = sprintf(i18n.generics.errors.customMaxLength, maxLength)
  }

  return error
}

function validateZipCode(i18n, zipCode) {
  let error = null

  if (zipCode && validateZipCodeFormat(zipCode)) {
    error = sprintf(i18n.generics.errors.zipCode, zipCode)
  }
  return error
}

/**
 *  Form Validation Class
 */
export default class FormValidator {
  constructor(i18n) {
    this.i18n = i18n
  }

  /**
   * Validates
   * @param {string} type
   * @param {string} value (could have 1 or more)
   */
  validate(type, ...values) {
    let validationFn = null

    switch (type) {
      case CURRENT_PASSWORD:
        validationFn = validateCurrentPassword
        break
      case DATE:
        validationFn = validateDateFormat
        break
      case DATE_TO:
        validationFn = validateDateTo
        break
      case EMAIL:
        validationFn = validateEmail
        break
      case FREQUENCY:
        validationFn = validateFrequency
        break
      case FREQUENCY_TYPE:
        validationFn = validateFrequencyType
        break
      case GROUP_NAME:
        validationFn = validateGroupName
        break
      case NAME:
        validationFn = validateName
        break
      case PASSWORD:
        validationFn = validatePassword
        break
      case PASSWORD_CONFIRMATION:
        validationFn = validatePasswordConfirmation
        break
      case PHONE:
        validationFn = validatePhone
        break
      case REQUIRED:
        validationFn = validateRequired
        break
      case REQUIRED_ALLOW_ZERO:
        validationFn = validateRequiredAllowZero
        break
      case TEXT:
        validationFn = validateTextLength
        break
      case ZIP_CODE:
        validationFn = validateZipCode
        break
      default:
        break
    }

    return validationFn ? validationFn(this.i18n, ...values) : null
  }
}
