import { Col, Container, Row } from 'reactstrap'
import { isNil } from 'lodash'
import { MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { COUNTRIES, I18N, PERSON } from 'constants/props'
import { StyledButton, StyledTextField, StyledSelect } from 'generics/StyledFormComponents'
import genderPronouns from 'constants/genderPronouns'
import Translation from 'generics/Translation'

import styles from './FormAccountInformation.scss'
import useFormAccountInformation from './useFormAccountInformation'

const getFieldTitle = (i18n, fieldName, isRequired) => (
  <div className={styles['field-title']}>
    <span title={i18n.myProfile.accountInformationForm.fields[fieldName]}>
      {i18n.myProfile.accountInformationForm.fields[fieldName]}
    </span>
    {isRequired && (
      <span className={styles.required} title={i18n.generics.requiredField}>
        *
      </span>
    )}
  </div>
)

const FormAccountInformation = ({
  i18n,
  isLearningAccount,
  countriesList,
  initialValues,
  onSubmit,
  updateEmailAction,
  isChannelPartner = false,
}) => {
  const { account } = initialValues

  const { errors, isValid, handleChange, handleSubmit, values } = useFormAccountInformation({
    i18n,
    initialValues,
    onSubmit,
  })

  const getFormFieldText = fieldName => (
    <StyledTextField
      className={styles['text-field']}
      name={fieldName}
      error={isNil(values[fieldName]) ? false : !!errors[fieldName]}
      helperText={isNil(values) ? ' ' : errors[fieldName]}
      onChange={e => handleChange(fieldName, e.currentTarget.value)}
      value={values[fieldName] || ''}
    />
  )

  const countriesMenuList = countriesList.map(({ id, name }) => (
    <MenuItem key={id} value={id}>
      {name}
    </MenuItem>
  ))

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col>
            <h2 className={styles['sub-title']} title={i18n.myProfile.accountInformationForm.personalInformation}>
              {i18n.myProfile.accountInformationForm.personalInformation}
            </h2>
          </Col>
        </Row>

        <Row>
          <Col xs="12">{getFieldTitle(i18n, 'name', true)}</Col>
        </Row>

        <Row>
          <Col xs="12" sm="6">
            {getFormFieldText('firstName')}
          </Col>
          <Col xs="12" sm="6">
            {getFormFieldText('lastName')}
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="6">
            {getFieldTitle(i18n, 'genderPronoun')}
            <StyledSelect
              color="primary"
              iconClassName={styles.icon}
              error={isNil(values.genderPronounPreference) ? false : !!errors.genderPronounPreference}
              fullWidth
              helperText={isNil(values.genderPronounPreference) ? ' ' : errors.genderPronounPreference}
              name="genderPronounPreference"
              onChange={e => handleChange('genderPronounPreference', e.target.value)}
              value={values.genderPronounPreference}
            >
              {genderPronouns.map(genderPronoun => (
                <MenuItem key={genderPronoun.value} value={genderPronoun.value}>
                  {genderPronoun.gender}
                </MenuItem>
              ))}
            </StyledSelect>
          </Col>
          <Col xs="12" sm="6">
            <div className={styles['label-action']}>
              {getFieldTitle(i18n, 'emailAddress', true)}
              {!isChannelPartner && updateEmailAction}
            </div>
            {account.isPersonal && getFormFieldText('email')}
            {!account.isPersonal && (
              <>
                <div className={styles['edit-field--not-allowed']} title={initialValues.email}>
                  {initialValues.email}
                </div>
              </>
            )}
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="6">
            {getFieldTitle(i18n, 'mobilePhone')}
            {getFormFieldText('mobilePhone')}
          </Col>
          <Col xs="12" sm="6">
            {getFieldTitle(i18n, 'workPhone')}
            {getFormFieldText('workPhone')}
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            {getFieldTitle(i18n, 'street')}
            {getFormFieldText('address')}
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="6">
            {getFieldTitle(i18n, 'country')}
            <StyledSelect
              color="primary"
              iconClassName={styles.icon}
              fullWidth
              name="countryId"
              onChange={e => handleChange('countryId', e.target.value)}
              value={values.countryId || 0}
            >
              <MenuItem value={0} className={styles.placeholder} disabled>
                {i18n.myProfile.accountInformationForm.fields.selectCountry}
              </MenuItem>
              {countriesMenuList}
            </StyledSelect>
          </Col>
          <Col xs="12" sm="6">
            {getFieldTitle(i18n, 'state')}
            {getFormFieldText('state')}
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="6">
            {getFieldTitle(i18n, 'city')}
            {getFormFieldText('city')}
          </Col>
          <Col xs="12" sm="6">
            {getFieldTitle(i18n, 'zipCode')}
            {getFormFieldText('postalCode')}
          </Col>
        </Row>
      </Container>

      {!account.isPersonal && (
        <Container>
          <Row>
            <Col>
              <h2
                className={styles['sub-title']}
                title={
                  !isLearningAccount
                    ? i18n.myProfile.accountInformationForm.employeeInformation
                    : i18n.myProfile.accountInformationForm.studentInformation
                }
              >
                {!isLearningAccount
                  ? i18n.myProfile.accountInformationForm.employeeInformation
                  : i18n.myProfile.accountInformationForm.studentInformation}
              </h2>
            </Col>
          </Row>
          {!isLearningAccount && (
            <Row>
              <Col xs="12" sm="6">
                {getFieldTitle(i18n, 'jobTitle')}
                {getFormFieldText('jobTitle')}
              </Col>
            </Row>
          )}

          <Row>
            <Col xs="12" sm="6">
              {getFieldTitle(i18n, !isLearningAccount ? 'company' : 'organization')}
              <div className={styles['edit-field--not-allowed']} title={account.name}>
                {account.name}
              </div>
            </Col>
          </Row>
          {initialValues?.department && (
            <Row>
              <Col xs="12" sm="6">
                {getFieldTitle(i18n, 'department')}
                <div className={styles['edit-field--not-allowed']} title={initialValues.department}>
                  {initialValues.department}
                </div>
              </Col>
            </Row>
          )}

          {!isLearningAccount && (
            <Row>
              <Col xs="12" sm="6">
                {getFieldTitle(i18n, 'division')}
                {getFormFieldText('division')}
              </Col>
            </Row>
          )}
        </Container>
      )}
      <Container>
        <Row>
          <Col xs={12} md={{ size: 2, offset: 10 }}>
            <StyledButton
              disabled={!isValid}
              fullWidth
              title={i18n.myProfile.accountInformationForm.saveButtonLabel}
              type="submit"
            >
              {i18n.myProfile.accountInformationForm.saveButtonLabel}
            </StyledButton>
          </Col>
        </Row>
      </Container>
    </form>
  )
}

FormAccountInformation.propTypes = {
  countriesList: COUNTRIES.isRequired,
  i18n: I18N.isRequired,
  initialValues: PERSON.isRequired,
  isLearningAccount: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  updateEmailAction: PropTypes.node.isRequired,
  isChannelPartner: PropTypes.bool.isRequired,
}

export default Translation(FormAccountInformation, ['myProfile', 'generics'])
