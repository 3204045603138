import { every, has, isNil, orderBy, sortBy } from 'lodash'
import { loadStripe } from '@stripe/stripe-js'
import { saveAs } from 'file-saver'
import { scaleThreshold } from 'd3-scale'
import shortid from 'shortid'

import { VTT } from 'constants/contentTypes'

const { MODULES_ORDER } = process.env
const KEY_REGEX = /_\d+/g
const MAX_INDEX = 9999
const MODULES_ORDER_ARRAY = (MODULES_ORDER || '').split('|')

// TODO: refactor helper
export function setIterationIndex(list) {
  if (list) {
    list.map(item => Object.assign(item, { id: item.id || shortid.generate() }))
  }
  return list
}

export function keyIndex(array) {
  return array.map(item => Object.assign(item, { id: shortid.generate() }))
}

const energyRange = scaleThreshold().domain([0, 2, 5, 9, 12, 14]).range([
  'R',
  'R', // 0-1 = Reserved
  'D', // 2-4 = Deliberate
  'D+', // 5-8 = Deliberate+
  'E', // 9-11 = Effortless
  'E+', // 12-13 = Effortless+
  'A', // 14-15 = Abundant
])

export function mapAssessmentScores(assessmentScores) {
  if (!assessmentScores) {
    return {}
  }

  // [Explore, Excite, Examine, Execute] = assessmentScores
  let [e1 = '-', e2 = '-', e3 = '-', e4 = '-'] = assessmentScores.map(score => Math.round(score * 100) / 100)

  const scoresLabel = `(${e1}, ${e2}, ${e3}, ${e4})`

  ;[e1, e2, e3, e4] = assessmentScores.map(score => energyRange(score))

  const energyRanges = `(${e1}, ${e2}, ${e3}, ${e4})`

  return {
    energyRanges,
    scoresLabel,
  }
}

const moduleLabels = {
  leading___name_s_12: {
    isLeader: 'Is Leader',
  },
  leading_the_team_14: {
    isLeader: 'Is Leader',
  },
}

export const getModuleLabels = ({ moduleKey, labelId }) => {
  const labels = moduleLabels[moduleKey]
  return labels && labels[labelId]
}

export const uppercaseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

/**
 * Get all CSS rules applied to a node element
 * @param  {HTMLElement} nodeElement - HTML DOM Element
 * @return {string} All styles definitions applied to an element
 */
export function getMatchedCSSRules(nodeElement) {
  const element = nodeElement

  element.matches =
    nodeElement.matches ||
    nodeElement.webkitMatchesSelector ||
    nodeElement.mozMatchesSelector ||
    nodeElement.msMatchesSelector ||
    nodeElement.oMatchesSelector

  return [].slice.call(document.styleSheets).reduce(
    (sheetAccumulator, sheet) =>
      sheetAccumulator +
      [].slice.call(has(sheet, 'rules') ? sheet.rules : sheet.cssRules).reduce((ruleAccumulator, rule) => {
        // some inherit selectors could be invalid for the current browser
        try {
          if (element.matches(rule.selectorText)) {
            return ruleAccumulator + rule.cssText
          }
        } catch (e) {
          return ruleAccumulator
        }

        return ruleAccumulator
      }, ''),
    '',
  )
}

/**
 * @description Gets the CSS styles from a list of SVGs
 * @param  { array } svgs
 * @returns { array } CSS rules
 */
export const getCSSFromSVGs = svgs =>
  [].slice
    .call(svgs[0].componentNode.querySelectorAll('*'))
    .reduce((accumulator, element) => accumulator + getMatchedCSSRules(element), '')

export function name(separator) {
  const firstName = this.firstName || ''
  const lastName = this.lastName ? `${separator || ' '}${this.lastName}` : ''
  return `${firstName}${lastName}`
}

// TODO: Remove this when descriptions cames fine from Epic
export const getModulesDescription = module => {
  switch (module.key) {
    case 'leading_the_team_14':
      return {
        ...module,
        description:
          'Where you may need to adjust your natural leadership style to meet the team’s needs and potential friction points within the team.',
      }

    case 'leading___name_s_12':
      return {
        ...module,
        description:
          'How do you lead others? Learn more about working with individual team members throughout a Project Completion Cycle.',
      }

    case 'working_together_6':
      return {
        ...module,
        description:
          'How do two individuals align Energetically? Look at two people in a peer-to-peer relationship to learn more about their collaboration styles.',
      }

    default:
      return module
  }
}

/**
 * Crossbrowser window.dispatchEvent (use this function because some events don't work on IE)
 */
export const fireWindowEvent = eventName => {
  if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
    const newEvent = document.createEvent('UIEvents')
    newEvent.initUIEvent(eventName, true, false, window, 0)
    window.dispatchEvent(newEvent)
  } else {
    window.dispatchEvent(new Event(eventName))
  }
}

/**
 * checks if assessmentScores has values
 */
export const hasScores = assessmentScores => assessmentScores && !assessmentScores.every(score => score === null)

/**
 * @description Checks if there are form errors
 * @param { Object } formErrors
 * @returns true if there are formErrors
 */
export const hasFormErrors = formErrors => {
  if (!Array.isArray(formErrors)) {
    return !every(formErrors, prop => isNil(prop))
  }
  let listHasErrors = false
  for (let i = 0; i < formErrors.length; i += 1) {
    if (!every(formErrors[i], prop => isNil(prop))) {
      listHasErrors = true
      break
    }
  }
  return listHasErrors
}

/**
 * @description Transforms fn with callbacks into a promise
 * @param {function} fn fn(data, handleResponse, handleError)
 * @returns { Promise } fn(data).then().catch()
 */
export const fromCallbacksToPromise = (fn, data = null) =>
  new Promise((resolve, reject) =>
    fn(
      data,
      response => resolve(response),
      error => reject(error),
    ),
  )

/**
 * @description Sorts collection by object firstName, lastName (not sensitive case)
 * @param {array} list
 * @returns { array } ordered collection
 */
export const orderByFirstNameLastName = list =>
  sortBy(list, [
    ({ firstName = '', name: teamName = '' }) => (firstName || teamName).toLowerCase(),
    ({ lastName = '' }) => lastName?.toLowerCase(),
  ])

export const sortModules = (modules = []) => {
  const indexedModules = modules.map(module => {
    const index = MODULES_ORDER_ARRAY.indexOf(module.key.replace(KEY_REGEX, ''))

    return {
      ...module,
      index: index === -1 ? MAX_INDEX : index,
    }
  })

  return orderBy(indexedModules, ['index'], ['asc'])
}

/**
 * @description IE11 doesn't read vtt files from different domains, this is a polyfill to read them
 * It requests the file and creates a local copy for it
 */
export const applyTrackPolyfill = () => {
  const applyPolyfill = /MSIE|Trident|Firefox/.test(window.navigator.userAgent)

  if (applyPolyfill) {
    const elements = document.querySelectorAll('track')

    Array.from(elements).forEach(trackObj => {
      const track = trackObj

      if (!track.src.includes(window.location.hostname)) {
        const xmlHttpRequest = new XMLHttpRequest()
        xmlHttpRequest.open('GET', track.src, true)
        xmlHttpRequest.responseType = VTT

        xmlHttpRequest.onload = () => {
          // create new blob (File Content)
          const blob = new Blob([xmlHttpRequest.response], { type: VTT })
          // Create the local temp url for the blob
          track.src = window.URL.createObjectURL(blob)

          if (track.default) {
            track.track.mode = 'showing'
          }
        }

        xmlHttpRequest.send()
      }
    })
  }
}

/**
 * @description Gets the name of the file from a path
 * @param { string } path
 * @returns { string } file name
 */
export const getFileNameFromPath = path => path.replace(/^.*\//g, '').replace(/\?.*/g, '')

/**
 * @description Gets the format of the file from a path
 * @param { string } path
 * @returns { string } file format
 */
export const getFileFormat = path => {
  const fileNameFromPath = getFileNameFromPath(path)
  return fileNameFromPath.slice(fileNameFromPath.indexOf('.'))
}

/**
 * @description Format title and date for video name
 * @param { string } title
 * @param { string } date
 * @returns { string } file name
 */
export const formatDateTitleVideoName = (title, date, format) =>
  `${title.replace(' ', '_')}_${date.slice(0, date.indexOf('T'))}${format}`

export const isDateExpired = date => new Date(date) < new Date()

/**
 * @description Saves a file
 * @param { object } data
 * @param { string } fileName
 * @param { function } onFinish
 * @param { string } type
 * @returns { string } file name
 */
export const saveFile = (data, fileName, onFinish) => {
  saveAs(data, fileName)
  if (onFinish) {
    onFinish(data)
  }
}

export const loadStripePlugin = () => {
  const { STRIPE_PUBLISHABLE_KEY, NODE_ENV } = process.env
  const stripeTestingKey = 'pk_test_TYooMQauvdEDq54NiTphI7jx'
  const stripeKey = NODE_ENV === 'test' ? stripeTestingKey : STRIPE_PUBLISHABLE_KEY || ' '

  return loadStripe(stripeKey)
}
