import { formatRoute } from 'react-router-named-routes'
import { get } from 'lodash'
import { Link, Route, Switch } from 'react-router-dom'
import { Tab, Tabs } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import * as networkSelectors from 'app_modules/network/selectors'
import * as sessionSelectors from 'app_modules/session/selectors'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as notificationActions from 'app_modules/notifications/actions'
import * as proMemberActions from 'app_modules/proMember/actions'

import { ACCOUNT_ADMIN, MEMBER, TOKEN_BUYER } from 'roles'
import {
  ADMINISTRATION,
  MY_5_DYNAMICS,
  NETWORK,
  NEWS,
  SETUP_RELATIONSHIPS_ACCOUNT,
  TAB,
  TEAMS,
  TOKEN_ADMINISTRATION,
  TRAINING_CENTER,
  GENERAL_ACCOUNT_INFORMATION,
} from 'constants/urls'
import { I18N, PERSON, ACTIONS, NETWORK_DETAILS } from 'constants/props'
import Translation from 'generics/Translation'

import styles from './menu.scss'

const MenuDesktop = ({ i18n, isStudentWaitingResults, profile, proMemberActionsProps, networkDetails }) => {
  const { newsNotifications, account, limitedMember: limitedMemberFromProfile } = profile
  const { flags: learningMenuTabsVisibility, id: accountId, isPersonal: isPersonalAccount } = account
  const { limitedMember: limitedMemberFromNetworkDetails } = networkDetails || {}

  const isLimitedMember =
    limitedMemberFromNetworkDetails === undefined ? limitedMemberFromProfile : limitedMemberFromNetworkDetails

  const {
    administrationTab: isAdministrationTabVisible,
    networkTab: isNetworkTabVisible,
    newsTab: isNewsTabVisible,
    relationshipsTab: isRelationshipsTabVisible,
    teamsTab: isTeamsTabVisible,
    tokenAdministrationTab: isTokenAdministrationTabVisible,
    trainingCenterTab: isTrainingCenterTabVisible,
  } = learningMenuTabsVisibility || {}

  // Visibility for menu tabs validations for roles
  const isCorporateAdminRole = !isPersonalAccount && profile.hasRole(ACCOUNT_ADMIN)
  const isCorporateMemberRole = !isPersonalAccount && profile.hasRole(MEMBER)
  const isCorporateTokenBuyerRole = !isPersonalAccount && profile.hasRole(TOKEN_BUYER)
  const { isLicenseBuyer } = profile?.permissions?.find(({ isLicenseBuyer: licenseBuyer }) => licenseBuyer) || {}
  const showMySubscriptions = !isCorporateAdminRole && isLicenseBuyer

  const getMenu = () => [
    {
      id: 'my-5-dynamics',
      isVisible: true,
      isClickable: true,
      label: i18n.menu.my5Dynamics,
      path: formatRoute(MY_5_DYNAMICS, { accountId }),
    },
    {
      id: 'network',
      isVisible: isNetworkTabVisible,
      isClickable: isCorporateMemberRole || !isLimitedMember,
      label: i18n.menu.network,
      path: formatRoute(NETWORK, { accountId }),
    },
    {
      id: 'teams',
      isVisible: isTeamsTabVisible && !isStudentWaitingResults,
      isClickable: isCorporateMemberRole || !isLimitedMember,
      label: i18n.menu.teams,
      path: formatRoute(TEAMS, { accountId }),
    },
    {
      id: 'training-center',
      isVisible: isTrainingCenterTabVisible,
      isClickable: isCorporateMemberRole || !isLimitedMember,
      label: i18n.menu.trainingCenter,
      path: formatRoute(TRAINING_CENTER, { accountId }),
    },
    {
      id: 'administration',
      isVisible: (isAdministrationTabVisible && isCorporateAdminRole) || showMySubscriptions,
      isClickable: true,
      label: showMySubscriptions ? i18n.menu.mySubscriptions : i18n.menu.administration,
      path: formatRoute(ADMINISTRATION, { accountId, sectionId: 'people' }),
    },
    {
      hasNotification: newsNotifications,
      id: 'news',
      isVisible: isNewsTabVisible,
      isClickable: true,
      label: i18n.menu.news,
      path: formatRoute(NEWS, { accountId }),
    },
    {
      id: 'relationships',
      isVisible: isCorporateMemberRole && !isStudentWaitingResults && isRelationshipsTabVisible,
      isClickable: isCorporateMemberRole && !isStudentWaitingResults && isRelationshipsTabVisible,
      label: i18n.menu.relationships,
      path: formatRoute(SETUP_RELATIONSHIPS_ACCOUNT, { accountId }),
    },
    {
      id: 'token-administration',
      isVisible: isTokenAdministrationTabVisible && (isCorporateAdminRole || isCorporateTokenBuyerRole),
      isClickable: isTokenAdministrationTabVisible && (isCorporateAdminRole || isCorporateTokenBuyerRole),
      label: i18n.menu.tokenAdministration,
      path: formatRoute(TOKEN_ADMINISTRATION, { accountId, sectionId: 'people' }),
    },
  ]

  const showProMemberModal = (e, isClickable) => {
    if (!isClickable) {
      e.preventDefault()
      proMemberActionsProps.toggleProMemberModal(true)
    }
  }

  const renderMenuListItems = () =>
    getMenu()
      .filter(({ isVisible }) => isVisible)
      .map(({ hasNotification, isClickable, id, label, path }) => (
        <Tab
          component={Link}
          className={classnames(styles.link, {
            [styles.notification]: hasNotification,
            [styles.disabled]: !isClickable,
          })}
          onClick={e => showProMemberModal(e, isClickable)}
          to={path}
          href={path}
          id={id}
          key={id}
          label={label}
          title={label}
          value={id}
        />
      ))

  // eslint-disable-next-line react/prop-types
  const renderNav = ({ match }) => {
    const tab = get(match, 'params.tab', 'my-5-dynamics')

    return (
      <nav className={classnames(styles.menu, { [styles.large]: profile.hasRole(ACCOUNT_ADMIN) })}>
        <Tabs
          classes={{
            flexContainer: styles.justifyCenter,
            indicator: styles['tab-indicator'],
          }}
          value={tab}
        >
          {renderMenuListItems()}
        </Tabs>
      </nav>
    )
  }

  if (profile === null) return null

  return (
    <Switch>
      <Route path={TAB} render={renderNav} />
      <Route path={GENERAL_ACCOUNT_INFORMATION} render={renderNav} />
    </Switch>
  )
}

MenuDesktop.propTypes = {
  i18n: I18N.isRequired,
  isStudentWaitingResults: PropTypes.bool.isRequired,
  profile: PERSON,
  networkDetails: NETWORK_DETAILS.isRequired,
  proMemberActionsProps: ACTIONS.isRequired,
}

MenuDesktop.defaultProps = {
  profile: null,
}

const mapStateToProps = state => {
  const { id } = sessionSelectors.currentProfile(state)

  return {
    networkDetails: networkSelectors.networkDetails.profileNetworkDetails(state, id),
  }
}

const mapDispatchToProps = dispatch => ({
  proMemberActionsProps: bindActionCreators(proMemberActions, dispatch),
  notificationActionsProps: bindActionCreators(notificationActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Translation(MenuDesktop, ['menu']))
