/**
 * @description Settings for invites controller
 * @module API controller / Invites
 */
import { FORM } from 'constants/contentTypes'
import { NOT_APPLIED_FREQUENCY_TYPE } from 'constants/frequencies'
import { toSnakeCase } from 'case-converter'

module.exports = {
  accept: {
    /**
     * @description Get request settings (accept invitation)
     * @param { object } options
     * @param {number} options.accountId
     * @param {string} options.token - Token of the invitation
     * @returns {object} request settings
     */
    settings: ({ accountId, token }) => ({
      endpoint: `/account/${accountId}/invites/${token}/accept`,
      method: 'POST',
    }),
  },

  decline: {
    encrypt: {
      forceDefaultPassphrase: true,
    },
    /**
     * @description Get request settings (decline invitation)
     * @param { object } options
     * @param {number} options.accountId
     * @param {string} options.token
     * @returns {object} request settings
     */
    settings: ({ accountId, token }) => ({
      endpoint: `/account/${accountId}/invites/${token}/decline`,
      method: 'POST',
    }),
  },

  get: {
    encrypt: {
      forceDefaultPassphrase: true,
    },
    ignoreNotFound: true,

    /**
     * @description Get request settings
     * @param {object} options
     * @returns {object} request settings
     */
    settings: invitationToken => ({
      endpoint: `/invites/${invitationToken}/information`,
      method: 'GET',
    }),
  },

  /**
   * @description Get request settings (send invitation)
   * @param {object} options
   * @param {number} options.accountId
   * @param {array} options.invitationList
   * @returns {object} request settings
   */
  send: {
    settings: ({ accountId, invitationList }) => ({
      endpoint: `/account/${accountId}/invites/manual_invitations`,
      body: {
        inviteMembers: invitationList,
      },
      method: 'POST',
    }),
  },

  sendFile: {
    /**
     * @description Get request settings (send file invitations)
     * @param {object} options
     * @param {number} options.accountId
     * @param {object} options.file
     * @param {number} options.frequency
     * @param {number} options.frequencyType
     * @param {string} options.name
     * @returns {object} request settings
     */
    settings({ accountId, file, frequency, frequencyType = NOT_APPLIED_FREQUENCY_TYPE, name }) {
      const form = new FormData()

      form.append('file', file)

      form.append(
        'group_options',
        JSON.stringify(
          toSnakeCase({
            frequency,
            frequencyType,
            name,
          }),
        ),
      )

      return {
        endpoint: `/account/${accountId}/invites/via_csv`,
        body: form,
        headers: {
          enctype: FORM,
        },
        method: 'POST',
      }
    },
  },

  sentInvitations: {
    ignoreNotFound: true,
    /**
     * @description Get request settings (sent invitations)
     * @param {object} options
     * @param {number} options.accountId
     * @param {number} options.pageIndex
     * @param  {string} options.search - The filter to search.
     * @returns {object} request settings
     */
    settings({ accountId, pageIndex, search, filters }) {
      const { filterBy, value: searchValue } = search || {}

      let endpoint = `/account/${accountId}/invites/sent_invites`
      const cleanSearch = searchValue ? searchValue.trim() : null
      const searchBy = searchValue ? filterBy : null

      if (pageIndex) {
        endpoint += `/page/${pageIndex}`
      }

      if (searchBy) {
        endpoint += `/search_field/${searchBy}`
      }

      if (cleanSearch) {
        endpoint += `/search/${cleanSearch}`
      }

      if (filters) {
        for (let i = 0; i < filters.length; i += 1) {
          const { filterBy: filtersFilterBy, filterLabel } = filters[i]
          if (filtersFilterBy !== 'default') {
            endpoint += `/${filterLabel}/${filtersFilterBy}`
          }
        }
      }

      return {
        endpoint,
        method: 'GET',
      }
    },
  },

  resendInvitation: {
    /**
     * @description Gets request settings (Call Resend an existing invitation)
     * @param {object} options
     * @param  {int} options.accountId
     * @param  {int} options.invitationId
     * @returns {object} request settings
     */
    settings: ({ accountId, invitationId }) => ({
      endpoint: `/account/${accountId}/invites/resend_invitation`,
      body: {
        id: invitationId,
        renew: true,
      },
      method: 'POST',
    }),
  },

  invalidateInvitation: {
    /**
     * @description Gets request settings (Invalidates an invitation)
     * @param options
     * @param  {int} options.accountId
     * @param  {int} options.invitationId
     * @returns {object} request settings
     */
    settings: ({ accountId, invitationId }) => ({
      endpoint: `/account/${accountId}/invites/${invitationId}`,
      method: 'PATCH',
    }),
  },

  sendPersonalInvitation: {
    /**
     * @description Gets request settings (Invalidates an invitation)
     * @param options
     * @param  {int} options.accountId
     * @param  {string} options.email
     * @returns {object} request settings
     */
    settings: ({ accountId, email, isResend }) => ({
      body: { email },
      endpoint: `/account/${accountId}/invites/${isResend ? 'resend_' : ''}self_personal_invitation`,
      method: 'POST',
    }),
  },
}
