import { autobind } from 'core-decorators'
import { bindActionCreators } from 'redux'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { I18N } from 'constants/props'
import { Header, Main } from 'generics/Card'
import * as accountActions from 'app_modules/accounts/actions'
import * as networkActions from 'app_modules/network/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import * as teamsActions from 'app_modules/teams/actions'
import * as teamsSelectors from 'app_modules/teams/selectors'
import Modal from 'generics/Modal'
import Translation from 'generics/Translation'

import FormSendReportRequest from '../FormSendReportRequest/FormSendReportRequest'
import styles from './ModalSendReportRequest.scss'

/**
 * @description: Create / Edit Team Modal Component
 * @extends Component
 */
class ModalSendReportRequest extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  /**
   * @description: Handles close modal event
   */
  @autobind
  handleCloseModal() {
    const { onClose } = this.props
    onClose()
  }

  render() {
    const { i18n, onSubmit } = this.props

    const { isFetching } = this.state

    return (
      <Modal
        card={{
          barClassName: styles.bar,
          fullHeight: true,
          isFetching,
        }}
        onClose={this.handleCloseModal}
      >
        <Header>
          <h1 title={i18n.pageTokenAdministration.useTokens.sendReportRequestModal.title}>
            {i18n.pageTokenAdministration.useTokens.sendReportRequestModal.title}
          </h1>
        </Header>

        <Main>
          <Container fluid>
            <p className={styles.description}>
              {i18n.pageTokenAdministration.useTokens.sendReportRequestModal.description}
            </p>

            <FormSendReportRequest className={styles.form} onSubmit={onSubmit} />
          </Container>
        </Main>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  profile: sessionSelectors.currentProfile(state),
  team: teamsSelectors.team(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...accountActions,
      ...networkActions,
      ...teamsActions,
    },
    dispatch,
  ),
})

ModalSendReportRequest.propTypes = {
  i18n: I18N.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Translation(connect(mapStateToProps, mapDispatchToProps)(ModalSendReportRequest), [
  'pageTokenAdministration',
  'generics',
])
