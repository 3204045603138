import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { SECTIONS } from 'constants/props'
import { StyledButton } from 'generics/StyledFormComponents'
import Icon from 'generics/Icon'

import styles from './VerticalMenu.scss'

const VerticalMenu = ({
  className,
  onChangeSection,
  sectionClassname,
  sections,
  selectedSectionId,
  showMySubscriptions,
}) => {
  const sectionIndex = sections.findIndex(({ id }) => id === selectedSectionId)

  const thisSections = () => {
    if (!showMySubscriptions) return sections
    return sections.filter(({ id }) => id !== 'content-modules' && id !== 'customization-modules')
  }

  return (
    <ul className={className} role="tablist">
      {thisSections().map(({ id, icon, name: sectionName }, index) => (
        <li
          aria-selected={index === sectionIndex}
          className={classnames(
            styles['section-item'],
            { [styles[`section-item--${sectionClassname}`]]: sectionClassname },
            { [styles[`section-item--${index}`]]: !sectionClassname },
            { [styles['section-item--is-active']]: index === sectionIndex },
          )}
          key={`section-${sectionName}`}
          role="tab"
        >
          <StyledButton color="default" fullWidth onClick={() => onChangeSection(id)} variant="text">
            <div
              className={classnames(styles['section-item__button'], {
                [styles[`section-item__button--${sectionClassname}`]]: sectionClassname,
              })}
            >
              <Icon.Stroke7
                className={classnames(
                  styles['section-item__icon'],
                  { [styles[`section-item__icon--${sectionClassname}`]]: index === sectionIndex && sectionClassname },
                  { [styles[`section-item__icon--${index}`]]: index === sectionIndex && !sectionClassname },
                )}
                name={icon}
              />
              <span className={styles['section-item__title']} title={sectionName}>
                {sectionName}
              </span>
            </div>
          </StyledButton>
        </li>
      ))}
    </ul>
  )
}

VerticalMenu.propTypes = {
  className: PropTypes.string,
  onChangeSection: PropTypes.func.isRequired,
  sectionClassname: PropTypes.string,
  sections: SECTIONS.isRequired,
  selectedSectionId: PropTypes.string.isRequired,
  showMySubscriptions: PropTypes.bool,
}

VerticalMenu.defaultProps = {
  className: null,
  sectionClassname: '',
  showMySubscriptions: false,
}

export default VerticalMenu
