module.exports = {
  ACCOUNT_ADMIN: {
    manage: 'all',
  },
  CONTENT_MANAGER: {
    manage: ['account', 'bundle', 'contentManager'],
    account: 'show',
  },
  MEMBER: {
    manage: ['account', 'bundle', 'connectionRequest', 'contentManager', 'pdf', 'profile', 'team'],
    invite: ['acceptInvitation', 'declineInvitation'],
    role: 'getRolesByProfile',
  },
  TEAM_MANAGER: {
    manage: 'team',
  },
  TOKEN_BUYER: {
    manage: ['charge', 'token'],
  },
  USER_ALLOCATOR: {
    account: 'show',
    invite: ['index', 'sendInvitationManually', 'sendInvitationViaCsv'],
  },
  USER_MANAGER: {
    invite: ['index', 'sendInvitationManually', 'sendInvitationViaCsv'],
    account: 'show',
    profile: 'getMyNetwork',
  },
}
