/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap'
import { get, isEqual, size } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import { ACTIONS, BUNDLE, I18N, PERSON, TEAMS } from 'constants/props'
import { Footer, Header, Main } from 'generics/Card'
import { StyledButton } from 'generics/StyledFormComponents'
import ListSelection from 'generics/ListSelection'
import Modal from 'generics/Modal'
import Search from 'generics/Search'
import Translation from 'generics/Translation'

import styles from './ModalShareTeams.scss'

const MIN_SIZE_SCROLL = 7

const ModalShareTeams = ({ accountTeams, bundle, currentProfile, i18n, onClose, teamsActions }) => {
  const [filter, setFilter] = useState(null)
  const [hasMorePages, setHasMorePages] = useState(false)
  const [isDisableDone, setIsDisableDone] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [isFetchingTeams, setIsFetchingTeams] = useState(false)
  const [selectedTeams, setSelectedTeams] = useState(bundle.teams.map(team => team.clone({ canDrag: true })))

  let listSelection = useRef(null)

  const getInitialState = () => {
    setFilter(null)
    setHasMorePages(false)
    setIsDisableDone(true)
    setIsFetching(false)
    setIsFetchingTeams(false)
    setSelectedTeams(bundle.teams.map(team => team.clone({ canDrag: true })))
  }

  const handleFetchTeams = (pageIndex = 1) => {
    setIsFetchingTeams(true)

    const onSuccess = ({ meta, teams }) => {
      const { morePages } = meta

      if (size(teams) <= MIN_SIZE_SCROLL && morePages) {
        handleFetchTeams(pageIndex + 1)
      } else {
        setHasMorePages(morePages)
        setIsFetchingTeams(false)
      }
    }

    const onError = () => {
      setIsFetchingTeams(false)
    }

    teamsActions.fetchCorporateTeams({ pageIndex, search: filter }, onSuccess, onError)
  }

  useEffect(() => {
    handleFetchTeams()
  }, [])

  const handleChangeFilter = newFilter => {
    if (filter !== newFilter) {
      setFilter(newFilter)

      if (get(listSelection.InfiniteScrollList, 'infiniteScrollRef')) {
        listSelection.InfiniteScrollList.infiniteScrollRef.resetIndex()
      }
    }
  }

  useEffect(() => {
    handleFetchTeams()
  }, [filter])

  const handleCloseModal = () => {
    getInitialState()
    onClose()
  }

  const handleClickDone = () => {
    const { id: bundleId } = bundle

    setIsFetching(true)

    const onSuccess = () => {
      setIsFetching(false)
      handleCloseModal()
    }

    const onError = () => {
      setIsFetching(false)
      handleCloseModal()
    }

    teamsActions.fetchShareTeamsWithABundle(
      {
        accountId: currentProfile.account.id,
        bundleId,
        teams: selectedTeams,
      },
      onSuccess,
      onError,
    )
  }

  const handleChangeList = newSelectedTeams => {
    const { teams } = bundle

    const newIsDisableDone = isEqual(
      teams.map(({ id }) => id),
      selectedTeams.map(({ id }) => id),
    )

    setSelectedTeams(newSelectedTeams)
    setIsDisableDone(newIsDisableDone)
  }

  const title = i18n.pageAdministration.modalShareTeams.modalTitle

  const description = i18n.pageAdministration.modalShareTeams.modalDescription

  const infiniteScroll = {
    hasMorePages,
    listHeight: 250,
    onFetch: handleFetchTeams,
  }

  return (
    <Modal
      card={{
        barClassName: styles.bar,
        isFetching,
      }}
      onClose={handleCloseModal}
    >
      <Header className={styles.header}>
        <h1 className={styles.title} title={title}>
          {title}
        </h1>
        <p className={styles.description}>{description}</p>
      </Header>

      <Main className={styles.main}>
        <Container fluid>
          <Search
            className={styles.search}
            floatingLabelText={i18n.generics.filterLabel}
            onChange={handleChangeFilter}
            onSearch={handleChangeFilter}
          />

          <ListSelection
            filter={filter}
            listA={{
              infiniteScroll,
              id: 'share-teams-list-1',
              isFetching: isFetchingTeams,
              profiles: accountTeams,
              title: i18n.pageAdministration.modalShareTeams.listATitle,
            }}
            listB={{
              emptyMessage: i18n.pageAdministration.modalShareTeams.listBEmptyMessage,
              id: 'share-teams-list2',
              isFetching: isFetchingTeams,
              profiles: selectedTeams,
              title: i18n.pageAdministration.modalShareTeams.listBTitle,
            }}
            onChange={handleChangeList}
            componentRefProp={component => {
              listSelection = component
            }}
          />
        </Container>
      </Main>

      <Footer>
        <Container>
          <Row>
            <Col xs="4" md="2">
              <StyledButton
                color="default"
                fullWidth
                onClick={handleCloseModal}
                title={i18n.generics.cancelLabel}
                variant="text"
              >
                {i18n.generics.cancelLabel}
              </StyledButton>
            </Col>

            <Col xs={{ size: 4, offset: 4 }} md={{ size: 2, offset: 8 }}>
              <StyledButton
                disabled={isDisableDone}
                fullWidth
                onClick={handleClickDone}
                color="primary"
                title={i18n.generics.doneLabel}
              >
                {i18n.generics.doneLabel}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </Modal>
  )
}

ModalShareTeams.propTypes = {
  accountTeams: TEAMS,
  bundle: BUNDLE.isRequired,
  currentProfile: PERSON.isRequired,
  i18n: I18N.isRequired,
  onClose: PropTypes.func.isRequired,
  teamsActions: ACTIONS.isRequired,
}

ModalShareTeams.defaultProps = {
  accountTeams: null,
}

export default Translation(ModalShareTeams, ['pageAdministration', 'generics'])
