/**
 * @description Settings for videos controller
 * @module API controller / Videos
 */

module.exports = {
  upgradeMemberRequest: {
    /**
     * @description Get request settings to get videos.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId }) {
      return {
        endpoint: `/account/${accountId}/profiles/member_upgrade_request`,
        method: 'POST',
      }
    },
  },

  getUpgradeMemberRequests: {
    /**
     * @description Get request settings to get videos.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId, pageIndex, search, filters }) {
      const { filterBy, value: searchValue } = search || {}

      let endpoint = `/account/${accountId}/profiles/members_upgrade`
      const cleanSearch = searchValue ? searchValue.trim() : null
      const searchBy = searchValue ? filterBy : null

      if (filters) {
        for (let i = 0; i < filters.length; i += 1) {
          const { filterBy: filtersFilterBy, filterLabel } = filters[i]
          if (filtersFilterBy !== 'default') {
            endpoint += `/${filterLabel}/${filtersFilterBy}`
          }
        }
      }

      if (searchBy) {
        endpoint += `/search_field/${searchBy}`
      }

      if (cleanSearch) {
        endpoint += `/search/${cleanSearch}`
      }

      if (pageIndex) {
        endpoint += `/page/${pageIndex}`
      }

      return {
        endpoint,
        method: 'GET',
      }
    },
  },

  upgradeMemberRequestsAction: {
    /**
     * @description Get request settings to get videos.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId, accept, decline }) {
      const endpoint = `/account/${accountId}/profiles/members_upgrade`

      return {
        body: {
          idsToAccept: [...accept],
          idsToDecline: [...decline],
        },
        endpoint,
        method: 'POST',
      }
    },
  },
}
