import { pickHTMLProps } from 'pick-react-known-prop'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import videoConnect from 'react-html5video'

import { CHILDREN, I18N } from 'constants/props'
import Icon from 'generics/Icon'
import Translation from 'generics/Translation'

import styles from './video.scss'

const handleContextMenu = event => {
  event.preventDefault()
  return false
}

const VideoPlayer = props => {
  const { children, className, hasCaptions, hasOverlay, i18n, onEnded, video, videoEl, ...rest } = props

  const { paused } = video
  const moreProps = hasCaptions ? { crossOrigin: 'anonymous' } : {}

  return (
    <div className={classnames(styles.wrapper, className)}>
      <video // eslint-disable-line jsx-a11y/media-has-caption
        controls={['PlayPause', 'Volume', 'Seek', 'Time', 'Fullscreen', 'Captions']}
        controlsList="nodownload"
        onContextMenu={handleContextMenu}
        onEnded={onEnded}
        {...pickHTMLProps(rest)}
        {...pickHTMLProps(moreProps)}
      >
        {children}
      </video>
      {paused && hasOverlay && video.currentTime === 0 && (
        <div // eslint-disable-line
          className={styles.overlay}
          onClick={() => {
            videoEl.play()
          }}
        >
          <Icon.Stroke7 className={styles.icon} name="film" title={i18n.generics.play} />
        </div>
      )}
    </div>
  )
}

VideoPlayer.propTypes = {
  children: CHILDREN.isRequired,
  className: PropTypes.string,
  hasCaptions: PropTypes.bool,
  hasOverlay: PropTypes.bool,
  i18n: I18N.isRequired,
  onEnded: PropTypes.func,
  video: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  videoEl: PropTypes.object, // eslint-disable-line react/forbid-prop-types
}

VideoPlayer.defaultProps = {
  className: null,
  hasCaptions: false,
  hasOverlay: false,
  onEnded: null,
  videoEl: undefined,
}

export default Translation(videoConnect(VideoPlayer), ['generics'])
