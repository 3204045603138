import { combineReducers } from 'redux'

import initialState from 'app_modules/store/initialState.json'

import notificationsActionTypes from 'app_modules/notifications/constants'
import sessionActionTypes from 'app_modules/session/constants'

export const bar = (state = initialState.notifications.bar, action) => {
  const { type: actionType, payload } = action

  switch (actionType) {
    case notificationsActionTypes.ADD_BAR_NOTIFICATION:
      return {
        currentNotification: payload,
        remainingNotifications: [...state.remainingNotifications, payload],
      }

    case notificationsActionTypes.CLEAR_BAR_NOTIFICATION:
      return {
        currentNotification: {},
        remainingNotifications: [],
      }

    case sessionActionTypes.RESET_STATE:
      return initialState.notifications.bar

    default:
      return state
  }
}

export const modal = (state = initialState.notifications.modal, action) => {
  const { type: actionType, payload } = action
  switch (actionType) {
    case notificationsActionTypes.ADD_MODAL_NOTIFICATION:
      return {
        ...payload,
        open: true,
      }

    case notificationsActionTypes.CLEAR_MODAL_NOTIFICATION:
      return {
        options: {},
        open: false,
      }

    case sessionActionTypes.RESET_STATE:
      return initialState.notifications.modal

    default:
      return state
  }
}

export default combineReducers({
  bar,
  modal,
})
