export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
}

export const TEAM_GRID_SORT_COLUMNS = {
  NAME: 'name',
  E1: 'e1',
  E2: 'e2',
  E3: 'e3',
  E4: 'e4',
}
