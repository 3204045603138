/**
 * @fileoverview Processing selectors for the SensaiAI module
 * @module sensaiAi/selectors/processingSelectors
 */

/**
 * Base selector for processing state
 * @param {Object} state - Global state
 * @returns {Object} Processing state
 */
const getProcessingState = state => state.sensaiAi.processing

/**
 * Selector for retrieving current request ID
 * @param {Object} state - Global state
 * @returns {string|null} Request ID
 */
export const getSensaiRequestId = state => getProcessingState(state).requestId

/**
 * Selector for checking if processing is ongoing
 * @param {Object} state - Global state
 * @returns {boolean} Processing state
 */
export const isProcessing = state => getProcessingState(state).isProcessing

/**
 * Selector for checking if processing is complete
 * @param {Object} state - Global state
 * @returns {boolean} Completion state
 */
export const isProcessingComplete = state => getProcessingState(state).isComplete
