import * as fetchCorporateTeams from './fetchCorporateTeams'
import * as fetchCreateTeam from './fetchCreateTeam'
import * as fetchDeleteTeam from './fetchDeleteTeam'
import * as fetchEditTeam from './fetchEditTeam'
import * as fetchOthersTeams from './fetchOthersTeams'
import * as fetchPrivateTeams from './fetchPrivateTeams'
import * as fetchPublishedTeams from './fetchPublishedTeams'
import * as fetchSetPrivacy from './fetchSetPrivacy'
import * as fetchShareTeamsWithABundle from './fetchShareTeamsWithABundle'
import * as fetchTeamDashboard from './fetchTeamDashboard'
import * as fetchTeamDetails from './fetchTeamDetails'
import * as fetchTeamMembers from './fetchTeamMembers'
import * as fetchTeamModules from './fetchTeamModules'
import * as fetchUpdateLogo from './fetchUpdateLogo'

module.exports = {
  ...fetchCorporateTeams,
  ...fetchCreateTeam,
  ...fetchDeleteTeam,
  ...fetchEditTeam,
  ...fetchOthersTeams,
  ...fetchPrivateTeams,
  ...fetchPublishedTeams,
  ...fetchSetPrivacy,
  ...fetchShareTeamsWithABundle,
  ...fetchTeamDashboard,
  ...fetchTeamDetails,
  ...fetchTeamMembers,
  ...fetchTeamModules,
  ...fetchUpdateLogo,
}
