/* eslint-disable react-hooks/exhaustive-deps */
import { bindActionCreators } from 'redux'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { Col, Container, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { ACTIONS, I18N, PERSON } from 'constants/props'
import { ADMIN, MEMBER, TOKEN_BUYER } from 'constants/roleNames'
import { Footer, Header, Main } from 'generics/Card'
import { StyledButton } from 'generics/StyledFormComponents'
import * as networkActionsM from 'app_modules/network/actions'
import * as networkSelectors from 'app_modules/network/selectors'
import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import List from 'generics/List'
import Modal from 'generics/Modal'
import Translation from 'generics/Translation'

import styles from './ModalAssignRole.scss'

const ModalAssignRole = ({ accountId, i18n, networkActions, onClose, onComplete, thirdPersonProfile }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [rolesList, setRolesList] = useState([])
  const [userRoles, setUserRoles] = useState(thirdPersonProfile.roles.map(({ id }) => id))

  const getInitialState = () => {
    setIsFetching(false)
    setRolesList([])
    setUserRoles(thirdPersonProfile.roles.map(({ id }) => id))
  }

  const fetchRoles = () => {
    getInitialState()
    setIsFetching(true)

    const handleResponse = ({ roles }) => {
      setIsFetching(false)
      setRolesList(roles)
    }

    api.roles.get(accountId, handleResponse)
  }

  useEffect(() => {
    fetchRoles()
  }, [])

  const getAdminId = () => rolesList.find(role => role.name === ADMIN)?.id

  const getMemberId = () => rolesList.find(role => role.name === MEMBER)?.id

  const getTokenBuyerId = () => rolesList.find(role => role.name === TOKEN_BUYER)?.id

  const handleToggleRole = id => {
    if (userRoles.includes(id) && userRoles.length === 1) {
      setUserRoles([...userRoles])
      return
    }
    if (
      (id === getAdminId() && userRoles.includes(getAdminId())) ||
      (id === getTokenBuyerId() && userRoles.includes(getTokenBuyerId()))
    ) {
      setUserRoles([getMemberId()])
      return
    }
    if (id === getAdminId()) {
      setUserRoles([getAdminId(), getMemberId()])
      return
    }
    if (id === getTokenBuyerId()) {
      setUserRoles([getMemberId(), getTokenBuyerId()])
      return
    }
    setUserRoles([...userRoles, id])
  }

  const handleCloseModal = () => {
    getInitialState()
    onClose()
  }

  const handleDone = () => {
    const roles = rolesList.map(({ id }) => ({
      assigned: userRoles.includes(id),
      roleId: id,
    }))

    networkActions.fetchUpdateProfileRoles(roles)

    handleCloseModal()

    if (onComplete) {
      onComplete()
    }
  }

  const rolesListItems = rolesList.map(role => {
    const { id: roleId, name } = role

    return (
      <li key={roleId} className={styles.list__role}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!userRoles.find(id => id === roleId)}
              color="primary"
              onClick={() => handleToggleRole(roleId)}
            />
          }
          label={name}
        />
      </li>
    )
  })

  return (
    <Modal
      card={{
        barClassName: styles.bar,
        center: true,
      }}
      onClose={handleCloseModal}
    >
      <Header title={i18n.thirdPersonProfile.modalAssignRole.header} />

      <Main>
        <Container>
          <p className={styles.description}>{i18n.thirdPersonProfile.modalAssignRole.description}</p>

          <List isFetching={isFetching} className={styles.list}>
            {rolesListItems}
          </List>
        </Container>
      </Main>

      <Footer>
        <Container>
          <Row>
            <Col xs={{ size: 4, offset: 8 }}>
              <StyledButton
                disabled={userRoles.length === 0}
                fullWidth
                onClick={handleDone}
                color="primary"
                title={i18n.thirdPersonProfile.modalAssignRole.done}
              >
                {i18n.thirdPersonProfile.modalAssignRole.done}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </Modal>
  )
}

const mapStateToProps = state => ({
  accountId: sessionSelectors.accountId(state),
  thirdPersonProfile: networkSelectors.thirdPerson.profile(state),
})

const mapDispatchToProps = dispatch => ({
  networkActions: bindActionCreators(networkActionsM, dispatch),
})

ModalAssignRole.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  networkActions: ACTIONS.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  thirdPersonProfile: PERSON.isRequired, // eslint-disable-line
}

ModalAssignRole.defaultProps = {
  onComplete: null,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Translation(ModalAssignRole, ['thirdPersonProfile'])),
)
