import { Col, Container, Row } from 'reactstrap'
import { get } from 'lodash'
import { sprintf } from 'sprintf-js'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Card, Header, Main } from 'generics/Card'
import { SECTIONS, I18N, INFO_LIST, PERSON } from 'constants/props'
import { StyledButton } from 'generics/StyledFormComponents'
import Avatar from 'generics/Avatar'
import DefinitionList from 'generics/DefinitionList'
import PictureSelectorModal from 'generics/PictureSelectorModal'
import Translation from 'generics/Translation'
import VerticalMenu from 'generics/VerticalMenu'
import { ACCOUNT_ADMIN } from 'roles'

import styles from './AccountDetails.scss'

const AccountDetails = ({
  className,
  data,
  i18n,
  onChangeSection,
  onUpdateLogo,
  profile,
  sectionId,
  sections,
  showMySubscriptions,
}) => {
  const [isPictureModalOpen, setIsPictureModalOpen] = useState(false)
  const { account } = profile || {}
  const { isPersonalAccount } = account || {}
  const isAdmin = !isPersonalAccount && profile.hasRole(ACCOUNT_ADMIN)

  /**
   * Handles Open/Close Picture Selector Modal
   */

  const handleOpenClosePictureModal = () => {
    setIsPictureModalOpen(!isPictureModalOpen)
  }

  /**
   * Handles save logo event
   * @param {object} logo
   */

  const handleSaveLogo = logo => {
    handleOpenClosePictureModal()
    onUpdateLogo(logo)
  }

  /**
   * Handles the remove logo event
   */
  const handleRemoveLogo = () => {
    handleSaveLogo(null)
  }

  const name = get(account, 'name', null)
  const logoUrl = get(account, 'logo.url')

  const avatarTitle = sprintf(i18n.pageAdministration.accountDetails.logoTitle, name)

  return (
    <Card className={className}>
      <Container className={styles.container}>
        <Header ariaLabel={`${name} menu.`} className={styles.header}>
          <Avatar
            className={styles.avatar}
            hasBorder
            icon={{
              className: styles.avatar__icon,
              name: 'global',
            }}
            src={logoUrl}
            title={avatarTitle}
          />

          <Container>
            <Row>
              <Col>
                <div className={styles['full-name']}>
                  <span title={name}>{name}</span>
                </div>
                {isAdmin && (
                  <StyledButton
                    className={styles['edit-picture']}
                    color="secondary"
                    onClick={handleOpenClosePictureModal}
                    title={i18n.pageAdministration.accountDetails.editLogo}
                    variant="text"
                  >
                    {i18n.pageAdministration.accountDetails.editLogo}
                  </StyledButton>
                )}
              </Col>
            </Row>
          </Container>
        </Header>
        <Main className={styles.main}>
          <Row>
            <Col>
              <DefinitionList data={data} />
            </Col>
          </Row>
        </Main>
        <Row>
          <Col className={styles['sections-content']}>
            <VerticalMenu
              onChangeSection={onChangeSection}
              selectedSectionId={sectionId}
              sections={sections}
              showMySubscriptions={showMySubscriptions}
            />
          </Col>
        </Row>
      </Container>
      {isPictureModalOpen && (
        <PictureSelectorModal
          onClose={handleOpenClosePictureModal}
          onRemovePicture={handleRemoveLogo}
          onSavePicture={handleSaveLogo}
          pictureUrl={logoUrl}
          uploadMessage={i18n.pageAdministration.accountDetails.uploadLogoMessage}
        />
      )}
    </Card>
  )
}

AccountDetails.propTypes = {
  className: PropTypes.string,
  data: INFO_LIST.isRequired,
  i18n: I18N.isRequired,
  onChangeSection: PropTypes.func.isRequired,
  onUpdateLogo: PropTypes.func.isRequired,
  profile: PERSON,
  sectionId: PropTypes.string.isRequired,
  sections: SECTIONS.isRequired,
  showMySubscriptions: PropTypes.bool,
}

AccountDetails.defaultProps = {
  className: null,
  profile: null,
  showMySubscriptions: false,
}

export default Translation(AccountDetails, ['pageAdministration'])
