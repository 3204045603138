import { mapValues, merge } from 'lodash'

import { BarError } from 'classes/errors'
import { expireSession } from 'app_modules/session/actions/expiration'
import { notifyError } from 'app_modules/notifications/actions'
import ApiController from 'classes/api/ApiController.class'
import ApiCrypto from 'classes/api/ApiCrypto.class'
import controllers from 'api/controllers'

const { API_ENCRYPTION_PASSPHRASE } = process.env

const EXPIRATION_TIME = 1800000

/**
 * @class API
 * @description Basic API for Backend Requests
 * */
export default class API {
  constructor() {
    this.crypto = new ApiCrypto(API_ENCRYPTION_PASSPHRASE)

    const apiControllers = mapValues(
      controllers,
      controller =>
        new ApiController({
          api: this,
          controller,
        }),
    )

    merge(this, apiControllers)
  }

  /**
   * @description Sets store
   */
  setStore(newStore) {
    this.store = newStore
  }

  /**
   * @description Clears session expiration timeout
   */
  clearTimeout() {
    if (this.expirationTimeout) {
      clearTimeout(this.expirationTimeout)
    }
  }

  /**
   * @description Sets session expirtaiton timeout
   */
  setTimeout() {
    this.clearTimeout()
    this.expirationTimeout = setTimeout(this.dispatchExpireSession.bind(this), EXPIRATION_TIME)
  }

  /**
   * @description Dispatches expire session action (Redux)
   */
  dispatchExpireSession() {
    this.clearTimeout()
    this.store.dispatch(expireSession('sessionExpired'))
  }

  dispatchConnectionError() {
    this.setTimeout()
    this.store.dispatch(notifyError(new BarError({ label: 'canNotConnectToServer' })))
  }

  rateLimit() {
    this.store.dispatch(notifyError(new BarError({ label: 'rateLimit' })))
    this.clearTimeout()
  }
}
