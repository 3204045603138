import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import PropTypes from 'prop-types'
import React from 'react'

import Icon from 'generics/Icon'

import { COLORS } from 'constants/colors'

const DATE_FORMAT = 'MM/DD/YYYY'

const lightGray = COLORS.grey3.rgba
const borderColor = 'rgba(155, 155, 155, 0.5)'

const borderBottom = `1px solid ${borderColor}`

const height = 70

const useStyles = makeStyles({
  calendar: {
    right: 0,
    top: 0,
  },
  close: {
    right: '35px',
    top: '15px',
  },
  hide: {
    display: 'none',
  },
  icon: {
    color: COLORS.secondaryBlue.rgba,
    cursor: 'pointer',
    fontSize: '20px',
    position: 'absolute',
  },
  root: {
    minHeight: `${height}px`,
    '& .MuiFormLabel-root': {
      color: lightGray,
    },
    '& .MuiInput-underline:before': {
      borderBottom,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom,
    },
    '& .MuiIconButton-root:hover': {
      background: 'transparent',
    },
  },
})

const StyledDatePicker = ({
  ariaLabel,
  className,
  disabled,
  isUTC,
  maxDate,
  name,
  onChange,
  title,
  value,
  ...rest
}) => {
  const classes = useStyles()

  const getDate = date => {
    if (isUTC) {
      return moment.utc(date).format(DATE_FORMAT)
    }
    return moment(date).format(DATE_FORMAT)
  }

  const keyboardIcon = <Icon.Stroke7 className={classnames(classes.icon, classes.calendar)} name="date" />

  return (
    <div className={className}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          autoOk
          className={classes.root}
          disabled={disabled}
          format={DATE_FORMAT}
          fullWidth
          InputProps={{
            'aria-label': ariaLabel,
            tabIndex: 0,
          }}
          KeyboardButtonProps={{
            'aria-label': 'Change date',
          }}
          keyboardIcon={keyboardIcon}
          label={title}
          maxDate={maxDate}
          name={name}
          onChange={date => onChange(name, getDate(date))}
          title={title}
          value={value}
          variant="dialog"
          {...rest}
        />
      </MuiPickersUtilsProvider>
      <Icon.Stroke7
        className={classnames(classes.icon, classes.close, { [classes.hide]: !value })}
        disabled={disabled}
        name="close"
        onClick={() => onChange(name, null)}
      />
    </div>
  )
}

StyledDatePicker.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isUTC: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
}

StyledDatePicker.defaultProps = {
  ariaLabel: '',
  className: null,
  disabled: false,
  isUTC: false,
  maxDate: undefined,
  value: null,
}

export default StyledDatePicker
