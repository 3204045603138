import { NewRelicError } from 'classes/errors'

const newrelicMiddleware = store => next => action => {
  const { payload } = action

  const state = store.getState()

  if (payload instanceof Error) {
    // Notify error to New Relic API
    if (window.newrelic) {
      window.newrelic.noticeError(new NewRelicError({ action, state }))
    }
  }

  next(action)
}

export default newrelicMiddleware
