import { uniq } from 'lodash'
import deepMerge from 'deepmerge'

export default [
  {
    subject: 'account',
    actions: [
      {
        action: 'index',
        description: "Gets user's accounts",
      },
      {
        action: 'show',
        description: 'Show an account',
      },
      {
        action: 'update',
        description: 'Update an account',
      },
      {
        action: 'myMembers',
        description: 'Get all members by account',
        map: 'getMembers',
      },
      {
        action: 'accountInfo',
        description: 'Get account information',
        map: 'getInformation',
      },
      {
        action: 'updateLogo',
        description: 'Update account logo',
      },
    ],
  },
  {
    subject: 'bundle',
    actions: [
      {
        action: 'index',
        description: 'Gets bundles by account',
      },
      {
        action: 'show',
        description: 'Show a bundle by account',
      },
      {
        action: 'bundleAllocation',
        description: 'Show the bundles and their available modules by account',
        map: 'showBundleAllocation',
      },
      {
        action: 'bundleAllocationDetails',
        description: 'Show the bundles details by account',
        map: 'showBundleAllocationDetails',
      },
    ],
  },
  {
    subject: 'charge',
    actions: [
      {
        action: 'tokens',
        description: 'Buy Tokens to assign',
      },
    ],
  },
  {
    subject: 'connectionRequest',
    actions: [
      {
        action: 'sendRequest',
        description: 'Send a connection request by account',
        map: 'send',
      },
      {
        action: 'getOwnConnectionRequests',
        description: 'Get all received or sent connection request by account',
        map: 'getOwn',
      },
      {
        action: 'acceptRequest',
        description: 'Accept a connection request',
        map: 'accept',
      },
      {
        action: 'declineRequest',
        description: 'Decline a connection request',
        map: 'decline',
      },
    ],
  },
  {
    subject: 'contentManager',
    actions: [
      {
        action: 'individualDashboard',
        description: 'Shows 2nd and 3rd person individual dashboard',
        map: 'showIndividualDashboard',
      },
      {
        action: 'teamDashboard',
        description: 'Shows Team dashboard',
        map: 'showTeamDashboard',
      },
      {
        action: 'showIndividualModuleContent',
        description: 'Shows Individual module content',
      },
      {
        action: 'showPairModuleContent',
        description: 'Shows Pair module content',
      },
      {
        action: 'showTeamModuleContent',
        description: 'Shows Team module content',
      },
      {
        action: 'showRoleModuleContent',
        description: 'Shows Role module content',
      },
    ],
  },
  {
    subject: 'group',
    actions: [
      {
        action: 'index',
        description: 'Get all Group by account',
      },
      {
        action: 'show',
        description: 'Show a Group by account',
      },
      {
        action: 'update',
        description: 'Update a Group by account',
      },
      {
        action: 'destroy',
        description: 'Delete a Group by account',
      },
    ],
  },
  {
    subject: 'invite',
    actions: [
      {
        action: 'index',
        description: 'Get all invitations by account',
      },
      {
        action: 'show',
        description: 'Show an invitation',
      },
      {
        action: 'inviteInformation',
        description: 'Is a valid token invitation?',
        map: 'getInviteInformation',
      },
      {
        action: 'acceptInvitation',
        description: 'Accepts an invitation',
      },
      {
        action: 'declineInvitation',
        description: 'Declines an invitation',
      },
      {
        action: 'viaCsv',
        description: 'Invite people via CSV file by account',
        map: 'sendInvitationViaCsv',
      },
      {
        action: 'manualInvitations',
        description: 'Invite people manually by account',
        map: 'sendInvitationManually',
      },
      {
        action: 'sentInvites',
        description: 'Get all invitations by account sent',
        map: 'getSentInvites',
      },
      {
        action: 'resendInvitation',
        description: 'Re-send an invitation by account',
      },
    ],
  },
  {
    subject: 'pdf',
    actions: [
      {
        action: 'downloadPdf',
        description: 'Download PDF',
        map: 'download',
      },
      {
        action: 'learningChart',
        description: 'Create a learning chart',
        map: 'createLearningChart',
      },
    ],
  },
  {
    subject: 'profile',
    actions: [
      {
        action: 'index',
        description: 'Get all profiles by account',
      },
      {
        action: 'update',
        description: 'Update a profile by account',
      },
      {
        action: 'search',
        description: 'Get profiles by account and search text',
      },
      {
        action: 'myNetwork',
        description: 'Get all profiles connected to current user by account',
        map: 'getMyNetwork',
      },
      {
        action: 'showNetworkProfile',
        description: "Show current user's profile by account",
      },
      {
        action: 'setPrivacy',
        description: "Set profile's privacy by account",
      },
      {
        action: 'updateAvatar',
        description: "Set profile's avatar by account",
      },
      {
        action: 'destroy',
        description: 'Delete a user and profiles by account',
      },
      {
        action: 'updateInviteQuantities',
        description: 'Update invite quantities per Profile',
      },
    ],
  },
  {
    subject: 'role',
    actions: [
      {
        action: 'index',
        description: 'List all roles',
      },
      {
        action: 'rolesByProfile',
        description: 'List the roles owned by a profile',
        map: 'getRolesByProfile',
      },
      {
        action: 'updateProfileRoles',
        description: 'Update the roles assigned to a profile',
      },
    ],
  },
  {
    subject: 'team',
    actions: [
      {
        action: 'index',
        description: 'Get all teams by account',
      },
      {
        action: 'show',
        description: 'Show a team by account',
      },
      {
        action: 'create',
        description: 'Create a team by account',
      },
      {
        action: 'update',
        description: 'Update a team by account',
      },
      {
        action: 'destroy',
        description: 'Delete a team by account',
      },
      {
        action: 'publicTeams',
        description: 'Get all public teams by account',
        map: 'getPublicTeams',
      },
      {
        action: 'setPrivacy',
        description: "Set team's privacy by account",
      },
      {
        action: 'addBundleToTeams',
        description: 'Add bundles to a team by account',
      },
      {
        action: 'removeBundleFromTeams',
        description: 'Remove bundles to a team by account',
      },
      {
        action: 'publishedTeams',
        description: 'Return all published teams private or public',
        map: 'getPublishedTeams',
      },
    ],
  },
  {
    subject: 'token',
    actions: [
      {
        action: 'assign',
        description: 'Assign tokens to tokens allocators',
      },
      {
        action: 'change',
        description: 'Reassign tokens to tokens allocators',
      },
      {
        action: 'use',
        description: 'Use tokens to send bulk report invitations',
      },
    ],
  },
  {
    subject: 'user',
    actions: [
      {
        action: 'viewAssessment',
        description: "Get valid current user's assessment",
      },
      {
        action: 'updateAssessmentQuestions',
        description: "Update ongoing current user's assessment",
      },
      {
        action: 'createAssessment',
        description: "Create new current user's assessment",
      },
      {
        action: 'createAccount',
        description: 'Create new user with personal account',
      },
      {
        action: 'myProfile',
        description: 'Get my personal profile',
      },
      {
        action: 'forgotPassword',
        description: 'Sends Forgot Password email',
      },
      {
        action: 'forgotPasswordUserInfo',
        description: 'Send a profile by token',
      },
      {
        action: 'resetPassword',
        description: 'Reset the new password',
      },
      {
        action: 'updateProfile',
        description: 'Update a profile',
      },
    ],
  },
  {
    subject: 'country',
    actions: [
      {
        action: 'index',
        description: 'Get a list of countries',
      },
    ],
  },
  {
    subject: 'workshop',
    actions: [
      {
        action: 'index',
        description: 'Get all workshops by account',
      },
      {
        action: 'show',
        description: 'Show a workshop by account',
      },
      {
        action: 'create',
        description: 'Create a workshop by account',
      },
      {
        action: 'update',
        description: 'Update a workshop by account',
      },
      {
        action: 'destroy',
        description: 'Delete a workshop by account',
      },
    ],
  },
]

export const mapPermissions = filterAction => (acc, { subject, actions }) =>
  deepMerge(
    acc,
    {
      [subject]: actions
        .filter(({ action }) => filterAction === 'manage' || action === filterAction)
        .map(({ action, map }) => map || action),
    },
    {
      arrayMerge: (a, b) => uniq([...a, ...b]),
    },
  )
