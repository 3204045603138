import { autobind } from 'core-decorators'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core'
import { formatRoute } from 'react-router-named-routes'
import { sprintf } from 'sprintf-js'
import DocumentTitle from 'react-document-title'
import htmlParser from 'html-react-parser'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ACTIONS, HISTORY, I18N, QUESTION } from 'constants/props'
import { MY_5_DYNAMICS } from 'urls'
import { StyledButton } from 'generics/StyledFormComponents'
import * as moduleAssessmentActions from 'app_modules/assessment/actions'
import * as moduleSessionActions from 'app_modules/session/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import Translation from 'generics/Translation'

import styles from './assessment.scss'
import WordPairs from './partials/WordPairs'

class assessment extends Component {
  componentDidMount() {
    const { assessmentActions, isCompleted } = this.props

    if (isCompleted) {
      this.handleRedirect()
    } else {
      assessmentActions.fetchAssessment()

      window.onpopstate = event => {
        event.preventDefault()
        window.history.forward()
      }
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { accountId, expirationDate, history, isCompleted, isOpenDialog, sessionActions } = nextProps

    if (isCompleted && !isOpenDialog) {
      this.handleRedirect()
    }

    if (!this.timeOut && expirationDate) {
      const time = moment(expirationDate).diff(moment())
      if (time > 0) {
        this.timeOut = setTimeout(() => {
          history.replace(formatRoute(MY_5_DYNAMICS, { accountId }))
          // eslint-disable-next-line react/prop-types
          sessionActions.expireSession('assessmentExpired')
        }, time)
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut)
    window.onpopstate = undefined
  }

  @autobind
  handleRedirect() {
    const { accountId, history } = this.props
    history.replace(formatRoute(MY_5_DYNAMICS, { accountId }))
  }

  @autobind
  handleClick(answer) {
    const { assessmentActions } = this.props
    assessmentActions.addAnswer(answer)
  }

  render() {
    const { currentQuestion, i18n, isDisable, isOpenDialog, userName } = this.props
    return (
      <DocumentTitle title="Simpli5® - Assessment Page">
        <div className={styles.assessment}>
          <div className={styles.legal}>{htmlParser(sprintf(i18n.assessment.copyRight, new Date().getFullYear()))}</div>
          <main>
            <div>
              <p className={styles.title}>
                <span className={styles.name} title={userName}>
                  {userName}
                </span>
                ,{i18n.assessment.wordPairDescription[0]}
                <br />
                <span className={styles.title__click}>{i18n.assessment.wordPairDescription[1]}</span>
                <br />
                {i18n.assessment.wordPairDescription[2]}
              </p>
              {!!Object.keys(currentQuestion).length && (
                <WordPairs disable={isDisable} onClick={this.handleClick} question={currentQuestion} />
              )}
            </div>
          </main>
          <Dialog
            aria-describedby="dialog-description"
            aria-labelledby="dialog-title"
            onClose={this.handleRedirect}
            open={isOpenDialog}
          >
            <DialogTitle id="dialog-title">{i18n.assessment.successTitle}</DialogTitle>
            <DialogContent id="dialog-description">
              {sprintf(i18n.assessment.successDescription, userName)}
            </DialogContent>
            <DialogActions>
              <StyledButton
                autoFocus
                className={styles.continue}
                title={i18n.assessment.continue}
                onClick={this.handleRedirect}
                color="default"
                variant="text"
              >
                {i18n.assessment.continue}
              </StyledButton>
            </DialogActions>
          </Dialog>
        </div>
      </DocumentTitle>
    )
  }
}

assessment.propTypes = {
  accountId: PropTypes.number.isRequired,
  assessmentActions: ACTIONS.isRequired,
  currentQuestion: QUESTION.isRequired,
  expirationDate: PropTypes.string,
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isDisable: PropTypes.bool.isRequired,
  isOpenDialog: PropTypes.bool.isRequired,
  sessionActions: ACTIONS.isRequired,
  userName: PropTypes.string.isRequired,
}

assessment.defaultProps = {
  expirationDate: null,
}

const mapStateToProps = state => ({
  accountId: sessionSelectors.accountId(state),
  currentQuestion: state.assessment.currentQuestion,
  expirationDate: state.assessment.expirationDate,
  isCompleted: state.assessment.isCompleted,
  isDisable: state.assessment.isDisable,
  isOpenDialog: state.assessment.isOpenDialog,
  sessionActions: ACTIONS.isRequired,
  userName: sessionSelectors.currentProfile(state).firstName,
})

const mapDispatchToProps = dispatch => ({
  assessmentActions: bindActionCreators(moduleAssessmentActions, dispatch),
  sessionActions: bindActionCreators(moduleSessionActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Translation(assessment, ['assessment']))
