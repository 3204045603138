import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as accountActions from 'app_modules/accounts/actions'
import * as accountSelectors from 'app_modules/accounts/selectors'
import * as notificationActions from 'app_modules/notifications/actions'
import * as networkSelectors from 'app_modules/network/selectors'
import * as networkActions from 'app_modules/network/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import PageAdministration from 'components/PageAdministration/'

const mapStateToProps = state => {
  const profile = sessionSelectors.currentProfile(state)

  return {
    adminLicenseBuyer: accountSelectors.adminLicenseBuyer(state),
    canPurchaseLicenses: accountSelectors.canPurchaseLicenses(state),
    isLicenseBuyerRole: accountSelectors.isLicenseBuyerRole(state),
    currentProfile: profile,
    hasSubscriptionNotifications: accountSelectors.hasSubscriptionNotifications(state),
    networkDetails: networkSelectors.networkDetails.profileNetworkDetails(state, profile.id),
  }
}

const mapDispatchToProps = dispatch => ({
  accountActions: bindActionCreators(accountActions, dispatch),
  notificationActions: bindActionCreators(notificationActions, dispatch),
  networkActionsProps: bindActionCreators(networkActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PageAdministration)
