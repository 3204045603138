import { Link, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { BREADCRUMBS } from 'constants/props'

import styles from './breadcrumbs.scss'

export const Breadcrumbs = props => {
  const { className, linkList } = props

  return (
    <div className={classnames(styles.breadcrumbs, className)}>
      {linkList.map(({ label, to }) => {
        if (to) {
          return [
            <Link
              aria-label={`Back to ${label} page.`}
              className={styles.link}
              href={to}
              id="main"
              key={`${label}-link`}
              title={label}
              to={to}
            >
              {label}
            </Link>,
            <span className={styles.level} key={`${label}-level`}>
              /
            </span>,
          ]
        }
        return (
          <span key={label} title={label}>
            {label}
          </span>
        )
      })}
    </div>
  )
}

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  linkList: BREADCRUMBS.isRequired,
}

Breadcrumbs.defaultProps = {
  className: null,
}

export default withRouter(Breadcrumbs)
