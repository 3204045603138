/**
 * @fileoverview Credentials reducer for the SensaiAI module
 */

import { CREDENTIALS } from '../constants/actionTypes'

const initialState = {
  appSecret: null,
  licenseKey: null,
}

const credentialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREDENTIALS.SET_SENSAI_CREDENTIALS:
      return {
        ...state,
        appSecret: action.payload.appSecret,
        licenseKey: action.payload.licenseKey,
      }
    case 'Session/FETCH_SENSAI_STATUS_SUCCESS':
      return {
        ...state,
        appSecret: action.payload.appSecret,
        licenseKey: action.payload.licenseKey,
      }
    default:
      return state
  }
}

export default credentialsReducer
