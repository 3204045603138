/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import actionTypes from '../constants'

export const toggleProMemberModal = (showModal = true) => ({
  type: actionTypes.TOGGLE_PRO_MEMBER_MODAL,
  payload: showModal,
})
