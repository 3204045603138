export const en = {
  almostDoneModal: {
    header: 'Almost Done',
    noButton: 'Continue without Personal Account.',
    subtitle: 'Would you also create a personal account?',
    title: "You are creating an account under {name}'s Network.",
    whyButton: 'Why?',
    yesButton: 'Yes, Create a Personal Account.',
  },
  emailModal: {
    subtitle: 'Please enter an email address to setup your Personal Account.',
    title: 'Personal Account',
  },
  emailForm: {
    doneButton: 'Done',
    email: 'Email',
    errors: {
      duplicatedEmail: 'This email is already taken. Please use a different one.',
      email: 'You need to provide an email address.',
      invalidEmail: 'You need to provide a valid email format.',
      invalidEmailLength: 'Email should no have more than 120 characters.',
    },
  },
  setupProfile: {
    headerText: 'Setup Profile',
    invitationText: 'Invitation for: %s',
    languages: 'Select Assessment Language',
    linkAccount: 'Link Account',
    login: 'Login',
    haveAccountText: 'Already have a 5 Dynamics<sup>&reg;</sup> account?',
    haveRelationshipsAccountText: 'Already have a 5 Dynamics<sup>&reg;</sup> Relationships account?',
    requiredFieldsMessage:
      '* All fields are required. After you have agreed to the Terms, you will be able to Save the profile.',
  },
  whyModal: {
    text1:
      'As long as you are connected to an organization, you will have access to your 5 Dynamics profile, the profiles of others in the network, and the valuable information available. If you are removed from the organization, you will no longer be able to access that organization’s network. By entering a separate and unique email address, you will continue to have access to your personal information and your private network',
    text2:
      '5 Dynamics is a powerful platform for self-awareness and collaboration. We want you to continue to benefit from the information. The personal account belongs to you alone. Any other organization network will not have visibility to your personal account and vice versa. We encourage you to continue your access and the enjoy the value of 5 Dynamics.',
    title: 'Why create a Personal Account ?',
  },
}

export const es = en
