import { autobind } from 'core-decorators'
import { isNil } from 'lodash'
import { sprintf } from 'sprintf-js'
import { Tab, Tabs } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ACCOUNT_ADMIN } from 'roles'
import {
  ACTIONS,
  COMPLETE_WORKSHOP,
  I18N,
  INVITATION_GROUP,
  INVITATION_GROUPS,
  INVITATION,
  LIST,
  NETWORK_DETAILS,
  PERSON,
  WORKSHOPS,
} from 'constants/props'
import { Card, Header, Separator } from 'generics/Card'
import { DECLINED, EXPIRED, PENDING_PRO_MEMBER } from 'constants/invitationStatus'
import { StyledButton } from 'generics/StyledFormComponents'
import api from 'api'
import ModalPeople from 'containers/ModalPeople'
import Translation from 'generics/Translation'

import AssignTokens from '../AssignTokens/AssignTokens'
import BuyTokens from '../BuyTokens/BuyTokens'
import ModalCreateWorkshop from '../../../PageAdministration/components/ModalCreateWorkshop/ModalCreateWorkshop'
import ReAssignTokens from '../ReAssignTokens/ReAssignTokens'
import UseTokens from '../UseTokens/UseTokens'
import styles from './TokenManagement.scss'

class TokenManagement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      showAssignTokensOptions: null,
      tabSelected: 'buyTokens',
    }
  }

  componentDidMount() {
    this.getTokensAllocators()
  }

  @autobind
  getTokensAllocators() {
    this.setState({ isFetching: true })

    const {
      currentProfile: { accountId },
    } = this.props

    const onSuccess = response => {
      if (response?.profiles?.length > 1) {
        this.setState({ showAssignTokensOptions: true })
      } else {
        this.setState({ showAssignTokensOptions: false })
      }
      this.setState({ isFetching: false })
    }

    const onError = error => {
      this.setState({
        isFetching: false,
        showAssignTokensOptions: false,
      })
      console.log(error)
    }

    api.tokens.getTokensAllocators({ accountId }, onSuccess, onError)
  }

  @autobind
  async handleChangeList(tabSelected) {
    this.setState({ tabSelected })
  }

  @autobind
  renderTabs() {
    const { currentProfile, i18n } = this.props

    const { showAssignTokensOptions } = this.state

    const { isSSOAccount } = currentProfile || {}

    const tabs = []

    if (currentProfile.can({ charge: 'tokens' })) {
      tabs.push({
        label: sprintf(i18n.pageTokenAdministration.tokenManagement.tab.buyTokens),
        value: 'buyTokens',
      })
    }
    if (currentProfile.can({ token: 'use' })) {
      tabs.push({
        label: sprintf(i18n.pageTokenAdministration.tokenManagement.tab.useTokens),
        value: 'useTokens',
      })
    }
    if (currentProfile.can({ token: 'assign' }) && showAssignTokensOptions) {
      tabs.push({
        label: sprintf(i18n.pageTokenAdministration.tokenManagement.tab.assignTokens),
        value: 'assignTokens',
      })
    }
    if (currentProfile.can({ token: 'change' }) && showAssignTokensOptions) {
      tabs.push({
        label: sprintf(i18n.pageTokenAdministration.tokenManagement.tab.ownerChange),
        value: 'ownerChange',
      })
    }

    return tabs.map(tab => (
      <Tab
        className={classnames(
          styles.tab,
          { [styles.tabs__expanded]: isSSOAccount },
          { [styles['tabs__not-expanded']]: !isSSOAccount },
        )}
        key={`tab-${tab.label}`}
        title={tab.label}
        {...tab}
      />
    ))
  }

  @autobind
  renderLabel(status) {
    const {
      i18n: {
        pageAdministration: { peopleList },
      },
    } = this.props
    const { resendLabel, sendNewLabel } = peopleList

    switch (status) {
      case PENDING_PRO_MEMBER:
        return resendLabel

      case DECLINED:
      case EXPIRED:
        return sendNewLabel

      default:
        return ''
    }
  }

  render() {
    const {
      className,
      currentProfile,
      i18n,
      isPersonalAccount,
      networkActions,
      networkDetails,
      notificationActions,
      pageActions,
    } = this.props

    const { isFetching, isModalPeopleOpen, isModalWorkshopOpen, showAssignTokensOptions, tabSelected } = this.state

    const { account, accountId, email, id, isSSOAccount } = currentProfile

    const { name: accountName } = account

    const actions = []

    if (currentProfile.can({ invite: 'sendInvitationViaCsv' }, { invite: 'sendInvitationManually' }) && !isSSOAccount) {
      actions.push(
        <StyledButton
          id="button-invite-people"
          onClick={() => {
            this.handleOpenCloseModal('ModalPeople')
          }}
          color="primary"
          style={{ display: undefined }}
          title={i18n.pageTokenAdministration.peopleList.invitePeopleLabel}
        >
          {i18n.pageTokenAdministration.peopleList.invitePeopleLabel}
        </StyledButton>,
      )
    } else if (currentProfile.can({ workshop: 'create' }) && isSSOAccount) {
      actions.push(
        <StyledButton
          id="button-create-workshop"
          onClick={() => {
            this.handleOpenCloseModal('ModalWorkshop')
          }}
          color="primary"
          style={{ display: undefined }}
          title={i18n.pageTokenAdministration.peopleList.createWorkshopLabel}
        >
          {i18n.pageTokenAdministration.peopleList.createWorkshopLabel}
        </StyledButton>,
      )
    }

    const { tokensAllocated, tokensConsumed, tokensUnallocated, tokensUnassigned } = networkDetails

    const info = (
      <>
        <div>
          <p>
            <strong>{i18n.pageTokenAdministration.assignTokens.myTokensLabel}</strong>
          </p>
          <p>
            {i18n.pageTokenAdministration.assignTokens.unallocatedLabel}:<span>{tokensUnallocated || 0}</span>
          </p>
          <p>
            {i18n.pageTokenAdministration.assignTokens.allocatedLabel}:<span>{tokensAllocated || 0}</span>
          </p>
          <p>
            {i18n.pageTokenAdministration.assignTokens.consumedLabel}:<span>{tokensConsumed || 0}</span>
          </p>
        </div>
        {currentProfile.hasRole(ACCOUNT_ADMIN) && (
          <div>
            <p>
              <strong>{i18n.pageTokenAdministration.assignTokens.organizationTokensLabel}</strong>
            </p>
            <p>
              {i18n.pageTokenAdministration.assignTokens.unassignedLabel}:<span>{tokensUnassigned || 0}</span>
            </p>
          </div>
        )}
      </>
    )

    return (
      <Card
        barClassName={styles.bar}
        className={classnames(styles.people, className)}
        disabled={isPersonalAccount}
        isFetching={isFetching}
      >
        <Header
          // actions={actions}
          className={styles.header}
          info={info}
          title={i18n.pageTokenAdministration.peopleList.peopleLabel}
        />

        <Tabs
          className={styles.tabs}
          indicatorColor="primary"
          onChange={(e, value) => this.handleChangeList(value)}
          textColor="primary"
          value={tabSelected}
        >
          {!isNil(showAssignTokensOptions) && this.renderTabs()}
        </Tabs>

        <Separator />
        {tabSelected === 'buyTokens' && (
          <BuyTokens
            accountId={accountId}
            email={email}
            i18n={i18n}
            notificationActions={notificationActions}
            onFetchNetworkDetails={networkActions.fetchNetworkDetails}
            profileId={id}
          />
        )}
        {tabSelected === 'useTokens' && (
          <UseTokens
            accountId={accountId}
            i18n={i18n}
            isAdmin={currentProfile?.hasRole(ACCOUNT_ADMIN)}
            notificationActions={notificationActions}
            onFetchNetworkDetails={networkActions.fetchNetworkDetails}
            profileId={id}
          />
        )}
        {tabSelected === 'assignTokens' && (
          <AssignTokens
            accountId={accountId}
            accountName={accountName}
            i18n={i18n}
            isAdmin={currentProfile?.hasRole(ACCOUNT_ADMIN)}
            notificationActions={notificationActions}
            onFetchNetworkDetails={networkActions.fetchNetworkDetails}
            profileId={id}
          />
        )}
        {tabSelected === 'ownerChange' && (
          <ReAssignTokens
            accountId={accountId}
            i18n={i18n}
            isAdmin={currentProfile?.hasRole(ACCOUNT_ADMIN)}
            notificationActions={notificationActions}
            onFetchNetworkDetails={networkActions.fetchNetworkDetails}
            profileId={id}
          />
        )}
        {isModalPeopleOpen && (
          <ModalPeople
            onClose={() => {
              this.handleOpenCloseModal('ModalPeople')
            }}
            onComplete={this.fetchAll}
          />
        )}
        {isModalWorkshopOpen && (
          <ModalCreateWorkshop
            accountId={accountId}
            onClose={() => {
              this.handleOpenCloseModal('ModalWorkshop')
            }}
            onComplete={this.fetchAll}
            pageActions={pageActions}
          />
        )}
      </Card>
    )
  }
}

TokenManagement.propTypes = {
  accountActions: ACTIONS.isRequired,
  className: PropTypes.string,
  currentGroup: INVITATION_GROUP,
  currentProfile: PERSON.isRequired,
  currentWorkshop: COMPLETE_WORKSHOP.isRequired,
  groups: INVITATION_GROUPS.isRequired,
  groupsActions: ACTIONS.isRequired,
  i18n: I18N.isRequired,
  invitesActions: ACTIONS.isRequired,
  isPersonalAccount: PropTypes.bool,
  myMembers: LIST(PERSON).isRequired,
  networkActions: ACTIONS.isRequired,
  networkDetails: NETWORK_DETAILS,
  notificationActions: ACTIONS.isRequired,
  pageActions: ACTIONS.isRequired,
  profileActions: ACTIONS.isRequired,
  sentInvitations: LIST(INVITATION).isRequired,
  workshops: WORKSHOPS.isRequired,
  workshopActions: ACTIONS.isRequired,
}

TokenManagement.defaultProps = {
  className: null,
  currentGroup: null,
  isPersonalAccount: false,
  networkDetails: {
    numberOfConnections: 0,
    numberOfTeamsCreated: 0,
  },
}

export default Translation(TokenManagement, ['pageTokenAdministration', 'generics'])
