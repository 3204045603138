import { isEmpty } from 'lodash'

import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import workshopsActionTypes from 'app_modules/workshops/constants'

// Actions

export const fetchWorkshopsFailure = error => ({
  payload: error,
  type: workshopsActionTypes.FETCH_WORKSHOPS_FAILURE,
})

export const fetchWorkshopsRequest = pageIndex => ({
  payload: pageIndex,
  type: workshopsActionTypes.FETCH_WORKSHOPS_REQUEST,
})

export const fetchWorkshopsSuccess = response => ({
  payload: response,
  type: workshopsActionTypes.FETCH_WORKSHOPS_SUCCESS,
})

export const fetchWorkshopsSuccessWithNoResults = () => ({
  type: workshopsActionTypes.FETCH_WORKSHOPS_SUCCESS_NO_RESULTS,
})

// Thunks
export const fetchWorkshops = ({ pageIndex = 1, searchInfo = {} }, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchWorkshopsRequest(pageIndex))

    const handleResponse = response => {
      const { workshops } = response

      if (!workshops) {
        dispatch(fetchWorkshopsSuccessWithNoResults())
        if (onSuccess) {
          onSuccess(response)
        }
      }

      const fixedResponse = {
        ...response,
        updateCounters: isEmpty(searchInfo),
      }

      dispatch(fetchWorkshopsSuccess(fixedResponse))

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchWorkshopsFailure(error))
      if (onError) {
        onError(error)
      }
    }

    return api.workshops.getWorkshops(
      {
        accountId,
        pageIndex,
        ...searchInfo,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = workshopsActionTypes.FETCH_WORKSHOPS

  return thunk
}

export default fetchWorkshops
