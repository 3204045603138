/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import React from 'react'

import Modal from 'generics/Modal'
import Translation from 'generics/Translation'

import styles from './ModalEditCustomization.scss'

const ModalEditCustomization = ({ children, isFetching, onClose, title }) => (
  <Modal
    card={{
      barClassName: styles.bar,
      fullHeight: true,
      isFetching,
    }}
    onClose={onClose}
    title={title}
  >
    {children}
  </Modal>
)

ModalEditCustomization.propTypes = {
  children: PropTypes.node.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default Translation(ModalEditCustomization, ['customizations'])
