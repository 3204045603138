import { Col, Container, Row } from 'reactstrap'
import { isNil } from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents'
import Translation from 'generics/Translation'

import styles from './FormSendPersonalsInvitation.scss'
import useFormSendPersonalsInvitation from './useFormSendPersonalsInvitation'

/**
 * FormSendPersonalsInvitation Component
 */
const FormSendPersonalsInvitation = ({
  error,
  formAriaLabel,
  i18n,
  initialValues,
  isSubmitting,
  onChange,
  onSubmit,
}) => {
  const { errors, isValid, handleChange, handleSubmit, values } = useFormSendPersonalsInvitation({
    i18n,
    onSubmit,
    initialValues,
  })

  const emailError = error || errors?.email
  const disabled = !isValid || isSubmitting || !!error

  const completeChange = value => {
    onChange()
    handleChange('email', value)
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col xs="12" md="6">
            <StyledTextField
              ariaLabel={`${formAriaLabel}. ${i18n.news.form.inputLabel}`}
              className={styles['text-field']}
              error={isNil(values.email) ? false : !!emailError}
              helperText={isNil(values.email) ? ' ' : emailError}
              id="main"
              label={i18n.news.form.inputLabel}
              name="email"
              onChange={e => completeChange(e.currentTarget.value)}
              value={values.email || ''}
            />
          </Col>
          <Col xs={12} md="6">
            <StyledButton
              aria-disabled={disabled}
              aria-label={`${i18n.news.form.submitBtn} email`}
              className={classnames(styles['submit-btn'], { [styles.disabled]: disabled })}
              color="primary"
              title={i18n.news.form.submitBtn}
              type="submit"
            >
              {i18n.news.form.submitBtn}
            </StyledButton>
          </Col>
        </Row>
      </Container>
    </form>
  )
}

FormSendPersonalsInvitation.propTypes = {
  error: PropTypes.string,
  formAriaLabel: PropTypes.string.isRequired,
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}),
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

FormSendPersonalsInvitation.defaultProps = {
  error: null,
  isSubmitting: false,
  initialValues: {},
}

export default Translation(FormSendPersonalsInvitation, ['news', 'generics'])
