import React from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import styles from './CardSection.scss'

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#32325d',
      fontFamily:
        'muli,-apple-system,blinkmacsystemfont,Segoe UI,roboto,Helvetica Neue,arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}
const CardSection = () => (
  <p>
    <CardElement options={CARD_ELEMENT_OPTIONS} className={styles.StripeElement} />
  </p>
)

export default CardSection
