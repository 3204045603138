import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as notificationActions from 'app_modules/notifications/actions'
import * as sessionActions from 'app_modules/session/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import PageGeneralAccountInformation from 'components/PageGeneralAccountInformation'

const mapStateToProps = state => ({
  defaultAccount: sessionSelectors.defaultAccount(state),
  profile: sessionSelectors.currentProfile(state),
  profiles: sessionSelectors.userProfiles(state),
})

const mapDispatchToProps = dispatch => ({
  notificationActions: bindActionCreators(notificationActions, dispatch),
  sessionActions: bindActionCreators(sessionActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PageGeneralAccountInformation)
