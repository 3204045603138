export const en = {
  accountDetails: {
    editLogo: 'Edit Logo',
    logoTitle: '%s logo',
    uploadLogoMessage: 'Upload a logo image here (1 MB max file size)',
  },
  accountTypeLabel: 'Account Type',
  bulkDelete: {
    title: 'Bulk Delete',
    fieldErrors: 'Errors found while deleting the members',
    fileError: 'Invalid File. Make sure you are uploading a CSV with the right format.',
    fileFormatError: 'Invalid File, only csv files are accepted',
    fileReaderDescription: 'Upload a list of people to delete them (Only CSV files with a limit of 500 rows).',
    resultMessage: {
      failed: 'The following emails have errors',
      removed: 'The following members were removed',
    },
  },
  bundleModules: {
    modulesLabel: 'Modules',
    selectedTeamsCountLabel: 'Selected Teams: %(teamsCount)s',
    shareButtonLabel: 'Add / Remove Teams',
  },
  bundlesList: {
    detailsLabel: 'Details',
  },
  bundlesListItem: {
    modulesCountLabel: '%(modulesCount)s %(subject)s',
    moduleSubject: 'Modules',
    sharedWithEntireNetworkLabel: 'Shared with the entire network',
    teamsCountSharedLabel: 'Shared with %(teamsCount)s %(subject)s',
    teamSubject: 'Teams',
  },
  contactLabel: 'sales@simpli5.com',
  expirationDateLabel: 'Renewal Date',
  formFileInvitation: {
    description: 'Description',
    fileError: 'Invalid file format',
    fileReaderDescription: 'Upload a list of people to invite here.',
    frequency: ['Daily', 'Weekly', 'Never'],
    frequencyLabel: 'Frequency',
    frequencyType: {
      day: '%(dayCount)d %(text)s',
      dayText: 'Day',
      weekDays: {
        10: 'Sunday',
        4: 'Monday',
        5: 'Tuesday',
        6: 'Wednesday',
        7: 'Thursday',
        8: 'Friday',
        9: 'Saturday',
      },
    },
    frequencyTypeLabel: 'Frequency Type',
    groupName: 'Group Name',
    instructions: 'Please add a Group Name and the frequency of the invitation reminders for it',
    templateFileDescription:
      '* The CSV file for the people list invitation must have the following headers: %(headers)s. The CSV must use UTF-8 encoding.',
    templateFileDownloadDescription: 'Download CSV template',
    templateInvitationsFileName: 'invitations.csv',
    tooltip: {
      description: 'This will set the frequency of the reminder email to be sent to the members of the group.',
      title: 'What is this?',
    },
  },
  formGroupSearch: {
    date: {
      startDate: 'From',
      endDate: 'To',
    },
    labelAllGroups: 'All',
    labelArchiveGroups: 'Yes',
    labelGroupName: 'Name',
    labelShowArchiveFilter: 'Show Archived',
    labelUnarchiveGroups: 'No',
    btnTextSubmit: 'Filter',
    titleError: 'Error message',
  },
  groupListItem: {
    archive: 'Archive',
    dateJoined: 'Date Joined:',
    dateJoinedTitle: 'Date Joined: %(dateJoined)s',
    dailyInvitationReminder: 'Invitation Reminder every %(dayCount)s %(dayText)s ',
    weeklyInvitationReminder: 'Invitation Reminder %(weekly)s every %(weekDay)s',
    never: 'Never',
    weekly: 'weekly',
    dayText: 'day',
    weekDays: {
      10: 'Sunday',
      4: 'Monday',
      5: 'Tuesday',
      6: 'Wednesday',
      7: 'Thursday',
      8: 'Friday',
      9: 'Saturday',
    },
    unarchive: 'Restore',
  },
  invitationsSentLabel: 'Total Sent Invitations',
  licenseLimitLabel: 'License Limit',
  licensesAllocatedLabel: 'Licenses Allocated',
  licensesAvailableLabel: 'Licenses Available',
  members: {
    bulkDelete: 'Bulk Delete',
    exportMembers: 'Export Members',
    exportMembersTitle: 'Export a CSV with all members',
    generateReports: {
      label: 'Generate Reports',
      resultMessage: {
        failed: 'We could not send reports to you email because of an error creating the files.',
        success:
          'You will receive an email with the attached file when it is ready with the members reports. Thank you.',
      },
      selectionLabel: 'Generate Reports (%(selectedMembers)s / %(selectedMembersLimit)s)',
    },
    search: 'Find a Member',
    workshopOptions: {
      all: 'All Members',
      in: 'In a Workshop',
      notIn: 'Not in a Workshop',
    },
  },
  modalGenerateReports: {
    close: 'Close',
    confirmationMessage: 'The reports of the following members will be sent to your email.',
    send: 'Send',
    title: {
      sendReports: 'Send Learning Reports',
    },
  },
  modalShareTeams: {
    listATitle: 'All Teams',
    listBEmptyMessage: 'No teams shared yet',
    listBTitle: 'Selected',
    modalDescription: 'Please select the teams you wish to share this Module Bundle with',
    modalTitle: 'Select Teams',
  },
  modalPeople: {
    close: 'Close',
    invite: 'Invite',
    formInvitation: {
      description: "Enter an email address to send an invitation to join your organization's network.",
      editDescription: 'Enter an email address to send an invitation and join this group',
    },
    resultMessage: {
      failed: 'We could not send the invitation to the following people because of errors in the data submitted',
      failedEditGroup: 'We found some errors in the data submitted',
      removed: 'Invitation successfully cancelled to the following people',
      success: 'Invitation successfully sent to the following people',
    },
    tabs: ['Add a member', 'Add multiple members'],
    title: {
      editGroup: 'Edit Group',
      error: 'Error!',
      invitePeople: 'Invite People',
      inviteLimitedMember: 'Invite Team Members',
      success: 'Success!',
    },
  },
  pendingInvitesLabel: 'Pending Invitations',
  peopleList: {
    createWorkshopLabel: 'Create Workshop',
    dateCreated: 'Date Created',
    dateSent: 'Date Sent',
    deleteMemberConfirmationMessage: 'Are you sure that you want to permanently delete the member? There is no Undo.',
    deleteMemberSuccessMessage: 'The member was successfully removed.',
    department: 'Department',
    editDepartment: {
      title: 'Edit department',
    },
    groupListHeader: {
      never: 'Never',
      edit: 'Edit',
      back: 'Back',
      dateCreated: 'Date created:',
      dateCreatedTitle: 'Date created: %(dateCreated)s',
      InvitationReminder: 'Invitation Reminder:',
      InvitationReminderTitle: 'Invitation Reminder: %(invitationReminder)s',
      frequency: 'Every %(frequency)s',
    },
    invalidateInvitation: 'Invalidate Invitation',
    invalidateInvitationConfirmationMessage: 'Are you sure you want to invalidate this invitation? There is no Undo.',
    invitationSuccessMessage: 'A new invitation has been sent.',
    invitePeopleLabel: 'Invite People',
    latestWorkshop: 'Go to the latest workshop: %(workshop)s',
    noResultsToDisplayYetLabel: 'No results to display yet',
    peopleLabel: 'People',
    resendLabel: 'Resend Invitation',
    sendNewLabel: 'Send New Invitation',
    tab: {
      groups: 'Groups (%(groupsCount)s)',
      members: 'Members (%(myMembersCount)s)',
      sentInvites: 'Sent Invitations (%(invitesCount)s)',
      workshops: 'Workshops (%(workshopsCount)s)',
      memberUpgradeRequests: 'Member Upgrade Requests (%(memberUpgradeRequestsCount)s)',
    },
    updateDepartment: 'Department updated successfully',
  },
  purchaseLabel: 'To purchase more licenses, contact ',
  purchaseLabelNoBuyer: 'To purchase more licenses, contact your license buyer, %(licensesBuyerName)s at ',
  sentInvites: {
    search: 'Find an Invitation',
    statusFilterLabel: 'Filter By Status',
  },
  totalTeamsQuantityLabel: 'Number of Teams',
  upgradeMemberRequests: {
    approve: {
      selectionLabel: 'Approve/Reject Requests (%(selectedMembers)s)',
    },
    modalApproveRequests: {
      approve: 'Approve',
      close: 'Close Upgrade Member Requests Modal',
      confirmationMessage: 'The following members will be upgraded to Pro Members or you can reject the requests.',
      title: 'Upgrade Member Requests',
      reject: 'Reject',
      resultMessage: {
        failed: 'We could not upgrade or reject the following members because of an error.',
        success: 'Approved requests',
        successRejected: 'Rejected requests',
        successCantUpgrade: "There aren't enough licenses available to complete this action",
        successCantUpgradeWithAccepted:
          'The upgrade was successful for %(users)s user(s). The following users could not be upgraded because there are no licenses available: %(emails)s. Please purchase additional licenses and try again.',
      },
    },
  },
}

export const es = en
