import { Container, Row } from 'reactstrap'
import { sprintf } from 'sprintf-js'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { BUNDLE, I18N } from 'constants/props'
import { Header, Main } from 'generics/Card'
import { StyledButton } from 'generics/StyledFormComponents'
import BackButton from 'generics/BackButton'
import Icon from 'generics/Icon'
import ModalShareTeams from 'containers/ModalShareTeams'
import ModulesList from 'generics/ModulesList'
import Translation from 'generics/Translation'

import styles from './BundleModules.scss'

const BundleModules = ({ accountId, bundle, i18n, onBack }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
  }

  // EPIC error with dynamic title depending of user session
  const moduleList = bundle.modules.map(module => {
    if (module.key === 'leading___name_s_12') {
      return {
        ...module,
        title: i18n.generics.leadershipModuleTitle,
      }
    }
    return module
  })

  return (
    <>
      <Header>
        <BackButton onClick={onBack} className={styles.back} />
        <div className={styles['title-container']}>
          <Icon.Stroke7 className={styles['title-container__icon']} name="box1" />
          <h1>{bundle.i18NBundle.title}</h1>
        </div>
        <div className={styles['subtitle-container']}>
          <span className={styles['subtitle-container__team-count']}>
            {sprintf(i18n.pageAdministration.bundleModules.selectedTeamsCountLabel, {
              teamsCount: bundle.teams.length || 0,
            })}
          </span>
          {!bundle.sharingModeLocked && (
            <StyledButton
              color="primary"
              onClick={() => {
                handleModalOpen(bundle)
              }}
              title={i18n.pageAdministration.bundleModules.shareButtonLabel}
              variant="text"
            >
              {i18n.pageAdministration.bundleModules.shareButtonLabel}
            </StyledButton>
          )}
        </div>
      </Header>
      <Main className={styles.main}>
        <Container>
          <Row>
            <ModulesList
              accountId={accountId}
              showBullets={false}
              className={styles.modules}
              cardClassName={styles.cards}
              col={{ xs: 12, lg: 6 }}
              header={{
                isUpperCase: false,
                title: i18n.pageAdministration.bundleModules.modulesLabel,
              }}
              modules={moduleList}
            />
          </Row>
        </Container>
      </Main>
      {isModalOpen && <ModalShareTeams bundle={bundle} onClose={handleModalClose} />}
    </>
  )
}

BundleModules.propTypes = {
  accountId: PropTypes.number.isRequired,
  bundle: BUNDLE.isRequired,
  i18n: I18N.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default Translation(BundleModules, ['pageAdministration', 'generics'])
