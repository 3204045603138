/**
 * @fileoverview Processing-related actions for the SensaiAI module
 * @module sensaiAi/actions/processingActions
 */

import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import { PROCESSING } from '../constants/actionTypes'
import { ERROR_MESSAGES } from '../constants/errorMessages'
import { handleApiError } from '../utils/apiUtils'
import { getSensaiCredentials } from '../selectors/credentialsSelectors'

/**
 * Action creator for setting request ID
 * @param {string} requestId - Request ID
 * @returns {Object} Action object
 */
export const setSensaiRequestId = requestId => ({
  type: PROCESSING.SET_REQUEST_ID,
  payload: requestId,
})

/**
 * Action creator for setting processing status
 * @param {boolean} isProcessing - Processing status
 * @returns {Object} Action object
 */
export const setSensaiProcessing = isProcessing => ({
  type: PROCESSING.SET_STATUS,
  payload: isProcessing,
})

/**
 * Action creator for setting processing complete
 * @returns {Object} Action object
 */
export const setSensaiProcessingComplete = () => ({
  type: PROCESSING.SET_COMPLETE,
})

/**
 * Action creator for processing failure
 * @param {Error} error - Error object
 * @returns {Object} Action object
 */
export const setSensaiProcessingFailure = error => ({
  type: PROCESSING.SET_FAILURE,
  payload: error,
})

/**
 * Thunk action creator for fetching coach status
 * @param {string} requestId - The request ID to check status for
 * @returns {Function} Thunk function
 */
export const fetchCoachStatus = requestId => {
  const thunk = (dispatch, getState) => {
    const state = getState()
    const accountId = sessionSelectors.accountId(state)
    const credentials = getSensaiCredentials(state)

    return new Promise((resolve, reject) => {
      const onSuccess = response => {
        // Extract the actual response data
        const statusData = response?.data || response
        resolve(statusData)
      }

      const onError = error => {
        console.error('Status API Error:', error)
        handleApiError(error, dispatch, setSensaiProcessingFailure, ERROR_MESSAGES.STATUS_FAILED)
        reject(error)
      }

      api.sensai.getCoachStatus(
        {
          requestId,
          userId: accountId,
          appSecret: credentials.appSecret,
          licenseKey: credentials.licenseKey,
        },
        onSuccess,
        onError,
      )
    })
  }

  thunk.type = PROCESSING.FETCH_STATUS
  return thunk
}
