import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as accountActions from 'app_modules/accounts/actions'
import * as notificationActions from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import PageTokenAdministration from 'components/PageTokenAdministration/'

const mapStateToProps = state => ({
  currentProfile: sessionSelectors.currentProfile(state),
})

const mapDispatchToProps = dispatch => ({
  notificationActions: bindActionCreators(notificationActions, dispatch),
  accountActions: bindActionCreators(accountActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PageTokenAdministration)
