/* eslint-disable camelcase, no-undef */

import { unset, merge } from 'lodash'

const filterState = state => {
  const stateFiltered = merge({}, state)

  // login form
  unset(stateFiltered, 'form.login.fields.password')
  unset(stateFiltered, 'form.login.syncErrors.password')
  unset(stateFiltered, 'form.login.values.password')

  // sign up form
  unset(stateFiltered, 'form.singUp.fields.singUpPassword')
  unset(stateFiltered, 'form.singUp.fields.singUpPasswordConfirm')
  unset(stateFiltered, 'form.singUp.syncErrors.singUpPassword')
  unset(stateFiltered, 'form.singUp.syncErrors.singUpPasswordConfirm')
  unset(stateFiltered, 'form.singUp.values.singUpPassword')
  unset(stateFiltered, 'form.singUp.values.singUpPasswordConfirm')

  // change password form
  unset(stateFiltered, 'form.changePasswordForm.fields.password')
  unset(stateFiltered, 'form.changePasswordForm.syncErrors.password')
  unset(stateFiltered, 'form.changePasswordForm.values.password')

  // reset password form
  unset(stateFiltered, 'form.resetPassword.fields.password')
  unset(stateFiltered, 'form.resetPassword.syncErrors.password')
  unset(stateFiltered, 'form.resetPassword.values.password')

  unset(stateFiltered, 'invites')
  unset(stateFiltered, 'teams')
  return stateFiltered
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    const build = localStorage.getItem('build')
    const hasState = serializedState !== null

    if (build !== __webpack_hash__) {
      if (hasState) {
        localStorage.clear()
      }

      localStorage.setItem('build', __webpack_hash__)

      return undefined
    }

    if (!hasState) {
      return undefined
    }

    return JSON.parse(serializedState)
  } catch (error) {
    localStorage.clear()
    localStorage.setItem('build', __webpack_hash__)
    return undefined
  }
}

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(filterState(state))
    localStorage.setItem('state', serializedState)
  } catch (error) {
    // TODO: handle error
  }
}
