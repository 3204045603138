import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as sessionSelectors from 'app_modules/session/selectors'
import * as teamActions from 'app_modules/teams/actions'
import TeamList from 'components/PageTeams/components/TeamList'

const mapStateToProps = state => ({
  accountId: sessionSelectors.accountId(state),
  profile: sessionSelectors.currentProfile(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(teamActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamList)
