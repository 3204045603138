import PropTypes from 'prop-types'

import { I18N } from 'constants/props'
import useForm from 'custom_hooks/useForm'

const useFormEditDepartment = ({ initialValues, onSubmit }) => {
  const handleValidate = () => ({
    department: null,
  })

  const { errors, isValid, handleChange, handleSubmit, reset, values = { department: null } } = useForm({
    initialValues,
    onSubmit,
    onValidate: handleValidate,
  })

  const handleDepartmentChange = (name, value) => {
    /* let newValue = value;
    if (!Number.isNaN(Number(value)) && (Number(value) >= 1 || value === '')) {
      if (Number(value) > 100) {
        newValue = 100;
      }
      handleChange(name, newValue);
    } */

    handleChange(name, value)
  }

  return {
    errors,
    isValid,
    handleDepartmentChange,
    handleSubmit,
    reset,
    values,
  }
}

useFormEditDepartment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
}

export default useFormEditDepartment
