import { get, isEmpty, merge } from 'lodash'
import { Observable } from 'rxjs/Rx'
import { toCamelCase, toSnakeCase } from 'case-converter'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/add/observable/dom/ajax'

import { ApiError } from 'classes/errors'
import {
  API_NOT_FOUND,
  APPLICATION_AUTHENTICATE_TOKEN,
  INVALID_USER_OR_TOKEN,
  NO_RESULTS,
  RATE_LIMIT,
  SESSION_EXPIRED,
  CAN_NOT_CONNECT,
} from 'constants/apiErrorCodes'
import * as contentTypes from 'constants/contentTypes'
import * as contentTypesRegex from 'constants/contentTypesRegex'

const AJAX_ERROR = 'ajax error'
const MAX_TRIES = 2
const NOT_FOUND = 404
const RETRY_TIME = 500

const { API_ENCRYPTION_PASSPHRASE, API_RESPONSE_HAS_ENCRYPTION, API_URL, SENSAI_ENVIRONMENT } = process.env

const DEFAULT_CONTENT_TYPE = contentTypes.JSON
const API_VERSION = 2
const CONTENT_SECURITY_POLICY = 'policy'
const MAX_AGE = '31536000'
const X_CONTENT_TYPE_OPTIONS = 'nosniff'
const X_FRAME_OPTIONS = 'SAMEORIGIN'

const PENETRATION_TESTING_HEADERS = {
  'Content-Security-Policy': CONTENT_SECURITY_POLICY,
  'Strict-Transport-Security': `max-age=${MAX_AGE}; includeSubDomains; preload`,
  'X-Content-Type-Options': X_CONTENT_TYPE_OPTIONS,
  'X-Frame-Options': X_FRAME_OPTIONS,
}

/**
 * @class ApiEndpoint
 * @description Contains functions to send the request and process the response
 */
export default class ApiEndpoint {
  constructor(options) {
    merge(this, options)

    this.crypto = options.crypto || this.api.crypto
  }

  /**
   * @function retryWhen
   * @description Sends the request while it fails and the response is not a valid API error
   * @param {number} attempts
   */
  retryWhen(attempts) {
    return attempts.zip(Observable.range(1, MAX_TRIES + 1)).flatMap(([errorString, i]) => {
      const { session } = this.api.store.getState()
      const isAuthenticated = get(session, 'isAuthenticated', false)

      const error = this.decryptResponse(errorString)

      if (i > MAX_TRIES) {
        if (DEVELOPMENT) {
          console.log(error)
        }
        return Observable.throw(new ApiError({ errorCode: CAN_NOT_CONNECT }))
      }

      const errorCode = get(error, 'errorCode')

      if (
        isAuthenticated &&
        (errorCode === INVALID_USER_OR_TOKEN ||
          errorCode === APPLICATION_AUTHENTICATE_TOKEN ||
          (errorString.status === 401 && errorString.responseType === 'blob'))
      ) {
        return Observable.throw(new ApiError({ errorCode: SESSION_EXPIRED }))
      }

      // ignore 404 error (used in empty lists)
      if (errorCode === API_NOT_FOUND && this.ignoreNotFound) {
        return Observable.throw(new ApiError({ errorCode: NO_RESULTS }))
      }

      // if there are connection issues, we need to try again
      if (
        (isEmpty(error) && get(errorString, 'message') === AJAX_ERROR) ||
        (get(error, '_status') === NOT_FOUND && !errorCode)
      ) {
        return Observable.timer(i * RETRY_TIME)
      }

      // rate limit
      if (error._status === RATE_LIMIT) {
        this.api.rateLimit()
        return Observable.throw(new ApiError({ errorCode: RATE_LIMIT }))
      }

      // is a valid api error, we don't need to try the request again
      return Observable.throw(new ApiError(error))
    })
  }

  /**
   * @function setTimeout
   * @description sets the expire session timeout
   */
  setTimeout() {
    const { session } = this.api.store.getState()

    const { isAuthenticated } = session

    if (isAuthenticated) {
      this.api.setTimeout()
    }
  }

  /**
   * @function request
   * @description Sends a backend request
   * @param {object} options - Request Options
   * @param {function} onSuccess - Success callback function
   * @param {function} onError - Error callback function
   */
  request(options, onSuccess, onError) {
    const settings = this.settings(options)
    let contentType = {}

    const { forceDefaultPassphrase, useOptionsToken } = this.encrypt || {}

    const { user } = this.api.store?.getState() || {}

    const { token, impersonateToken, ssoToken } = useOptionsToken ? options : user || {}

    const hasEmptyToken = isEmpty(token) && isEmpty(impersonateToken) && isEmpty(ssoToken)

    let Authorization = get(settings.headers, 'Authorization', {})

    if (token) {
      Authorization = `Token token=${token}`
    } else if (impersonateToken) {
      Authorization = `Token impersonate=${impersonateToken}`
    } else if (ssoToken) {
      Authorization = `Token sso=${ssoToken}`
    }

    if (forceDefaultPassphrase || hasEmptyToken) {
      this.crypto.setPassphrase(API_ENCRYPTION_PASSPHRASE)
    } else {
      this.crypto.setPassphrase(token || impersonateToken || ssoToken)
    }

    let { body } = settings

    if (body && get(settings.headers, 'enctype') !== contentTypes.FORM) {
      body = JSON.stringify(toSnakeCase(body))
      contentType = {
        'Content-Type': DEFAULT_CONTENT_TYPE,
      }
    }
    const requestSettings = this.encryptRequest({
      responseType: '*',
      ...settings,
      body,
      headers: {
        Accept: `application/json; version=${API_VERSION}`,
        ...(settings.headers || {}),
        Authorization,
        ...contentType,
        ...PENETRATION_TESTING_HEADERS,
        ...(settings?.endpoint?.includes('sensai') && { env: SENSAI_ENVIRONMENT }),
      },
      url: `${API_URL}${settings.endpoint}`,
    })

    return this.ajax$(requestSettings, onSuccess, onError)
  }

  /**
   * @function externalRequest
   * @description Sends a request to external APIs (e.g., Sensai AI endpoints)
   * @param {object} options - Request Options
   * @param {function} onSuccess - Success callback function
   * @param {function} onError - Error callback function
   */
  externalRequest(options, onSuccess, onError) {
    const settings = this.settings(options)
    let contentType = {}

    const { user } = this.api.store?.getState() || {}
    const { token, impersonateToken, ssoToken } = user || {}

    let Authorization = ''
    if (token) {
      Authorization = `Bearer token=${token}`
    } else if (impersonateToken) {
      Authorization = `Bearer impersonate=${impersonateToken}`
    } else if (ssoToken) {
      Authorization = `Bearer sso=${ssoToken}`
    }

    let { body } = settings

    if (body && get(settings.headers, 'enctype') !== contentTypes.FORM) {
      body = JSON.stringify(body)
      contentType = {
        'Content-Type': DEFAULT_CONTENT_TYPE,
      }
    }

    const requestSettings = {
      responseType: '*',
      ...settings,
      body,
      headers: {
        Accept: `application/json; version=${API_VERSION}`,
        ...contentType,
        Authorization,
        env: SENSAI_ENVIRONMENT,
        ...(settings.headers || {}),
      },
      url: settings.baseURL + settings.endpoint,
    }

    // Log headers for debugging

    return this.ajax$(requestSettings, onSuccess, onError)
  }

  /**
   * @description Encrypts Request
   * @param {object} settings
   * @returns {object} settings with encrypted body (if it has encryption)
   */
  encryptRequest(settings) {
    if (API_RESPONSE_HAS_ENCRYPTION && settings.body && settings.body.constructor !== FormData) {
      const settingsEncrypt = {
        body: this.crypto.encrypt(settings.body),
        headers: {
          'Content-Type': contentTypes.TEXT_PLAIN,
        },
      }

      return merge({}, settings, settingsEncrypt)
    }
    return settings
  }

  /**
   * @description Decrypt response
   * @param {AjaxResponse} ajaxResponse - Pipe response
   * @throws {Observable}
   * @returns {AjaxResponse} Camel case decrypted response
   */
  decryptResponse(ajaxResponse) {
    const { response, status, xhr } = ajaxResponse

    const contentType = xhr.getResponseHeader('Content-Type')

    if (response === null) {
      throw new Error('There is not valid response')
    }

    if (contentTypesRegex.TEXT_PLAIN.test(contentType)) {
      return {
        ...toCamelCase(this.crypto.decrypt(response)),
        _status: status,
      }
    }
    if (contentTypesRegex.APPLICATION_JSON.test(contentType)) {
      let jsonResponse = null
      try {
        jsonResponse = toCamelCase(JSON.parse(response))
      } catch (error) {
        if (DEVELOPMENT) {
          console.log('Server is returning an invalid JSON')
        }
        jsonResponse = {}
      }
      return {
        ...jsonResponse,
        _status: status,
      }
    }

    return response
  }

  /**
   * @description Creates an observable for an Ajax request with either a settings object with url,
   * headers, etc or a string for a URL.
   * @param {object} - settings - An object with the request settings.
   * @param {Boolean} settings.async - Whether the request is async. The default is true.
   * @param {Object} settings.body - Optional body
   * @param {Boolean} settings.crossDomain - true if to use CORS, else false. The default is false.
   * @param {Boolean} settings.withCredentials - true if to use CORS withCredentials, else false.
   * The default is false.
   * @param {Object} settings.headers - Optional headers
   * @param {String} settings.method - Method of the request, such as GET, POST, PUT, PATCH, DELETE.
   * The default is GET.
   * @param {String} settings.password - The password for the request.
   * @param {Observer} settings.progressObserver - An optional Observer which listen to XHR2
   * progress events or error timeout values.
   * @param {String} settings.responseType - The response type. Either can be 'json', 'text' or
   * 'blob'. The default is 'text'
   * @param {Number} settings.timeout - Number - a number representing the number of milliseconds a
   * request can take before automatically being terminated. A value of 0 (which is the default)
   * means there is no timeout.
   * @param {String} settings.url - URL of the request
   * @param {String} settings.user - The user for the request
   * @param {function} onSuccess
   * @param {function} onError
   * {@link https://github.com/Reactive-Extensions/RxJS-DOM/blob/master/doc/operators/ajax.md}
   */
  ajax$(settings, onSuccess, onError) {
    return Observable.ajax(settings)
      .retryWhen(this.retryWhen.bind(this))
      .map(response => this.decryptResponse(response))
      .subscribe(
        response => {
          if (this.clearTimeout) {
            this.api.clearTimeout()
          } else {
            this.setTimeout()
          }
          if (this.afterResponse) {
            this.afterResponse(response, onSuccess)
          } else if (onSuccess) {
            onSuccess(response)
          }
        },
        error => {
          const { errorCode = '' } = error
          switch (errorCode) {
            case NO_RESULTS:
              this.setTimeout()
              onSuccess({})
              break
            case RATE_LIMIT:
              onSuccess({})
              this.api.rateLimit()
              break
            case SESSION_EXPIRED:
              this.api.dispatchExpireSession()
              break
            case CAN_NOT_CONNECT:
              this.api.dispatchConnectionError()
              onError({ error, isConnectionError: true })
              break
            default:
              onError({ error })
          }
        },
      )
  }
}
