import { formatRoute } from 'react-router-named-routes'
import { replace } from 'connected-react-router'

import { NETWORK } from 'urls'
import { sortModules } from 'helpers'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/network/constants'
import api from 'api'

// Actions

export const fetchThirdPersonProfileFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_THIRD_PERSON_PROFILE_FAILURE,
})

export const fetchThirdPersonProfileSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_THIRD_PERSON_PROFILE_SUCCESS,
})

export const fetchThirdPersonProfileRequest = () => ({
  type: actionTypes.FETCH_THIRD_PERSON_PROFILE_REQUEST,
})

// Thunks

export const fetchThirdPersonProfile = (options, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchThirdPersonProfileRequest())

    const handleResponse = response => {
      dispatch(
        fetchThirdPersonProfileSuccess({
          ...response,
          dashboardModules: sortModules(response.dashboardModules),
        }),
      )

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchThirdPersonProfileFailure(error))
      dispatch(replace(formatRoute(NETWORK, { accountId })))

      if (onError) {
        onError(error)
      }
    }

    return api.contentManager.getContent({ accountId, ...options }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_THIRD_PERSON_PROFILE

  return thunk
}

export default fetchThirdPersonProfile
