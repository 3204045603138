import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import { sortModules } from 'helpers'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/bundles/constants'
import api from 'api'

// Actions

export const fetchBundleDetailsFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_BUNDLE_DETAILS_FAILURE,
})

export const fetchBundleDetailsRequest = () => ({
  type: actionTypes.FETCH_BUNDLE_DETAILS_REQUEST,
})

export const fetchBundleDetailsSuccess = request => ({
  payload: request,
  type: actionTypes.FETCH_BUNDLE_DETAILS_SUCCESS,
})

// Thunks

export const fetchBundleDetails = (bundleId, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchBundleDetailsRequest())

    const handleResponse = response => {
      dispatch(
        fetchBundleDetailsSuccess({
          ...response,
          bundleId,
          modules: sortModules(response.modules),
        }),
      )

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchBundleDetailsFailure(error))
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'fetchBundleDetails' })))
      }

      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.bundles.getBundleAllocationDetails({ accountId, bundleId }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_BUNDLES

  return thunk
}

export default fetchBundleDetails
