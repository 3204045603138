import { applyTrackPolyfill } from 'helpers'
import { autobind } from 'core-decorators'
import { Col, Container, Row } from 'reactstrap'
import { filter, find, findIndex, get, isEmpty, size } from 'lodash'
import { formatRoute } from 'react-router-named-routes'
import { sprintf } from 'sprintf-js'
import classnames from 'classnames'
import React, { Component } from 'react'

import { ACTIONS, HISTORY, I18N, MATCH, TRAININGS } from 'constants/props'
import { BarError } from 'classes/errors'
import { StyledButton } from 'generics/StyledFormComponents'
import { TRAINING_CENTER } from 'constants/urls'
import Breadcrumbs from 'generics/Breadcrumbs'
import Icon from 'generics/Icon'
import ProgressBar from 'generics/ProgressBar'
import Translation from 'generics/Translation'
import Video from 'generics/Video'

import Exercises from './components/Exercises'
import Header from './components/Header'
import styles from './PageTraining.scss'

const { PUBLIC_PATH } = process.env
const IMG_PATH = `${window.location.origin}${PUBLIC_PATH}training`

/**
 * @class PageTraining
 * @description Training Page
 */
class PageTraining extends Component {
  constructor(props) {
    super(props)

    const selectedVideoIndex = 0
    this.state = { selectedVideoIndex }
  }

  componentDidMount() {
    const { trainings } = this.props

    if (isEmpty(trainings)) {
      const { trainingActions } = this.props
      trainingActions.fetchVideos()
    }

    applyTrackPolyfill()
  }

  componentDidUpdate() {
    applyTrackPolyfill()
  }

  @autobind
  getTrainingInfo() {
    const { trainings, match } = this.props

    const { trainingName } = match.params

    return find(trainings, { name: trainingName }) || {}
  }

  @autobind
  handleChange(index) {
    this.setState({ selectedVideoIndex: index }, () => {
      this.reloadVideo()
    })
  }

  @autobind
  reloadVideo() {
    if (this.video && this.video.videoEl) {
      this.video.videoEl.load()
    }
  }

  @autobind
  handleEnded(videoName) {
    const training = this.getTrainingInfo()
    const { trainingActions } = this.props
    const videoIndex = findIndex(training.videos, { videoName })
    const selectedVideoIndex = videoIndex + 1
    const { isCompleted } = training.videos[videoIndex]

    const onFinish = () => {
      if (selectedVideoIndex < size(training.videos)) {
        this.setState({ selectedVideoIndex }, () => {
          this.reloadVideo()
        })
      }
    }

    if (!isCompleted) {
      trainingActions.fetchCompleteVideo({ trainingName: training.name, videoName }, onFinish)
    } else {
      onFinish()
    }
  }

  renderVideoList() {
    const { selectedVideoIndex } = this.state

    const { i18n } = this.props

    const { name, videos } = this.getTrainingInfo()

    return videos.map(({ isCompleted }, index) => {
      const videoName = get(i18n.training, `trainings[${name}].videos[${index}].name`, `Video ${index}`)
      const isActive = index === selectedVideoIndex
      const key = `video-${index}`

      return (
        <li className={classnames({ [styles.active]: isActive })} key={key}>
          <StyledButton
            classes={{ root: styles['video-link'], label: styles['video-link__label'] }}
            fullWidth
            onClick={() => this.handleChange(index)}
            title={`Play video ${videoName}`}
            variant="text"
          >
            <Icon.Stroke7
              className={classnames(styles['video-link__icon'], {
                [styles['video-link__icon--completed']]: isCompleted,
              })}
              name="check"
            />
            <span
              className={classnames(styles['video-link__text'], { [styles['video-link__text--active']]: isActive })}
            >
              {videoName}
            </span>
          </StyledButton>
        </li>
      )
    })
  }

  render() {
    const { selectedVideoIndex } = this.state

    const { history, i18n, match, notificationActions } = this.props

    const { accountId: accountIdStr } = match.params

    const { isDisabled: isTrainingDisabled, name: trainingName, videos } = this.getTrainingInfo()

    if (isTrainingDisabled || !videos) {
      notificationActions.notifyError(new BarError({ label: 'invalidAddress' }))
      history.go(-1)
      return null
    }

    const accountId = parseInt(accountIdStr, 10)
    const videosCount = size(videos)
    const completedVideos = size(filter(videos, { isCompleted: true }))
    const selectedVideo = videos[selectedVideoIndex]
    const selectedVideoName = get(i18n.training, `trainings[${trainingName}].videos[${selectedVideoIndex}].name`, '')

    const breadcrumsList = [
      {
        label: i18n.training.trainingCenter,
        to: formatRoute(TRAINING_CENTER, { accountId }),
      },
      {
        label: i18n.training.trainings[trainingName].subtitle,
      },
    ]

    return (
      <Container>
        <Breadcrumbs className={styles.breadcrums} linkList={breadcrumsList} />
        <Header trainingName={trainingName} />
        <Row className={styles['progress-info']}>
          <Col xs="12" md="4" lg="3">
            <span className={styles.counter}>{sprintf(i18n.training.videosCount, videosCount)}</span>
          </Col>
          <Col xs="12" md="8" lg="9">
            <ProgressBar className={styles['progress-bar']} max={videosCount} value={completedVideos} testAttributePercentage="percentage-completed" />
          </Col>
        </Row>
        <Row className={styles.videos}>
          <Col xs="12" md="4" lg="3">
            <ul className={styles['video-list']}>{this.renderVideoList()}</ul>
          </Col>
          <Col xs="12" md="8" lg="9">
            <div className={styles['video-wrapper']}>
              <h4>{selectedVideoName}</h4>
              <Video
                autoPlay
                hasCaptions
                iconType="play"
                onEnded={() => this.handleEnded(selectedVideo.videoName)}
                poster={`${IMG_PATH}/${trainingName}/video_${selectedVideoIndex + 1}.png`}
                ref={element => {
                  this.video = element
                }}
              >
                <source src={selectedVideo.videoUrl} type="video/mp4" />
                <track
                  default
                  kind="captions"
                  label={i18n.training.captions.english}
                  src={selectedVideo.captionUrl}
                  srcLang="en"
                />
              </Video>
              <Exercises trainingName={trainingName} videoIndex={selectedVideoIndex} />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

PageTraining.propTypes = {
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  match: MATCH.isRequired,
  notificationActions: ACTIONS.isRequired,
  trainingActions: ACTIONS.isRequired,
  trainings: TRAININGS.isRequired,
}

export default Translation(PageTraining, ['training'])
