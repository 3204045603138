import { autobind } from 'core-decorators'
import { bindActionCreators } from 'redux'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { I18N, OWNERS } from 'constants/props'
import { Header, Main } from 'generics/Card'
import * as accountActions from 'app_modules/accounts/actions'
import * as networkActions from 'app_modules/network/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import * as teamsActions from 'app_modules/teams/actions'
import * as teamsSelectors from 'app_modules/teams/selectors'
import Modal from 'generics/Modal'
import Translation from 'generics/Translation'

import FormAssignTo from '../FormAssignTo/FormAssignTo'
import styles from './ModalAssign.scss'

/**
 * @description: Create / Edit Team Modal Component
 * @extends Component
 */
class ModalAssign extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  /**
   * @description: Handles close modal event
   */
  @autobind
  handleCloseModal() {
    const { onClose } = this.props
    onClose()
  }

  render() {
    const { i18n, onSubmit, owners } = this.props

    const { isFetching } = this.state

    return (
      <Modal
        card={{
          barClassName: styles.bar,
          fullHeight: true,
          isFetching,
        }}
        onClose={this.handleCloseModal}
      >
        <Header>
          <h1 title={i18n.pageTokenAdministration.ownerChange.reAssignModal.title}>
            {i18n.pageTokenAdministration.ownerChange.reAssignModal.title}
          </h1>
        </Header>

        <Main>
          <Container fluid>
            <p className={styles.description}>{i18n.pageTokenAdministration.ownerChange.reAssignModal.description}</p>

            <FormAssignTo className={styles.form} onSubmit={onSubmit} owners={owners} />
          </Container>
        </Main>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  profile: sessionSelectors.currentProfile(state),
  team: teamsSelectors.team(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...accountActions,
      ...networkActions,
      ...teamsActions,
    },
    dispatch,
  ),
})

ModalAssign.propTypes = {
  i18n: I18N.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  owners: OWNERS.isRequired,
}

export default Translation(connect(mapStateToProps, mapDispatchToProps)(ModalAssign), [
  'pageTokenAdministration',
  'generics',
])
