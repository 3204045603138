import { Card, Main } from 'generics/Card'
import { Col, Row } from 'reactstrap'
import { filter, kebabCase, size } from 'lodash'
import { formatRoute } from 'react-router-named-routes'
import { Link } from 'react-router-dom'
import { sprintf } from 'sprintf-js'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N, TRAINING_VIDEOS } from 'constants/props'
import { TRAINING } from 'constants/urls'
import ProgressBar from 'generics/ProgressBar'
import Translation from 'generics/Translation'

import styles from './TrainingCard.scss'

const { PUBLIC_PATH } = process.env

const IMG_PATH = `${window.location.origin}${PUBLIC_PATH}training`

/**
 * @class TrainingCard
 * @description receives SSO redirection once backend assertion is successful
 */
const TrainingCard = ({ accountId, i18n, isFirst, name, videos }) => {
  const videosCount = size(videos)
  const categoryRoute = formatRoute(TRAINING, { accountId, trainingName: name })
  const videosCompleted = size(filter(videos, { isCompleted: true }))

  return (
    <Card barClassName={styles.bar}>
      <Main className={styles.main}>
        <Row>
          <Col xs="12" md="4">
            <div className={styles['img-container']}>
              <img
                alt={i18n.training.trainings[name].subtitle}
                className={styles.image}
                src={`${IMG_PATH}/${name}/${name}_sm.png`}
              />
            </div>
          </Col>
          <Col xs="12" md="8">
            <div className={styles.content}>
              <h4>{i18n.training.trainings[name].subtitle}</h4>
              <span className={styles.counter}>{sprintf(i18n.training.videosCount, videosCount)}</span>
              <p>{i18n.training.trainings[name].description}</p>
              <ProgressBar
                completedOfText={i18n.training.completedOf}
                className={styles['progress-bar']}
                max={videosCount}
                value={videosCompleted}
                testAttributePercentage="percentage-completed"
                testAttributeCompleted="videos-watched"
              />
              <Link
                aria-label={`${sprintf(i18n.training.launchTrainingType, {
                  trainingType: i18n.training.trainings[name].title,
                })}. ${i18n.training.trainings[name].subtitle}`}
                className={classnames(
                  styles.link,
                  `${kebabCase(
                    sprintf(i18n.training.launchTrainingType, { trainingType: i18n.training.trainings[name].title }),
                  )}`,
                )}
                href={categoryRoute}
                id={isFirst ? 'main' : null}
                title={`${sprintf(i18n.training.launchTrainingType, {
                  trainingType: i18n.training.trainings[name].title,
                })}`}
                to={categoryRoute}
              >
                {i18n.training.launchTraining}
              </Link>
            </div>
          </Col>
        </Row>
      </Main>
    </Card>
  )
}

TrainingCard.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  isFirst: PropTypes.bool,
  name: PropTypes.string.isRequired,
  videos: TRAINING_VIDEOS,
}

TrainingCard.defaultProps = {
  isFirst: false,
  videos: [],
}

export default Translation(TrainingCard, ['training'])
