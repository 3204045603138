/**
 * @fileoverview Processing reducer for the SensaiAI module
 * @module sensaiAi/reducers/processingReducer
 */

import { PROCESSING } from '../constants/actionTypes'

/**
 * @typedef {Object} ProcessingState
 * @property {string|null} requestId - Current request ID
 * @property {boolean} isProcessing - Processing state flag
 * @property {boolean} isComplete - Completion state flag
 */

/**
 * @type {ProcessingState}
 */
const initialState = {
  requestId: null,
  isProcessing: false,
  isComplete: false,
}

/**
 * Processing reducer
 * @param {ProcessingState} state - Current state
 * @param {Object} action - Action object
 * @returns {ProcessingState} New state
 */
const processingReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROCESSING.SET_REQUEST_ID:
      return {
        ...state,
        requestId: action.payload,
      }

    case PROCESSING.SET_STATUS:
      return {
        ...state,
        isProcessing: action.payload,
        isComplete: false,
      }

    case PROCESSING.SET_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        isComplete: true,
      }

    default:
      return state
  }
}

export default processingReducer
