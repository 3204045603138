import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import * as invitesActions from 'app_modules/invites/actions'
import Invitation from 'components/PageInvitation'

const mapStateToProps = state => ({
  isAuthenticated: state.session.isAuthenticated,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(invitesActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invitation))
