import { combineReducers } from 'redux'

import initialState from 'app_modules/store/initialState.json'
import profilesActionsTypes from 'app_modules/profile/constants'
import sessionActionTypes from 'app_modules/session/constants'
import userActionTypes from 'app_modules/user/constants'

export const error = (state = initialState.session.error, action) => {
  const { type: actionType, payload } = action

  switch (actionType) {
    case sessionActionTypes.FETCH_LOGIN_REQUEST:
    case sessionActionTypes.RESET_STATE:
      return null

    case sessionActionTypes.FETCH_HOME_FAILURE:
    case sessionActionTypes.FETCH_LOGIN_FAILURE:
    case sessionActionTypes.FETCH_LOGOUT_FAILURE:
    case sessionActionTypes.FETCH_CATEGORY_BY_KEY_FAILURE:
      return payload

    default:
      return state
  }
}

export const expirationMessage = (state = initialState.session.expirationMessage, { type, payload }) => {
  switch (type) {
    case sessionActionTypes.EXPIRE_SESSION_REQUEST:
      return payload

    case profilesActionsTypes.FETCH_CREATE_USER_REQUEST:
    case sessionActionTypes.EXPIRE_SESSION_RESET:
    case sessionActionTypes.FETCH_LOGIN_REQUEST:
      return null

    case sessionActionTypes.RESET_STATE:
      return initialState.session.expirationMessage

    default:
      return state
  }
}

export const isAuthenticated = (state = initialState.session.isAuthenticated, action) => {
  switch (action.type) {
    case sessionActionTypes.FETCH_LOGIN_SUCCESS:
    case sessionActionTypes.FETCH_IMPERSONATE_SUCCESS:
    case sessionActionTypes.FETCH_SSO_LOGIN_SUCCESS:
      return true

    case sessionActionTypes.FETCH_LOGOUT_REQUEST:
    case sessionActionTypes.EXPIRE_SESSION_REQUEST:
      return false

    case userActionTypes.FETCH_ACCOUNT_FAILURE_BY_EXPIRATION:
      return !!action.payload.find(({ expiresAt }) => new Date(expiresAt) > new Date())

    default:
      return state
  }
}

export const isFetching = (state = initialState.session.isFetching, action) => {
  switch (action.type) {
    case sessionActionTypes.FETCH_LOGIN_REQUEST:
    case sessionActionTypes.FETCH_LOGOUT_REQUEST:
      return true

    case sessionActionTypes.FETCH_LOGIN_FAILURE:
    case sessionActionTypes.FETCH_LOGIN_SUCCESS:
    case sessionActionTypes.FETCH_LOGOUT_FAILURE:
    case sessionActionTypes.FETCH_LOGOUT_SUCCESS:
    case sessionActionTypes.FETCH_IMPERSONATE_SUCCESS:
      return false

    case sessionActionTypes.RESET_STATE:
      return initialState.session.isFetching

    default:
      return state
  }
}

export default combineReducers({
  error,
  expirationMessage,
  isAuthenticated,
  isFetching,
})
