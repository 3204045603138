export const en = {
  createTeam: {
    confirmation: {
      acceptLabel: 'Create Team',
      messages: [
        'Your team will be created as Draft and only visible to you.',
        'You can publish your team to make it visible to all of its members from the Team Details box in the Team page.',
      ],
      title: 'Confirm Team Creation',
    },
    description: {
      createTeam: 'Please fill in your new team information',
      editTeam: 'Edit your team information',
    },
    instructions:
      'Add members to your team by selecting them from the list below (only members of your network will be displayed on the list)',
    list: {
      emptyMessage: 'No members added yet',
      listATitle: 'Network',
      listBTitle: 'In this team',
    },
    teamMembers: 'Team Members',
    title: {
      createTeam: 'Create Team',
      editTeam: 'Edit Team',
    },
  },
  sections: {
    corporate: {
      description: "These are teams that have been shared with you by your organization's leadership team.",
      title: 'Corporate Teams',
    },
    others: {
      description:
        'These are teams that you are a member of that were created by someone else. The team owner published this team to all the team members.',
      title: 'Teams Created by Others',
    },
    private: {
      description:
        'These are private teams that you created. You can share these teams with the other team members by publishing the team.',
      title: 'My Private Teams',
    },
    published: {
      description: 'These are teams that you created that have been shared with the other team members.',
      title: 'My Published Teams',
    },
  },
  teamList: {
    btnCreateTeam: 'New Team',
    btnDeleteTeam: 'Delete Team',
    deleteConfirmationMessage: 'Are you sure that you want to permanently delete the team? There is no Undo',
    lblSearch: 'Find a Team',
    listItem: {
      avatarTitle: '%s Avatar',
      draft: 'Draft',
      privateTeam: 'Private Team',
      publicTeam: 'Public Team',
      teamOwner: 'Team Owner',
    },
  },
}

export const es = en
