export const DAILY = 0
export const NEVER = 2
export const WEEKLY = 1

export const NOT_APPLIED_FREQUENCY_TYPE = 11

export const FREQUENCIES = {
  [DAILY]: [0, 1, 2, 3],
  [WEEKLY]: [4, 5, 6, 7, 8, 9, 10],
  [NEVER]: [NOT_APPLIED_FREQUENCY_TYPE],
}
