import { autobind } from 'core-decorators'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import styles from '../assessment.scss'

class WordPairs extends Component {
  componentDidMount() {
    this.resetTime()
  }

  componentDidUpdate() {
    this.resetTime()
  }

  @autobind
  resetTime() {
    this.startTime = new Date().getTime()
  }

  @autobind
  handleClick(response) {
    const { onClick } = this.props
    const duration = new Date().getTime() - this.startTime
    onClick({ ...response, duration })
  }

  render() {
    const { disable, question } = this.props || {}
    const { leftWordPair, rightWordPair, id } = question || {}
    const isMediumButton = leftWordPair?.text?.length > 13 || rightWordPair?.text?.length > 13
    const isLargeButton = leftWordPair?.text?.length > 16 || rightWordPair?.text?.length > 16
    const isExtraLargeButton = leftWordPair?.text?.length > 28 || rightWordPair?.text?.length > 28
    const isSuperExtraLargeButton = leftWordPair?.text?.length > 38 || rightWordPair?.text?.length > 38

    return (
      <div>
        <button
          className={classnames(
            styles.button,
            { [styles['button--medium']]: isMediumButton },
            { [styles['button--large']]: isLargeButton },
            { [styles['button--extra-large']]: isExtraLargeButton },
            { [styles['button--super-extra-large']]: isSuperExtraLargeButton },
          )}
          disabled={disable}
          onClick={() => {
            this.handleClick({ selectedWordPairId: leftWordPair.id, id })
          }}
          type="button"
        >
          {leftWordPair.text}
        </button>
        <button
          className={classnames(
            styles.button,
            { [styles['button--medium']]: isMediumButton },
            { [styles['button--large']]: isLargeButton },
            { [styles['button--extra-large']]: isExtraLargeButton },
            { [styles['button--super-extra-large']]: isSuperExtraLargeButton },
          )}
          disabled={disable}
          onClick={() => {
            this.handleClick({ selectedWordPairId: rightWordPair.id, id })
          }}
          type="button"
        >
          {rightWordPair.text}
        </button>
      </div>
    )
  }
}

WordPairs.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default WordPairs
