import { pickHTMLProps } from 'pick-react-known-prop'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { CHILDREN } from 'constants/props'

import styles from './mask.scss'

const Mask = ({ children, className, fixed, open, ...rest }) => (
  <div
    className={classnames(styles.mask, { [styles['is-open']]: open }, { [styles['is-fixed']]: fixed }, className)}
    {...pickHTMLProps(rest)}
  >
    <div className={styles.content}>{children}</div>
  </div>
)

Mask.propTypes = {
  children: CHILDREN,
  className: PropTypes.string,
  fixed: PropTypes.bool,
  open: PropTypes.bool.isRequired,
}

Mask.defaultProps = {
  children: null,
  className: null,
  fixed: false,
}

export default Mask
