import { Col, Container, Row } from 'reactstrap'
import { get } from 'lodash'
import classnames from 'classnames'
import diff from 'object-diff'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { Card, Header, Main } from 'generics/Card'
import { ACTIONS, I18N, PERSON } from 'constants/props'
import { StyledButton } from 'generics/StyledFormComponents'
import api from 'api'
import DefinitionList from 'generics/DefinitionList'
import FormAccountInformation from 'generics/FormAccountInformation'
import FormAccountInformationEditEmail from 'generics/FormAccountInformationEditEmail'
import FormValidateCode from 'generics/FormValidateCode'
import genderPronouns from 'constants/genderPronouns'
import Modal from 'generics/Modal'
import Translation from 'generics/Translation'
import { CHANNEL_PARTNER_ACCOUNT_TYPE } from 'constants/accountTypes'

import styles from './AccountInformation.scss'

export const useAccountInformationElements = ({
  i18n,
  isLearningAccount,
  isThirdPersonProfile,
  onSubmitForm,
  profile,
  isChannelPartner,
}) => {
  const [countries, setCountries] = useState([])
  const [isEditEmailOpen, setIsEditEmailOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isValidateCodeOpen, setIsValidateCodeOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [newEmail, setNewEmail] = useState(null)

  useEffect(() => {
    const handleResponse = ({ countries: newCountries }) => {
      setIsLoading(false)
      setCountries(newCountries)
    }

    api.countries.getList(null, handleResponse, () => {
      setIsLoading(false)
    })
  }, [])

  const onGetData = () => {
    const {
      account,
      department,
      division,
      email,
      fullAddress,
      genderPronounPreference,
      jobTitle,
      mobilePhone,
      name,
      workPhone,
    } = profile

    if (!account) {
      return []
    }

    const { isPersonal, name: companyName } = account

    const { gender = 'None' } = genderPronouns.find(({ value }) => value === genderPronounPreference) || {}

    const sectionsData = [
      {
        fieldList: [
          {
            children: name,
            label: i18n.profileDetail.personalInformation.labels.name,
          },
          {
            children: gender,
            label: i18n.profileDetail.personalInformation.labels.genderPronoun,
          },
          {
            children: email,
            label: (
              <>
                {i18n.profileDetail.personalInformation.labels.email}
                {isThirdPersonProfile && !isChannelPartner && (
                  <StyledButton
                    className={styles.edit}
                    color="secondary"
                    onClick={() => setIsEditEmailOpen(true)}
                    title={i18n.myProfile.accountInformation.editEmailLabel}
                    variant="text"
                  >
                    {i18n.myProfile.accountInformation.editEmailLabel}
                  </StyledButton>
                )}
              </>
            ),
          },
          {
            children: mobilePhone,
            label: i18n.profileDetail.personalInformation.labels.mobilePhone,
          },
          {
            children: workPhone,
            label: i18n.profileDetail.personalInformation.labels.workPhone,
          },
          {
            children: fullAddress,
            label: i18n.profileDetail.personalInformation.labels.address,
          },
        ],
        subTitle: i18n.profileDetail.personalInformation.title,
      },
    ]

    if (!isPersonal) {
      sectionsData.push({
        fieldList: [
          {
            children: jobTitle,
            label: i18n.profileDetail.employeeInformation.labels.jobTitle,
          },
          {
            children: companyName,
            label: !isLearningAccount
              ? i18n.profileDetail.employeeInformation.labels.company
              : i18n.profileDetail.studentInformation.labels.organization,
          },
          department && {
            children: department,
            label: i18n.profileDetail.employeeInformation.labels.department,
          },
          {
            children: division,
            label: i18n.profileDetail.employeeInformation.labels.division,
          },
        ],
        subTitle: !isLearningAccount
          ? i18n.profileDetail.employeeInformation.title
          : i18n.profileDetail.studentInformation.title,
      })
    }
    return sectionsData
  }

  /**
   *  Handles open / close events on Edit Form
   */
  const handleOpenCloseEditForm = () => {
    setIsEditing(!isEditing)
  }

  /**
   * Handles Submit Form event
   */
  const handleSubmit = newValues => {
    if (!onSubmitForm) {
      return
    }

    const profileToSave = diff(profile, newValues)

    setIsLoading(true)

    const onSuccess = () => {
      setIsEditing(false)
      setIsLoading(false)
    }

    onSubmitForm({ id: newValues.id, ...profileToSave }, onSuccess, () => {
      setIsLoading(false)
    })
  }

  return {
    form: {
      countries,
      isEditing,
      onOpenCloseForm: handleOpenCloseEditForm,
      onSubmit: handleSubmit,
    },
    onGetData,
    isLoading,
    isEditEmailOpen,
    isValidateCodeOpen,
    newEmail,
    setIsEditEmailOpen,
    setIsValidateCodeOpen,
    setNewEmail,
  }
}

/**
 *  AccountInformation component
 */
const AccountInformation = ({
  canEdit,
  className,
  i18n,
  isFetching,
  isLearningAccount,
  isThirdPersonProfile,
  notificationActions,
  onAdminUpdateEmail,
  onSubmitForm,
  profile,
  title,
  userActions,
}) => {
  const {
    account: { id: accountId, accountType },
    id: profileId,
  } = profile || {}

  const isChannelPartner = accountType === CHANNEL_PARTNER_ACCOUNT_TYPE

  const {
    form,
    isEditEmailOpen,
    isValidateCodeOpen,
    isLoading,
    newEmail,
    onGetData,
    setIsEditEmailOpen,
    setIsValidateCodeOpen,
    setNewEmail,
  } = useAccountInformationElements({
    i18n,
    isLearningAccount,
    isThirdPersonProfile,
    onSubmitForm,
    profile,
    profileId,
    isChannelPartner,
  })

  /**
   * Handle admin update email
   */

  const handleAdminUpdateEmail = email => {
    setIsEditEmailOpen(false)
    onAdminUpdateEmail({ ...profile, email })
    notificationActions.notifySuccess(i18n.myProfile.accountInformation.emailUpdatedLabel)
  }

  /**
   * Handle open validation modal
   */

  const handleValidateCode = email => {
    setIsValidateCodeOpen(true)
    setIsEditEmailOpen(false)
    setNewEmail(email)
  }

  /**
   * Handle set new email profile
   */

  const handleUpdateEmail = email => {
    notificationActions.notifySuccess(i18n.myProfile.accountInformation.emailUpdatedLabel)
    userActions.updateCurrentProfile({ ...profile, email })
    setIsValidateCodeOpen(false)
  }

  /**
   * Renders Form Fields
   */
  const renderFields = () => (
    <div className={className}>
      {onGetData().map(infoSection => {
        const { fieldList, subTitle } = infoSection
        return (
          <div className={styles['info-section']} key={subTitle}>
            <h2 className={styles['sub-title']}>{subTitle}</h2>
            <div className={styles['field-list']}>
              <DefinitionList className={styles['definition-list']} data={fieldList} />
            </div>
          </div>
        )
      })}
    </div>
  )

  /**
   * Renders Form
   */
  const renderForm = () => {
    const initialValues = profile.clone({ countryId: get(profile.country, 'id', null) })
    return form.isEditing ? (
      <FormAccountInformation
        updateEmailAction={
          <StyledButton
            className={styles.edit}
            color="secondary"
            onClick={() => setIsEditEmailOpen(true)}
            title={i18n.myProfile.accountInformation.editEmailLabel}
            variant="text"
          >
            {i18n.myProfile.accountInformation.editEmailLabel}
          </StyledButton>
        }
        initialValues={initialValues}
        isLearningAccount={isLearningAccount}
        onSubmit={form.onSubmit}
        countriesList={form.countries}
        isChannelPartner={isChannelPartner}
      />
    ) : (
      renderFields()
    )
  }

  /**
   * Renders Form
   */
  const renderFormEditEmail = () => {
    const { email } = profile.clone({ countryId: get(profile.country, 'id', null) })
    return (
      <FormAccountInformationEditEmail
        accountId={accountId}
        initialValues={{ email }}
        isLearningAccount={isLearningAccount}
        isThirdPersonProfile={isThirdPersonProfile}
        notificationActions={notificationActions}
        onFinish={newEmailParam =>
          !isThirdPersonProfile ? handleValidateCode(newEmailParam) : handleAdminUpdateEmail(newEmailParam)
        }
        profileId={profileId}
      />
    )
  }

  /**
   * Renders Form
   */
  const renderFormValidateCode = () => (
    <FormValidateCode
      accountId={accountId}
      isLearningAccount={isLearningAccount}
      newEmail={newEmail}
      notificationActions={notificationActions}
      onFinish={email => handleUpdateEmail(email)}
    />
  )

  const buttonMessage = form.isEditing
    ? i18n.myProfile.accountInformation.header.cancelButtonLabel
    : i18n.myProfile.accountInformation.header.editButtonLabel

  const headerActions = [
    <StyledButton
      className={styles.edit}
      color="secondary"
      onClick={() => form.onOpenCloseForm()}
      title={buttonMessage}
      variant="text"
    >
      {buttonMessage}
    </StyledButton>,
  ]

  return (
    <>
      <Card className={classnames(styles['card-container'], className)} isFetching={isFetching || isLoading}>
        <Header actions={canEdit && headerActions} className={styles.header} title={title} />

        <Main>
          <Container>
            <Row>
              <Col>{renderForm()}</Col>
            </Row>
          </Container>
        </Main>
      </Card>
      {isEditEmailOpen && (
        <Modal
          card={{
            isFetching,
          }}
          modalCardClassName={styles.modal}
          onClose={() => setIsEditEmailOpen(false)}
          switchModalView={false}
          title={i18n.myProfile.accountInformation.editEmailLabel}
        >
          {renderFormEditEmail()}
        </Modal>
      )}
      {isValidateCodeOpen && (
        <Modal
          card={{
            isFetching,
          }}
          modalCardClassName={styles.modal}
          onClose={() => {
            setIsValidateCodeOpen(false)
            setNewEmail(null)
          }}
          switchModalView={false}
          title={i18n.myProfile.accountInformation.editEmailLabel}
        >
          {renderFormValidateCode()}
        </Modal>
      )}
    </>
  )
}

AccountInformation.propTypes = {
  canEdit: PropTypes.bool,
  className: PropTypes.string,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool,
  isLearningAccount: PropTypes.bool,
  isThirdPersonProfile: PropTypes.bool,
  notificationActions: ACTIONS.isRequired,
  onAdminUpdateEmail: PropTypes.func,
  onSubmitForm: PropTypes.func,
  profile: PERSON.isRequired,
  title: PropTypes.string,
  userActions: ACTIONS.isRequired,
}

AccountInformation.defaultProps = {
  canEdit: false,
  className: null,
  isFetching: false,
  isLearningAccount: false,
  isThirdPersonProfile: false,
  onAdminUpdateEmail: null,
  onSubmitForm: null,
  title: null,
}

export default Translation(AccountInformation, ['profileDetail', 'myProfile'])
