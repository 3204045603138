import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/accounts/constants'
import api from 'api'

// Actions
/**
 * failure action dispatched when there is an error updating the logo
 */
export const updateLogoFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_UPDATE_LOGO_FAILURE,
})

/**
 * update logo request action dispatched
 */
export const updateLogoRequest = () => ({
  type: actionTypes.FETCH_UPDATE_LOGO_REQUEST,
})

/**
 * Success action dispatched when updating the logo
 */
export const updateLogoSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_UPDATE_LOGO_SUCCESS,
})

// Thunks

export const updateLogo = logo => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())
    dispatch(updateLogoRequest())

    const handleResponse = response => dispatch(updateLogoSuccess(response))

    const handleError = ({ error, isConnectionError }) => {
      dispatch(updateLogoFailure(error))
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'updateLogo' })))
      }
    }

    return api.accounts.updateLogo({ accountId, logo }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_UPDATE_LOGO

  return thunk
}

export default updateLogo
