import { CORPORATE_TEAMS } from 'constants/teamSections'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/teams/constants'
import api from 'api'

// Actions

export const fetchCorporateTeamsFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_CORPORATE_TEAMS_FAILURE,
})

export const fetchCorporateTeamsRequest = pageIndex => ({
  payload: pageIndex,
  type: actionTypes.FETCH_CORPORATE_TEAMS_REQUEST,
})

export const fetchCorporateTeamsSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_CORPORATE_TEAMS_SUCCESS,
})

// Thunks

export const fetchCorporateTeams = ({ pageIndex, search }, onSuccess) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchCorporateTeamsRequest(pageIndex))

    const handleResponse = response => {
      dispatch(fetchCorporateTeamsSuccess(response))

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchCorporateTeamsFailure(error))
      throw error
    }

    return api.teams.getTeamList(
      { accountId, pageIndex, search, sectionName: CORPORATE_TEAMS },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_CORPORATE_TEAMS

  return thunk
}

export default fetchCorporateTeams
