import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'

import Application from './components/Application'
import './assets/styles/styles.scss'

const rootElement = document.getElementById('react-root')

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    rootElement,
  )
}

render(Application)

if (module.hot) {
  module.hot.accept()
}
