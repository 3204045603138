import { formatRoute } from 'react-router-named-routes'
import { replace } from 'connected-react-router'

import { BarError } from 'classes/errors'
import { fromCallbacksToPromise } from 'helpers'
import { LOGIN } from 'urls'
import { notifyError } from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import sessionActionTypes from 'app_modules/session/constants'

import { logout } from './fetchLogout'
import { setUser } from './setUser'

// Actions

export const fetchImpersonateFailure = error => ({
  payload: error,
  type: sessionActionTypes.FETCH_IMPERSONATE_FAILURE,
})

export const fetchImpersonateRequest = () => ({
  type: sessionActionTypes.FETCH_IMPERSONATE_REQUEST,
})

export const fetchImpersonateSuccess = () => ({
  type: sessionActionTypes.FETCH_IMPERSONATE_SUCCESS,
})

// Thunks

export const impersonate = token => {
  const thunk = (dispatch, getState) => {
    const loggedUser = sessionSelectors.user(getState())

    if (loggedUser.impersonateToken !== token) {
      return Promise.all([dispatch(logout())]).then(() =>
        Promise.all([
          dispatch(fetchImpersonateRequest()),
          fromCallbacksToPromise(api.session.impersonate, token)
            .then(({ user }) => Promise.all([dispatch(fetchImpersonateSuccess()), dispatch(setUser(user))]))
            .catch(error => {
              dispatch(logout())
              dispatch(notifyError(new BarError({ ...error, label: 'impersonationTokenExpired' })))
              dispatch(fetchImpersonateFailure(error.message))
              throw error
            }),
        ]),
      )
    }

    return dispatch(replace(formatRoute(LOGIN)))
  }

  thunk.type = sessionActionTypes.FETCH_IMPERSONATE

  return thunk
}

export default impersonate
