import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as accountActions from 'app_modules/accounts/actions'
import * as accountSelectors from 'app_modules/accounts/selectors'
import * as groupActions from 'app_modules/groups/actions'
import * as pageActions from 'app_modules/page/actions'
import * as sessionSelectors from 'app_modules/session/selectors'

import ModalPeople from 'components/PageAdministration/components/ModalPeople'

const mapStateToProps = state => ({
  accountId: sessionSelectors.accountId(state),
  isDepartmentEnabled: accountSelectors.isDepartmentEnabled(state),
  profile: sessionSelectors.currentProfile(state),
})

const mapDispatchToProps = dispatch => ({
  accountActions: bindActionCreators(accountActions, dispatch),
  groupActions: bindActionCreators(groupActions, dispatch),
  pageActions: bindActionCreators(pageActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalPeople)
