import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/bundles/constants'
import api from 'api'

// Actions

export const fetchBundlesFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_BUNDLES_FAILURE,
})

export const fetchBundlesRequest = () => ({
  type: actionTypes.FETCH_BUNDLES_REQUEST,
})

export const fetchBundlesSuccess = request => ({
  payload: request,
  type: actionTypes.FETCH_BUNDLES_SUCCESS,
})

// Thunks

export const fetchBundles = onFinish => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchBundlesRequest())

    const handleResponse = response => {
      dispatch(fetchBundlesSuccess(response))

      if (onFinish) {
        onFinish(response)
      }
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchBundlesFailure(error))
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'fetchBundles' })))
      }

      if (onFinish) {
        onFinish(error)
      } else {
        throw error
      }
    }

    return api.bundles.getBundles(accountId, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_BUNDLES

  return thunk
}

export default fetchBundles
