import * as accountActions from 'app_modules/accounts/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import teamsActionTypes from 'app_modules/teams/constants'

// Actions

export const fetchSetPrivacyTeamFailure = error => ({
  payload: error,
  type: teamsActionTypes.FETCH_SET_PRIVACY_TEAM_FAILURE,
})

export const fetchSetPrivacyTeamRequest = () => ({
  type: teamsActionTypes.FETCH_SET_PRIVACY_TEAM_REQUEST,
})

export const fetchSetPrivacyTeamSuccess = newTeam => ({
  payload: newTeam,
  type: teamsActionTypes.FETCH_SET_PRIVACY_TEAM_SUCCESS,
})

// Thunks

export const setPrivacyTeam = ({ isPublic, isPublished, teamId }) => {
  const thunk = (dispatch, getState) => {
    const state = getState()

    const accountId = sessionSelectors.accountId(state)

    dispatch(fetchSetPrivacyTeamRequest())

    const handleResponse = response => {
      dispatch(fetchSetPrivacyTeamSuccess(response))
      dispatch(accountActions.fetchAccount(accountId))
      return response
    }

    const handleError = ({ error }) => {
      dispatch(fetchSetPrivacyTeamFailure(error))
      throw error
    }

    return api.teams.setPrivacy({ accountId, isPublic, isPublished, teamId }, handleResponse, handleError)
  }

  thunk.type = teamsActionTypes.FETCH_SET_PRIVACY_TEAM

  return thunk
}

export default setPrivacyTeam
