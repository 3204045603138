import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { balloonShape } from 'constants/charts'
import { ENERGY_LABELS } from 'constants'
import { PERSON } from 'constants/props'
import Svg from 'generics/Svg'

import styles from './BeachBalls.scss'

const BALLOON_LIST = [
  {
    key: 'e1',
    transform: 'rotate(90)',
  },
  {
    key: 'e2',
    transform: 'rotate(180)',
  },
  {
    key: 'e3',
    transform: 'rotate(270)',
  },
  {
    key: 'e4',
  },
]

const BeachBalls = props => {
  const { className, profile, threshold } = props

  const { assessmentScores, dynamics, energies, isVisible } = profile

  const isValidationOverwrite =
    isVisible && !energies.isNull && energies.every(energy => energy.includes(ENERGY_LABELS[1]))

  const balloonList = BALLOON_LIST.map(({ key, transform }, index) => {
    const hasColoredBalloon = isVisible && (isValidationOverwrite || assessmentScores[index] >= threshold)

    const energy = hasColoredBalloon ? `: ${energies[index]}` : ''

    const title = dynamics ? `${dynamics[index]}${energy}` : 'Loading'

    const transformGroup = transform ? `${transform} translate(0.238, 0.238)` : 'translate(0.238, 0.238)'

    return (
      <g key={key} transform={transformGroup}>
        <title>{title}</title>
        <path
          className={classnames(styles.peach, { [styles[`e${index}`]]: hasColoredBalloon })}
          d={balloonShape(2.3805)}
        />
      </g>
    )
  })

  return (
    <Svg
      className={classnames(styles['beach-balls'], className)}
      viewBox="0 0 10 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(5, 5)">{balloonList}</g>
    </Svg>
  )
}

BeachBalls.propTypes = {
  className: PropTypes.string,
  profile: PERSON.isRequired,
  threshold: PropTypes.number,
}

BeachBalls.defaultProps = {
  className: null,
  threshold: 9,
}

export default BeachBalls
