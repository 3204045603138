/**
 * @fileoverview Status selectors for the SensaiAI module
 * @module sensaiAi/selectors/statusSelectors
 */

/**
 * Base selector for status state
 * @param {Object} state - Global state
 * @returns {Object} Status state
 */
const getStatusState = state => state.sensaiAi.status

/**
 * Selector for retrieving sensai status
 * @param {Object} state - Global state
 * @returns {Object|null} Sensai status
 */
export const getSensaiStatus = state => getStatusState(state).sensaiStatus

/**
 * Selector for retrieving status fetch error
 * @param {Object} state - Global state
 * @returns {Object|null} Error object
 */
export const getStatusError = state => getStatusState(state).error

/**
 * Selector for checking if status is being fetched
 * @param {Object} state - Global state
 * @returns {boolean} Fetching state
 */
export const isFetchingStatus = state => getStatusState(state).isFetching
