/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FaFacebookSquare, FaLinkedin, FaRss, FaTwitter } from 'react-icons/fa'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

import { ACTIONS, CHILDREN, HISTORY, I18N, ICON, STYLE } from 'constants/props'
import { Card } from 'generics/Card'
import { COLORS } from 'constants/colors'
import * as pageActions from 'app_modules/page/actions'
import Icon from 'generics/Icon'
import InformationCardHeader from 'generics/InformationCardHeader'
import Modal from 'components/Modal'
import Translation from 'generics/Translation'

import styles from './NewsListItem.scss'

const { PUBLIC_PATH } = process.env

const DATE_FORMAT = 'LL'
const LOGO = `${window.location.origin}${PUBLIC_PATH}android-chrome-384x384.png`

const NewsListItem = ({
  actions,
  ariaLabel,
  children,
  className,
  date,
  description,
  disabled,
  focusId,
  i18n,
  icon,
  imageUrl,
  isComingSoon,
  modalCardClassName,
  onClick,
  style,
  title,
  viewDetails,
  ...rest
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const focusRef = useRef(null)

  const handleClose = () => {
    actions.switchModalView()
    setModalIsOpen(false)
  }

  const handleOpen = () => {
    if (disabled) {
      return
    }

    actions.switchModalView()
    setModalIsOpen(true)
  }

  const handleClick = () => {
    handleOpen()
  }

  const support = () => {
    if (!window.DOMParser) return false
    const parser = new DOMParser()
    try {
      parser.parseFromString('x', 'text/html')
    } catch (err) {
      return false
    }
    return true
  }

  const stringToHTML = str => {
    let htmlText
    // If DOMParser is supported, use it
    if (support()) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(str, 'text/html')
      const hrefList = doc.body.querySelectorAll('a')
      for (let i = 0; i < hrefList.length; i += 1) {
        hrefList[i].setAttribute('tabindex', -1)
      }
      htmlText = doc.body
    } else {
      // Otherwise, fallback to old-school method
      const dom = document.createElement('div')
      dom.innerHTML = str
      const hrefList = dom.querySelectorAll('a')
      for (let i = 0; i < hrefList.length; i += 1) {
        hrefList[i].setAttribute('tabindex', -1)
      }
      htmlText = dom
    }

    if (htmlText.childElementCount !== 1) {
      const article = document.createElement('article')
      article.innerHTML = str
      const hrefList = article.querySelectorAll('a')
      for (let i = 0; i < hrefList.length; i += 1) {
        hrefList[i].setAttribute('tabindex', -1)
      }
      return article.outerHTML
    }
    return str
  }

  return (
    <Card
      barBackgroundColor={COLORS.lightBlue.rgba}
      barClassName={styles.bar}
      className={classnames(styles.card, className, { [styles['card--is-not-clickeable']]: isComingSoon })}
      disabled={disabled}
      onClick={onClick || handleClick}
      style={style}
      title={title}
      {...rest}
    >
      <figure
        className={classnames(styles.image, styles['card-image'], { [styles['empty-image']]: !imageUrl })}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      >
        {!imageUrl && <Icon.Stroke7 name="photo" />}
      </figure>

      {date && <p className={styles.date}>{moment(date).format(DATE_FORMAT)}</p>}

      <InformationCardHeader className={styles.header} title={title} titleClassName={styles.title} />

      <main
        className={styles.main}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: stringToHTML(description) }}
        role="presentation"
        tabIndex={-1}
      />

      <div role="presentation" className={styles.footer}>
        <figure>
          <img alt={i18n.news.social.imageAuthor} src={LOGO} />
        </figure>
        <button
          aria-label={`${ariaLabel}. Date: ${moment(date).format(DATE_FORMAT)}. Read more about ${title}.`}
          id={focusId}
          type="button"
        >
          <Icon.Stroke7 name="angle-right" />
        </button>
      </div>

      {modalIsOpen && (
        <Modal
          categoryColor={COLORS.lightBlue.rgba}
          focusRef={focusRef}
          isBlog
          modalCardClassName={styles.modal}
          onClose={handleClose}
        >
          <section className={styles['modal-header']}>
            {date && (
              <p className={classnames(styles.date, styles['modal-date'])}>{moment(date).format(DATE_FORMAT)}</p>
            )}
            {title && (
              <div
                className={styles['modal-title']}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            <div className={styles.social}>
              <figure>
                <img alt={i18n.news.social.imageAuthor} src={LOGO} />
              </figure>
              <div>
                <a
                  aria-label="5 Dynamics Linkedin Page"
                  href="https://www.linkedin.com/company/5-dynamics/about/"
                  rel="noopener noreferrer"
                  target="_blank"
                  ref={focusRef}
                >
                  <FaLinkedin />
                </a>
                <a
                  aria-label="5 Dynamics Facebook Page"
                  href="https://www.facebook.com/5Dynamics/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FaFacebookSquare />
                </a>
                <a
                  aria-label="5 Dynamics Twitter Page"
                  href="https://twitter.com/5Dynamics"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FaTwitter />
                </a>
                <a
                  aria-label="5 Dynamics Rss Page"
                  href="https://www.simpli5.com/blog/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FaRss />
                </a>
              </div>
            </div>
          </section>
          {imageUrl && (
            <figure
              className={classnames(styles.image, styles['modal-image'])}
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
            />
          )}
          <div
            className={styles['modal-description']}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Modal>
      )}
    </Card>
  )
}

NewsListItem.propTypes = {
  actions: ACTIONS.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  children: CHILDREN,
  className: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  focusId: PropTypes.string,
  goToUrl: PropTypes.string,
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  icon: ICON,
  imageUrl: PropTypes.string,
  isComingSoon: PropTypes.bool,
  modalCardClassName: PropTypes.string,
  onClick: PropTypes.func,
  style: STYLE,
  title: PropTypes.string.isRequired,
  viewDetails: PropTypes.bool,
}

NewsListItem.defaultProps = {
  children: null,
  className: null,
  date: undefined,
  description: undefined,
  disabled: false,
  focusId: null,
  goToUrl: undefined,
  icon: null,
  imageUrl: null,
  isComingSoon: false,
  modalCardClassName: undefined,
  onClick: undefined,
  style: undefined,
  viewDetails: false,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(pageActions, dispatch),
})

export default Translation(connect(null, mapDispatchToProps)(withRouter(NewsListItem)), ['news', 'generics'])
