/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Row } from 'reactstrap'
import { formatRoute } from 'react-router-named-routes'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { ACTIONS, I18N, MATCH, THIRD_PERSON } from 'constants/props'
import { NETWORK_DASHBOARD, NETWORK } from 'urls'
import AccountInformation from 'generics/AccountInformation'
import Breadcrumbs from 'generics/Breadcrumbs'
import ProfileDetailThirdPerson from 'containers/ProfileDetailThirdPerson'
import Translation from 'generics/Translation'

import styles from './PageProfileThirdPerson.scss'

const CONTENT_TYPE = 'individual'

/**
 * Page Profile Detail component
 * @extends Component
 */
const PageProfileThirdPerson = ({
  i18n,
  isFetchingProfile,
  match,
  networkActions,
  notificationActions,
  thirdPersonProfile,
}) => {
  useEffect(() => {
    const { accountId, profileId } = match.params

    networkActions.fetchThirdPersonProfile({
      contentType: CONTENT_TYPE,
      profileId,
    })

    networkActions.fetchThirdPersonRoles(profileId)

    networkActions.fetchNetworkDetails(accountId, profileId)
  }, [])

  /**
   * Get Breadcrums array
   * @returns {array}
   */

  const getBreadcrumbs = () => {
    const { accountId, firstName, id: profileId } = thirdPersonProfile

    if (!thirdPersonProfile.account) {
      return null
    }

    return [
      {
        label: i18n.thirdPersonProfile.breadcrumbs[0],
        to: formatRoute(NETWORK, { accountId }),
      },
      {
        label: firstName || 'Loading',
        to: formatRoute(NETWORK_DASHBOARD, {
          accountId,
          contentType: CONTENT_TYPE,
          profileId,
        }),
      },
      { label: i18n.thirdPersonProfile.breadcrumbs[1] },
    ]
  }

  /**
   * Component main render
   */

  return (
    <div className={styles.page}>
      <Container>
        <Row>
          <Col>
            <Breadcrumbs className={styles.breadcrumbs} linkList={getBreadcrumbs()} />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6" lg="4">
            <ProfileDetailThirdPerson />
          </Col>
          <Col sm="12" md="6" lg="8">
            <AccountInformation
              isFetching={isFetchingProfile}
              isThirdPersonProfile
              notificationActions={notificationActions}
              onAdminUpdateEmail={networkActions.adminUpdateEmail}
              profile={thirdPersonProfile}
              title={i18n.profileDetail.accountInformationTitle}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

PageProfileThirdPerson.propTypes = {
  i18n: I18N.isRequired,
  isFetchingProfile: PropTypes.bool.isRequired,
  match: MATCH.isRequired,
  networkActions: ACTIONS.isRequired,
  notificationActions: ACTIONS.isRequired,
  thirdPersonProfile: THIRD_PERSON.isRequired,
}

export default Translation(PageProfileThirdPerson, ['thirdPersonProfile', 'profileDetail'])
