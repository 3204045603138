/**
 * @description Accounts controller endpoints settings
 * @module API controller / Accounts
 */

import { lowerCase } from 'lodash'
import { FORM } from 'constants/contentTypes'

module.exports = {
  editCustomization: {
    /**
     * @description Updates account modules customization
     * @param  {object} options
     * @param  {number} options.accountId
     * @returns {object} - Request settings.
     */
    settings({ accountId, values }) {
      return {
        body: values,
        endpoint: `/accounts/${accountId}`,
        method: 'PATCH',
      }
    },
  },
  editAccount: {
    /**
     * @description Updates account modules customization
     * @param  {object} options
     * @param  {number} options.accountId
     * @returns {object} - Request settings.
     */
    settings({ accountId, values }) {
      return {
        body: {
          ...values,
        },
        endpoint: `/accounts/${accountId}`,
        method: 'PUT',
      }
    },
  },
  editDepartment: {
    /**
     * @description Updates account department
     * @param  {object} options
     * @param  {number} options.accountId
     * @param  {number} options.profileId
     * @param  {string} options.department
     * @returns {object} - Request settings.
     */
    settings({ accountId, profileId, values }) {
      return {
        body: values,
        endpoint: `/account/${accountId}/profiles/${profileId}`,
        method: 'PUT',
      }
    },
  },
  getAccount: {
    /**
     * @description Get request settings to get details for an account.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings(accountId) {
      return {
        endpoint: `/accounts/${accountId}`,
        method: 'GET',
      }
    },
  },
  getMyMembers: {
    ignoreNotFound: true,

    /**
     * @description Compute request settings to get members list for an specific account.
     * @param {object} options
     * @param {number} options.accountId - The account ID.
     * @param {number} options.pageIndex - The page index.
     * @param  {string} options.search - The filter to search.
     * @param  {string} options.workshopFilter - workshop filter.
     * @returns {object} - Request settings.
     */
    settings({ accountId, pageIndex, search, workshopFilter }) {
      const { filterBy, value: searchValue } = search || {}

      let endpoint = `/accounts/${accountId}/my_members`
      const cleanSearch = searchValue ? searchValue.trim() : null
      const searchBy = searchValue ? filterBy : null

      if (pageIndex) {
        endpoint += `/page/${pageIndex}`
      }

      if (searchBy) {
        endpoint += `/search_field/${searchBy}`
      }

      if (cleanSearch) {
        endpoint += `/search/${cleanSearch}`
      }

      if (workshopFilter && workshopFilter !== 'all') {
        endpoint += `?workshop_filter=${lowerCase(workshopFilter)}`
      }

      return {
        endpoint,
        method: 'GET',
      }
    },
  },
  updateLogo: {
    /**
     * @description Updates account logo
     * @param  {object} options
     * @param  {number} options.accountId
     * @param  {object} options.logo
     * @returns {object} - Request settings.
     */
    settings({ accountId, logo }) {
      const form = new FormData()

      if (logo) {
        form.append('logo', logo)
      }

      return {
        body: form,
        endpoint: `/accounts/${accountId}/set_logo`,
        headers: {
          enctype: FORM,
        },
        method: 'PATCH',
      }
    },
  },
  setDefaultAccount: {
    /**
     * @description Sets Default Account for the current user
     * @param  {object} options
     * @param  {number} options.accountId
     * @param  {number} options.currentAccountId
     * @returns {object} - Request settings.
     */

    settings({ accountId, currentAccountId }) {
      return {
        body: { id: accountId },
        endpoint: `/accounts/${currentAccountId}/set_default`,
        method: 'PATCH',
      }
    },
  },
}
