import { Col, Container, Row } from 'reactstrap'
import { isNil } from 'lodash'
import { pickHTMLProps } from 'pick-react-known-prop'
import PropTypes from 'prop-types'
import React from 'react'

import { Footer, Header, Main } from 'generics/Card'
import { I18N } from 'constants/props'
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents'
import Translation from 'generics/Translation'
import useFormEditOverviewReportCustomization from './useFormEditOverviewReportCustomization'

const FormEditOverviewReportCustomization = ({ i18n, initialValues, onClose, onSubmit, ...rest }) => {
  const { errors, isValid, handleChange, handleSubmit, reset, values } = useFormEditOverviewReportCustomization({
    i18n,
    initialValues,
    onSubmit,
  })

  return (
    <>
      <Header>
        <h1 title={i18n.customizations.overviewReport.title}>{i18n.customizations.overviewReport.title}</h1>
      </Header>
      <Main>
        <Container fluid>
          <form {...pickHTMLProps(rest)}>
            <StyledTextField
              error={isNil(values.overviewRptIntro) ? false : !!errors.overviewRptIntro}
              helperText={isNil(values.overviewRptIntro) ? ' ' : errors.overviewRptIntro}
              label={`${i18n.customizations.overviewReport.introduction}:`}
              maxLength={2600}
              multiline
              name="overviewRptIntro"
              onChange={e => handleChange('overviewRptIntro', e.currentTarget.value)}
              rows={32}
              title={i18n.customizations.overviewReport.introduction}
              value={values.overviewRptIntro || ''}
            />
          </form>
        </Container>
      </Main>
      <Footer>
        <Container>
          <Row>
            <Col xs="3" md="2">
              <StyledButton
                color="default"
                fullWidth
                onClick={() => onClose()}
                title={i18n.generics.cancelLabel}
                variant="text"
              >
                {i18n.generics.cancelLabel}
              </StyledButton>
            </Col>

            <Col xs={{ size: 3, offset: 3 }} md={{ size: 2, offset: 6 }}>
              <StyledButton
                color="default"
                fullWidth
                onClick={() => reset()}
                title={i18n.generics.resetLabel}
                variant="text"
              >
                {i18n.generics.resetLabel}
              </StyledButton>
            </Col>

            <Col xs={{ size: 3 }} md={{ size: 2 }}>
              <StyledButton
                color="primary"
                disabled={!isValid}
                fullWidth
                id="create-team-btn"
                onClick={e => handleSubmit(e)}
                title={i18n.generics.doneLabel}
              >
                {i18n.generics.doneLabel}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  )
}

FormEditOverviewReportCustomization.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
}

export default Translation(FormEditOverviewReportCustomization, ['customizations', 'generics'])
