export const en = {
  administration: 'Administration',
  mySubscriptions: 'Invite & Buy',
  my5Dynamics: 'View My 5 Dynamics',
  network: 'View My Network',
  news: 'News',
  relationships: 'Relationships',
  teams: 'View My Teams',
  tokenAdministration: 'Token Administration',
  trainingCenter: 'Learn',
}

export const es = en
