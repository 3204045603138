/* eslint-disable import/prefer-default-export */

import userActionTypes from 'app_modules/user/constants'

export const setPersonalsSelfInvitation = selfInvitation => {
  const thunk = dispatch => {
    dispatch({
      payload: selfInvitation,
      type: userActionTypes.SET_PERSONALS_SELF_INVITATION,
    })
  }
  return thunk
}
