import PropTypes from 'prop-types'

import { BarError } from 'classes/errors'
import { I18N } from 'constants/props'
import { REQUIRED } from 'constants/inputTypes'
import { UPDATE_EMAIL_INVALID_CODE } from 'constants/apiErrorCodes'

import api from 'api'
import useForm from '../../../custom_hooks/useForm'
import useFormValidation from '../../../custom_hooks/useFormValidation'

const useFormValidateCode = ({ accountId, newEmail, i18n, initialValues, notificationActions, onFinish }) => {
  const { validator } = useFormValidation({ i18n })

  const handleValidate = values => {
    const { code } = values

    return {
      code: validator.validate(REQUIRED, code),
    }
  }

  const onSubmit = ({ code }) => {
    const onSuccess = () => {
      onFinish(newEmail)
    }

    const onError = ({ error }) => {
      if (error.errorCode === UPDATE_EMAIL_INVALID_CODE) {
        notificationActions.notifyError(
          new BarError({
            ...error,
            label: 'invalidCodeUpdateEmail',
          }),
        )
      }
    }

    const codeEmailObj = {
      confirmEmailCode: code,
      email: newEmail,
    }

    api.profiles.updateEmailValidateCode({ accountId, body: codeEmailObj }, onSuccess, onError)
  }

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = {
      email: null,
    },
  } = useForm({ initialValues, onSubmit, onValidate: handleValidate })

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  }
}

useFormValidateCode.propTypes = {
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}),
  newEmail: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
}

useFormValidateCode.defaultProps = {
  initialValues: {},
}

export default useFormValidateCode
