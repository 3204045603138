import { Col, Row } from 'reactstrap'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import { StyledDatePicker, StyledTextField } from 'generics/StyledFormComponents'
import Translation from 'generics/Translation'
import useFormWorkshop from './useFormWorkshop'

const FormWorkshop = ({ date, i18n, isDisabled, message, name, onChange }) => {
  const { errors, handleChange, values } = useFormWorkshop({ i18n, initialValues: { date, name }, onChange })

  return (
    <>
      {!!message && <p>{message}</p>}
      <Row>
        <Col xs="12" md="6">
          <StyledTextField
            disabled={isDisabled}
            error={isNil(values.name) ? false : !!errors.name}
            fullWidth
            helperText={isNil(values.name) ? ' ' : errors.name}
            id="file-invitation-group-name"
            label={i18n.workshops.createWorkshop.name}
            name="name"
            onChange={e => handleChange('name', e.currentTarget.value)}
            title={i18n.workshops.createWorkshop.name}
            value={values.name ?? ''}
          />
        </Col>
        <Col xs="12" md="6">
          <StyledDatePicker
            disabled={isDisabled}
            error={isNil(values.date) ? false : !!errors.date}
            helperText={isNil(values.date) ? ' ' : errors.date}
            isUTC
            name="date"
            onChange={handleChange}
            title={i18n.workshops.createWorkshop.date}
            value={values.date}
          />
        </Col>
      </Row>
    </>
  )
}

FormWorkshop.propTypes = {
  isDisabled: PropTypes.bool,
  i18n: I18N.isRequired,
  onChange: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  message: PropTypes.string,
  name: PropTypes.string.isRequired,
}

FormWorkshop.defaultProps = {
  isDisabled: false,
  message: null,
}

export default Translation(FormWorkshop, ['workshops', 'generics'])
