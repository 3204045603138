import { isEmpty } from 'lodash'

import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import groupsActionTypes from 'app_modules/groups/constants'

// Actions

export const fetchGroupsFailure = error => ({
  payload: error,
  type: groupsActionTypes.FETCH_GROUPS_FAILURE,
})

export const fetchGroupsRequest = pageIndex => ({
  payload: pageIndex,
  type: groupsActionTypes.FETCH_GROUPS_REQUEST,
})

export const fetchGroupsSuccess = response => ({
  payload: response,
  type: groupsActionTypes.FETCH_GROUPS_SUCCESS,
})

export const fetchGroupsSuccessWithNoResults = () => ({
  type: groupsActionTypes.FETCH_GROUPS_SUCCESS_NO_RESULTS,
})

// Thunks
export const fetchGroups = ({ pageIndex = 1, searchInfo = {} }, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchGroupsRequest(pageIndex))

    const handleResponse = response => {
      const { groups } = response

      if (!groups) {
        dispatch(fetchGroupsSuccessWithNoResults())
        if (onSuccess) {
          onSuccess(response)
        }
      }

      const fixedResponse = {
        ...response,
        updateCounters: isEmpty(searchInfo),
      }

      dispatch(fetchGroupsSuccess(fixedResponse))

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchGroupsFailure(error))
      if (onError) {
        onError(error)
      }
    }

    return api.groups.getGroups(
      {
        accountId,
        pageIndex,
        ...searchInfo,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = groupsActionTypes.FETCH_GROUPS

  return thunk
}

export default fetchGroups
