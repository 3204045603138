import { formatRoute } from 'react-router-named-routes'
import { isNil } from 'lodash'
import { replace } from 'connected-react-router'

import { ACCEPT_INVITATION, MY_5_DYNAMICS, WELCOME } from 'urls'
import { updateState } from 'app_modules/assessment/actions'
import * as notificationActions from 'app_modules/notifications/actions'
import actionTypes from 'app_modules/session/constants'

import { fetchDashboard } from './fetchDashboard'
import { fetchVideos } from './fetchVideos'
import { logout } from './fetchLogout'
import { setCurrentProfile } from './setProfile'
import acceptInvitation from '../../invites/actions/fetchAcceptInvitation'

// Actions

export const setCurrentUser = user => ({
  payload: user,
  type: actionTypes.SET_CURRENT_USER,
})

export const setImpersonateUser = user => ({
  payload: user,
  type: actionTypes.SET_USER_BY_IMPERSONATION,
})

// Thunks

export const setUser = (user, isInvitationPending = false, isInvitationPreAccepted = false, invitationId) => {
  const thunk = dispatch => {
    const {
      defaultAccount,
      hasActiveAssessment: isCompleted,
      hasOngoingAssessment: isInProgress,
      profiles,
      impersonateToken,
    } = user

    let userDispatch = setCurrentUser
    let defaultProfile
    let isExpired = false

    if (impersonateToken) {
      userDispatch = setImpersonateUser

      defaultProfile = profiles.find(profile => profile.account.isPersonal) || profiles[0]
    } else {
      defaultProfile = profiles.find(({ account }) => account.id === defaultAccount.id)
      isExpired = defaultAccount ? new Date(defaultAccount.expiresAt) < new Date() : false
    }

    if (isNil(defaultProfile) || (isExpired && !isInvitationPending && !isInvitationPreAccepted)) {
      return Promise.all([dispatch(logout()), dispatch(notificationActions.notifyWarning({ label: 'accountExpired' }))])
    }

    const promises = [dispatch(updateState({ isCompleted, isInProgress })), dispatch(userDispatch(user))]

    if (!isExpired || isInvitationPreAccepted) {
      promises.push([dispatch(setCurrentProfile(defaultProfile)), dispatch(fetchVideos()), dispatch(fetchDashboard())])
    }

    const handleOnAcceptInvitation = () => {
      dispatch(replace(formatRoute(MY_5_DYNAMICS, { accountId: invitationId })))
    }

    return Promise.all(promises).then(() => {
      if (!isExpired) {
        if (isCompleted) {
          dispatch(replace(formatRoute(MY_5_DYNAMICS, { accountId: defaultProfile.account.id })))
        } else {
          dispatch(replace(formatRoute(WELCOME, { accountId: defaultProfile.account.id })))
        }
      } else if (isInvitationPreAccepted) {
        dispatch(acceptInvitation(true, handleOnAcceptInvitation))
      } else {
        dispatch(replace(formatRoute(ACCEPT_INVITATION)))
      }
    })
  }

  thunk.type = actionTypes.SET_USER

  return thunk
}

export default setUser
