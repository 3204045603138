/* eslint-disable react-hooks/exhaustive-deps */
import { bindActionCreators } from 'redux'
import { Col, Container, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { pickHTMLProps } from 'pick-react-known-prop'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { ACTIONS, BREADCRUMS, CHILDREN } from 'constants/props'
import { COLORS } from 'constants/colors'
import * as pageActions from 'app_modules/page/actions'

import styles from './module.scss'

const Module = ({ actions, barBackgroundColor, breadcrumbs, children, ...rest }) => {
  const [currentBarBackgroundColor, setCurrentBarBackgroundColor] = useState(null)

  useEffect(() => {
    if (currentBarBackgroundColor !== barBackgroundColor) {
      actions.setModule({
        bar: {
          backgroundColor: barBackgroundColor,
        },
        styles: {
          backgroundColor: COLORS.primaryWhite.rgba,
        },
      })
      setCurrentBarBackgroundColor(currentBarBackgroundColor)
    }
  }, [barBackgroundColor])

  useEffect(
    () => () => {
      actions.setModule({
        bar: {},
        styles: {},
      })
    },
    [],
  )

  return (
    <section className={styles.module} {...pickHTMLProps(rest)}>
      <nav>
        <Container>
          <Row>
            <Col>{breadcrumbs}</Col>
          </Row>
        </Container>
      </nav>
      {children}
    </section>
  )
}

Module.propTypes = {
  actions: ACTIONS.isRequired,
  barBackgroundColor: PropTypes.string,
  children: CHILDREN.isRequired,
  breadcrumbs: BREADCRUMS.isRequired,
}

Module.defaultProps = {
  barBackgroundColor: undefined,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(pageActions, dispatch),
})

export default connect(null, mapDispatchToProps)(Module)
