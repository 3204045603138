import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/teams/constants'
import api from 'api'

// Actions
export const fetchShareTeamsWithABundleFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_SHARE_TEAMS_WITH_A_BUNDLE_FAILURE,
})

export const fetchShareTeamsWithABundleRequest = () => ({
  type: actionTypes.FETCH_SHARE_TEAMS_WITH_A_BUNDLE_REQUEST,
})

export const fetchShareTeamsWithABundleSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_SHARE_TEAMS_WITH_A_BUNDLE_SUCCESS,
})

// Thunks
/**
 * @param  {object} options
 * @param  {number} options.bundlesAccountId
 * @param  {number} options.bundleId
 * @param  {array} options.teams
 * @returns {promise} api promise
 */
export const fetchShareTeamsWithABundle = ({ bundlesAccountId, bundleId, teams }, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = bundlesAccountId || sessionSelectors.accountId(getState())

    dispatch(fetchShareTeamsWithABundleRequest())

    const teamIds = teams.map(({ id }) => id)

    const handleResponse = response => {
      dispatch(fetchShareTeamsWithABundleSuccess({ bundleId, teams }))

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchShareTeamsWithABundleFailure(error))
      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.teams.shareTeamsWithABundle({ accountId, bundleId, teamIds }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_SHARE_TEAMS_WITH_A_BUNDLE

  return thunk
}

export default fetchShareTeamsWithABundle
