import { pickSVGProps } from 'pick-react-known-prop'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import { CHILDREN } from 'constants/props'

import styles from './svg.scss'

const Svg = ({ children, className, viewBox, ...rest }) => {
  const svg = useRef(null)

  const [width, height] = viewBox.split(' ')

  return (
    <div className={classnames(styles.svg, className)} role="img">
      <canvas className={styles.svg__canvas} {...{ height, width }} />
      <svg
        className={styles.svg__content}
        ref={svg}
        version="1.1"
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        {...pickSVGProps(rest)}
      >
        {children}
      </svg>
    </div>
  )
}

Svg.propTypes = {
  children: CHILDREN,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

Svg.defaultProps = {
  children: null,
  className: null,
  viewBox: '0 0 0 0',
}

export default Svg
