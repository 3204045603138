import { CircularProgress } from '@material-ui/core'
import { sprintf } from 'sprintf-js'
import classnames from 'classnames'
import Mask from 'generics/Mask'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import { PEOPLE_LIST_ITEM, I18N } from 'constants/props'
import Icon from 'generics/Icon'
import Translation from 'generics/Translation'

import styles from './WorkshopListItem.scss'

const WorkshopListItem = ({ className, i18n, isFetching, item, onClick, ...rest }) => {
  const handleKeyPress = event => {
    switch (event.key) {
      case 'Enter':
        if (onClick) {
          onClick()
        }
        break
      default:
    }
  }

  const { establishedAt, name } = item

  const date = establishedAt && moment.utc(new Date(establishedAt)).format('L')

  return (
    <li className={classnames(styles.item, className)} {...rest}>
      <Icon.Stroke7 className={styles.icon} name="share" />
      <div className={styles['item-content']}>
        <div className={styles['item-information']}>
          <span
            className={styles['name-link']}
            onClick={onClick}
            onKeyPress={event => {
              handleKeyPress(event)
            }}
            role="button"
            tabIndex="0"
            title={name}
          >
            {name}
          </span>
          {date && (
            <div className={styles.date} title={sprintf(i18n.workshops.listItem.date, { date })}>
              <span className={styles.date__title}>{i18n.workshops.listItem.dateTitle}</span>
              <time className={styles.date__value} dateTime={date}>
                {date}
              </time>
            </div>
          )}
        </div>
      </div>
      <Mask className={styles.mask} open={isFetching}>
        <CircularProgress className={styles.loader} size={25} thickness={2} />
      </Mask>
    </li>
  )
}

WorkshopListItem.propTypes = {
  className: PropTypes.string,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool,
  item: PEOPLE_LIST_ITEM.isRequired,
  onClick: PropTypes.func,
}

WorkshopListItem.defaultProps = {
  className: null,
  isFetching: false,
  onClick: undefined,
}

export default Translation(WorkshopListItem, ['workshops'])
