const { SENSAI_REDIRECT_URL } = process.env

const constructSensaiUrl = ({ accountId, userToken, currentUrl, credentials, isReturningUser, userName }) => {
  return `${SENSAI_REDIRECT_URL}/?orgId=${accountId}&id=${encodeURIComponent(
    userToken,
  )}&callBackUrl=${encodeURIComponent(currentUrl)}&appSecret=${encodeURIComponent(
    credentials.appSecret,
  )}&licenseKey=${encodeURIComponent(
    credentials.licenseKey,
  )}&isReturningUser=${isReturningUser}&userName=${encodeURIComponent(userName)}`
}

export { constructSensaiUrl }
export default constructSensaiUrl
