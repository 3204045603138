/* eslint-disable react/prefer-stateless-function */

import { reduce } from 'lodash'
import React from 'react'

import { messages } from 'translation/en'

const TranslationHOC = (WrappedComponent, selections = []) => {
  // eslint-disable-next-line react/prop-types
  const Translation = ({ forwardedRef, ...rest }) => {
    if (WrappedComponent === null) return null

    const i18n = reduce(
      messages,
      (accumulator, value, key) => {
        if (selections.includes(key)) {
          return {
            ...accumulator,
            [key]: value,
          }
        }
        return accumulator
      },
      {},
    )

    return <WrappedComponent i18n={i18n} ref={forwardedRef} {...rest} />
  }

  return React.forwardRef((props, ref) => <Translation {...props} forwardedRef={ref} />)
}

export default TranslationHOC
