/**
 * @fileoverview Status-related actions for the SensaiAI module
 * @module sensaiAi/actions/statusActions
 */

import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import { STATUS } from '../constants/actionTypes'
import { ERROR_MESSAGES } from '../constants/errorMessages'
import { handleApiError } from '../utils/apiUtils'

/**
 * Action creator for status fetch request
 * @returns {Object} Action object
 */
export const fetchSensaiStatusRequest = () => ({
  type: STATUS.FETCH_REQUEST,
})

/**
 * Action creator for successful status fetch
 * @param {Object} response - API response data
 * @returns {Object} Action object
 */
export const fetchSensaiStatusSuccess = response => ({
  type: STATUS.FETCH_SUCCESS,
  payload: response,
})

/**
 * Action creator for failed status fetch
 * @param {Error} error - Error object
 * @returns {Object} Action object
 */
export const fetchSensaiStatusFailure = error => ({
  type: STATUS.FETCH_FAILURE,
  payload: error,
})

/**
 * Thunk action creator for fetching SensaiAI status
 * @returns {Function} Thunk function
 */
export const fetchSensaiStatus = () => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    if (!accountId) {
      const error = new Error(ERROR_MESSAGES.MISSING_ACCOUNT_ID)
      return handleApiError(error, dispatch, fetchSensaiStatusFailure, ERROR_MESSAGES.STATUS_FAILED)
    }

    dispatch(fetchSensaiStatusRequest())

    return api.sensai.getStatus(
      { accountId },
      response => {
        dispatch(fetchSensaiStatusSuccess(response))
        // Update session state
        dispatch({
          type: 'Session/FETCH_SENSAI_STATUS_SUCCESS',
          payload: response,
        })
        return response
      },
      error => handleApiError(error, dispatch, fetchSensaiStatusFailure, ERROR_MESSAGES.STATUS_FAILED),
    )
  }

  thunk.type = STATUS.FETCH_REQUEST
  return thunk
}
