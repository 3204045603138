import { ConnectedRouter as Router } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-intl-redux'
import HttpsRedirect from 'react-https-redirect'
import React from 'react'

import api from 'api'
import configureStore from 'app_modules/store/configureStore'
import fiveDynamicsTheme from 'theme'
import Routes from 'components/Routes'

const history = createBrowserHistory()

const store = configureStore({ history })

api.setStore(store)

const Application = () => (
  <Provider store={store}>
    <HttpsRedirect>
      <Router history={history}>
        <MuiThemeProvider theme={fiveDynamicsTheme}>
          <Routes />
        </MuiThemeProvider>
      </Router>
    </HttpsRedirect>
  </Provider>
)

export default Application
