import { formatRoute } from 'react-router-named-routes'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import DocumentTitle from 'react-document-title'
import React, { useState } from 'react'

import { Card, Header, Main } from 'generics/Card'
import { HISTORY, I18N } from 'constants/props'
import { LOGIN } from 'urls'
import { StyledButton } from 'generics/StyledFormComponents'
import api from 'api'
import FormForgotPassword from 'components/FormForgotPassword'
import Translation from 'generics/Translation'

import styles from './PageForgotPassword.scss'

const PageForgotPassword = ({ history, i18n }) => {
  const [emailText, setEmailText] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)

  const handleSubmit = ({ email }) => {
    setIsFetching(true)

    const onFinish = () => {
      setEmailText(email)
      setIsFetching(false)
      setStepIndex(1)
    }

    api.users.forgotPassword(email, onFinish, onFinish)
  }

  const handleBack = async () => {
    history.replace(formatRoute(LOGIN))
  }

  const subtitle =
    stepIndex === 1 ? (
      <p className={styles}>
        {i18n.login.forgot.step[stepIndex].subtitle}
        <span className={styles.emailText} title={emailText}>
          {emailText}
        </span>
      </p>
    ) : null

  return (
    <DocumentTitle title="Simpli5® - Forgot Password Page">
      <section className={styles.page}>
        <Card center isFetching={isFetching}>
          <Header className={styles.header} title={i18n.login.forgot.step[stepIndex].title}>
            {subtitle}
          </Header>

          <Main className={styles.main}>
            <p className={classnames(styles.description, { [styles['description--has-margin']]: stepIndex === 1 })}>
              {i18n.login.forgot.step[stepIndex].description}
            </p>

            {stepIndex === 0 && (
              <FormForgotPassword className={styles.form} onSubmit={handleSubmit} submitting={isFetching} />
            )}

            {stepIndex === 1 && (
              <StyledButton
                className={styles.submit}
                onClick={handleBack}
                color="primary"
                title={i18n.login.forgot.step[stepIndex].button}
              >
                {i18n.login.forgot.step[stepIndex].button}
              </StyledButton>
            )}

            {stepIndex === 0 && (
              <Link className={styles.link} href={LOGIN} title={i18n.login.link.backToLogin} to={LOGIN}>
                {i18n.login.link.backToLogin}
              </Link>
            )}
          </Main>
        </Card>
      </section>
    </DocumentTitle>
  )
}

PageForgotPassword.propTypes = {
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
}

export default Translation(PageForgotPassword, ['login'])
