/**
 * @description Settings for connection request controller
 * @module API controller / Connection Requests
 */
module.exports = {
  get: {
    ignoreNotFound: true,

    /**
     * Get request settings (get connectionRequests endpoint)
     * @param {object} options
     * @param {number} options.accountId
     * @param {string} options.list
     * @param {number} options.pageIndex
     * @returns {object} request settings
     */
    settings({ accountId, list, pageIndex }) {
      let endpoint = `/account/${accountId}/connection_requests/${list}`

      if (pageIndex) {
        endpoint += `/page/${pageIndex}`
      }

      return {
        endpoint,
        method: 'GET',
      }
    },
  },

  /**
   * @description Get request settings (reply connectionRequest endpoint)
   * @param {object} options
   * @param {number} options.accountId
   * @param {string} options.action
   * @param {number} options.requestId
   * @returns {object} request settings
   */
  reply: {
    settings({ accountId, action, requestId }) {
      return {
        endpoint: `/account/${accountId}/connection_requests/${action}`,
        method: 'POST',
        body: {
          id: requestId,
        },
      }
    },
  },

  send: {
    /**
     * @description Get request settings (send connectionRequest endpoint)
     * @param {object} options
     * @param {number} options.accountId
     * @param {array} options.receiversIds
     * @returns {object} request settings
     */
    settings({ accountId, receiversIds }) {
      return {
        endpoint: `/account/${accountId}/connection_requests/send`,
        method: 'POST',
        body: {
          connectionRequest: {
            receiversIds,
          },
        },
      }
    },
  },
}
