import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/network/constants'
import api from 'api'

// Actions

export const fetchReplyConnectionRequestFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_REPLY_CONNECTION_REQUEST_FAILURE,
})

export const fetchReplyConnectionRequestRequest = pageIndex => ({
  payload: pageIndex,
  type: actionTypes.FETCH_REPLY_CONNECTION_REQUEST_REQUEST,
})

export const fetchReplyConnectionRequestSuccess = id => ({
  payload: { id },
  type: actionTypes.FETCH_REPLY_CONNECTION_REQUEST_SUCCESS,
})

// Thunks

export const fetchReplyConnectionRequest = (action, requestId, onSuccess) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchReplyConnectionRequestRequest())

    const handleResponse = () => {
      dispatch(fetchReplyConnectionRequestSuccess(requestId))

      if (onSuccess) {
        onSuccess()
      }
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchReplyConnectionRequestFailure(error))
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'replyConnectionRequest' })))
      }
    }

    return api.connectionRequests.reply(
      {
        accountId,
        action,
        requestId,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_REPLY_CONNECTION_REQUEST_REQUEST

  return thunk
}

export default fetchReplyConnectionRequest
