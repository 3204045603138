/* eslint-disable no-use-before-define */
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import shortid from 'shortid'

import { CHILDREN, INFINITE_SCROLL } from 'constants/props'
import { Main, Separator } from 'generics/Card'
import InfiniteScroll from 'generics/InfiniteScroll'

import styles from './InfiniteScrollList.scss'

const InfiniteScrollList = ({
  children,
  className,
  innerRef,
  infiniteScroll,
  isLoading,
  listId,
  // listIsEmpty,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [listHeight, setListHeight] = useState(280 + (infiniteScroll.listHeight || 0))

  let infiniteScrollRef = useRef(null)

  useEffect(() => {
    if (innerRef && infiniteScrollRef) {
      innerRef({ infiniteScrollRef })
    }
  }, [innerRef, infiniteScrollRef])

  const handleShowFullList = () => {
    setListHeight(600 + (infiniteScroll.listHeight || 0))
    setIsCollapsed(false)
  }

  const handleShowLessList = () => {
    setListHeight(280 + (infiniteScroll.listHeight || 0))
    setIsCollapsed(true)
  }

  const handleKeyUp = ({ key }) => {
    switch (key) {
      case 'Enter': {
        if (isCollapsed) {
          handleShowFullList()
        } else {
          handleShowLessList()
        }
        break
      }

      case 'Escape':
        handleShowLessList()
        break

      default:
    }
  }

  const renderListActions = () => {
    const { hideFullLink } = infiniteScroll

    if (/* listIsEmpty || */ hideFullLink) {
      return null
    }

    const label = isCollapsed ? 'Show Full List' : 'Show Less'

    return (
      <div>
        <Separator />
        <div className={styles.full}>
          <span
            id={`show-hide-${listId}`}
            onClick={isCollapsed ? handleShowFullList : handleShowLessList}
            onKeyUp={handleKeyUp}
            role="button"
            tabIndex="0"
            title={label}
          >
            {label}
          </span>
        </div>
      </div>
    )
  }

  const { className: infiniteScrollClassName, hasMorePages, onFetch, pageStart } = infiniteScroll

  return (
    <div>
      <Main className={classnames(styles.main, className)}>
        <InfiniteScroll
          className={classnames(styles.list, infiniteScrollClassName)}
          hasMorePages={hasMorePages}
          id={listId}
          isLoading={isLoading}
          onFetch={onFetch}
          pageStart={pageStart}
          ref={component => {
            infiniteScrollRef = component
          }}
          scrollbar={{
            className: classnames({ [styles['is-empty']]: isEmpty(children) }),
          }}
          style={{ height: `${listHeight}px` }}
          tag="ul"
        >
          {children}
        </InfiniteScroll>
      </Main>

      {renderListActions()}
    </div>
  )
}

InfiniteScrollList.propTypes = {
  children: CHILDREN,
  className: PropTypes.string,
  innerRef: () => {},
  infiniteScroll: INFINITE_SCROLL,
  isLoading: PropTypes.bool,
  listId: PropTypes.string,
}

InfiniteScrollList.defaultProps = {
  children: null,
  className: null,
  innerRef: null,
  infiniteScroll: {
    className: null,
    hasMorePages: false,
    hideFullLink: false,
    listHeight: 0,
    onFetch: undefined,
    pageStart: 2,
  },
  isLoading: false,
  listId: `infinite-scroll-list-${shortid.generate()}`,
  // listIsEmpty: false,
}

export default InfiniteScrollList
