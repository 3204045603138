export const CURRENT_PASSWORD = 'currentPassword'
export const DATE_TO = 'dateTo'
export const EMAIL = 'email'
export const FREQUENCY = 'frequency'
export const FREQUENCY_TYPE = 'frequencyType'
export const GROUP_NAME = 'groupName'
export const NAME = 'name'
export const PASSWORD = 'password'
export const PASSWORD_CONFIRMATION = 'passwordConfirmation'
export const PHONE = 'phone'
export const REQUIRED = 'required'
export const REQUIRED_ALLOW_ZERO = 'requiredAllowZero'
export const TEXT = 'text'
export const ZIP_CODE = 'zipCode'
