/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'

import { I18N, INVITATION_GROUP } from 'constants/props'
import { FREQUENCIES, NEVER } from 'constants/frequencies'
import { FREQUENCY, FREQUENCY_TYPE, GROUP_NAME } from 'constants/inputTypes'
import { hasFormErrors } from 'helpers'
import useFormValidation from 'custom_hooks/useFormValidation'
import { useEffect, useState } from 'react'

const FILE_EXTENSION_REGEX = /^.*\.csv$/

const useFormFileInvitation = ({ edit, i18n, initialValues, onLoadFile, onValidate }) => {
  const { validator } = useFormValidation({ i18n })
  const [errors, setErrors] = useState({})
  const [file, setFile] = useState(null)
  const [hasFileError, setHasFileError] = useState(false)
  const [values, setValues] = useState(
    edit
      ? () => {
          const { frequency, frequencyType, name } = initialValues

          return {
            frequency,
            frequencyType,
            name,
          }
        }
      : {
          frequency: NEVER,
          frequencyType: FREQUENCIES[NEVER][0],
          name: null,
        },
  )

  const handleValidate = () => {
    const { frequency, frequencyType, name } = values

    const newErrors = {
      frequency: validator.validate(FREQUENCY, frequency),
      frequencyType: validator.validate(FREQUENCY_TYPE, frequency, frequencyType),
      name: validator.validate(GROUP_NAME, name),
    }

    setErrors({ ...newErrors })
  }

  useEffect(() => {
    onValidate(values, !hasFormErrors(errors))
  }, [errors])

  useEffect(() => {
    handleValidate()
  }, [values])

  const handleChange = (fieldName, value) => {
    let newValues = {}

    if (fieldName === 'frequency') {
      const [frequencyType] = FREQUENCIES[value]
      newValues = { frequencyType }
    }

    setValues({
      ...values,
      [fieldName]: value,
      ...newValues,
    })
  }

  const handleLoadFile = uploadedFile => {
    const newHasFileError = !FILE_EXTENSION_REGEX.test(uploadedFile.name)
    const newFile = !hasFileError ? uploadedFile : null

    onLoadFile(newFile)

    setHasFileError(newHasFileError)
    setFile(newFile)
  }

  return {
    errors,
    file,
    handleChange,
    handleLoadFile,
    hasFileError,
    values,
  }
}

useFormFileInvitation.propTypes = {
  edit: PropTypes.bool,
  initialValues: INVITATION_GROUP,
  i18n: I18N.isRequired,
  onLoadFile: PropTypes.func,
  onValidate: PropTypes.func.isRequired,
}

useFormFileInvitation.defaultProps = {
  edit: false,
  initialValues: undefined,
  onLoadFile: null,
}

export default useFormFileInvitation
