export const en = {
  avatarTitle: "%s's avatar",
  loading: 'Loading',
  more: 'Account Information',
  overview: 'Overview',
  readMore: 'Read more',
  scoresTitle: 'Scores',
  userInfo: {
    email: 'E-mail',
    jobTitle: 'Job title',
    division: 'Division',
  },
}

export const es = en
