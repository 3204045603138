/**
 * @fileoverview Hook for managing status-related operations
 * @module sensaiAi/hooks/useStatus
 */

import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSensaiStatus } from '../actions/statusActions'
import { setSensaiCredentials } from '../actions/credentialsActions'
import { getSensaiStatus, getStatusError, isFetchingStatus } from '../selectors/statusSelectors'

const useStatus = () => {
  const dispatch = useDispatch()

  // Selectors
  const status = useSelector(getSensaiStatus)
  const error = useSelector(getStatusError)
  const isLoading = useSelector(isFetchingStatus)

  // Callbacks
  const handleFetchStatus = useCallback(() => dispatch(fetchSensaiStatus()), [dispatch])

  // Update credentials when status is fetched
  useEffect(() => {
    if (status?.sensaiStatus?.appSecret) {
      dispatch(setSensaiCredentials(status.sensaiStatus.appSecret, status.sensaiStatus.licenseKey))
    }
  }, [status, dispatch])

  return {
    // State
    status,
    error,
    isLoading,
    // Actions
    fetchStatus: handleFetchStatus,
  }
}

export { useStatus }
export default useStatus
