import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/accounts/constants'
import api from 'api'

// Actions

export const editAccountFailure = error => ({
  payload: error,
  type: actionTypes.EDIT_ACCOUNT_FAILURE,
})

export const editAccountRequest = () => ({
  type: actionTypes.EDIT_ACCOUNT_REQUEST,
})

export const editAccountSuccess = request => ({
  payload: request,
  type: actionTypes.EDIT_ACCOUNT_SUCCESS,
})

// Thunks

export const editAccount = (values, onFinish) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    const handleResponse = response => {
      dispatch(editAccountSuccess(response))
      onFinish()
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(editAccountFailure(error))
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'getAccountInformationError' })))
      }
    }

    api.accounts.editAccount({ accountId, values }, handleResponse, handleError)
  }

  thunk.type = actionTypes.EDIT_ACCOUNT

  return thunk
}

export default editAccount
