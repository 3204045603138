import { pickHTMLProps } from 'pick-react-known-prop'
import CircularProgress from '@material-ui/core/CircularProgress'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import { CHILDREN } from 'constants/props'
import Mask from 'generics/Mask'

import styles from './list.scss'

const List = ({
  accepts,
  canDrop,
  children,
  className,
  componentRef,
  disabled,
  emptyMessage,
  isDragging,
  isFetching,
  isOver,
  ...rest
}) => {
  const listRef = useRef(null)

  useEffect(() => {
    if (componentRef) {
      componentRef(listRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isActive = canDrop && isOver

  return (
    <ul
      className={classnames(
        styles.list,
        { [styles['is-disabled']]: disabled },
        { [styles['is-empty']]: !children },
        { [styles['can-drop']]: canDrop },
        { [styles['is-active']]: isActive },
        className,
      )}
      ref={listRef}
      {...pickHTMLProps(rest)}
    >
      {children || emptyMessage}
      <Mask className={styles.mask} open={isFetching}>
        <CircularProgress className={styles.loader} size={60} thickness={7} />
      </Mask>
    </ul>
  )
}

List.propTypes = {
  accepts: PropTypes.string,
  canDrop: PropTypes.bool,
  children: CHILDREN,
  className: PropTypes.string,
  componentRef: PropTypes.func,
  disabled: PropTypes.bool,
  emptyMessage: PropTypes.string,
  isDragging: PropTypes.bool,
  isFetching: PropTypes.bool,
  isOver: PropTypes.bool,
}

List.defaultProps = {
  accepts: '',
  canDrop: false,
  children: undefined,
  className: null,
  componentRef: undefined,
  disabled: false,
  emptyMessage: undefined,
  isDragging: false,
  isFetching: false,
  isOver: false,
}

export default List
