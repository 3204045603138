/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'

import { EMAIL, NAME, REQUIRED } from 'constants/inputTypes'
import { I18N } from 'constants/props'
import useForm from 'custom_hooks/useForm'
import useFormValidation from 'custom_hooks/useFormValidation'

const usePersonForm = ({ i18n, onSubmit, onValidate, resetOnSubmit }) => {
  const { validator } = useFormValidation({ i18n })

  const handleValidate = values => {
    const { email, firstName, lastName } = values

    return {
      email: validator.validate(REQUIRED, email) || validator.validate(EMAIL, email) || onValidate({ email })?.email,
      firstName: validator.validate(NAME, firstName),
      lastName: validator.validate(NAME, lastName),
    }
  }

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = { email: null, department: null, firstName: null, lastName: null },
  } = useForm({ onSubmit, onValidate: handleValidate, resetOnSubmit })

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  }
}

usePersonForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
}

export default usePersonForm
