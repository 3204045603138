import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/teams/constants'
import api from 'api'

// Actions

export const editTeamFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_EDIT_TEAM_FAILURE,
})

export const editTeamRequest = () => ({
  type: actionTypes.FETCH_EDIT_TEAM_REQUEST,
})

export const editTeamSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_EDIT_TEAM_SUCCESS,
})

// Thunks

export const fetchEditTeam = (options, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(editTeamRequest())

    const handleResponse = response => {
      dispatch(editTeamSuccess(response))
      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(editTeamFailure(error))
      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.teams.editTeam({ accountId, ...options }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_EDIT_TEAM

  return thunk
}

export default fetchEditTeam
