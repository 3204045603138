import { camelCase, compact, isEqual, mapKeys, mapValues, merge, uniq } from 'lodash'
import deepMerge from 'deepmerge'

import { hasScores, name } from 'helpers'
import PERMISSIONS, { mapPermissions } from 'permissions'

import Profile, { toString } from './Profile'

const AllPermissions = PERMISSIONS.reduce(mapPermissions('manage'), {})

/**
 * Class Person
 * @augments Profile
 */
export default class Person extends Profile {
  static mapInstance(array) {
    return array.map(item => new Person(item))
  }

  constructor(options = {}) {
    super(options)

    const defaults = {
      _avatar: {
        url: null,
      },
      _isPerson: true,
      _permissions: null,
      _type: 'person',
      account: {
        accountType: null,
        activeInvites: null,
        enableSso: false,
        expiresAt: null,
        id: null,
        isPersonal: false,
        licenseLimit: 0,
        licensesAllocated: null,
        logo: {
          url: null,
        },
        name: null,
        nameAbbreviation: null,
        remainingLicensesCount: null,
        siteUrl: null,
        totalMembersQuantity: 0,
        totalTeamsQuantity: 0,
      },
      address: null,
      agreedToTermsOfService: false,
      city: null,
      connected: false,
      country: {
        abbreviation: null,
        createdAt: null,
        id: null,
        name: null,
        updatedAt: null,
      },
      createdAt: null,
      division: null,
      email: null,
      firstName: null,
      genderPronounPreference: null,
      id: null,
      jobTitle: null,
      lastName: null,
      lastWorkshop: null,
      mobilePhone: null,
      otherPhone: null,
      overwrittenPolicy: false,
      permissions: null,
      postalCode: null,
      public: false,
      state: null,
      totalTeamsByOwner: 0,
      user: {
        assessmentScores: [null, null, null, null],
        hasActiveAssessment: false,
        hasOngoingAssessment: false,
        id: null,
        selfInvitation: false,
      },
      workPhone: null,
    }

    const optionsKeysMap = {
      avatar: '_avatar',
      agreedToTermsOfService: 'isAgreedToTermsOfService',
      connected: 'isConnected',
      public: 'isPublic',
    }

    const mappedOptions = mapKeys(options, (value, key) => (key in optionsKeysMap ? optionsKeysMap[key] : key))

    merge(this, defaults, mappedOptions)

    if (!this.user.assessmentScores) {
      this.user.assessmentScores = [null, null, null, null]
    }

    const { permissions } = options || {}

    if (permissions && permissions.constructor === Array && permissions.length > 0) {
      this._permissions = permissions.reduce((acc, permission) => {
        const { action, subject: permissionSubject } = mapValues(permission, value => camelCase(value))

        const per =
          permissionSubject === 'all'
            ? AllPermissions
            : PERMISSIONS.filter(({ subject }) => subject === permissionSubject).reduce(mapPermissions(action), {})

        const permissionsMerged = deepMerge(acc, per, {
          arrayMerge: (a, b) => uniq([...a, ...b]),
        })

        const manage = Object.keys(permissionsMerged).reduce((manageActions, subject) => {
          const actions = permissionsMerged[subject]

          return isEqual(AllPermissions[subject], actions) ? [...manageActions, subject] : manageActions
        }, [])

        if (manage.length === Object.keys(AllPermissions).length) {
          manage.push('all')
        }

        return {
          ...permissionsMerged,
          manage,
        }
      }, {})
    }
  }

  get avatar() {
    return this._avatar.url
  }

  get isSSOAccount() {
    return this.account.enableSso
  }

  get assessmentScores() {
    const { assessmentScores, hasActiveAssessment } = this.user

    assessmentScores.isNull = !hasScores(assessmentScores)

    assessmentScores.toString =
      hasActiveAssessment || assessmentScores.isNull
        ? () => 'This information is not available' // TODO: Use translations
        : toString

    assessmentScores.toStringWithoutValidate = toString

    return assessmentScores
  }

  /**
   * Getter method for fullAddress
   * @returns {string} fullAddress
   */
  get fullAddress() {
    const addressData = [this.address, this.city, this.state, this.postalCode]

    if (this.country) {
      addressData.push(this.country.name)
    }

    return compact(addressData).join(', ')
  }

  get isVisible() {
    return (this.isConnected || this.isPublic || this.overwrittenPolicy) && !this.assessmentScores.isNull
  }

  get legend() {
    return this._legend
  }

  set legend(legend) {
    this._legend = legend
    this.hasLegend = true
  }

  get name() {
    return name.call(this)
  }

  // TODO: Add documentation
  // Example
  // Person.can({
  //   manage: [
  //     'account',
  //     'bundle',
  //     'connectionRequest',
  //     'contentManager',
  //     'pdfs',
  //     'profile',
  //     'team',
  //   ],
  //   account: 'show',
  //   invite: [
  //     'index',
  //     'sendInvitationManually',
  //     'sendInvitationViaCsv',
  //   ],
  // },
  // {
  //   manage: 'team',
  // });
  can() {
    if (!this._permissions || this._permissions.constructor !== Object) {
      return false
    }
    return Array.from(arguments).reduce(
      (can, requiredPermission) =>
        can ||
        Object.keys(requiredPermission).every(subject => {
          if (!(subject in this._permissions)) {
            return false
          }

          const requiredAction = requiredPermission[subject]

          const requiredActions = requiredAction.constructor === Array ? requiredAction : [requiredAction]

          return requiredActions.every(action => !!this._permissions[subject].includes(action))
        }),
      false,
    )
  }

  // TODO: Add documentation
  // Person.hasRole([ROLE_A, ROLE_B], ROLE_C) === (ROLE_A && ROLE_B) || ROLE_C
  hasRole() {
    return Array.from(arguments).reduce((hasRole, role) => {
      const roles = role.constructor === Array ? role : [role]
      return hasRole || roles.every(singleRole => this.can(singleRole))
    }, false)
  }
}
