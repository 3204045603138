import { Col, Row } from 'reactstrap'
import { isNil } from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { ACCEPTED, PENDING_PRO_MEMBER } from 'constants/invitationStatus'
import { Person } from 'classes'
import { RECORDS } from 'constants/props'
import { setIterationIndex } from 'helpers'
import ListItem from 'generics/ListItem'

import ModalErrors from '../ModalErrors'
import styles from './SelectedRecords.scss'

/**
 * @description Creates a new Person instance based on the invitation
 * @param { Object } invitation
 * @returns { Person } person
 */
function getProfileFromRecord(record) {
  return new Person({
    account: {
      id: record.id,
      isPersonal: true,
      name: record.firstName,
    },
    agreedToTermsOfService: true,
    genderPronounPreference: 'sr',
    ...record,
  })
}

const SelectedRecords = ({ className, isDepartmentEnabled, list, onEditEmail, onRemove, onValidate }) => {
  setIterationIndex(list)

  return (
    <Row>
      <Col xs="12">
        <ul className={classnames(styles.list, className)}>
          {list.map((record, idx) => {
            const profile = getProfileFromRecord(record)

            profile.account.id = idx
            profile.id = idx

            const canEdit = onEditEmail && (isNil(profile.status) || profile.status === PENDING_PRO_MEMBER)

            const onChangeEmail = canEdit ? ({ email }) => onEditEmail(record.id, email) : undefined

            const errorMessages = <ModalErrors errors={profile.errors} />

            return (
              <ListItem
                canDrag={record.status !== ACCEPTED}
                errors={errorMessages}
                isDepartmentEnabled={isDepartmentEnabled}
                key={`list-item-${profile.id}`}
                onRequestRemove={() => {
                  onRemove(record.id)
                }}
                profile={profile}
                onChangeEmail={onChangeEmail}
                onValidate={onValidate}
              />
            )
          })}
        </ul>
      </Col>
    </Row>
  )
}

SelectedRecords.propTypes = {
  className: PropTypes.string,
  isDepartmentEnabled: PropTypes.bool.isRequired,
  list: RECORDS.isRequired,
  onEditEmail: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
}

SelectedRecords.defaultProps = {
  className: null,
  onEditEmail: undefined,
  onValidate: undefined,
}

export default SelectedRecords
