import PropTypes from 'prop-types'

import { EMAIL, REQUIRED } from 'constants/inputTypes'
import { I18N } from 'constants/props'
import useForm from 'custom_hooks/useForm'
import useFormValidation from 'custom_hooks/useFormValidation'

const useFormLogin = ({ i18n, onSubmit }) => {
  const { validator } = useFormValidation({ i18n })

  const handleValidate = values => {
    const { email, password } = values

    return {
      email: validator.validate(REQUIRED, email) || validator.validate(EMAIL, email),
      password: !password ? i18n.login.form.errors.password : null,
    }
  }

  const { errors, isValid, handleChange, handleSubmit, values = { email: null, password: null } } = useForm({
    onSubmit,
    onValidate: handleValidate,
  })

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  }
}

useFormLogin.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
}

export default useFormLogin
