export const draw = props => {
  const { isPair, lib } = props

  const {
    drawBands,
    drawDot,
    drawProfiles,
    drawQuadrantsLabels,
    updateQuadrantsLabelsGeometry,
    drawLegend,
    updateLegendGeometry,
  } = lib

  // References
  drawBands(props)
  drawDot(props)

  // Profiles
  drawProfiles(props)

  // Labels
  const quadrantsText = drawQuadrantsLabels(props)
  if (quadrantsText) {
    updateQuadrantsLabelsGeometry({ ...props, quadrantsText })
  }

  // Legend
  if (isPair) {
    const legendItem = drawLegend(props)
    updateLegendGeometry({ legendItem, ...props })
  }
}
