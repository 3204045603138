import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/network/constants'
import api from 'api'

// Actions

export const fetchMyNetworkFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_MY_NETWORK_FAILURE,
})

export const fetchMyNetworkRequest = (pageIndex, search) => ({
  payload: {
    pageIndex,
    search,
  },
  type: actionTypes.FETCH_MY_NETWORK_REQUEST,
})

export const fetchMyNetworkSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_MY_NETWORK_SUCCESS,
})

// Thunks

export const fetchMyNetwork = ({ includeAll = false, pageIndex, search, teamId = null }, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchMyNetworkRequest(pageIndex, search))

    const handleResponse = response => {
      dispatch(fetchMyNetworkSuccess(response))
      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchMyNetworkFailure(error))
      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.profiles.getMyNetwork(
      {
        accountId,
        includeAll,
        pageIndex,
        search,
        teamId,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_MY_NETWORK

  return thunk
}

export default fetchMyNetwork
