import { sprintf } from 'sprintf-js'
import classnames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'

import { I18N, WORKSHOP } from 'constants/props'
import { Separator } from 'generics/Card'
import { StyledButton } from 'generics/StyledFormComponents'
import BackButton from 'generics/BackButton'
import Icon from 'generics/Icon'
import Search from 'generics/Search'
import Translation from 'generics/Translation'

import styles from './ListPeopleWorkshopListHeader.scss'

const ListPeopleWorkshopListHeader = ({
  canEdit,
  i18n,
  onClickBackButton,
  onClickEditButton,
  onReset,
  onSearch,
  workshop,
}) => {
  let search = useRef(null)

  const handleEdit = () => {
    search.reset(onClickEditButton)
  }

  const { establishedAt, name } = workshop

  const date = moment.utc(new Date(establishedAt)).format('L')

  return (
    <>
      <div className={styles.header}>
        <BackButton className={styles['header__back-button']} onClick={onClickBackButton} />
        <div className={styles.header__row}>
          <div className={classnames(styles.header__col, styles['header__col--left'])}>
            <Icon.Stroke7 name="share" />
          </div>
          <div className={classnames(styles.header__col, styles['header__col--right'])}>
            <div className={styles.header__name}>
              <span title={name}>{name}</span>
            </div>
            <div className={classnames(styles.header__row, styles['header__row--wrap'])}>
              <div className={styles.header__dates}>
                <p className={styles['header__date-created']} title={sprintf(i18n.workshops.listItem.date, { date })}>
                  <span className={styles['header__date-created-label']}>{i18n.workshops.listItem.dateTitle}</span>
                  <time className={styles['header__date-created-time']} dateTime={date}>
                    {date}
                  </time>
                </p>
              </div>
              <Search
                className={styles.search}
                floatingLabelText={i18n.workshops.searchFilter}
                onChange={onSearch}
                onReset={onReset}
                innerRef={component => {
                  search = component
                }}
              />
            </div>
          </div>
          {canEdit && (
            <StyledButton
              className={styles.header__edit}
              color="secondary"
              onClick={handleEdit}
              title={i18n.generics.edit}
              variant="text"
            >
              {i18n.generics.edit}
            </StyledButton>
          )}
        </div>
      </div>
      <Separator />
    </>
  )
}

ListPeopleWorkshopListHeader.propTypes = {
  canEdit: PropTypes.bool,
  i18n: I18N.isRequired,
  onClickBackButton: PropTypes.func,
  onClickEditButton: PropTypes.func,
  onReset: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  workshop: WORKSHOP.isRequired,
}

ListPeopleWorkshopListHeader.defaultProps = {
  canEdit: false,
  onClickBackButton: undefined,
  onClickEditButton: undefined,
}

export default Translation(ListPeopleWorkshopListHeader, ['generics', 'workshops'])
