import { Col, Container, Row } from 'reactstrap'
import { NavLink as Link } from 'react-router-dom'
import { sprintf } from 'sprintf-js'
import classnames from 'classnames'
import htmlParser from 'html-react-parser'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import { TERMS_OF_USE } from 'urls'
import Translation from 'generics/Translation'

import styles from './ApplicationFooter.scss'

const FULL_YEAR = new Date().getFullYear()

const Footer = ({ className, i18n }) => (
  <footer className={classnames(styles.footer, className)}>
    <Container>
      <Row>
        <Col xs="12" className={styles['privacy-policy']}>
          {htmlParser(sprintf(i18n.applicationFooter.copyRightHtml, FULL_YEAR))}
          <a
            href="https://www.simpli5.com/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
            title={i18n.applicationFooter.privacyPolicy}
            to="https://www.simpli5.com/privacy-policy/"
          >
            {i18n.applicationFooter.privacyPolicy}
          </a>
          <Link href={TERMS_OF_USE} target="_blank" title={i18n.applicationFooter.terms} to={TERMS_OF_USE}>
            {i18n.applicationFooter.terms}
          </Link>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className={styles['copy-right-text']}>
          {i18n.applicationFooter.copyRightText}
        </Col>
      </Row>
    </Container>
  </footer>
)

Footer.propTypes = {
  className: PropTypes.string,
  i18n: I18N.isRequired,
}

Footer.defaultProps = {
  className: null,
}

export default Translation(Footer, ['applicationFooter'])
