import PropTypes from 'prop-types'

import { I18N } from 'constants/props'
import useForm from 'custom_hooks/useForm'

const useFormEditOverviewReportCustomization = ({ initialValues, onSubmit }) => {
  const handleValidate = () => ({
    overviewRptIntro: null,
  })

  const { errors, isValid, handleChange, handleSubmit, values = { overviewRptIntro: null }, reset } = useForm({
    initialValues,
    onSubmit,
    onValidate: handleValidate,
  })

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    reset,
    values,
  }
}

useFormEditOverviewReportCustomization.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
}

export default useFormEditOverviewReportCustomization
