import { FormControl, FormHelperText, InputLabel, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { CHILDREN } from 'constants/props'
import { COLORS } from 'constants/colors'
import Icon from 'generics/Icon'

const borderColor = 'rgba(155, 155, 155, 0.5)'
const borderBottom = `1px solid ${borderColor}`
const lightGray = COLORS.grey3.rgba

const styles = makeStyles({
  formControl: {
    width: '100%',
    minHeight: '70px',
    '& .MuiInputLabel-root': {
      color: lightGray,

      '& .MuiFormLabel-asterisk': {
        color: 'red',
      },
    },
  },
  select: {
    '&.MuiInput-underline:before': {
      borderBottom,
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom,
    },
    '& .MuiSelect-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiListItem-root.Mui-selected': {
      background: 'transparent',
    },
  },
})

const StyledSelect = ({
  ariaLabel,
  className,
  color,
  children,
  error,
  formControlClassName,
  helperText,
  iconClassName,
  label,
  name,
  required,
  ...rest
}) => {
  const classes = styles()

  return (
    <FormControl className={classnames(formControlClassName, classes.formControl)} error={error}>
      {label && (
        <InputLabel required={required} id={`label-${name}`}>
          {label}
        </InputLabel>
      )}
      <Select
        className={classnames(className, classes.select)}
        color={color}
        fullWidth
        IconComponent={props => (
          <Icon.Stroke7 {...props} name="angle-down" className={classnames('MuiSelect-icon', iconClassName)} />
        )}
        SelectDisplayProps={{
          'aria-label': ariaLabel || label,
          'aria-labelledby': null,
        }}
        {...rest}
      >
        {children}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

StyledSelect.propTypes = {
  ariaLabel: PropTypes.string,
  children: CHILDREN,
  className: PropTypes.string,
  color: PropTypes.string,
  error: PropTypes.bool,
  formControlClassName: PropTypes.string,
  helperText: PropTypes.string,
  iconClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
}

StyledSelect.defaultProps = {
  ariaLabel: null,
  children: null,
  className: null,
  color: 'primary',
  error: false,
  formControlClassName: null,
  helperText: ' ',
  iconClassName: null,
  label: null,
  name: 'styled-select',
  required: false,
}

export default StyledSelect
