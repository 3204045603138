import { BAR_NOTIFICATION } from 'constants/notificationTypes'

import AppError from './AppError.class'

export default class BarError extends AppError {
  constructor(error) {
    super({
      ...error,
      notificationType: BAR_NOTIFICATION,
    })
  }
}
