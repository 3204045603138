export const en = {
  createWorkshop: {
    createLabel: 'Create Workshop',
    date: 'Date',
    editLabel: 'Edit Workshop',
    fieldErrors: 'Errors found while creating the workshop',
    fileError: 'Invalid File, only csv files are accepted',
    fileReaderDescription: 'Upload a list of people to create a workshop',
    formDescription: 'Please add workshop name and date.',
    name: 'Name',
    resultMessage: {
      failed: 'We could not add the following people to the workshop, there are errors in the data submitted',
      removed: 'The following people were removed from the workshop',
      success: 'The following people were added to the workshop',
    },
  },
  editWorkshop: {
    enterMemberMessage: 'Enter an email address to join this workshop',
    formDescription: 'Please add a workshop name and date',
  },
  listItem: {
    date: 'Workshop Date: %(date)s',
    dateTitle: 'Workshop Date',
  },
  searchFilter: 'Find a member',
  status: ['Complete', 'Assessment Pending', 'Pre-Authorized'],
}

export const es = en
