/* eslint-disable no-use-before-define */
import { Col, Container, Row } from 'reactstrap'
import { find } from 'lodash'
import React, { useState } from 'react'

import { ACTIONS, I18N, PEOPLE, PERSON } from 'constants/props'
import { Card, Header, Main } from 'generics/Card'
import { fromCallbacksToPromise } from 'helpers'
import { USERS_UPDATE_PASSWORD } from 'constants/apiErrorCodes'
import { StyledButton } from 'generics/StyledFormComponents'
import api from 'api'
import DefinitionList from 'generics/DefinitionList'
import FormChangePassword from 'components/FormChangePassword'
import Translation from 'generics/Translation'

import styles from './AccountPreferences.scss'

/**
 *  AccountPreferences component
 *  @extends Component
 */
const AccountPreferences = ({ i18n, notificationActions, profile, profiles }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [passwordError, setPasswordError] = useState(null)

  /**
   * Gets the Account Information Data by section
   */

  const getData = () => {
    if (!profile) {
      return []
    }

    const { isSSOAccount, name } = profile

    const infoSections = !isSSOAccount
      ? [
          {
            fieldList: getPasswordFields(),
            subTitle: (
              <>
                <span>{i18n.pageGeneralAccountInformation.sections.headerLoginInformation}</span>
                {renderEditLink()}
              </>
            ),
          },
        ]
      : []

    infoSections.push({
      fieldList: profiles.map(({ account, email }) => ({
        children: email,
        label: account.name,
      })),
      subTitle: i18n.pageGeneralAccountInformation.sections.headerEmailAddresses,
    })

    if (find(profiles, { account: { isPersonal: true } })) {
      infoSections.unshift({
        fieldList: [
          {
            children: name,
            label: i18n.generics.name,
          },
        ],
        subTitle: i18n.pageGeneralAccountInformation.sections.headerPersonalInformation,
      })
    }

    return infoSections
  }

  const getPasswordFields = () => {
    if (isEditing) {
      return [
        {
          children: (
            <FormChangePassword
              col={{ xs: '12', md: '6' }}
              error={passwordError}
              isSubmitting={isFetching}
              onSubmit={handleChangePasswordSubmit}
              hasCurrentPassword
            />
          ),
        },
      ]
    }

    return [
      {
        children: i18n.pageGeneralAccountInformation.maskPassword,
        label: i18n.generics.password,
      },
    ]
  }

  /**
   *  Handles open / close events for Change Password Form
   */

  const handleOpenCloseChangePasswordForm = () => {
    setIsEditing(!isEditing)
  }

  /**
   * Handles submit event
   */

  const handleChangePasswordSubmit = data => {
    setIsFetching(true)

    const onSuccess = () => {
      setIsEditing(false)
      setIsFetching(false)
      notificationActions.notifySuccess(i18n.login.reset.toast.success.passwordChanged)
    }

    // We need a promise in this case otherwise Redux Form can not handle the Submission Error
    return fromCallbacksToPromise(api.users.changePassword, data)
      .then(onSuccess)
      .catch(({ error }) => {
        setIsFetching(false)

        if (error.errorCode === USERS_UPDATE_PASSWORD) {
          setPasswordError(i18n.generics.errors.password.wrongPassword)
        } else {
          notificationActions.notifyError(error)
        }
      })
  }

  // eslint-disable-next-line arrow-body-style
  const renderEditLink = () => {
    return !isEditing ? (
      <StyledButton
        className={styles.link}
        color="secondary"
        onClick={handleOpenCloseChangePasswordForm}
        title={i18n.pageGeneralAccountInformation.btnTextChangePassword}
        variant="text"
      >
        {i18n.pageGeneralAccountInformation.btnTextChangePassword}
      </StyledButton>
    ) : (
      <StyledButton
        className={styles.link}
        color="secondary"
        onClick={handleOpenCloseChangePasswordForm}
        title={i18n.generics.cancelLabel}
        variant="text"
      >
        {i18n.generics.cancelLabel}
      </StyledButton>
    )
  }

  /**
   * Renders Form Fields
   */
  const renderFields = () => (
    <div className={styles['main-container']}>
      {getData().map(infoSection => {
        const { fieldList, subTitle } = infoSection
        return (
          <div className={styles['info-section']} key={subTitle}>
            <h2 className={styles['sub-title']}>{subTitle}</h2>
            <div className={styles['field-list']}>
              <DefinitionList className={styles['definition-list']} data={fieldList} />
            </div>
          </div>
        )
      })}
    </div>
  )

  /**
   * Component main render
   */
  return (
    <Card className={styles['card-container']} isFetching={isFetching}>
      <Header className={styles.header} title={i18n.pageGeneralAccountInformation.headerAccountPreferences} />

      <Main>
        <Container>
          <Row>
            <Col>{renderFields()}</Col>
          </Row>
        </Container>
      </Main>
    </Card>
  )
}

AccountPreferences.propTypes = {
  i18n: I18N.isRequired,
  notificationActions: ACTIONS.isRequired,
  profile: PERSON.isRequired,
  profiles: PEOPLE.isRequired,
}

export default Translation(AccountPreferences, ['login', 'pageGeneralAccountInformation', 'generics'])
