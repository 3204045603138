const NAME_SPACE = 'Network'

const actionTypes = {
  ADMIN_UPDATE_EMAIL_SUCCESS: `${NAME_SPACE}/ADMIN_UPDATE_EMAIL_SUCCESS`,
  FETCH_CONNECTION_REQUESTS_FAILURE: `${NAME_SPACE}/FETCH_CONNECTION_REQUESTS_FAILURE`,
  FETCH_CONNECTION_REQUESTS_REQUEST: `${NAME_SPACE}/FETCH_CONNECTION_REQUESTS_REQUEST`,
  FETCH_CONNECTION_REQUESTS_SUCCESS: `${NAME_SPACE}/FETCH_CONNECTION_REQUESTS_SUCCESS`,
  FETCH_CONNECTION_REQUESTS: `${NAME_SPACE}/FETCH_CONNECTION_REQUESTS`,
  FETCH_MY_CONNECTIONS_FAILURE: `${NAME_SPACE}/FETCH_MY_CONNECTIONS_FAILURE`,
  FETCH_MY_CONNECTIONS_REQUEST: `${NAME_SPACE}/FETCH_MY_CONNECTIONS_REQUEST`,
  FETCH_MY_CONNECTIONS_SUCCESS: `${NAME_SPACE}/FETCH_MY_CONNECTIONS_SUCCESS`,
  FETCH_MY_CONNECTIONS: `${NAME_SPACE}/FETCH_MY_CONNECTIONS`,
  FETCH_MY_NETWORK_FAILURE: `${NAME_SPACE}/FETCH_MY_NETWORK_FAILURE`,
  FETCH_MY_NETWORK_REQUEST: `${NAME_SPACE}/FETCH_MY_NETWORK_REQUEST`,
  FETCH_MY_NETWORK_SUCCESS: `${NAME_SPACE}/FETCH_MY_NETWORK_SUCCESS`,
  FETCH_MY_NETWORK: `${NAME_SPACE}/FETCH_MY_NETWORK`,
  FETCH_NETWORK_DETAILS_FAILURE: `${NAME_SPACE}/FETCH_NETWORK_DETAILS_FAILURE`,
  FETCH_NETWORK_DETAILS_REQUEST: `${NAME_SPACE}/FETCH_NETWORK_DETAILS_REQUEST`,
  FETCH_NETWORK_DETAILS_SUCCESS: `${NAME_SPACE}/FETCH_NETWORK_DETAILS_SUCCESS`,
  FETCH_NETWORK_DETAILS: `${NAME_SPACE}/FETCH_NETWORK_DETAILS`,
  FETCH_NETWORK_LISTS: `${NAME_SPACE}/FETCH_NETWORK_LISTS`,
  FETCH_RECEIVED_REQUESTS_FAILURE: `${NAME_SPACE}/FETCH_RECEIVED_REQUESTS_FAILURE`,
  FETCH_RECEIVED_REQUESTS_REQUEST: `${NAME_SPACE}/FETCH_RECEIVED_REQUESTS_REQUEST`,
  FETCH_RECEIVED_REQUESTS_SUCCESS: `${NAME_SPACE}/FETCH_RECEIVED_REQUESTS_SUCCESS`,
  FETCH_RECEIVED_REQUESTS: `${NAME_SPACE}/FETCH_RECEIVED_REQUESTS`,
  FETCH_REPLY_CONNECTION_REQUEST_FAILURE: `${NAME_SPACE}/FETCH_REPLY_CONNECTION_REQUEST_FAILURE`,
  FETCH_REPLY_CONNECTION_REQUEST_REQUEST: `${NAME_SPACE}/FETCH_REPLY_CONNECTION_REQUEST_REQUEST`,
  FETCH_REPLY_CONNECTION_REQUEST_SUCCESS: `${NAME_SPACE}/FETCH_REPLY_CONNECTION_REQUEST_SUCCESS`,
  FETCH_REPLY_CONNECTION_REQUEST: `${NAME_SPACE}/FETCH_REPLY_CONNECTION_REQUEST`,
  FETCH_SEND_CONNECTION_REQUEST_FAILURE: `${NAME_SPACE}/FETCH_SEND_CONNECTION_REQUEST_FAILURE`,
  FETCH_SEND_CONNECTION_REQUEST_REQUEST: `${NAME_SPACE}/FETCH_SEND_CONNECTION_REQUEST_REQUEST`,
  FETCH_SEND_CONNECTION_REQUEST_SUCCESS: `${NAME_SPACE}/FETCH_SEND_CONNECTION_REQUEST_SUCCESS`,
  FETCH_SEND_CONNECTION_REQUEST: `${NAME_SPACE}/FETCH_SEND_CONNECTION_REQUEST`,
  FETCH_SENT_REQUESTS_FAILURE: `${NAME_SPACE}/FETCH_SENT_REQUESTS_FAILURE`,
  FETCH_SENT_REQUESTS_REQUEST: `${NAME_SPACE}/FETCH_SENT_REQUESTS_REQUEST`,
  FETCH_SENT_REQUESTS_SUCCESS: `${NAME_SPACE}/FETCH_SENT_REQUESTS_SUCCESS`,
  FETCH_SENT_REQUESTS: `${NAME_SPACE}/FETCH_SENT_REQUESTS`,
  FETCH_THIRD_PERSON_PROFILE_FAILURE: `${NAME_SPACE}/FETCH_THIRD_PERSON_PROFILE_FAILURE`,
  FETCH_THIRD_PERSON_PROFILE_REQUEST: `${NAME_SPACE}/FETCH_THIRD_PERSON_PROFILE_REQUEST`,
  FETCH_THIRD_PERSON_PROFILE_SUCCESS: `${NAME_SPACE}/FETCH_THIRD_PERSON_PROFILE_SUCCESS`,
  FETCH_THIRD_PERSON_PROFILE: `${NAME_SPACE}/FETCH_THIRD_PERSON_PROFILE`,
  FETCH_THIRD_PERSON_ROLES_FAILURE: `${NAME_SPACE}/FETCH_THIRD_PERSON_ROLES_FAILURE`,
  FETCH_THIRD_PERSON_ROLES_REQUEST: `${NAME_SPACE}/FETCH_THIRD_PERSON_ROLES_REQUEST`,
  FETCH_THIRD_PERSON_ROLES_SUCCESS: `${NAME_SPACE}/FETCH_THIRD_PERSON_ROLES_SUCCESS`,
  FETCH_THIRD_PERSON_ROLES: `${NAME_SPACE}/FETCH_THIRD_PERSON_ROLES`,
  FETCH_UPDATE_PROFILE_ROLES_FAILURE: `${NAME_SPACE}/FETCH_UPDATE_PROFILE_ROLES_FAILURE`,
  FETCH_UPDATE_PROFILE_ROLES_REQUEST: `${NAME_SPACE}/FETCH_UPDATE_PROFILE_ROLES_REQUEST`,
  FETCH_UPDATE_PROFILE_ROLES_SUCCESS: `${NAME_SPACE}/FETCH_UPDATE_PROFILE_ROLES_SUCCESS`,
  FETCH_UPDATE_PROFILE_ROLES: `${NAME_SPACE}/FETCH_UPDATE_PROFILE_ROLES`,
}

export default actionTypes
