import { bindActionCreators } from 'redux'
import { Col, Container, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import React from 'react'

import { Card, Header, Main } from 'generics/Card'
import { COLORS } from 'constants/colors'
import Mask from 'generics/Mask'
import Translation from 'generics/Translation'

import { ACTIONS, I18N } from 'constants/props'
import { StyledButton } from 'generics/StyledFormComponents'
import * as notificationActions from 'app_modules/notifications/actions'

import styles from './modalNotification.scss'

const Invitation = ({ actions, i18n, open, options }) => {
  const handleAccept = () => {
    actions.clearModalNotification()

    const { onAccept } = options

    if (onAccept) {
      onAccept()
    }
  }

  const { buttonLabel, title, subtitle, message } = options
  const label = buttonLabel || i18n.generics.acceptLabel

  return (
    <Mask fixed open={open}>
      <Card barBackgroundColor={COLORS.primaryBlue.rgba} center className={styles.card}>
        <Header>
          <Container>
            <Row>
              <Col>
                <h1 className={styles.title}>{title}</h1>
              </Col>
            </Row>
          </Container>
        </Header>
        <Main>
          <Container>
            {subtitle && (
              <Row>
                <Col>
                  <p className={styles.subtitle}>{subtitle}</p>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <p className={styles.message}>{message}</p>
              </Col>
            </Row>
            <Row className={styles.buttons}>
              <Col xs="12" sm={{ size: 6, offset: 6 }}>
                <StyledButton
                  className={styles.mask__button}
                  color="primary"
                  fullWidth
                  onClick={handleAccept}
                  title={label}
                >
                  {label}
                </StyledButton>
              </Col>
            </Row>
          </Container>
        </Main>
      </Card>
    </Mask>
  )
}

Invitation.propTypes = {
  actions: ACTIONS.isRequired,
  i18n: I18N.isRequired,
  open: PropTypes.bool,
  options: PropTypes.shape(),
}

Invitation.defaultProps = {
  open: false,
  options: {},
}

const mapStateToProps = state => ({
  isAuthenticated: state.session.isAuthenticated,
  open: state.notifications.modal.open,
  options: state.notifications.modal.options,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(notificationActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Translation(Invitation, ['generics'])))
