const NAME_SPACE = 'Training'

const constants = {
  FETCH_COMPLETE_VIDEO_FAILURE: `${NAME_SPACE}/FETCH_COMPLETE_VIDEO_FAILURE`,
  FETCH_COMPLETE_VIDEO_REQUEST: `${NAME_SPACE}/FETCH_COMPLETE_VIDEO_REQUEST`,
  FETCH_COMPLETE_VIDEO_SUCCESS: `${NAME_SPACE}/FETCH_COMPLETE_VIDEO_SUCCESS`,
  FETCH_COMPLETE_VIDEO: `${NAME_SPACE}/FETCH_COMPLETE_VIDEO`,
  FETCH_VIDEOS_FAILURE: `${NAME_SPACE}/FETCH_VIDEOS_FAILURE`,
  FETCH_VIDEOS_REQUEST: `${NAME_SPACE}/FETCH_VIDEOS_REQUEST`,
  FETCH_VIDEOS_SUCCESS: `${NAME_SPACE}/FETCH_VIDEOS_SUCCESS`,
  FETCH_VIDEOS: `${NAME_SPACE}/FETCH_VIDEOS`,
}

export default constants
