import React, { Component } from 'react'

import WelcomeCardSlider from './components/WelcomeCardSlider'

class PageWelcome extends Component {
  render() {
    return (
      <>
        {WelcomeCardSlider && (
          <WelcomeCardSlider
            refSlider={component => {
              this.slider = component
            }}
          />
        )}
      </>
    )
  }
}

export default PageWelcome
