import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import * as networkActions from 'app_modules/network/actions'
import * as notificationActions from 'app_modules/notifications/actions'
import * as networkSelectors from 'app_modules/network/selectors'
import PageNetworkDashboard from 'components/PageNetworkDashboard'

const mapStateToProps = state => ({
  modules: networkSelectors.thirdPerson.modules(state),
  profile: networkSelectors.thirdPerson.profile(state),
})

const mapDispatchToProps = dispatch => ({
  networkActions: bindActionCreators(networkActions, dispatch),
  notificationActions: bindActionCreators(notificationActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageNetworkDashboard))
