import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import InfoTooltip from 'generics/InfoTooltip'
import Translation from 'generics/Translation'

import styles from './FieldTitle.scss'

/**
 * FieldTitle Component
 */
const FieldTitle = props => {
  const { i18n, fieldName, infoTooltip, isRequired } = props

  return (
    <div className={styles['field-title']}>
      <span title={fieldName}>{fieldName}</span>
      {!isEmpty(infoTooltip) && <InfoTooltip text={infoTooltip} />}
      {isRequired && (
        <span className={styles.required} title={i18n.generics.requiredField}>
          *
        </span>
      )}
    </div>
  )
}

FieldTitle.propTypes = {
  i18n: I18N.isRequired,
  fieldName: PropTypes.string.isRequired,
  infoTooltip: PropTypes.string,
  isRequired: PropTypes.bool,
}

FieldTitle.defaultProps = {
  infoTooltip: null,
  isRequired: false,
}

export const FieldTitleComponent = FieldTitle

export default Translation(FieldTitle, ['generics'])
