import { Person, Team } from 'classes'

export const initializeData = data => {
  let newDataInit

  switch (data.constructor) {
    case Person || Team:
      newDataInit = [data]
      break

    case Array:
      newDataInit = data.slice(0, 2)
      break

    default:
      throw new Error("'data' prop data type must be Object or Array")
  }

  const isPairData = newDataInit.length === 2

  return {
    isPairData,
    newDataInit,
  }
}
