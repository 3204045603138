export const en = {
  assessmentInProgress: [
    "Ok great, let's get started",
    'You’ll see two pairs of words on each screen. Select the word pair that is ',
    'more like you',
    ', not how you would like to be.',
    'If you find the choice difficult, use your intuition and select the word pair that feels more like you.',
    'Please do not hit the back button at any time.',
  ],
  buttonRelax: {
    no: ['HMMM,', 'maybe this isn&#39;t the best time'],
    yes: ['YES!', 'I&#39;m free from all distractions'],
  },
  continue: 'Continue',
  continueAssessment: 'Continue Assessment',
  p: [
    'Understanding the innate way you work, learn, create and collaborate is a required element for success and satisfaction in all personal, learning and professional relationships.',
    'Take this short',
    ' (2-3 minutes) ',
    'assessment to discover your natural energetic preferences and begin unlocking your best you.',
    'Once you begin the assessment you',
    'will not be able to go back or to begin again',
    'so make sure you have three minutes to focus on this task and will not be interrupted.',
    'Are you in a relaxed setting?',
  ],
  startAssessment: 'Start Assessment',
  welcomeHeader: 'Welcome,',
}

export const es = en
