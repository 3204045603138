import actionTypes from 'app_modules/network/constants'
import fetchReceivedRequests from 'app_modules/network/actions/fetchReceivedRequests'
import fetchSentRequests from 'app_modules/network/actions/fetchSentRequests'

// Actions

export const fetchConnectionRequestsFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_CONNECTION_REQUESTS_FAILURE,
})

export const fetchConnectionRequestsRequest = () => ({
  type: actionTypes.FETCH_CONNECTION_REQUESTS_REQUEST,
})

export const fetchConnectionRequestsSuccess = () => ({
  type: actionTypes.FETCH_CONNECTION_REQUESTS_SUCCESS,
})

// Thunks

export const fetchConnectionRequests = ({ list, pageIndex }) => {
  const thunk = dispatch => {
    dispatch(fetchConnectionRequestsRequest())

    switch (list) {
      case 'received':
        return dispatch(fetchReceivedRequests(pageIndex))

      case 'sent':
        return dispatch(fetchSentRequests(pageIndex))

      default:
        throw new Error("'list' parameter is not defined")
    }
  }

  thunk.type = actionTypes.FETCH_CONNECTION_REQUESTS

  return thunk
}

export default fetchConnectionRequests
