import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { ACTIONS, CHILDREN, HISTORY, I18N, ICON, STYLE } from 'constants/props'
import { Card } from 'generics/Card'
import { COLORS } from 'constants/colors'
import { StyledButton } from 'generics/StyledFormComponents'
import * as accountSelectors from 'app_modules/accounts/selectors'
import * as pageActions from 'app_modules/page/actions'
import * as proMemberActions from 'app_modules/proMember/actions'
import InformationCardHeader from 'generics/InformationCardHeader'
import Modal from 'components/Modal'
import Translation from 'generics/Translation'

import styles from './ModulesListItem.scss'

const ModulesListItem = ({
  actions,
  category,
  categoryColor,
  children,
  className = null,
  description = undefined,
  disabled,
  goToUrl,
  history,
  i18n,
  icon = null,
  isComingSoon,
  isProMember,
  modalCardClassName,
  onClick,
  proMemberActionsProps,
  showBullets,
  style = undefined,
  title,
  viewDetails,
  isVideo,
  isLimitedMember,
  ...rest
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const grantAccess = isProMember || isVideo || !isLimitedMember

  const handleClose = () => setModalIsOpen(false)

  const handleOpen = () => {
    if (disabled) return
    setModalIsOpen(true)
  }

  const handleClick = e => {
    if (e) e.preventDefault()

    if (goToUrl) {
      actions.setModule({
        bar: {
          backgroundColor: categoryColor,
        },
        styles: {
          backgroundColor: COLORS.primaryWhite.rgba,
        },
      })
      history.push(goToUrl)
    }

    if (children) handleOpen()
  }

  const toggleProMemberModal = () => proMemberActionsProps?.toggleProMemberModal()

  return (
    <Card
      barBackgroundColor={categoryColor}
      barClassName={styles.bar}
      category={category}
      className={classnames(styles.card, className, { [styles['card--is-not-clickeable']]: isComingSoon })}
      disabled={disabled}
      onClick={grantAccess ? onClick || handleClick : toggleProMemberModal}
      style={style}
      title={title}
      {...rest}
    >
      <InformationCardHeader
        className={styles.header}
        category={category}
        categoryColor={categoryColor}
        icon={icon}
        title={title}
      />

      <main className={styles.main}>
        {!showBullets && <p>{description}</p>}
        {showBullets && (
          <div
            className={classnames('epic', styles['epic-content'])}
            /* eslint-disable react/no-danger */
            dangerouslySetInnerHTML={{ __html: description }}
            /* eslint-enable */
          />
        )}
        {isComingSoon && (
          <div className={styles['comming-soon']}>
            <StyledButton disabled title={i18n.modulesListItem.comingSoon}>
              {i18n.modulesListItem.comingSoon}
            </StyledButton>
          </div>
        )}
        <div className={styles['learn-more-container']}>
          <button
            type="button"
            className={classnames(styles['learn-more'])}
            title={grantAccess ? i18n.modulesListItem.learnMore : i18n.modulesListItem.upgradeToLearnMore}
          >
            {grantAccess ? i18n.modulesListItem.learnMore : i18n.modulesListItem.upgradeToLearnMore}
          </button>
        </div>
      </main>

      {viewDetails && (
        <footer className={styles.footer}>
          <span className={styles.link} title={i18n.generics.viewDetailsLabel}>
            {i18n.generics.viewDetailsLabel}
          </span>
        </footer>
      )}

      {modalIsOpen && children && (
        <Modal
          category={category}
          categoryColor={categoryColor}
          icon={icon}
          modalCardClassName={modalCardClassName}
          onClose={handleClose}
          title={title}
        >
          {children}
        </Modal>
      )}
    </Card>
  )
}

ModulesListItem.propTypes = {
  actions: ACTIONS.isRequired,
  category: PropTypes.string.isRequired,
  categoryColor: PropTypes.string.isRequired,
  children: CHILDREN,
  className: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  goToUrl: PropTypes.string,
  history: HISTORY.isRequired,
  i18n: I18N.isRequired,
  icon: ICON.isRequired,
  isComingSoon: PropTypes.bool,
  isProMember: PropTypes.bool.isRequired,
  modalCardClassName: PropTypes.string,
  onClick: PropTypes.func,
  proMemberActionsProps: ACTIONS.isRequired,
  showBullets: PropTypes.bool.isRequired,
  style: STYLE.isRequired,
  title: PropTypes.string.isRequired,
  viewDetails: PropTypes.bool,
  isVideo: PropTypes.bool,
  isLimitedMember: PropTypes.bool,
}

ModulesListItem.defaultProps = {
  children: null,
  disabled: false,
  goToUrl: undefined,
  isLimitedMember: true,
  isComingSoon: false,
  modalCardClassName: undefined,
  onClick: undefined,
  viewDetails: false,
  isVideo: false,
}

const mapStateToProps = state => ({
  isProMember: accountSelectors.isProMember(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(pageActions, dispatch),
  proMemberActionsProps: bindActionCreators(proMemberActions, dispatch),
})

export default Translation(connect(mapStateToProps, mapDispatchToProps)(withRouter(ModulesListItem)), [
  'modulesListItem',
  'generics',
])
