import { combineReducers } from 'redux'
import { findIndex, isEmpty, map } from 'lodash'

import initialState from 'app_modules/store/initialState.json'
import sessionActionTypes from 'app_modules/session/constants'
import trainingActionTypes from 'app_modules/training/constants'

const cleanPayload = ({ basic, advanced }) => ({ basic, advanced })

export const isFetching = (state = initialState.training.isFetching, { type }) => {
  switch (type) {
    case trainingActionTypes.FETCH_VIDEOS_REQUEST:
      return true

    case trainingActionTypes.FETCH_VIDEOS_FAILURE:
    case trainingActionTypes.FETCH_VIDEOS_SUCCESS:
      return false

    case sessionActionTypes.RESET_STATE:
      return initialState.training.isFetching

    default:
      return state
  }
}

export const trainings = (state = initialState.training.trainings, { type, payload }) => {
  switch (type) {
    case trainingActionTypes.FETCH_VIDEOS_REQUEST:
      if (payload === 1) {
        return []
      }
      return state

    case trainingActionTypes.FETCH_VIDEOS_FAILURE:
      return []

    case trainingActionTypes.FETCH_COMPLETE_VIDEO_SUCCESS: {
      const { trainingName, videoName } = payload
      // Creates a copy of the state, since it should be inmutable
      // TODO: Use inmutable library
      const trainingsList = [...state]
      const trainingIndex = findIndex(trainingsList, { name: trainingName })

      if (trainingIndex >= 0) {
        const training = trainingsList[trainingIndex]
        const videoIndex = findIndex(training.videos, { videoName })

        training.videos[videoIndex].isCompleted = true
        trainingsList[trainingIndex] = training
        return trainingsList
      }

      return state
    }

    case trainingActionTypes.FETCH_VIDEOS_SUCCESS: {
      const { videosCompleted } = payload
      return map(cleanPayload(payload), ({ videos }, key) => ({
        name: key,
        videos: videos.map(video => ({
          ...video,
          isCompleted: !isEmpty(videosCompleted) && videosCompleted.includes(video.videoName),
        })),
      }))
    }

    case sessionActionTypes.RESET_STATE:
      return initialState.training.trainings

    default:
      return state
  }
}

export default combineReducers({
  isFetching,
  trainings,
})
