/* eslint-disable react-hooks/exhaustive-deps */

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'

import { STRIPE_CUSTOMER_CARD } from 'constants/apiErrorCodes'
import api from 'api'

const useCheckoutForm = ({
  accountId,
  i18n,
  notificationActions,
  onFinish,
  onFetchNetworkDetails,
  profileId,
  setStripeError,
  stripeEmail,
}) => {
  const [countries, setCountries] = useState([])
  const [defaultCountryId, setDefaultCountryId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const fetchAllCountries = () => {
    const onSuccess = response => {
      if (response) {
        const { countries: countriesCollection } = response
        if (countriesCollection) {
          setCountries(
            countriesCollection.map(({ name, id }) => ({
              text: name,
              value: `${id}`,
            })),
          )
        }
      }
    }

    api.countries.getList(null, onSuccess)
  }

  useEffect(() => {
    if (countries) {
      const usaObj = countries.find(({ text }) => text === 'United States')
      if (usaObj) setDefaultCountryId(usaObj.value)
    }
  }, [countries])

  useEffect(() => {
    fetchAllCountries()
  }, [])

  const sendPayment = (stripeToken, card, values) => {
    const onSuccess = () => {
      setIsLoading(false)
      if (card && card.clear) {
        card.clear()
      }
      if (onFinish) {
        onFinish()
      }
      notificationActions.notifySuccess(i18n.pageTokenAdministration.tokenManagement.transactionSucessful)
      onFetchNetworkDetails(accountId, profileId)
    }

    const onError = ({ error }) => {
      setIsLoading(false)
      if (error && error.errorCode === STRIPE_CUSTOMER_CARD) {
        notificationActions.notifySuccess(error.message)
      } else {
        notificationActions.notifySuccess(i18n.pageTokenAdministration.tokenManagement.paymentError)
      }
    }

    const countryId = Number(values?.countryId)
    const postalCode = countryId === Number(defaultCountryId) ? Number(values?.postalCode) : ''
    const { fullName } = values || ''
    const quantity = Number(values?.quantity)

    api.charges.sendTokensPayment(
      {
        accountId,
        countryId,
        fullName,
        postalCode,
        stripeToken,
        stripeEmail,
        quantity,
      },
      onSuccess,
      onError,
    )
  }

  const handleSubmit = async values => {
    if (!stripe || !elements) {
      return
    }
    setIsLoading(true)
    const card = elements.getElement(CardElement)
    if (card) {
      const result = await stripe.createToken(card)
      if (result.error) {
        setStripeError(result.error.message || '')
        setIsLoading(false)
      } else {
        setStripeError('')
        sendPayment(result.token.id, card, values)
      }
    }
  }

  return {
    countries,
    defaultCountryId,
    handleSubmit,
    isLoading,
    stripe,
  }
}

export default useCheckoutForm
