import { Col, Container, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Card, Header, Main, Separator } from 'generics/Card'
import { COLORS } from 'constants/colors'
import { EnergyMap, TeamGrid } from 'generics/Charts'
import { ACTIONS, I18N, MEMBERS } from 'constants/props'
import { Person, Team } from 'classes'
import { StyledButton } from 'generics/StyledFormComponents'
import Icon from 'generics/Icon'
import InformationCardHeader from 'generics/InformationCardHeader'
import Translation from 'generics/Translation'

import ModalGenerateSpiderGraph from '../ModalGenerateSpiderGraph'
import styles from './TeamEnergy.scss'

const TeamEnergy = ({ accountId, className, i18n, isFetching, isProMember, members, proMemberActionsProps, team }) => {
  const [selectedProfile, setSelectedProfile] = useState(new Person())
  const [isSpiderGraphModalOpen, setIsSpiderGraphModalOpen] = useState(false)

  const handleChangeProfile = (newSelectedProfile = new Person()) => {
    setSelectedProfile(newSelectedProfile)
  }

  const handleOpenSpiderGraphModal = () => {
    if (!isProMember) {
      proMemberActionsProps.toggleProMemberModal()
      return
    }
    setIsSpiderGraphModalOpen(true)
  }

  const handleCloseSpiderGraphModal = () => {
    setIsSpiderGraphModalOpen(false)
  }

  return (
    <Card barClassName={styles.bar} className={className} isFetching={isFetching}>
      <Header title={i18n.pageTeamDashboard.teamEnergy.title} />

      <Main>
        <Container>
          <Row>
            <Col xs="12" lg="7">
              <h2 className={styles.title} title={i18n.pageTeamDashboard.teamEnergy.teamGrid.title}>
                {i18n.pageTeamDashboard.teamEnergy.teamGrid.title}
              </h2>

              <TeamGrid
                accountId={accountId}
                className={styles['team-grid']}
                members={members}
                onChange={handleChangeProfile}
                team={team}
              />
            </Col>
            <Col xs="12" lg="5">
              <EnergyMap data={[team, selectedProfile]} />
            </Col>
          </Row>
        </Container>

        <Separator className={styles.separator} />

        <Container>
          <Row>
            <Col>
              <InformationCardHeader
                category={i18n.pageTeamDashboard.teamEnergy.spiderGraph.category}
                categoryColor={COLORS.primaryGreen.rgba}
                className={styles['spider-graph__header']}
                icon={<Icon.Svg.TeamSpiderGraph />}
                onClick={handleOpenSpiderGraphModal}
                title={i18n.pageTeamDashboard.teamEnergy.spiderGraph.title}
              />
            </Col>
          </Row>
          <Row>
            <Col className={styles['spider-graph']} xs="12" lg="8">
              <p>{i18n.pageTeamDashboard.teamEnergy.spiderGraph.description}</p>
            </Col>
            <Col xs="12" lg="4">
              <StyledButton
                fullWidth
                onClick={handleOpenSpiderGraphModal}
                color="primary"
                title={i18n.pageTeamDashboard.teamEnergy.spiderGraph.button}
              >
                {i18n.pageTeamDashboard.teamEnergy.spiderGraph.button}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Main>

      {isSpiderGraphModalOpen && <ModalGenerateSpiderGraph members={members} onClose={handleCloseSpiderGraphModal} />}
    </Card>
  )
}

TeamEnergy.propTypes = {
  accountId: PropTypes.number.isRequired,
  className: PropTypes.string,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool,
  isProMember: PropTypes.bool.isRequired,
  members: MEMBERS.isRequired,
  proMemberActionsProps: ACTIONS.isRequired,
  team: PropTypes.instanceOf(Team).isRequired,
}

TeamEnergy.defaultProps = {
  className: null,
  isFetching: false,
}

export default Translation(TeamEnergy, ['pageTeamDashboard'])
