/**
 * @fileoverview Namespace constants for the SensaiAI module
 */

export const NAMESPACE = {
  ROOT: 'SENSAI_AI',
  COACH: 'SENSAI_AI/COACH',
  STATUS: 'SENSAI_AI/STATUS',
  PROCESSING: 'SENSAI_AI/PROCESSING',
}

export default NAMESPACE
