import { isNil } from 'lodash'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import { StyledTextField } from 'generics/StyledFormComponents'
import Translation from 'generics/Translation'

import styles from './LearningChartForm.scss'
import useLearningChartForm from './useLearningChartForm'

const LearningChartForm = ({ className, i18n, onSubmit }) => {
  const { errors, handleChange, handleSubmit, values } = useLearningChartForm({ i18n, onSubmit })

  return (
    <form className={className} onSubmit={handleSubmit}>
      <StyledTextField
        className={styles['text-field']}
        error={isNil(values.name) ? false : !!errors.name}
        fullWidth
        helperText={isNil(values.name) ? ' ' : errors.name}
        label={i18n.generics.formFields.enterNameOptional}
        name="name"
        onChange={e => handleChange('name', e.currentTarget.value)}
        title={i18n.generics.formFields.enterName}
        value={values.name ? values.name : ''}
      />
    </form>
  )
}

LearningChartForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  i18n: I18N.isRequired,
}

LearningChartForm.defaultProps = {
  className: null,
  onSubmit: () => {},
}

export default Translation(withRouter(LearningChartForm), ['generics'])
