import { CREDENTIALS } from '../constants/actionTypes'

export const setSensaiCredentials = (appSecret, licenseKey) => {
  return {
    type: CREDENTIALS.SET_SENSAI_CREDENTIALS,
    payload: {
      appSecret,
      licenseKey,
    },
  }
}

export default setSensaiCredentials
