import PropTypes from 'prop-types'

import { I18N } from 'constants/props'
import useForm from 'custom_hooks/useForm'
import useFormValidation from 'custom_hooks/useFormValidation'
import { REQUIRED, REQUIRED_ALLOW_ZERO } from 'constants/inputTypes'

const useFormLicensesQuantity = ({ i18n, onSubmit, isForStrategies, distributionLevel }) => {
  const { validator } = useFormValidation({ i18n })

  const handleValidate = values => {
    const { quantity, licenseStrategyId, depthOfHierarchy } = values

    const extraValidators = isForStrategies
      ? {
          licenseStrategyId: validator.validate(REQUIRED, licenseStrategyId),
          depthOfHierarchy:
            licenseStrategyId === distributionLevel ? validator.validate(REQUIRED_ALLOW_ZERO, depthOfHierarchy) : null,
        }
      : {}

    return {
      quantity: validator.validate(REQUIRED, quantity),
      ...extraValidators,
    }
  }

  const { errors, isValid, handleChange, handleSubmit, reset, values } = useForm({
    onSubmit,
    onValidate: handleValidate,
  })

  const handleNumberFieldChange = (name, value, max = 100, title = null) => {
    if (value === 0) handleChange(name, title && title === i18n.subscriptions.allLevels ? 0 : '')
    else if (!Number.isNaN(Number(value)) && (Number(value) >= 1 || value === ''))
      handleChange(name, Number(value) > max ? max : value)
  }

  return {
    errors,
    isValid,
    handleNumberFieldChange,
    handleChange,
    handleSubmit,
    reset,
    values,
  }
}

useFormLicensesQuantity.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
  isForStrategies: PropTypes.bool,
}

useFormLicensesQuantity.defaultProps = {
  isForStrategies: false,
}

export default useFormLicensesQuantity
