import { isNil } from 'lodash'
import React from 'react'

import { StyledTextField } from 'generics/StyledFormComponents'
import Translation from 'generics/Translation'

import styles from './FormEditEmail.scss'
import useFormEditEmail from './useFormEditEmail'

/**
 * FormEditEmail Component
 */
const FormEditEmail = props => {
  const { errors, handleChange, handleSubmit, values } = useFormEditEmail({ ...props })

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <StyledTextField
        className={styles['text-field']}
        error={isNil(values.email) ? false : !!errors.email}
        fullWidth
        helperText={isNil(values.email) ? ' ' : errors.email}
        name="email"
        onChange={e => handleChange('email', e.currentTarget.value)}
        onBlur={handleSubmit}
        value={values.email ? values.email : ''}
      />
    </form>
  )
}

export default Translation(FormEditEmail, ['generics'])
