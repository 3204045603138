/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'

import { REQUIRED, NAME } from 'constants/inputTypes'
import { I18N } from 'constants/props'
import useForm from 'custom_hooks/useForm'
import useFormValidation from 'custom_hooks/useFormValidation'
import { useEffect } from 'react'

const useSetupSSOProfileForm = ({ i18n, initialValues, onSubmit }) => {
  const { validator } = useFormValidation({ i18n })

  const handleValidate = values => {
    const { agreedToTermsOfService, firstName, genderPronounPreference, lastName } = values

    return {
      agreedToTermsOfService: validator.validate(REQUIRED, agreedToTermsOfService),
      email: null,
      firstName: validator.validate(NAME, firstName),
      lastName: validator.validate(NAME, lastName),
      genderPronounPreference: validator.validate(REQUIRED, genderPronounPreference),
    }
  }

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values = {
      agreedToTermsOfService: null,
      email: null,
      firstName: null,
      genderPronounPreference: null,
      lastName: null,
    },
  } = useForm({ onValidate: handleValidate, initialValues, onSubmit })

  useEffect(() => {
    handleValidate(values)
  }, [])

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  }
}

useSetupSSOProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
}

export default useSetupSSOProfileForm
