/**
 * @description Settings for videos controller
 * @module API controller / Videos
 */

module.exports = {
  createPaymentSession: {
    /**
     * @description Get request settings to get videos.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId, quantity, licenseStrategyId, depthOfHierarchy, autoSent }) {
      return {
        body: {
          quantity,
          licenseStrategyId,
          depthOfHierarchy,
          autoSent,
        },
        endpoint: `/account/${accountId}/subscriptions/create_payment_session`,
        method: 'POST',
      }
    },
  },

  createCustomerPortalSession: {
    /**
     * @description Get request settings to get videos.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId }) {
      return {
        endpoint: `/account/${accountId}/subscriptions/create_customer_portal_session`,
        method: 'POST',
      }
    },
  },

  createSubscriptionSession: {
    /**
     * @description Get request settings to get videos.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId }) {
      return {
        endpoint: `/account/${accountId}/subscriptions/create_subscription_session`,
        method: 'POST',
      }
    },
  },
}
