/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'

import api from 'api'

const useBuyTokens = ({ accountId, onFetchNetworkDetails, profileId }) => {
  const [amount, setAmount] = useState(null)
  const [orderQuantity, setOrderQuantity] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [quantityValue, setQuantityValue] = useState(null)

  useEffect(() => {
    onFetchNetworkDetails(accountId, profileId)
  }, [])

  useEffect(() => {
    if (amount && orderQuantity && !quantityValue) {
      setOrderQuantity(null)
      setAmount('')
    }
  }, [amount, orderQuantity])

  const onEstimate = quantity => {
    setQuantityValue(quantity)
    if (quantity) {
      setIsLoading(true)

      const onSuccess = response => {
        const { price } = response
        setAmount(price)
        setOrderQuantity(quantity)
        setIsLoading(false)
      }

      const onError = error => {
        console.log(error)
        setIsLoading(false)
      }

      api.charges.getTokensPrice({ accountId, quantity }, onSuccess, onError)
    } else {
      setOrderQuantity(null)
      setAmount('')
    }
  }

  const resetEstimationOrder = () => {
    setAmount(null)
    setOrderQuantity(null)
  }

  return {
    amount,
    isLoading,
    onEstimate,
    orderQuantity,
    resetEstimationOrder,
  }
}

export default useBuyTokens
