import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/groups/constants'
import api from 'api'

// Actions

export const fetchGroupDetailsFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_GROUP_DETAILS_FAILURE,
})

export const fetchGroupDetailsRequest = () => ({
  type: actionTypes.FETCH_GROUP_DETAILS_REQUEST,
})

export const fetchGroupDetailsSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_GROUP_DETAILS_SUCCESS,
})

export const clearGroup = () => ({
  type: actionTypes.CLEAR_GROUP,
})

// Thunks

export const fetchGroupDetails = (groupId, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchGroupDetailsRequest())

    const handleResponse = response => {
      dispatch(fetchGroupDetailsSuccess(response))

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchGroupDetailsFailure(error))

      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.groups.getGroupDetails(
      {
        accountId,
        groupId,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_GROUP_DETAILS

  return thunk
}

export default fetchGroupDetails
