export const en = {
  altText: 'SenSai AI',
  label: 'AI',
  title: 'Meet SenSai',
  description: {
    part1:
      "I'm SenSai, your guide and companion through work, relationships, and life. Available 24/7, I'm here to answer your questions, listen to you, and help you embark on new journeys—supporting you in achieving your personal and professional goals.",
    part2: "Whenever you're ready to begin, I'll be here.",
  },
  talkLabel: 'Connect with SenSai',
  talkButton: 'Talk',
  chatButton: 'Chat',
  poweredByAlt: 'Powered by SuperNomics',
  loadingMsg: 'Setting up... please wait',
}

export const es = en
