/**
 * @description Settings for Sensai AI controller
 * @module API controller / Sensai
 * @requires constants/sensai
 * @requires config
 */

import { SENSAI_CONFIG, SENSAI_ENDPOINTS, BASE_URL } from 'constants/sensai'

/**
 * @description Validates required parameters for Sensai API requests
 * @param {Object} params - Parameters to validate
 * @param {number} [params.accountId] - Account identifier
 * @param {string} [params.userId] - User identifier
 * @throws {Error} If neither accountId nor userId is provided
 * @throws {Error} If parameters are of invalid type
 */
const validateSensaiParams = ({ accountId, userId }) => {
  if (!accountId && !userId) {
    throw new Error('Either accountId or userId is required')
  }
}

module.exports = {
  getStatus: {
    validateRequest: true,
    ignoreNotFound: true,
    /**
     * @description Get Sensai status for an account
     * @param {Object} options
     * @param {number} options.accountId - Account identifier
     * @returns {Object} Request configuration
     * @throws {Error} If accountId is invalid
     */
    settings: ({ accountId }) => {
      validateSensaiParams({ accountId })

      return {
        endpoint: SENSAI_ENDPOINTS.STATUS(accountId),
        method: 'GET',
      }
    },
  },

  getCoaches: {
    useExternalRequest: true,
    validateRequest: true,
    ignoreNotFound: true,
    /**
     * @description Retrieve list of AI coaches
     * @param {Object} options
     * @param {string} options.userId - User identifier
     * @param {string} options.appSecret - Application secret key
     * @param {string} options.licenseKey - License key
     * @returns {Object} Request configuration
     * @throws {Error} If required parameters are missing
     */
    settings: ({ userId, appSecret, licenseKey }) => {
      validateSensaiParams({ userId })

      return {
        endpoint: SENSAI_ENDPOINTS.COACHES,
        method: 'GET',
        baseURL: BASE_URL,
        params: {
          ...SENSAI_CONFIG.DEFAULT_PARAMS,
          userId,
        },
        headers: {
          ...SENSAI_CONFIG.HEADERS.DEFAULT,
          appsecret: appSecret,
          licensekey: licenseKey,
          organizationId: userId,
        },
      }
    },
  },

  createCoach: {
    validateRequest: true,
    ignoreNotFound: true,
    /**
     * @description Create a new AI coach
     * @param {Object} options
     * @param {string} options.userId - User identifier
     * @param {string} options.userName - User's name
     * @param {Array} options.userknowledgeBase - Knowledge base content
     * @param {string} options.appSecret - Application secret key
     * @param {string} options.licenseKey - License key
     * @returns {Object} Request configuration
     * @throws {Error} If required parameters are missing
     */
    settings: ({ userId, appSecret, licenseKey }) => {
      validateSensaiParams({ userId })

      return {
        endpoint: SENSAI_ENDPOINTS.CREATE_COACH(userId),
        method: 'GET',
        baseURL: BASE_URL,
        headers: {
          ...SENSAI_CONFIG.HEADERS.DEFAULT,
          appsecret: appSecret,
          licensekey: licenseKey,
          organizationId: userId,
        },
      }
    },
  },

  getCoachStatus: {
    validateRequest: true,
    ignoreNotFound: true,
    useExternalRequest: true,
    settings: ({ requestId, appSecret, licenseKey, userId }) => {
      if (!requestId) {
        throw new Error('requestId is required')
      }

      return {
        endpoint: SENSAI_ENDPOINTS.COACH_STATUS(requestId),
        method: 'GET',
        baseURL: BASE_URL,
        params: {
          requestId,
        },
        headers: {
          ...SENSAI_CONFIG.HEADERS.DEFAULT,
          licenseKey,
          appSecret,
          organizationId: userId,
        },
      }
    },
  },
}
