import { formatRoute } from 'react-router-named-routes'
import { replace } from 'connected-react-router'

import { BarError } from 'classes/errors'
import { NETWORK } from 'urls'
import { TOKENS_ASSIGNED } from 'constants/apiErrorCodes'
import * as networkSelectors from 'app_modules/network/selectors'
import * as notificationActions from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/network/constants'
import api from 'api'

// Actions

export const fetchUpdateProfileRolesFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_UPDATE_PROFILE_ROLES_FAILURE,
})

export const fetchUpdateProfileRolesSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_UPDATE_PROFILE_ROLES_SUCCESS,
})

export const fetchUpdateProfileRolesRequest = () => ({
  type: actionTypes.FETCH_UPDATE_PROFILE_ROLES_REQUEST,
})

// Thunks

export const fetchUpdateProfileRoles = roles => {
  const thunk = (dispatch, getState) => {
    const state = getState()
    const accountId = sessionSelectors.accountId(state)
    const profileId = networkSelectors.thirdPerson.profile(state).id

    dispatch(fetchUpdateProfileRolesRequest())

    const handleResponse = response => {
      dispatch(fetchUpdateProfileRolesSuccess(response))
      return response
    }

    const handleError = ({ error }) => {
      dispatch(fetchUpdateProfileRolesFailure(error))
      dispatch(replace(formatRoute(NETWORK, { accountId })))
      if (error?.errorCode === TOKENS_ASSIGNED) {
        dispatch(
          notificationActions.notifyError(
            new BarError({
              ...error,
              label: 'tokenBuyerTokensAssigned',
            }),
          ),
        )
      }
    }

    return api.roles.updateProfileRoles({ accountId, profileId, roles }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_UPDATE_PROFILE_ROLES

  return thunk
}

export default fetchUpdateProfileRoles
