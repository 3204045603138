/* eslint-disable camelcase */
import truncate from 'html-truncate'

import d3 from 'vendor/d3'

const makeBalloonShape = d => {
  const pathGenerator = d3.path()
  pathGenerator.moveTo(0, 0)
  pathGenerator.lineTo(d.radius, 0)
  pathGenerator.arc(d.radius, d.radius, d.radius, -0.5 * Math.PI, Math.PI)
  pathGenerator.closePath()
  return pathGenerator.toString()
}

export const drawLegend = ({ elements, geometry, isPair, data, styles }) => {
  if (elements && geometry) {
    const { g_legend } = elements
    const { legendBalloonRadius } = geometry

    let legendItem = g_legend.selectAll('g').data(data.map(profile => ({ profile, radius: legendBalloonRadius })))

    legendItem.exit().remove()

    const legendItemEnter = legendItem.enter().append('g').attr('class', styles.legend)

    legendItemEnter.append('path').attr('class', styles.legend__path)

    legendItemEnter.append('text')

    legendItemEnter.append('title')

    legendItem = legendItem.merge(legendItemEnter)

    legendItem.select('path').classed(styles['legend__path--is-highlight'], (d, idx) => isPair && idx === 0)

    legendItem.select('text').text(({ profile }) => {
      const { _isTeam, firstName, name } = profile

      const text = _isTeam ? name : firstName

      return truncate(text || '-', 8)
    })

    legendItem.select('title').text(({ profile }) => profile.name || '')

    return legendItem
  }

  return null
}

export const updateLegendGeometry = ({ elements, geometry, legendItem }) => {
  if (elements && geometry) {
    const { legend, legendBalloonRadius, side } = geometry

    const { balloon, fontSize, marginBottomFromReference } = legend

    const { g_legend } = elements

    const legendY = side + marginBottomFromReference

    g_legend.attr('transform', `translate(0, ${legendY})`)

    legendItem.filter((d, i) => i === 0).attr('transform', `translate(${-side},0)`)

    legendItem
      .select('path')
      .attr('transform', `translate(${legendBalloonRadius}, 0) rotate(-135)`)
      .attr('d', makeBalloonShape)

    legendItem
      .select('text')
      .attr('transform', `translate(${legendBalloonRadius}, ${-0.65 * legendBalloonRadius})`)
      .attr('font-size', fontSize)
      .attr('dx', legendBalloonRadius + balloon.margin.right)
  }
}
