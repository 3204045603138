import { formatRoute } from 'react-router-named-routes'
import { replace } from 'connected-react-router'

import { NETWORK } from 'urls'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/network/constants'
import api from 'api'

// Actions

export const fetchThirdPersonRolesFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_THIRD_PERSON_ROLES_FAILURE,
})

export const fetchThirdPersonRolesSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_THIRD_PERSON_ROLES_SUCCESS,
})

export const fetchThirdPersonRolesRequest = () => ({
  type: actionTypes.FETCH_THIRD_PERSON_ROLES_REQUEST,
})

// Thunks

export const fetchThirdPersonRoles = profileId => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchThirdPersonRolesRequest())

    const handleResponse = response => {
      dispatch(fetchThirdPersonRolesSuccess(response))
      return response
    }

    const handleError = ({ error }) => {
      dispatch(fetchThirdPersonRolesFailure(error))
      dispatch(replace(formatRoute(NETWORK, { accountId })))
    }

    return api.roles.getProfileRoles({ accountId, profileId }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_THIRD_PERSON_ROLES

  return thunk
}

export default fetchThirdPersonRoles
