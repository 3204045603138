import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import * as notificationActions from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import * as userActions from 'app_modules/user/actions'
import PageRelationships from 'components/PageRelationships'

const mapStateToProps = state => ({
  accountId: sessionSelectors.accountId(state),
  user: sessionSelectors.user(state),
  isSSOAccount: sessionSelectors.currentProfile(state).isSSOAccount,
})

const mapDispatchToProps = dispatch => ({
  notificationActions: bindActionCreators(notificationActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageRelationships))
