import { Col, Container, Row } from 'reactstrap'
import { CSVLink } from 'react-csv'
import { sprintf } from 'sprintf-js'
import FileReaderInput from 'react-file-reader-input'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { I18N } from 'constants/props'
import StyledButton from 'generics/StyledFormComponents/StyledButton'
import Translation from 'generics/Translation'

import styles from './FileReader.scss'

const MIME_TYPE_REGEX = /.*\//g

/**
 * Generic File Reader Component
 * @extends Component
 */
const FileReader = ({ descriptionMessage, fileTypes, i18n, onLoadFile, templateFile }) => {
  const [file, setFile] = useState(null)
  /**
   * Returns the name of the file uploaded or a message if the file doesn't exist yet
   */
  const getFileName = () => {
    const renamedFileTypes = fileTypes.map(fileType => fileType.replace(MIME_TYPE_REGEX, ''))

    const noFileText = sprintf(i18n.fileReader.noFile, renamedFileTypes.join(', ').toUpperCase())

    return file ? file.name : noFileText
  }

  /**
   * handles file change event
   * @param {object} event
   * @param {object} results
   */
  const handleChange = (event, results) => {
    results.forEach(result => {
      const [, fileResult] = result
      setFile(fileResult)
      if (onLoadFile && fileResult !== undefined) {
        onLoadFile(fileResult)
      }
    })
  }

  /**
   * Renders the file name
   */
  const renderFileName = () => {
    const fileName = getFileName()

    return (
      <span className={styles['file-name']} title={fileName}>
        {fileName}
      </span>
    )
  }

  /**
   * Render File Input
   */
  const renderFileReaderInput = () => {
    const browseText = i18n.fileReader.browse

    const allFileTypes = fileTypes.reduce(
      (types, fileType) => [...types, fileType.replace(MIME_TYPE_REGEX, '.')],
      fileTypes,
    )

    return (
      <form className={styles['file-input']}>
        <FileReaderInput accept={allFileTypes} as="text" id="my-file-input" onChange={handleChange}>
          <StyledButton
            className={styles.browse}
            color="default"
            id="button-file-reader-browse"
            title={browseText}
            variant="text"
          >
            {browseText}
          </StyledButton>
        </FileReaderInput>
      </form>
    )
  }

  const { description: templateDescription, downloadDescription, headers: templateHeaders, templateFileName } =
    templateFile || {}

  return (
    <div className={styles.container}>
      <span>{descriptionMessage}</span>
      {templateFile && (
        <div className={styles['template-file-description']}>
          <p>{templateDescription}</p>
          <span className={styles['template-file-link']}>
            <CSVLink data={[templateHeaders]} filename={templateFileName}>
              {downloadDescription}
            </CSVLink>
          </span>
        </div>
      )}
      <Container className={styles['upload-container']}>
        <Row>
          <Col xs="12" className={styles['input-container']}>
            {renderFileReaderInput()}
            {renderFileName()}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

FileReader.propTypes = {
  descriptionMessage: PropTypes.string.isRequired,
  fileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  i18n: I18N.isRequired,
  onLoadFile: PropTypes.func,
  templateFile: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    downloadDescription: PropTypes.string,
    templateFileName: PropTypes.string,
  }),
}

FileReader.defaultProps = {
  onLoadFile: null,
  templateFile: null,
}

export default Translation(FileReader, ['fileReader'])
