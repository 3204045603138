import { CircularProgress } from '@material-ui/core'
import { Col, Container, Row } from 'reactstrap'
import { isEmpty, size } from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { EnergyMap } from 'generics/Charts'
import { getModulesDescription, getModuleLabels } from 'helpers'
import { MODULE, PROFILES, PROFILE } from 'constants/props'
import { Person, Team } from 'classes'
import AutoComplete from 'generics/AutoComplete'
import Mask from 'generics/Mask'
import PageTitle from 'generics/PageTitle'
import ProfileSelector from 'generics/ProfileSelector'

import styles from './PageHeader.scss'

const getSelectedProfile = (index, selectedProfiles) => {
  const newSelectedProfiles = { ...selectedProfiles }
  if (Object.keys(newSelectedProfiles).length > 1) {
    delete newSelectedProfiles[index]
  }

  return Object.values(newSelectedProfiles)[0]
}

const getProfileSelector = (
  entityDescription,
  index,
  item,
  selectedProfiles,
  setSelectedProfiles,
  dataSize,
  useAutoComplete,
) => {
  const profileSelector = useAutoComplete ? (
    <AutoComplete
      className={styles['auto-complete']}
      hasAvatar
      onChange={item.onChange}
      onSearch={item.onSearch}
      onSelectProfile={profileId => setSelectedProfiles({ ...selectedProfiles, [index]: profileId })}
      profiles={item.data}
      selectedProfile={() => getSelectedProfile(index, selectedProfiles)}
    />
  ) : (
    <>
      <ProfileSelector className={styles['profile-selector']} profile={item.data} {...item} />
      {index === 0 && entityDescription && <div className={styles['profile-description']}>{entityDescription}</div>}
    </>
  )

  return (
    <Col key={item.id} xs={12} lg={dataSize === 1 ? 12 : 6}>
      <div className={styles['employees-container']}>{profileSelector}</div>
    </Col>
  )
}

const getProfileSelectorList = (data, entityDescription, selectedProfiles, setSelectedProfiles, useAutoComplete) =>
  data
    .filter(item => item !== undefined)
    .map(item => {
      if (item.constructor === Person || item.constructor === Team) {
        const { hasLegend, legend } = item

        return {
          data: item,
          hasLegend,
          id: item.id,
          legend,
        }
      }

      const { id, profiles, ...rest } = item

      return {
        data: profiles,
        id,
        ...rest,
      }
    })
    .map((item, index) =>
      getProfileSelector(
        entityDescription,
        index,
        item,
        selectedProfiles,
        setSelectedProfiles,
        size(data),
        useAutoComplete,
      ),
    )

const PageHeader = props => {
  const { chartData, className, data, isFetching, module, useAutoComplete } = props

  const [selectedProfiles, setSelectedProfiles] = useState({})

  const { description, key: moduleKey } = getModulesDescription(module) // TODO: Remove this when descriptions cames fine fron Epic

  const entityDescription =
    moduleKey &&
    getModuleLabels({
      labelId: 'isLeader',
      moduleKey,
    })

  const { image, title } = module

  return (
    <header className={classnames(styles.header, { [styles['is-fetching']]: isFetching }, className)}>
      {!isFetching && (
        <Container>
          <Row>
            <Col xs="12" md="7" lg="8" xlg="9">
              <PageTitle {...module} />

              <Container>
                <Row>
                  {getProfileSelectorList(
                    data,
                    entityDescription,
                    selectedProfiles,
                    setSelectedProfiles,
                    useAutoComplete,
                  )}
                </Row>
              </Container>

              <div
                className={styles.description}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Col>

            {isEmpty(image) && !isEmpty(chartData) && (
              <Col xs="12" md="5" lg="4" xlg="3">
                <EnergyMap className={styles.chart} data={chartData} />
              </Col>
            )}
            {!isEmpty(image) && (
              <Col xs="12" md="5" lg="4" xlg="3" className={styles['image-container']}>
                <img src={image} alt={title} className={styles['module-image']} />
              </Col>
            )}
          </Row>
        </Container>
      )}

      <Mask className={styles.mask} open={isFetching}>
        <CircularProgress className={styles.loader} size={60} thickness={7} />
      </Mask>
    </header>
  )
}

PageHeader.propTypes = {
  chartData: PropTypes.oneOfType([PROFILES, PROFILE]),
  className: PropTypes.string,
  data: PropTypes.oneOfType([
    PROFILES,
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        onChange: PropTypes.function,
        profiles: PROFILES,
      }),
    ),
  ]),
  isFetching: PropTypes.bool,
  module: MODULE,
  useAutoComplete: PropTypes.bool,
}

PageHeader.defaultProps = {
  chartData: undefined,
  className: null,
  data: [],
  isFetching: false,
  module: {},
  useAutoComplete: false,
}

export default PageHeader
