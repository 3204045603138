/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import { ACTIONS, I18N, LIST, PERSON } from 'constants/props'
import { BarError } from 'classes/errors'
import { Card, Footer, Header, Main } from 'generics/Card'
import { COLORS } from 'constants/colors'
import { getCSSFromSVGs } from 'helpers'
import { StyledButton } from 'generics/StyledFormComponents'
import api from 'api'
import Icon from 'generics/Icon'
import PDFEnergyMaps from 'generics/PDFEnergyMaps'
import Translation from 'generics/Translation'

import BadgeResults from '../BadgeResults'
import styles from './modalGenerateReports.scss'

const ModalGenerateReports = ({ accountId, i18n, notificationActions, onClose, onFinish, pageActions, profiles }) => {
  const [isFetching, setIsFetching] = useState(false)

  const energyMaps = useRef(null)

  useEffect(() => {
    pageActions.switchModalView()

    return () => {
      pageActions.switchModalView()
    }
  }, [])

  const handleClose = () => {
    onClose()
  }

  const handleDownloadLearningReport = () => {
    if (energyMaps?.current) {
      setIsFetching(true)

      const { svgs } = energyMaps.current

      const css = getCSSFromSVGs(svgs)

      const handleResponse = () => {
        setIsFetching(false)
        notificationActions.notifySuccess(i18n.pageAdministration.members.generateReports.resultMessage.success)
        if (onClose) {
          handleClose()
        }
        if (onFinish) {
          onFinish()
        }
      }

      const handleError = ({ error }) => {
        setIsFetching(false)
        notificationActions.notifyError(
          new BarError({
            ...error,
            message: i18n.pageAdministration.members.generateReports.resultMessage.failed,
          }),
        )
        if (onClose) {
          handleClose()
        }
      }

      api.files.downloadLearningOverviewPDF(
        {
          accountId,
          css,
          profiles: svgs.map(({ id, svg }) => ({ id, svg })),
        },
        handleResponse,
        handleError,
      )
    }
  }

  const renderCancelButton = () => (
    <StyledButton
      aria-label={`${i18n.generics.cancelLabel} ${i18n.pageAdministration.modalGenerateReports.title.sendReports}`}
      className={styles.cancel}
      color="default"
      onClick={handleClose}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          e.preventDefault()
          onClose()
        }
      }}
      title={i18n.generics.cancelLabel}
      variant="text"
    >
      {i18n.generics.cancelLabel}
    </StyledButton>
  )

  const renderDoneButton = () => {
    const label = i18n.pageAdministration.modalGenerateReports.send
    const onClick = handleDownloadLearningReport

    return (
      <StyledButton
        aria-label="Send generated reports to your email"
        className={styles.invite}
        id="button-modal-people-invite"
        onClick={onClick}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            e.preventDefault()
            onClose()
          }
        }}
        color="primary"
        title={label}
      >
        {label}
      </StyledButton>
    )
  }

  return (
    <article className={styles.modal}>
      <Card barBackgroundColor={COLORS.primaryBlue.rgba} className={styles.card} isLoading={isFetching}>
        <Header ariaLabel={i18n.pageAdministration.modalGenerateReports.title.sendReports} className={styles.header}>
          <h1>{i18n.pageAdministration.modalGenerateReports.title.sendReports}</h1>
        </Header>
        <Icon.Stroke7
          className={styles.close}
          name="close"
          onClick={handleClose}
          title={i18n.pageAdministration.modalGenerateReports.close}
        />
        <Main className={styles.main}>
          <BadgeResults
            success={profiles}
            successTitle={i18n.pageAdministration.modalGenerateReports.confirmationMessage}
          />
          {!!profiles.length && (
            <PDFEnergyMaps
              profiles={profiles}
              innerRef={nodeElement => {
                energyMaps.current = nodeElement
              }}
            />
          )}
        </Main>

        <Footer>
          <Row>
            <Col xs="6">{renderCancelButton()}</Col>
            <Col xs="6">{renderDoneButton()}</Col>
          </Row>
        </Footer>
      </Card>
    </article>
  )
}

ModalGenerateReports.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  notificationActions: ACTIONS.isRequired,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  pageActions: ACTIONS.isRequired,
  profiles: LIST(PERSON).isRequired,
}

ModalGenerateReports.defaultProps = {
  onClose: undefined,
  onFinish: undefined,
}

export default Translation(ModalGenerateReports, ['pageAdministration', 'notifications', 'generics'])
