import * as accountActions from 'app_modules/accounts/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/teams/constants'
import api from 'api'

// Actions

export const fetchDeleteTeamFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_DELETE_TEAM_FAILURE,
})

export const fetchDeleteTeamRequest = () => ({
  type: actionTypes.FETCH_DELETE_TEAM_REQUEST,
})

export const fetchDeleteTeamSuccess = () => ({
  type: actionTypes.FETCH_DELETE_TEAM_SUCCESS,
})

// Thunks

export const fetchDeleteTeam = (teamId, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchDeleteTeamRequest())

    const handleResponse = response => {
      dispatch(fetchDeleteTeamSuccess())
      dispatch(accountActions.fetchAccount(accountId))

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error }) => {
      dispatch(fetchDeleteTeamFailure(error))

      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.teams.deleteTeam({ accountId, teamId }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_DELETE_TEAM

  return thunk
}

export default fetchDeleteTeam
