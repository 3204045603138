import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import * as trainingActions from 'app_modules/training/actions'
import * as trainingSelectors from 'app_modules/training/selectors'
import PageTrainingCenter from 'components/PageTrainingCenter'

const mapStateToProps = state => ({
  isFetching: trainingSelectors.isFetching(state),
  trainings: trainingSelectors.trainings(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(trainingActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageTrainingCenter))
