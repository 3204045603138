import { Col, Container, Row } from 'reactstrap'
import { isNil } from 'lodash'
import { MenuItem } from '@material-ui/core'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { ARCHIVE_GROUP_SETTING } from 'constants/archiveGroup'
import { DATE_TO } from 'constants/inputTypes'
import { I18N } from 'constants/props'
import { StyledDatePicker, StyledSelect } from 'generics/StyledFormComponents'
import Search from 'generics/Search'
import Translation from 'generics/Translation'
import useFormValidation from 'custom_hooks/useFormValidation'

import styles from './FormGroupSearch.scss'

/**
 * FormGroupSearch component
 */
const FormGroupSearch = ({ i18n, initialValues, onSearch }) => {
  const {
    endDate: thisEndDate,
    error: thisError,
    startDate: thisStartDate,
    isArchive: thisIsArchive,
    searchCriteria: thisSearchCriteria,
  } = initialValues || ARCHIVE_GROUP_SETTING
  const [error, setError] = useState(thisError)
  const [startDate, setStartDate] = useState(thisStartDate)
  const [debouncedStartDate, setDebouncedStartDate] = useState(thisStartDate)
  const [endDate, setEndDate] = useState(thisEndDate)
  const [debouncedEndDate, setDebouncedEndDate] = useState(thisEndDate)
  const [searchCriteria, setSearchCriteria] = useState(thisSearchCriteria)
  const [debouncedSearchCriteria, setDebouncedSearchCriteria] = useState(thisSearchCriteria)
  const [isArchive, setIsArchive] = useState(thisIsArchive)
  const { validator } = useFormValidation({ i18n })

  const handleChangeField = (fieldName, value) => {
    if (fieldName === 'searchCriteria') setSearchCriteria(value)
    else if (fieldName === 'startDate') setStartDate(value && new Date(value))
    else if (fieldName === 'endDate') setEndDate(value && new Date(value))
    else if (fieldName === 'isArchive') setIsArchive(value)
  }

  const debounceFn = (value, fn, time = 500) => {
    const timeout = setTimeout(() => fn(value), time)
    return () => clearTimeout(timeout)
  }

  useEffect(() => {
    const invalidDate = validator.validate(DATE_TO, startDate, endDate)
    setError(invalidDate)
    if (isNil(invalidDate)) onSearch({ searchCriteria: debouncedSearchCriteria, startDate, endDate, isArchive })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedStartDate, debouncedEndDate, debouncedSearchCriteria, isArchive])

  useEffect(() => debounceFn(searchCriteria, setDebouncedSearchCriteria), [searchCriteria])
  useEffect(() => debounceFn(startDate, setDebouncedStartDate), [startDate])
  useEffect(() => debounceFn(endDate, setDebouncedEndDate), [endDate])

  return (
    <Container className={styles.container} fluid>
      <div className={styles.fields}>
        <Row>
          <Col xs="12" md="3">
            <Search
              className={styles.textfield}
              floatingLabelText={i18n.pageAdministration.formGroupSearch.labelGroupName}
              onChange={value => handleChangeField('searchCriteria', value)}
              value={searchCriteria}
              debounce={false}
            />
          </Col>
          <Col xs="12" md="3">
            <StyledDatePicker
              ariaLabel={`${i18n.pageAdministration.formGroupSearch.date.startDate} date container.`}
              name="startDate"
              onChange={handleChangeField}
              title={i18n.pageAdministration.formGroupSearch.date.startDate}
              value={startDate}
            />
          </Col>
          <Col xs="12" md="3">
            <StyledDatePicker
              ariaLabel={`${i18n.pageAdministration.formGroupSearch.date.endDate} date container.`}
              name="endDate"
              onChange={handleChangeField}
              title={i18n.pageAdministration.formGroupSearch.date.endDate}
              value={endDate}
            />
          </Col>
          <Col xs="12" md="3">
            <StyledSelect
              color="primary"
              formControlClassName={classnames(styles.filter)}
              fullWidth
              iconClassName={styles.icon}
              label={i18n.pageAdministration.formGroupSearch.labelShowArchiveFilter}
              name="isArchive"
              onChange={e => handleChangeField('isArchive', e.target.value)}
              value={isArchive}
            >
              <MenuItem key={i18n.pageAdministration.formGroupSearch.labelAllGroups} value="all">
                {i18n.pageAdministration.formGroupSearch.labelAllGroups}
              </MenuItem>
              <MenuItem key={i18n.pageAdministration.formGroupSearch.labelUnarchiveGroups} value="unarchive">
                {i18n.pageAdministration.formGroupSearch.labelUnarchiveGroups}
              </MenuItem>
              <MenuItem key={i18n.pageAdministration.formGroupSearch.labelArchiveGroups} value="archive">
                {i18n.pageAdministration.formGroupSearch.labelArchiveGroups}
              </MenuItem>
            </StyledSelect>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md={{ size: 6, offset: 6 }}>
            <span className={styles.error} title={i18n.pageAdministration.formGroupSearch.titleError}>
              {error}
            </span>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

FormGroupSearch.propTypes = {
  i18n: I18N.isRequired,
  initialValues: PropTypes.shape({}),
  onSearch: PropTypes.func.isRequired,
}

FormGroupSearch.defaultProps = {
  initialValues: null,
}

export default Translation(FormGroupSearch, ['generics', 'pageAdministration'])
