import * as fetchMembers from './fetchMembers'
import * as fetchUpdateWorkshop from './fetchUpdateWorkshop'
import * as fetchWorkshopDetails from './fetchWorkshopDetails'
import * as fetchWorkshops from './fetchWorkshops'

module.exports = {
  ...fetchMembers,
  ...fetchUpdateWorkshop,
  ...fetchWorkshopDetails,
  ...fetchWorkshops,
}
