import React from 'react'

import { CHILDREN } from 'constants/props'
import ErrorBoundary from 'generics/ErrorBoundary'

import styles from './SimpleLayout.scss'

const SimpleLayout = ({ children }) => (
  <div className={styles.layout}>
    <main className={styles.main}>
      <ErrorBoundary className={styles['error-boundary']}>{children}</ErrorBoundary>
    </main>
  </div>
)

SimpleLayout.propTypes = {
  children: CHILDREN,
}

SimpleLayout.defaultProps = {
  children: null,
}

export default SimpleLayout
