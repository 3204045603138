export const en = {
  modules: {
    teamDynamics: {
      title: 'Team dynamics',
    },
    workingWithTeamMembers: {
      title: 'Working with team members',
      module: {
        leadership: '1:1 Leadership',
      },
    },
    resources: {
      title: 'Resources',
      module: {
        category: 'Resources',
        nameBadges: {
          title: 'Name Badges',
          description:
            "If you would like to print name badges that include your team's names, energy profiles and photos, click here.",
          modal: {
            description: [
              'You can create up to 24 individual badges to be used for Workshops or other events. The badge includes the person’s name as well as their Energy Map.',
              'The PDF file that is created prints to Avery 3x4 inch (6 to a page) or compatible labels.',
            ],
          },
        },
        tentCards: {
          title: 'Tent Cards',
          description:
            'If you would like to print name tents for an upcoming 5 Dynamics® session or for any meeting, click here.',
          modal: {
            description: [
              'You can create Name Tents for up to 24 people at a time. ',
              'The Name Tent includes the person’s name as well as their Energy Map.',
            ],
          },
        },
        learningChart: {
          title: 'Learning Charts',
          description:
            'If you would like to print learning charts to learn more about the optimal way to engage with a team, click here.',
          modal: {
            description: [
              'You can create a Learning Chart that includes up to 100 people.',
              'The Learning Chart includes the person’s name as well as their Energy Map.',
            ],
            form: {
              chartName: 'Learning chart name',
            },
          },
        },
      },
    },
  },
  teamEnergy: {
    spiderGraph: {
      category: 'Tools',
      title: 'Team Spider Graph',
      description: 'Select up to 12 team members to generate a Team Spider Graph',
      button: 'Generate spider graph',
    },
    teamGrid: {
      teamAverage: 'Team Average',
      title: 'Compare Team',
    },
    title: 'Team Energy',
  },
  modalCreatePdf: {
    submitText: 'Create',
    emptyListMessage: 'No members selected',
    listATitle: 'Team Members',
    listBTitle: 'Selected',
  },
  spiderGraphHistory: {
    title: 'Spider Graph History',
    name: 'Spider Graph - %s',
  },
}

export const es = en
