/* eslint-disable import/prefer-default-export */
export const COLORS = {
  blueDeepCerulean: {
    rgba: 'rgba(3, 125, 178, 1)',
  },
  blueDisabled: {
    rgba: 'rgba(134, 177, 201, 1)',
  },
  grey1: {
    rgba: 'rgba(51, 47, 47, 1)',
  },
  grey3: {
    rgba: 'rgba(155, 155, 155, 1)',
  },
  grey4: {
    rgba: 'rgba(187, 187, 187, 1)',
  },
  lightBlue: {
    rgba: 'rgba(0, 157, 204, 1)',
  },
  orangePumpkin: {
    rgba: 'rgba(241, 90, 34, 1)',
  },
  primaryBlue: {
    rgba: 'rgba(0, 91, 148, 1)',
  },
  primaryPink: {
    rgba: 'rgba(255, 0, 102, 1)',
  },
  primaryGreen: {
    rgba: 'rgba(142, 190, 63, 1)',
  },
  primaryWhite: {
    rgba: 'rgba(255, 255, 255, 1)',
  },
  resourcesCategory: {
    rgba: 'rgba(102, 51, 153, 1)',
  },
  secondaryBlue: {
    rgba: 'rgba(60, 146, 193, 1)',
  },
  secondaryPurple: {
    rgba: 'rgba(135, 5, 177, 1)',
  },
}
