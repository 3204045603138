/**
 * @description Settings for videos controller
 * @module API controller / Videos
 */

module.exports = {
  getVideos: {
    /**
     * @description Get request settings to get videos.
     * @param {number} accountId - The account ID.
     * @returns {object} - Request settings.
     */
    settings({ accountId }) {
      return {
        endpoint: `/account/${accountId}/videos`,
        method: 'GET',
      }
    },
  },
}
