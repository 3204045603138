import { Col, Container, Row } from 'reactstrap'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import { Link } from 'react-router-dom'
import { sprintf } from 'sprintf-js'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import truncate from 'html-truncate'

import { ACCOUNT_ADMIN } from 'roles'
import { Card, Main } from 'generics/Card'
import { EnergyMap } from 'generics/Charts'
import { getEntityByContextRoute, THIRD_PERSON_PROFILE } from 'urls'
import { I18N, THIRD_PERSON, PERSON } from 'constants/props'
import * as networkSelectors from 'app_modules/network/selectors'
import * as sessionSelectors from 'app_modules/session/selectors'
import Avatar from 'generics/Avatar'
import TestingScores from 'generics/TestingScores'
import Translation from 'generics/Translation'

import styles from './NetworkProfile.scss'

const EDUCATION_ACCOUNT_TYPE = 'education'

/**
 * Network Profile Component
 * @param {object} props - component properties
 */
const NetworkProfile = props => {
  const { className, currentProfile, i18n, isFetching, overview, profile } = props

  const {
    account: { accountType },
    accountId,
    division,
    email,
    id: profileId,
    jobTitle,
    name,
  } = profile

  const fullName = name || i18n.networkProfile.loading

  const avatarTitle = sprintf(i18n.networkProfile.avatarTitle, name)
  const category = accountType === EDUCATION_ACCOUNT_TYPE ? 'education_5' : 'fundamentals_1'
  const moduleKey = accountType === EDUCATION_ACCOUNT_TYPE ? 'working_styles_graph_18' : 'work_preferences_1'

  const profileByContextRoute = getEntityByContextRoute({
    accountId,
    category,
    context: 'user',
    contextType: 'individual',
    entity: profile,
    menuItem: 'network',
    moduleKey,
  })

  const pageThirdPersonProfile = formatRoute(THIRD_PERSON_PROFILE, {
    accountId,
    profileId,
  })

  const userInfo = {
    [i18n.networkProfile.userInfo.email]: email,
    [i18n.networkProfile.userInfo.jobTitle]: jobTitle,
    [i18n.networkProfile.userInfo.division]: division,
  }

  const { assessmentScores } = profile

  const description = overview && truncate(overview, 785)

  return (
    <Card barClassName={styles.bar} className={className} isFetching={isFetching}>
      <header className={styles.header}>
        <Container>
          <Row>
            <Col>
              <div className={styles['avatar-wrapper']}>
                <Avatar className={styles.avatar} profile={profile} title={avatarTitle} />
              </div>

              <div className={styles['full-name']}>
                <span title={fullName}>{fullName}</span>
              </div>
              {assessmentScores && assessmentScores.length > 0 && <EnergyMap className={styles.chart} data={profile} />}
              <TestingScores profile={profile} className={styles.scores} />
            </Col>
          </Row>
        </Container>
      </header>

      <Main>
        <Container>
          <Row>
            <Col>
              <h2 className={styles.overview} title={i18n.networkProfile.Overview}>
                {i18n.networkProfile.Overview}
              </h2>

              <div
                className={classnames('epic', styles.description)}
                /* eslint-disable react/no-danger */
                dangerouslySetInnerHTML={{ __html: description }}
                /* eslint-enable */
              />

              <Link
                className={styles['read-more']}
                href={profileByContextRoute}
                title={i18n.networkProfile.readMore}
                to={profileByContextRoute}
              >
                {i18n.networkProfile.readMore}
              </Link>

              <div className={styles['user-information']}>
                {Object.keys(userInfo).map(key => (
                  <dl key={key}>
                    <dt title={key}>{key.toUpperCase()}</dt>
                    <dd title={userInfo[key]}>{userInfo[key]}</dd>
                  </dl>
                ))}
              </div>

              {currentProfile.hasRole(ACCOUNT_ADMIN) && (
                <span className={styles['more-link']}>
                  <Link href={pageThirdPersonProfile} title={i18n.networkProfile.more} to={pageThirdPersonProfile}>
                    {i18n.networkProfile.more}
                  </Link>
                </span>
              )}
            </Col>
          </Row>
        </Container>
      </Main>
    </Card>
  )
}

NetworkProfile.propTypes = {
  className: PropTypes.string,
  currentProfile: PERSON.isRequired,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool.isRequired,
  overview: PropTypes.string,
  profile: THIRD_PERSON,
}

NetworkProfile.defaultProps = {
  className: null,
  overview: '',
  profile: undefined,
}

const mapStateToProps = state => ({
  accountId: sessionSelectors.accountId(state),
  currentProfile: sessionSelectors.currentProfile(state),
  isFetching: networkSelectors.thirdPerson.isFetching(state),
  overview: networkSelectors.thirdPerson.overview(state),
  profile: networkSelectors.thirdPerson.profile(state),
})

export default connect(mapStateToProps)(Translation(NetworkProfile, ['networkProfile']))
