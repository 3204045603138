/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { isNil } from 'lodash'
import classnames from 'classnames'

import d3 from 'vendor/d3'
import { DYNAMICS_LABELS } from 'constants'

const RADIUS = 0.5

export const drawProfiles = ({ elements, geometry, isPair, data, profile, scales, styles }) => {
  if (elements && geometry) {
    const { g_profiles } = elements

    const { offset, plusRadiusRatio, quadrantsAngle } = geometry

    const profiles = data
      .sort((profileA, profileB) => {
        if (profileA._isTeam === profileB._isTeam) {
          return 0
        }

        return profileA._isTeam ? -1 : 1
      })
      .reduceRight((accumulator, profileData, profileIndex) => {
        const { _type, _isTeam, scores } = profileData

        let isFilled = !_isTeam

        if (isFilled && isPair) {
          isFilled = profileIndex === 1
        }

        if (scores) {
          DYNAMICS_LABELS.forEach((dynamicName, dynamicIndex) => {
            const { energy, value } = scores[dynamicIndex]

            let radius
            let plusRadius
            let hasPlus = false

            if (!isNil(value)) {
              const balloonSize = scales.getBalloonSize(value)

              radius = RADIUS * scales.balloonDiameter(balloonSize)

              plusRadius = plusRadiusRatio * radius

              hasPlus = energy.endsWith('+')
            }

            if (hasPlus && isPair && profileIndex === 0) {
              const assessments = data.filter(({ assessmentScores }) => !assessmentScores.isNull).length

              hasPlus = assessments === 1
            }

            accumulator.push({
              _isTeam,
              _type,
              angle: quadrantsAngle[dynamicIndex],
              dynamicIndex,
              dynamicName,
              hasPlus,
              isFilled,
              isThicker: false,
              plusRadius,
              profileIndex,
              radius,
            })
          })
        }
        return accumulator
      }, [])

    if (profiles.length === 0) {
      return
    }

    if (g_profiles) {
      profile = g_profiles.selectAll(`.${styles.profile}`).data(profiles, d => `${d.profileIndex}-${d.dynamicName}`)

      profile.exit().remove()

      const profileEnter = profile
        .enter()
        .append('g')
        .attr('class', d => {
          const { dynamicIndex, isFilled } = d

          return classnames(
            styles.profile,
            { [styles['is-filled']]: isFilled },
            { [styles[`is-filled--dynamic-${dynamicIndex}`]]: isFilled },
            { [styles['is-highlight']]: !isFilled },
          )
        })

      profileEnter.append('path').attr('class', 'balloon')

      const plusEnter = profileEnter.append('g').attr('class', styles.plus)

      plusEnter.append('line')
      plusEnter.append('line')

      profile = profile
        .merge(profileEnter)
        .attr('transform', d => `rotate(${d.angle}) translate(${offset},${offset})`)
        .classed('team', data[0]._type === 'team')

      profile.select('.balloon').attr('d', d => {
        if (d.radius === undefined) {
          return null
        }

        const pathGenerator = d3.path()

        pathGenerator.moveTo(0, 0)
        pathGenerator.lineTo(d.radius, 0)
        pathGenerator.arc(d.radius, d.radius, d.radius, -RADIUS * Math.PI, Math.PI)
        pathGenerator.closePath()
        return pathGenerator.toString()
      })

      profile
        .select(`.${styles.plus}`)
        .attr('transform', ({ radius }) => (radius ? `translate(${radius},${radius})` : undefined))
        .classed('hide', d => !d.hasPlus)

      profile
        .select(`.${styles.plus} line`)
        .attr('y1', ({ plusRadius }) => (plusRadius ? -plusRadius : undefined))
        .attr('y2', ({ plusRadius }) => plusRadius)

      profile
        .select(`.${styles.plus} line:nth-child(2)`)
        .attr('x1', ({ plusRadius }) => (plusRadius ? -plusRadius : undefined))
        .attr('x2', ({ plusRadius }) => plusRadius)
    }
  }
}
