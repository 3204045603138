import { Col, Container, Row } from 'reactstrap'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents'
import Translation from 'generics/Translation'

import styles from './PersonForm.scss'
import usePersonForm from './usePersonForm'

const PersonForm = ({ i18n, isDepartmentEnabled, message, onSubmit, onValidate, resetOnSubmit }) => {
  const { errors, isValid, handleChange, handleSubmit, values } = usePersonForm({
    i18n,
    onSubmit,
    onValidate,
    resetOnSubmit,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Container className={styles.container} fluid>
        <Row>
          <Col xs="12">
            <p>{message}</p>
          </Col>
        </Row>
        <div className={styles.fields}>
          <Row>
            <Col xs="12" md="6">
              <StyledTextField
                className={styles.textfield}
                error={isNil(values.firstName) ? false : !!errors.firstName}
                fullWidth
                helperText={isNil(values.firstName) ? ' ' : errors.firstName}
                id="input-invitation-form-firstname"
                label={i18n.generics.formFields.firstName}
                name="firstName"
                onChange={e => handleChange('firstName', e.currentTarget.value)}
                title={i18n.generics.formFields.firstName}
                value={values.firstName ? values.firstName : ''}
              />
            </Col>
            <Col xs="12" md="6">
              <StyledTextField
                className={styles.textfield}
                error={isNil(values.lastName) ? false : !!errors.lastName}
                fullWidth
                helperText={isNil(values.lastName) ? ' ' : errors.lastName}
                id="input-invitation-form-lastname"
                label={i18n.generics.formFields.lastName}
                name="lastName"
                onChange={e => handleChange('lastName', e.currentTarget.value)}
                title={i18n.generics.formFields.lastName}
                value={values.lastName ? values.lastName : ''}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="10">
              <StyledTextField
                className={styles.textfield}
                error={isNil(values.email) ? false : !!errors.email}
                fullWidth
                helperText={isNil(values.email) ? ' ' : errors.email}
                id="input-invitation-form-email"
                label={i18n.generics.formFields.enterEmail}
                name="email"
                onChange={e => handleChange('email', e.currentTarget.value)}
                title={i18n.generics.formFields.email}
                value={values.email ? values.email : ''}
              />
            </Col>
            {!isDepartmentEnabled && (
              <Col xs="12" md="2">
                <StyledButton
                  className={styles.add}
                  id="button-invitation-form-add"
                  type="submit"
                  color="primary"
                  disabled={!isValid}
                  title={i18n.generics.addLabel}
                >
                  {i18n.generics.addLabel}
                </StyledButton>
              </Col>
            )}
          </Row>
          {isDepartmentEnabled && (
            <Row>
              <Col xs="12" md="10">
                <StyledTextField
                  className={styles.textfield}
                  error={isNil(values.department) ? false : !!errors.department}
                  fullWidth
                  helperText={isNil(values.department) ? ' ' : errors.department}
                  id="input-invitation-form-department"
                  label={i18n.generics.formFields.department}
                  name="department"
                  onChange={e => handleChange('department', e.currentTarget.value)}
                  title={i18n.generics.formFields.department}
                  value={values.department ? values.department : ''}
                />
              </Col>
              <Col xs="12" md="2">
                <StyledButton
                  className={styles.add}
                  id="button-invitation-form-add"
                  type="submit"
                  color="primary"
                  disabled={!isValid}
                  title={i18n.generics.addLabel}
                >
                  {i18n.generics.addLabel}
                </StyledButton>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </form>
  )
}

PersonForm.propTypes = {
  i18n: I18N.isRequired,
  isDepartmentEnabled: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onValidate: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  resetOnSubmit: PropTypes.bool,
}

PersonForm.defaultProps = {
  onValidate: undefined,
  resetOnSubmit: false,
}

export default Translation(PersonForm, ['generics'])
