/**
 * @fileoverview Status reducer for the SensaiAI module
 * @module sensaiAi/reducers/statusReducer
 */

import { STATUS } from '../constants/actionTypes'

/**
 * @typedef {Object} StatusState
 * @property {Object|null} sensaiStatus - Current sensai status
 * @property {boolean} isFetching - Loading state flag
 * @property {Object|null} error - Error state
 */

/**
 * @type {StatusState}
 */
const initialState = {
  status: null,
  error: null,
  isFetching: false,
  coachesStatus: null,
}

/**
 * Status reducer
 * @param {StatusState} state - Current state
 * @param {Object} action - Action object
 * @returns {StatusState} New state
 */
const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS.FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case STATUS.FETCH_SUCCESS:
      return {
        ...state,
        status: action.payload,
        isFetching: false,
        coachesStatus: 'succeeded',
      }

    case STATUS.FETCH_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      }

    default:
      return state
  }
}

export default statusReducer
