import { BarError } from 'classes/errors'
import * as notificationActions from 'app_modules/notifications/actions'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/profile/constants'
import api from 'api'

// Actions
/**
 * failure action dispatched when there is an error updating the avatar
 */
export const updateAvatarFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_UPDATE_AVATAR_FAILURE,
})

/**
 * update avatar request action dispatched
 */
export const updateAvatarRequest = () => ({
  type: actionTypes.FETCH_UPDATE_AVATAR_REQUEST,
})

/**
 * Success action dispatched when updating the avatar
 */
export const updateAvatarSuccess = response => ({
  payload: response.profile,
  type: actionTypes.FETCH_UPDATE_AVATAR_SUCCESS,
})

// Thunks

export const updateAvatar = avatar => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())
    dispatch(updateAvatarRequest())

    const handleResponse = response => {
      dispatch(updateAvatarSuccess(response))
      return response
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(updateAvatarFailure(error))
      if (!isConnectionError) {
        dispatch(
          notificationActions.notifyError(
            new BarError({
              ...error,
              label: 'updateAvatarError',
            }),
          ),
        )
      }
    }

    return api.profiles.updateAvatar({ accountId, avatar }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_UPDATE_AVATAR

  return thunk
}

export default updateAvatar
