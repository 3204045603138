/* eslint-disable no-nested-ternary */
import { Col, Row } from 'reactstrap'
import { sprintf } from 'sprintf-js'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { CHILDREN, I18N, PROFILE } from 'constants/props'
import Avatar from 'generics/Avatar'
import FormEditEmail from 'generics/FormEditEmail'
import Icon from 'generics/Icon'
import Translation from 'generics/Translation'

import styles from './listItem.scss'

const ListItem = ({
  canDrag,
  className,
  errors,
  i18n,
  isDepartmentEnabled,
  isDragging,
  onChangeEmail,
  onRequestAdd,
  onRequestRemove,
  onValidate,
  profile,
  showStatusLabel,
  tag: Tag,
  title,
  type,
}) => {
  const renderAction = () => {
    if (!canDrag || (profile.connectionRequestStatus && profile.connectionRequestStatus !== 'available')) {
      return null
    }

    if (onRequestAdd) {
      return (
        <Icon.Stroke7
          className={styles.add}
          id={`list-item-add-${profile.id}`}
          name="plus"
          onClick={() => {
            onRequestAdd(profile)
          }}
          title={i18n.generics.addLabel}
        />
      )
    }

    if (onRequestRemove) {
      return (
        <div className={styles.action}>
          <Icon.Stroke7
            id={`list-item-remove-${profile.id}`}
            name="less"
            onClick={() => {
              onRequestRemove(profile)
            }}
            title={i18n.generics.removeLabel}
          />
        </div>
      )
    }

    return null
  }

  const renderBadges = () => {
    const { connectionRequestStatus } = profile

    switch (connectionRequestStatus) {
      case 'available':
        return null

      case 'accepted':
      case 'blocked':
      case 'connected':
      case 'pending':
        return (
          <div className={classnames(styles.badge, styles[connectionRequestStatus])}>
            <span title={connectionRequestStatus}>{connectionRequestStatus}</span>
          </div>
        )

      default:
        return null
    }
  }

  const renderEmail = () => {
    const { email } = profile

    return onChangeEmail ? (
      <FormEditEmail
        form={`edit-email-${profile.id}`}
        initialValues={{ email }}
        onSubmit={onChangeEmail}
        onValidate={onValidate}
      />
    ) : (
      <span className={styles.email} title={email}>
        {email}
      </span>
    )
  }

  const { assessmentScores, department, isPublic, name, overwrittenPolicy } = profile

  const showStatus = !canDrag && showStatusLabel && ((!isPublic && !overwrittenPolicy) || assessmentScores.isNull)

  return (
    <Tag>
      <div
        className={classnames(
          styles.item,
          { [styles['is-type-a']]: type === 'a' },
          { [styles['is-type-b']]: type === 'b' },
          { [styles['is-dragging']]: isDragging },
          { [styles['can-drag']]: canDrag },
          className,
        )}
        title={sprintf(title, name)}
      >
        <Avatar
          className={styles.avatar}
          profile={profile}
          title={`${name}'s avatar`}
          hasBorder={profile._isTeam}
          icon={{
            name: profile._isTeam ? 'users' : 'user',
          }}
        />
        <Row className={styles.info}>
          {name && (
            <Col
              xs="12"
              md={type === 'a' && isDepartmentEnabled ? 4 : type === 'a' && !isDepartmentEnabled ? 5 : 12}
              className={styles.name2}
            >
              <span className={styles.name} title={name}>
                {name}
              </span>
            </Col>
          )}
          <Col xs="12" md={type === 'a' && isDepartmentEnabled ? 4 : type === 'a' && !isDepartmentEnabled ? 7 : 12}>
            {renderEmail()}
          </Col>
          {department && (
            <Col xs="12" md={4}>
              <span className={styles.name} title={department}>
                {department}
              </span>
            </Col>
          )}
          {showStatus && (
            <Col xs="12">
              <span className={styles.status}>
                <Icon.Stroke7 name={!isPublic && !overwrittenPolicy ? 'lock' : 'wristwatch'} />
                {!isPublic && !overwrittenPolicy
                  ? i18n.generics.listItem.status.private
                  : i18n.generics.listItem.status.pending}
              </span>
            </Col>
          )}
        </Row>
        <div className={styles.action}>{renderAction()}</div>
        {renderBadges()}
      </div>
      <div>{errors}</div>
    </Tag>
  )
}

ListItem.propTypes = {
  canDrag: PropTypes.bool,
  className: PropTypes.string,
  errors: CHILDREN,
  isDepartmentEnabled: PropTypes.bool,
  isDragging: PropTypes.bool,
  i18n: I18N.isRequired,
  onChangeEmail: PropTypes.func,
  onRequestAdd: PropTypes.func,
  onRequestRemove: PropTypes.func,
  onValidate: PropTypes.func,
  profile: PROFILE.isRequired,
  showStatusLabel: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.symbol, PropTypes.string]),
  title: PropTypes.string,
  type: PropTypes.string,
}

ListItem.defaultProps = {
  canDrag: false,
  className: null,
  errors: null,
  isDragging: false,
  onChangeEmail: undefined,
  onRequestAdd: undefined,
  onRequestRemove: undefined,
  onValidate: undefined,
  showStatusLabel: false,
  isDepartmentEnabled: false,
  tag: 'li',
  title: null,
  type: 'a',
}

export default Translation(ListItem, ['generics'])
