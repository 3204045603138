import { Col, Container, Row } from 'reactstrap'
import { MenuItem } from '@material-ui/core'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N, OWNERS } from 'constants/props'
import { StyledButton, StyledSelect } from 'generics/StyledFormComponents'
import Translation from 'generics/Translation'

import styles from './FormAssignTo.scss'
import useFormAssignTo from './useFormAssignTo'

/**
 * @description: Send Report Request Form
 * @extends FormComponent
 */
const FormAssignTo = ({ className, i18n, onSubmit, owners }) => {
  const { isValid, handleChange, handleSubmit, values } = useFormAssignTo({ i18n, onSubmit })

  const renderOwners = () => {
    const items = []

    if (owners && owners.length) {
      items.push(
        ...owners.map(owner => (
          <MenuItem key={owner.id} value={owner.id}>
            {`${owner.firstName} ${owner.lastName}`}
          </MenuItem>
        )),
      )
    }

    return items
  }

  return (
    <form className={className} onSubmit={handleSubmit}>
      <Container className={styles.container} fluid>
        <Row>
          <Col xs={12}>
            <StyledSelect
              className={styles['text-field']}
              label={i18n.pageTokenAdministration.ownerChange.selectOwnerLabel}
              name="owner"
              onChange={e => handleChange('owner', e.target?.value)}
              value={values.owner}
            >
              {renderOwners()}
            </StyledSelect>
          </Col>
          <Col xs={12} md={{ size: 8, offset: 2 }}>
            <StyledButton
              color="primary"
              disabled={!isValid}
              fullWidth
              id="select-owner-btn"
              title={i18n.pageTokenAdministration.ownerChange.reAssignTokensAction}
              type="submit"
            >
              {i18n.pageTokenAdministration.ownerChange.reAssignTokensAction}
            </StyledButton>
          </Col>
        </Row>
      </Container>
    </form>
  )
}

FormAssignTo.propTypes = {
  className: PropTypes.string,
  i18n: I18N.isRequired,
  onSubmit: PropTypes.func.isRequired,
  owners: OWNERS.isRequired,
}

FormAssignTo.defaultProps = {
  className: null,
}

export default Translation(withRouter(FormAssignTo), ['generics', 'pageTokenAdministration'])
