import { isNil } from 'lodash'
import { pickHTMLProps } from 'pick-react-known-prop'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import { StyledButton, StyledTextField } from 'generics/StyledFormComponents'
import InfoTooltip from 'generics/InfoTooltip'
import Translation from 'generics/Translation'

import styles from './FormResetPassword.scss'
import useFormResetPassword from './useFormResetPassword'

/**
 * FormResetPassword Component
 */
const FormResetPassword = ({ i18n, onSubmit, ...rest }) => {
  const { errors, isValid, handleChange, handleSubmit, values } = useFormResetPassword({ i18n, onSubmit })

  return (
    <form {...pickHTMLProps(rest)} onSubmit={handleSubmit}>
      <div className={styles['password-container']}>
        <StyledTextField
          className={styles.field}
          error={isNil(values.password) ? false : !!errors.password}
          helperText={isNil(values.password) ? ' ' : errors.password}
          label={`${i18n.pageGeneralAccountInformation.formChangePassword.labelNewPassword}:`}
          name="password"
          onChange={e => handleChange('password', e.currentTarget.value)}
          title={i18n.pageGeneralAccountInformation.formChangePassword.labelNewPassword}
          type="password"
          value={values.password || ''}
        />
        <InfoTooltip className={styles.tooltip} placement="left" text={i18n.generics.passwordTooltip} />
      </div>

      <StyledTextField
        className={styles.field}
        error={isNil(values.passwordConfirmation) ? false : !!errors.passwordConfirmation}
        helperText={isNil(values.passwordConfirmation) ? ' ' : errors.passwordConfirmation}
        label={`${i18n.pageGeneralAccountInformation.formChangePassword.labelPasswordConfirmation}:`}
        name="passwordConfirmation"
        onChange={e => handleChange('passwordConfirmation', e.currentTarget.value)}
        title={i18n.pageGeneralAccountInformation.formChangePassword.labelPasswordConfirmation}
        type="password"
        value={values.passwordConfirmation || ''}
      />

      <StyledButton
        className={styles.submit}
        color="primary"
        disabled={!isValid}
        title={i18n.login.button.submit}
        type="submit"
      >
        {i18n.login.button.submit}
      </StyledButton>
    </form>
  )
}

FormResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
}

export default Translation(FormResetPassword, ['pageGeneralAccountInformation', 'generics', 'login'])
