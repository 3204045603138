/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { isEqual } from 'lodash'
import { useEffect, useRef, useState } from 'react'

const MAXIMUM_PROFILES_ALLOWED = 12

const filterProfiles = props => {
  const { isTeamAverageInclude, profiles: profilesFromProps, team } = props

  const profiles = profilesFromProps.slice(0, MAXIMUM_PROFILES_ALLOWED).map((profile, profileIndex) =>
    profile.clone({
      index: profileIndex,
    }),
  )

  if (team && isTeamAverageInclude) {
    profiles.push(team)
  }

  return profiles
}

const useSpiderGraph = props => {
  const [profileIndexToHighlight, setProfileIndexToHighlight] = useState(null)
  const [profiles, setProfiles] = useState(filterProfiles(props))
  const [oldProps, setOldProps] = useState(null)

  const elementRefs = {
    container: useRef(null),
    nodeElement: useRef(null),
  }

  useEffect(() => {
    if (props) {
      if (!isEqual(props, oldProps)) {
        setProfiles(filterProfiles(props))
        setOldProps(props)

        const { innerRef } = props
        if (innerRef) {
          innerRef({ outerHTML })
        }
      }
    }
  }, [props])

  const handleOnMouseEnter = newProfileIndexToHighlight => {
    setProfileIndexToHighlight(newProfileIndexToHighlight)
  }

  const handleOnMouseLeave = () => {
    setProfileIndexToHighlight(null)
  }

  const outerHTML = () => elementRefs.container.current.outerHTML

  return {
    elementRefs,
    handleOnMouseEnter,
    handleOnMouseLeave,
    MAXIMUM_PROFILES_ALLOWED,
    profileIndexToHighlight,
    profiles,
  }
}

export default useSpiderGraph
