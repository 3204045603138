import { combineReducers } from 'redux'
import { findIndex, findKey, get } from 'lodash'

import accountsActionTypes from 'app_modules/accounts/constants'
import assessmentActionTypes from 'app_modules/assessment/constants'
import groupsActionTypes from 'app_modules/groups/constants'
import initialState from 'app_modules/store/initialState.json'
import invitesActionTypes from 'app_modules/invites/constants'
import profilesActionsTypes from 'app_modules/profile/constants'
import sessionActionTypes from 'app_modules/session/constants'
import userActionTypes from 'app_modules/user/constants'

export const assessmentScores = (state = initialState.user.assessmentScores, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case sessionActionTypes.SET_CURRENT_USER:
    case sessionActionTypes.SET_USER_BY_IMPERSONATION:
      return payload.assessmentScores && payload.assessmentScores.length > 0 ? [...payload.assessmentScores] : []

    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return [...payload]

    case sessionActionTypes.RESET_STATE:
      return initialState.user.assessmentScores

    default:
      return state
  }
}

export const selfInvitation = (state = initialState.user.selfInvitation, { payload, type }) => {
  switch (type) {
    case userActionTypes.SET_PERSONALS_SELF_INVITATION:
      return payload
    case sessionActionTypes.SET_CURRENT_USER:
    case sessionActionTypes.FETCH_COMPLETE_VIDEO_SUCCESS:
      return payload.selfInvitation

    case sessionActionTypes.RESET_STATE:
      return initialState.user.selfInvitation
    default:
      return state
  }
}

export const ssoProvisionData = (state = {}, { type, payload }) => {
  switch (type) {
    case sessionActionTypes.CONTINUE_PROVISIONING:
      return payload
    default:
      return state
  }
}

export const currentProfile = (state = initialState.user.currentProfile, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case accountsActionTypes.EDIT_ACCOUNT_SUCCESS:
    case accountsActionTypes.EDIT_CUSTOMIZATIONS_SUCCESS:
    case accountsActionTypes.FETCH_ACCOUNT_SUCCESS:
    case accountsActionTypes.FETCH_MY_MEMBERS_SUCCESS:
    case accountsActionTypes.FETCH_UPDATE_LOGO_SUCCESS:
    case groupsActionTypes.FETCH_GROUPS_SUCCESS:
    case invitesActionTypes.FETCH_SENT_INVITATIONS_SUCCESS:
      return {
        ...state,
        account: payload.account || state.account,
      }

    case profilesActionsTypes.FETCH_EDIT_PROFILE_SUCCESS:
    case profilesActionsTypes.FETCH_UPDATE_AVATAR_SUCCESS:
    case sessionActionTypes.FETCH_SET_PROFILE_PRIVACY_SUCCESS:
    case sessionActionTypes.SET_CURRENT_PROFILE:
    case userActionTypes.UPDATE_CURRENT_PROFILE:
      return {
        ...state,
        ...payload,
      }

    case userActionTypes.TOGGLE_NOTIFICATION:
      return {
        ...state,
        newsNotifications: false,
      }

    case accountsActionTypes.REMOVE_USER_PERMISSION:
      return {
        ...state,
        permissions: [
          ...state.permissions.map(permission => ({
            ...permission,
            [payload]: !permission[payload],
          })),
        ],
      }

    case sessionActionTypes.RESET_STATE:
      return initialState.user.currentProfile

    case assessmentActionTypes.FETCH_SAVING_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          assessmentScores: [...payload],
        },
      }

    default:
      return state
  }
}

export const documents = (state = initialState.user.documents, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case sessionActionTypes.FETCH_DOCUMENTS_SUCCESS:
      return [...payload]

    case sessionActionTypes.FETCH_DOCUMENTS_FAILURE:
    case sessionActionTypes.FETCH_DOCUMENTS_REQUEST:
      return []

    case sessionActionTypes.RESET_STATE:
      return initialState.user.documents

    default:
      return state
  }
}

export const id = (state = initialState.user.id, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case sessionActionTypes.SET_CURRENT_USER:
    case sessionActionTypes.SET_USER_BY_IMPERSONATION:
      return payload.id

    case sessionActionTypes.RESET_STATE:
      return initialState.user.id

    default:
      return state
  }
}

export const defaultAccount = (state = initialState.user.defaultAccount, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case sessionActionTypes.SET_DEFAULT_ACCOUNT_SUCCESS:
      return payload
    case sessionActionTypes.SET_CURRENT_USER:
    case sessionActionTypes.SET_USER_BY_IMPERSONATION:
      return payload.defaultAccount

    case sessionActionTypes.RESET_STATE:
      return initialState.user.defaultAccount

    default:
      return state
  }
}

export const impersonateToken = (state = initialState.user.impersonateToken, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case sessionActionTypes.SET_USER_BY_IMPERSONATION:
      return payload.impersonateToken

    case sessionActionTypes.RESET_STATE:
    case sessionActionTypes.SET_CURRENT_USER:
      return initialState.user.impersonateToken

    default:
      return state
  }
}

export const ssoToken = (state = initialState.user.ssoToken, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case sessionActionTypes.SET_CURRENT_USER:
      return payload.ssoToken || initialState.user.ssoToken

    case sessionActionTypes.RESET_STATE:
      return initialState.user.impersonateToken

    default:
      return state
  }
}

export const internalAdmin = combineReducers({
  createdAt: (state = initialState.user.internalAdmin.createdAt, action) => {
    const { payload, type: actionType } = action

    switch (actionType) {
      case sessionActionTypes.SET_USER_BY_IMPERSONATION:
        return payload.internalAdmin.id

      case sessionActionTypes.RESET_STATE:
        return initialState.user.internalAdmin.createdAt

      default:
        return state
    }
  },

  email: (state = initialState.user.internalAdmin.email, action) => {
    const { payload, type: actionType } = action

    switch (actionType) {
      case sessionActionTypes.SET_USER_BY_IMPERSONATION:
        return payload.internalAdmin.email

      case sessionActionTypes.RESET_STATE:
        return initialState.user.internalAdmin.email

      default:
        return state
    }
  },

  id: (state = initialState.user.internalAdmin.id, action) => {
    const { payload, type: actionType } = action

    switch (actionType) {
      case sessionActionTypes.SET_USER_BY_IMPERSONATION:
        return payload.internalAdmin.id

      case sessionActionTypes.RESET_STATE:
        return initialState.user.internalAdmin.id

      default:
        return state
    }
  },

  name: (state = initialState.user.internalAdmin.name, action) => {
    const { payload, type: actionType } = action

    switch (actionType) {
      case sessionActionTypes.SET_USER_BY_IMPERSONATION:
        return payload.internalAdmin.name

      case sessionActionTypes.RESET_STATE:
        return initialState.user.internalAdmin.name

      default:
        return state
    }
  },
})

export const modules = (state = initialState.user.modules, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case sessionActionTypes.FETCH_CATEGORY_BY_KEY_SUCCESS: {
      const { data, response } = payload
      const moduleIndex = findIndex(state, mod => mod.key === data.module_key)
      const docKey = findKey(state[moduleIndex].rawContent.documents, category => category.key === data.category_key)
      const newModules = [].concat(modules)
      if (newModules[moduleIndex] && newModules[moduleIndex].rawContent) {
        newModules[moduleIndex].rawContent.documents[docKey].content = response.content
      }
      return newModules
    }

    case sessionActionTypes.FETCH_DASHBOARD_SUCCESS:
      return [...payload.modules].filter(module => module.categoryKey !== 'meetings_8')

    case sessionActionTypes.FETCH_DASHBOARD_REQUEST:
      return []

    case sessionActionTypes.RESET_STATE:
      return initialState.user.modules

    default:
      return state
  }
}

export const overview = (state = initialState.user.overview, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case sessionActionTypes.FETCH_DASHBOARD_SUCCESS:
      return payload.overview

    case sessionActionTypes.FETCH_DASHBOARD_REQUEST:
      return null

    case sessionActionTypes.RESET_STATE:
      return initialState.user.overview

    default:
      return state
  }
}

export const overviewSummary = (state = initialState.user.overviewSummary, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case sessionActionTypes.FETCH_DASHBOARD_SUCCESS:
      return payload.overviewSummary

    case sessionActionTypes.RESET_STATE:
      return initialState.user.overviewSummary

    default:
      return state
  }
}

export const profiles = (state = initialState.user.profiles, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case userActionTypes.FETCH_ACCOUNT_FAILURE_BY_EXPIRATION:
      return state.map(profile => ({
        ...profile,
        account: payload.find(acc => acc.id === profile.account.id),
      }))

    case userActionTypes.UPDATE_CURRENT_PROFILE:
      return state.map(profile => {
        if (profile.account.id === payload.id) {
          return payload
        }
        return profile
      })

    case accountsActionTypes.FETCH_ACCOUNT_SUCCESS:
    case accountsActionTypes.FETCH_MY_MEMBERS_SUCCESS:
    case groupsActionTypes.FETCH_GROUPS_SUCCESS:
    case invitesActionTypes.FETCH_SENT_INVITATIONS_SUCCESS:
    case accountsActionTypes.FETCH_UPDATE_LOGO_SUCCESS:
      return state.map(profile => {
        if (profile.account.id === get(payload, 'account.id')) {
          return {
            ...profile,
            account: payload.account,
          }
        }
        return profile
      })

    case profilesActionsTypes.FETCH_EDIT_PROFILE_SUCCESS:
    case profilesActionsTypes.FETCH_UPDATE_AVATAR_SUCCESS:
    case sessionActionTypes.FETCH_SET_PROFILE_PRIVACY_SUCCESS:
      return state.map(profile => {
        if (profile.id === payload.id) {
          return {
            ...profile,
            ...payload,
          }
        }
        return profile
      })

    case sessionActionTypes.SET_CURRENT_USER:
    case sessionActionTypes.SET_USER_BY_IMPERSONATION:
      return [...payload.profiles]

    case sessionActionTypes.ADD_PROFILE:
      return [...state, payload]

    case userActionTypes.TOGGLE_NOTIFICATION:
      return [
        ...state.map(profile => ({
          ...profile,
          newsNotifications: profile.account.accountType === payload ? false : profile.newsNotifications,
        })),
      ]

    case sessionActionTypes.RESET_STATE:
      return initialState.user.profiles

    default:
      return state
  }
}

export const token = (state = initialState.user.token, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case sessionActionTypes.SET_CURRENT_USER:
      return payload.token || initialState.user.token

    case sessionActionTypes.RESET_STATE:
    case sessionActionTypes.SET_USER_BY_IMPERSONATION:
      return initialState.user.token

    default:
      return state
  }
}

export const tokenExpiration = (state = initialState.user.tokenExpiration, action) => {
  const { payload, type: actionType } = action

  switch (actionType) {
    case sessionActionTypes.SET_CURRENT_USER:
    case sessionActionTypes.SET_USER_BY_IMPERSONATION:
      return payload.tokenExpiration

    case sessionActionTypes.RESET_STATE:
      return initialState.user.tokenExpiration

    default:
      return state
  }
}

export const videos = (state = initialState.user.videos, { type: actionType, payload }) => {
  switch (actionType) {
    case sessionActionTypes.FETCH_VIDEOS_SUCCESS:
      return payload.videos

    case sessionActionTypes.RESET_STATE:
      return initialState.user.videos

    default:
      return state
  }
}

export default combineReducers({
  assessmentScores,
  currentProfile,
  defaultAccount,
  documents,
  id,
  impersonateToken,
  internalAdmin,
  modules,
  overview,
  overviewSummary,
  profiles,
  selfInvitation,
  ssoProvisionData,
  ssoToken,
  token,
  tokenExpiration,
  videos,
})
