const NAME_SPACE = 'User'

const constants = {
  FETCH_ACCOUNT_FAILURE_BY_EXPIRATION: `${NAME_SPACE}/FETCH_ACCOUNT_FAILURE_BY_EXPIRATION`,
  UPDATE_CURRENT_PROFILE: `${NAME_SPACE}/UPDATE_CURRENT_PROFILE`,
  SET_PERSONALS_SELF_INVITATION: `${NAME_SPACE}/SET_PERSONALS_SELF_INVITATION`,
  TOGGLE_NOTIFICATION: `${NAME_SPACE}/TOGGLE_NOTIFICATION`,
}

export default constants
