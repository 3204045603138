import { CircularProgress, MenuItem } from '@material-ui/core'
import { Col, Container, Row } from 'reactstrap'
import { findIndex } from 'lodash'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import shortid from 'shortid'

import { DOCUMENTS, I18N } from 'constants/props'
import { StyledButton, StyledSelect } from 'generics/StyledFormComponents'
import Icon from 'generics/Icon'
import Mask from 'generics/Mask'
import Translation from 'generics/Translation'

import styles from './PageCategories.scss'

const PageCategories = ({
  className,
  documents,
  i18n,
  isFetching,
  emptyMessage = i18n.generics.connectionsEmptyMessage,
  title: moduleTitle,
}) => {
  const [currentDocument, setCurrentDocument] = useState(documents[0])

  useEffect(() => {
    if (documents) {
      setCurrentDocument(documents[0])
    }
  }, [documents])

  const getDocumentByKey = key => documents.find(({ documentKey }) => documentKey === key)

  const getCurrentDocumentIndex = () => {
    const { documentKey: currentDocumentKey } = currentDocument
    return findIndex(documents, ({ documentKey }) => documentKey === currentDocumentKey)
  }

  const handleChangeContent = documentKey => {
    setCurrentDocument(getDocumentByKey(documentKey))
  }

  const handleNextContent = () => {
    const currentDocumentIndex = getCurrentDocumentIndex()

    if (currentDocumentIndex < documents.length - 1) {
      setCurrentDocument(documents[currentDocumentIndex + 1])
    }
  }

  const handlePreviousContent = () => {
    const currentDocumentIndex = getCurrentDocumentIndex()

    if (currentDocumentIndex > 0) {
      setCurrentDocument(documents[currentDocumentIndex - 1])
    }
  }

  const renderPrevButton = () => {
    if (currentDocument === undefined) {
      return null
    }

    const { documentKey: currentDocumentKey } = currentDocument

    if (documents.length === 0 || documents[0].documentKey === currentDocumentKey) {
      return null
    }

    return (
      <div className={styles.prev}>
        <StyledButton
          className={styles.button}
          onClick={handlePreviousContent}
          title={i18n.generics.previous}
          variant="text"
        >
          <Icon.Stroke7 className={styles.button__icon} name="angle-left" />
          <span className={styles.button__text}>{i18n.generics.previous}</span>
        </StyledButton>
      </div>
    )
  }

  const renderNextButton = () => {
    if (currentDocument === undefined) {
      return null
    }

    const { documentKey: currentDocumentKey } = currentDocument

    if (documents.length === 0 || documents[documents.length - 1].documentKey === currentDocumentKey) {
      return null
    }

    return (
      <div className={styles.next}>
        <StyledButton className={styles.button} onClick={handleNextContent} title={i18n.generics.next} variant="text">
          <span className={styles.button__text}>{i18n.generics.next}</span>
          <Icon.Stroke7 className={styles.button__icon} name="angle-right" />
        </StyledButton>
      </div>
    )
  }

  const isEmpty = isFetching ? true : currentDocument === undefined

  // TODO: use for setIterationIndex from helpers
  const matches = currentDocument
    ? currentDocument.matches.map(match => Object.assign(match, { id: shortid.generate() }))
    : []

  return (
    <div>
      <main className={classnames(styles.main, className, { [styles['is-empty']]: isEmpty })}>
        {isEmpty && <span title={emptyMessage}>{emptyMessage}</span>}

        {!isEmpty && (
          <Container>
            <Row>
              <Col className="hidden-md-up" xs="12">
                <StyledSelect
                  ariaLabel={`${moduleTitle} dropdown list. ${currentDocument.title} selected.`}
                  className={styles.select}
                  color="primary"
                  fullWidth
                  iconClassName={styles['select-icon']}
                  onChange={e => handleChangeContent(e.target.value)}
                  value={currentDocument.documentKey}
                >
                  {documents.map(({ documentKey, title }) => (
                    <MenuItem key={documentKey} value={documentKey}>
                      {title.toUpperCase()}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Col>
            </Row>
            <Row>
              <Col className="hidden-sm-down" md="3">
                <ul>
                  {documents.map(({ documentKey, title }) => (
                    <li
                      className={classnames(styles['list-item'], {
                        [styles['is-active']]: documentKey === currentDocument.documentKey,
                      })}
                      key={documentKey}
                    >
                      <StyledButton
                        color="default"
                        onClick={() => handleChangeContent(documentKey)}
                        title={title}
                        variant="text"
                      >
                        {title.toUpperCase()}
                      </StyledButton>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md="9" xs="12">
                <div className={styles.content}>
                  {matches.map(({ content, id, isCustom, title }) => (
                    <section key={id}>
                      {title && <h2 className={styles['section-title']}>{title}</h2>}
                      {content && !isCustom && (
                        <div
                          className="epic"
                          /* eslint-disable react/no-danger */
                          dangerouslySetInnerHTML={{ __html: content }}
                          /* eslint-enable */
                        />
                      )}
                      {content && isCustom && <div>{content}</div>}
                    </section>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        )}

        <Mask className={styles.mask} open={isFetching}>
          <CircularProgress className={styles.loader} size={60} thickness={7} />
        </Mask>
      </main>

      {currentDocument && (
        <nav>
          <Container>
            <Row>
              <Col xs="6">{renderPrevButton()}</Col>
              <Col xs="6">{renderNextButton()}</Col>
            </Row>
          </Container>
        </nav>
      )}
    </div>
  )
}

PageCategories.propTypes = {
  className: PropTypes.string,
  documents: DOCUMENTS,
  emptyMessage: PropTypes.string,
  i18n: I18N.isRequired,
  isFetching: PropTypes.bool,
  title: PropTypes.string.isRequired,
}

PageCategories.defaultProps = {
  className: null,
  documents: [],
  emptyMessage: '',
  isFetching: false,
}

export default Translation(PageCategories, ['generics'])
