import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { PROFILE } from 'props'
import Avatar from 'generics/Avatar'
import TestingScores from 'generics/TestingScores'

import styles from './ProfileSelector.scss'

const ProfileSelector = ({ className, hasLegend, legend, profile }) => (
  <div className={classnames(styles.employee, className)}>
    <Avatar className={styles.avatar} default icon={{ name: 'user' }} profile={profile} />

    <div className={styles['name-container']}>
      <div className={styles['first-last-name']}>{profile?.name}</div>

      {hasLegend && legend && (
        <div className={styles['job-title']}>
          <span title={legend.toUpperCase()}>{legend.toUpperCase()}</span>
        </div>
      )}
    </div>
    <TestingScores profile={profile} className={styles.scores} />
  </div>
)

ProfileSelector.propTypes = {
  className: PropTypes.string,
  profile: PROFILE,
  hasLegend: PropTypes.bool,
  legend: PropTypes.string,
}

ProfileSelector.defaultProps = {
  className: null,
  profile: null,
  hasLegend: false,
  legend: null,
}

export default ProfileSelector
