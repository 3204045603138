/**
 * @description Settings for licenses controller
 * @module API controller / Licenses
 */

module.exports = {
  getLicensesStrategies: {
    /**
     * @description Get request settings
     * @param {object} options
     * @returns {object} request settings
     */
    settings: accountId => ({
      endpoint: `/account/${accountId}/subscriptions/licenses_strategies`,
      method: 'GET',
    }),
  },
}
