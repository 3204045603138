const NAME_SPACE = 'ProMember'

const constants = {
  FETCH_UPGRADE_MEMBER_REQUESTS_FAILURE: `${NAME_SPACE}/FETCH_UPGRADE_MEMBER_REQUESTS_FAILURE`,
  FETCH_UPGRADE_MEMBER_REQUESTS_REQUEST: `${NAME_SPACE}/FETCH_UPGRADE_MEMBER_REQUESTS_REQUEST`,
  FETCH_UPGRADE_MEMBER_REQUESTS_SUCCESS_NO_RESULTS: `${NAME_SPACE}/FETCH_UPGRADE_MEMBER_REQUESTS_SUCCESS_NO_RESULTS`,
  FETCH_UPGRADE_MEMBER_REQUESTS_SUCCESS: `${NAME_SPACE}/FETCH_UPGRADE_MEMBER_REQUESTS_SUCCESS`,
  FETCH_UPGRADE_MEMBER_REQUESTS: `${NAME_SPACE}/FETCH_UPGRADE_MEMBER_REQUESTS`,
  TOGGLE_PRO_MEMBER_MODAL: `${NAME_SPACE}/TOGGLE_PRO_MEMBER_MODAL`,
}

export default constants
