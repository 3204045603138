import PropTypes from 'prop-types'
import React from 'react'

const ListItemActions = props => {
  const { actions, className } = props

  if (!actions || actions.length === 0) {
    return null
  }

  return <div className={className}>{actions.map(action => action)}</div>
}

ListItemActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
}

ListItemActions.defaultProps = {
  actions: [],
  className: null,
}

export default ListItemActions
