export const en = {
  addNoteTitle: 'Add a note',
  editNote: 'Edit Note',
  exportNotes: 'Export Notes',
  dateLabel: 'Last modified:',
  noteDescriptionLabel: 'Note',
  noteTitleLabel: 'Title',
  title: 'Notes',
  yourNotestitle: 'Your Notes',
}

export const es = en
