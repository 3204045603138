import PropTypes from 'prop-types'

import { I18N } from 'constants/props'
import useForm from 'custom_hooks/useForm'

const useFormEditVideoCustomization = ({ initialValues, onSubmit }) => {
  const handleValidate = () => ({
    bodyContent: null,
    embedCode: null,
    heading: null,
  })

  const {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    reset,
    values = { bodyContent: null, embedCode: null, heading: null },
  } = useForm({ initialValues, onSubmit, onValidate: handleValidate })

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    reset,
    values,
  }
}

useFormEditVideoCustomization.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
}

export default useFormEditVideoCustomization
