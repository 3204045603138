import { Col, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import React from 'react'

import { Footer as ModalFooter } from 'generics/Card'
import { I18N } from 'constants/props'
import { StyledButton } from 'generics/StyledFormComponents'
import Translation from 'generics/Translation'

import styles from './Footer.scss'

/**
 * Renders Cancel Button
 * @param {function} fn (handler)
 */
const renderCancelButton = (fn, title) => (
  <StyledButton className={styles.cancel} color="default" onClick={fn} title={title} variant="text">
    {title}
  </StyledButton>
)

/**
 * Renders Raised Button
 * @param {bool} isDisabled
 * @param {function} fn
 * @param {string} title
 */
const renderButton = (isDisabled, fn, title) => (
  <StyledButton className={styles.button} disabled={isDisabled} onClick={fn} color="primary" title={title}>
    {title}
  </StyledButton>
)

/**
 * Footer Component
 */
const Footer = props => {
  const { i18n, isRemoveDisabled, isSubmitDisabled, onCancel, onRemove, onSubmit } = props

  return (
    <ModalFooter className={styles.footer}>
      <Row>
        <Col sm="3" xs="12">
          {renderCancelButton(onCancel, i18n.pictureSelectorModal.cancel)}
        </Col>
        <Col sm="9" xs="12">
          {renderButton(isSubmitDisabled, onSubmit, i18n.pictureSelectorModal.save)}
          {renderButton(isRemoveDisabled, onRemove, i18n.pictureSelectorModal.remove)}
        </Col>
      </Row>
    </ModalFooter>
  )
}

Footer.propTypes = {
  i18n: I18N.isRequired,
  isRemoveDisabled: PropTypes.bool.isRequired,
  isSubmitDisabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default Translation(Footer, ['pictureSelectorModal'])
