import htmlParser from 'html-react-parser'
import PropTypes from 'prop-types'
import React from 'react'

import { Header } from 'generics/Card'
import { I18N } from 'constants/props'
import { NOT_AVAILABLE_INVITES, SSO_ERROR } from 'constants/apiErrorCodes'
import Translation from 'generics/Translation'

import styles from './ErrorContent.scss'

const { PUBLIC_PATH } = process.env
const LOGO = `${window.location.origin}${PUBLIC_PATH}tenants/linkedin-logo.png`

/**
 * @class ErrorContent
 * @description Linkedin error content
 */
const ErrorContent = ({ i18n, errorCode }) => {
  let errorName = null

  switch (errorCode) {
    case NOT_AVAILABLE_INVITES:
      errorName = 'noLicenses'
      break
    case SSO_ERROR:
    default:
      errorName = 'generalError'
  }

  return (
    <>
      <img alt="Linkedin" className={styles.logo} src={LOGO} />
      <Header className={styles.title} title={i18n.errorPages.linkedin[errorName].title} />
      <p className={styles.content}>{htmlParser(i18n.errorPages.linkedin[errorName].content)}</p>
    </>
  )
}

ErrorContent.propTypes = {
  errorCode: PropTypes.number,
  i18n: I18N.isRequired,
}

ErrorContent.defaultProps = {
  errorCode: null,
}

export default Translation(ErrorContent, ['errorPages'])
