/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'

import { DATE, GROUP_NAME, REQUIRED } from 'constants/inputTypes'
import { I18N } from 'constants/props'
import useForm from 'custom_hooks/useForm'
import useFormValidation from 'custom_hooks/useFormValidation'
import { useEffect, useState } from 'react'

const useFormWorkshop = ({ i18n, initialValues, onChange }) => {
  const { validator } = useFormValidation({ i18n })
  const [callOnChange, setCallOnChange] = useState(false)

  const handleValidate = values => {
    const { date, name } = values

    setCallOnChange(true)

    return {
      name: validator.validate(GROUP_NAME, name),
      date: validator.validate(REQUIRED, date) || validator.validate(DATE, date),
    }
  }

  const { errors, isValid, handleChange, handleSubmit, values = { email: null, password: null } } = useForm({
    initialValues,
    onValidate: handleValidate,
  })

  useEffect(() => {
    if (callOnChange) {
      onChange(values, isValid)
      setCallOnChange(false)
    }
  }, [callOnChange])

  return {
    errors,
    isValid,
    handleChange,
    handleSubmit,
    values,
  }
}

useFormWorkshop.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
}

export default useFormWorkshop
