import { Col, Container, Row } from 'reactstrap'
import { isNil } from 'lodash'
import { pickHTMLProps } from 'pick-react-known-prop'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { Footer, Header, Main } from 'generics/Card'
import { I18N, DISTRIBUTION_LEVELS } from 'constants/props'
import { StyledButton, StyledCheckbox, StyledTextField } from 'generics/StyledFormComponents'
import { FiInfo } from 'react-icons/fi'

import Translation from 'generics/Translation'

import styles from './FormLicensesQuantity.scss'
import useFormLicensesQuantity from './useFormLicensesQuantity'

const FormLicensesQuantity = ({
  i18n,
  onClose,
  onSubmit,
  licensesStrategies,
  isForStrategies,
  isLimitedMember,
  ...rest
}) => {
  const QUANTITY_MAX = 100
  const DOH_MAX = 25
  const DECREASE = '-'
  const INCREASE = '+'
  const formTitle = isForStrategies ? i18n.subscriptions.licenseDistribution.title : i18n.subscriptions.title
  const formQuantity = isForStrategies ? i18n.subscriptions.licenseDistribution.quantity : i18n.subscriptions.quantity

  const { errors, isValid, handleChange, handleNumberFieldChange, handleSubmit, values } = useFormLicensesQuantity({
    i18n,
    onSubmit,
    isForStrategies,
    distributionLevel: licensesStrategies[0]?.id,
  })

  const [showDepthOfHierarchy, setShowDepthOfHierarchy] = useState(false)
  const [showAutomaticInvitations, setShowAutomaticInvitations] = useState(false)
  const [licenseStrategyId, setLicenseStrategyId] = useState(null)
  const [licenseStrategyTitle, setLicenseStrategyTitle] = useState('')
  const [quantity, setQuantity] = useState(null)
  const [depthOfHierarchy, setDepthOfHierarchy] = useState(null)
  const [autoSent, setAutoSent] = useState(null)
  const [distributionLevelId, setDistributionLevelId] = useState(null)

  const handleStrategiesChangeField = (id, title, value) => {
    const isDistributionLevel = value && id === distributionLevelId

    if (value) {
      setLicenseStrategyId(id)
      setLicenseStrategyTitle(title)
    } else {
      setLicenseStrategyId(null)
      setLicenseStrategyTitle('')
    }

    if (isDistributionLevel) setShowAutomaticInvitations(true)
    else setShowAutomaticInvitations(false)

    if (isDistributionLevel && title === i18n.subscriptions.someLevels) setShowDepthOfHierarchy(true)
    else setShowDepthOfHierarchy(false)

    setDepthOfHierarchy(0)
    setAutoSent(null)
  }

  const handleThisChange = (value, setChange, max) => {
    const number = value?.currentTarget?.value && parseInt(value?.currentTarget?.value, 10)
    if (value === INCREASE) setChange(current => (current >= max ? max : current + 1))
    else if (value === DECREASE) setChange(current => (current < 0 ? 0 : current - 1))
    else if (number === 0) setChange(1)
    else if (!number) setChange(0)
    else setChange(number > max ? max : number)
  }

  const handleQuantityChange = value => handleThisChange(value, setQuantity, QUANTITY_MAX)
  const handleDepthOfHierarchyChange = value => handleThisChange(value, setDepthOfHierarchy, DOH_MAX)

  useEffect(() => {
    if (licensesStrategies.length > 0) setDistributionLevelId(licensesStrategies[0]?.id)
  }, [licensesStrategies])

  useEffect(() => {
    handleNumberFieldChange('quantity', quantity)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity])

  useEffect(() => {
    handleNumberFieldChange('depthOfHierarchy', depthOfHierarchy, DOH_MAX, licenseStrategyTitle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depthOfHierarchy, licenseStrategyTitle])

  useEffect(() => {
    handleChange('autoSent', autoSent ? true : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSent])

  return (
    <>
      <Header>
        <h1 className={styles.title} title={formTitle}>
          {formTitle}
        </h1>
        {(!isForStrategies && isLimitedMember) ? <p className={styles.description_1}>{i18n.subscriptions.descriptionLimitedMember}</p> : ""}
        {!isForStrategies && <p>{i18n.subscriptions.description}</p>}
      </Header>
      <Main>
        <Container fluid>
          <form className={classNames(styles.form, isForStrategies ? styles.adjust : null)} {...pickHTMLProps(rest)}>
            <section className={classNames(styles.counter, isForStrategies ? styles.half : null)}>
              {isForStrategies && (
                <button
                  type="button"
                  disabled={quantity === 0 || quantity === null}
                  onClick={() => handleQuantityChange(DECREASE)}
                >
                  {DECREASE}
                </button>
              )}
              <StyledTextField
                className={styles['text-field']}
                error={isNil(values.quantity) ? false : !!errors.quantity}
                helperText={isNil(values.quantity) ? ' ' : errors.quantity}
                label={formQuantity}
                name="quantity"
                onChange={handleQuantityChange}
                title={formQuantity}
                value={values.quantity}
              />
              {isForStrategies && (
                <button
                  type="button"
                  disabled={quantity === QUANTITY_MAX}
                  onClick={() => handleQuantityChange(INCREASE)}
                >
                  {INCREASE}
                </button>
              )}
            </section>
            {isForStrategies && (
              <div className={classNames(styles.disclosure, styles.half)}>
                <div className={classNames(styles.icon)}>
                  <FiInfo />
                </div>
                <div className={styles['disclosure-message']}>
                  <p className={styles['disclosure-title']}>
                    {i18n.subscriptions.licenseDistribution.disclosure.title}
                  </p>
                  {isLimitedMember ? (
                    <ul>
                      <li>{i18n.subscriptions.licenseDistribution.disclosure.autoAssign}</li>
                      <li>{i18n.subscriptions.licenseDistribution.disclosure.renewal}</li>
                    </ul>
                  ) : (
                    <p>{i18n.subscriptions.licenseDistribution.disclosure.renewal}</p>
                  )}
                </div>
              </div>
            )}

            {isForStrategies && licensesStrategies.length > 0 && (
              <>
                <p>{i18n.subscriptions.licenseStrategy}</p>
                {licensesStrategies.length > 0 && (
                  <div className={styles.checkboxes}>
                    {[licensesStrategies[0], ...licensesStrategies].map(({ id, name }, index) => {
                      let title = name

                      if (index === 0) title = i18n.subscriptions.allLevels
                      else if (index === 1) title = i18n.subscriptions.someLevels

                      const checked = id === licenseStrategyId && title === licenseStrategyTitle

                      return (
                        <div key={(id, title)} className={classNames(styles.container, checked && styles.checked)}>
                          <section className={styles.checkbox}>
                            <label htmlFor={title} aria-label={title}>
                              {title}
                            </label>
                            <StyledCheckbox
                              checked={checked}
                              name={checked ? 'licenseStrategyId' : index}
                              color="primary"
                              onChange={e => {
                                handleStrategiesChangeField(id, title, e.target.checked)
                                handleChange('licenseStrategyId', parseInt(e.target.value, 10) || null)
                              }}
                              value={id}
                            />
                          </section>
                          <img
                            className={styles.img}
                            src={DISTRIBUTION_LEVELS[index].img}
                            alt={DISTRIBUTION_LEVELS[index].name}
                          />
                          <ul className={styles.list}>
                            {i18n.subscriptions.licenseStrategiesDescriptions[index].map(description => (
                              <li key={description} className={styles['list-item']}>
                                {description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )
                    })}
                  </div>
                )}
              </>
            )}
            {isForStrategies && showDepthOfHierarchy && (
              <section className={classNames(styles.counter, styles.half)}>
                <button
                  type="button"
                  disabled={!depthOfHierarchy || depthOfHierarchy === 0 || depthOfHierarchy === null}
                  onClick={() => handleDepthOfHierarchyChange(DECREASE)}
                >
                  {DECREASE}
                </button>
                <StyledTextField
                  className={styles['text-field']}
                  error={isNil(values.depthOfHierarchy) ? false : !!errors.depthOfHierarchy}
                  helperText={isNil(values.depthOfHierarchy) ? ' ' : errors.depthOfHierarchy}
                  label={i18n.subscriptions.depthOfHierarchy}
                  name="depthOfHierarchy"
                  onChange={handleDepthOfHierarchyChange}
                  title={i18n.subscriptions.depthOfHierarchy}
                  value={values.depthOfHierarchy}
                />
                <button
                  type="button"
                  disabled={depthOfHierarchy === DOH_MAX}
                  onClick={() => handleDepthOfHierarchyChange(INCREASE)}
                >
                  {INCREASE}
                </button>
              </section>
            )}
            {isForStrategies && showAutomaticInvitations && (
              <section className={styles['auto-sent']}>
                <StyledCheckbox
                  checked={autoSent}
                  name="autoSent"
                  color="primary"
                  onChange={() => setAutoSent(current => !current)}
                />
                <label htmlFor="autoSent" aria-label="Auto Sent">
                  {i18n.subscriptions.autoSent}
                </label>
              </section>
            )}
          </form>
        </Container>
      </Main>
      <Footer>
        <Container>
          <Row>
            <Col xs="3" md="2">
              <StyledButton
                color="default"
                fullWidth
                onClick={onClose}
                title={i18n.generics.cancelLabel}
                variant="text"
              >
                {i18n.generics.cancelLabel}
              </StyledButton>
            </Col>
            <Col xs={{ size: 3, offset: 6 }} md={{ size: 3, offset: 7 }}>
              <StyledButton
                color="primary"
                disabled={!isValid}
                fullWidth
                id="save-video-customization-btn"
                onClick={handleSubmit}
                title={i18n.generics.continueLabel}
              >
                {i18n.generics.continueLabel}
              </StyledButton>
            </Col>
          </Row>
        </Container>
      </Footer>
    </>
  )
}

FormLicensesQuantity.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
  licensesStrategies: PropTypes.shape([]),
  isForStrategies: PropTypes.bool,
  isLimitedMember: PropTypes.bool,
}

FormLicensesQuantity.defaultProps = {
  licensesStrategies: [],
  isForStrategies: false,
  isLimitedMember: true,
}

export default Translation(FormLicensesQuantity, ['generics', 'subscriptions'])
