import * as sessionSelectors from 'app_modules/session/selectors'
import api from 'api'
import proMemberActionTypes from 'app_modules/proMember/constants'

// Actions

export const fetchMemberUpgradeRequestsFailure = error => ({
  payload: error,
  type: proMemberActionTypes.FETCH_UPGRADE_MEMBER_REQUESTS_FAILURE,
})

export const fetchMemberUpgradeRequestsRequest = pageIndex => ({
  payload: pageIndex,
  type: proMemberActionTypes.FETCH_UPGRADE_MEMBER_REQUESTS_REQUEST,
})

export const fetchMemberUpgradeRequestsSuccess = response => ({
  payload: response,
  type: proMemberActionTypes.FETCH_UPGRADE_MEMBER_REQUESTS_SUCCESS,
})

export const fetchMemberUpgradeRequestsSuccessWithNoResults = () => ({
  payload: { isEmptyList: true },
  type: proMemberActionTypes.FETCH_UPGRADE_MEMBER_REQUESTS_SUCCESS_NO_RESULTS,
})

// Thunks

export const fetchMemberUpgradeRequests = ({ pageIndex = 1, search, filters }) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchMemberUpgradeRequestsRequest(pageIndex))

    const handleResponse = response => {
      const { licenseRequests } = response

      if (!licenseRequests) {
        dispatch(fetchMemberUpgradeRequestsSuccessWithNoResults())
        return response
      }

      const fixedResponse = {
        ...response,
        updateCounters: !search,
      }

      dispatch(fetchMemberUpgradeRequestsSuccess(fixedResponse))
      return response
    }

    const handleError = ({ error }) => {
      dispatch(fetchMemberUpgradeRequestsFailure(error))
    }

    return api.proMember.getUpgradeMemberRequests(
      { accountId, pageIndex, search, filters },
      handleResponse,
      handleError,
    )
  }

  thunk.type = proMemberActionTypes.FETCH_UPGRADE_MEMBER_REQUESTS

  return thunk
}

export default fetchMemberUpgradeRequests
