import { get, isEmpty, some } from 'lodash'
import { replace } from 'connected-react-router'

import { AppError, ModalError } from 'classes/errors'
import { logout } from 'app_modules/session/actions/fetchLogout'
import { notifyError, notifyWarning } from 'app_modules/notifications/actions'
import { PENDING_PRO_MEMBER, PENDING_LIMITED_MEMBER } from 'constants/invitationStatus'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/invites/constants'
import api from 'api'

import { clearInvitation } from './fetchAcceptInvitation'

// Actions

export const fetchInvitationFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_INVITATION_FAILURE,
})

export const fetchInvitationRequest = () => ({
  type: actionTypes.FETCH_INVITATION_REQUEST,
})

export const fetchInvitationSuccess = invitation => ({
  payload: invitation,
  type: actionTypes.FETCH_INVITATION_SUCCESS,
})

// Thunks

export const getInvitation = (invitationToken, onError) => {
  const thunk = (dispatch, getState) => {
    const state = getState()

    const { invites, user } = state

    const { token: currentInvitationToken } = invites.invitation

    let { isNewUser } = invites.invitation

    if (invitationToken === currentInvitationToken) {
      if (isNewUser) {
        dispatch(replace('/signup'))
      } else {
        dispatch(replace('/login'))
      }
      return
    }

    dispatch(fetchInvitationRequest())

    const handleError = ({ error }) => {
      const options = {
        buttonLabel: 'OK',
        message:
          'If you previously accepted an invitation and set up your profile, then click "OK" to login in now. Otherwise, contact the person that sent you the email and request a new invitation.',
        subtitle: 'This link is no longer valid.',
        title: get(error, 'message', 'Invalid invitation'),
      }

      if (sessionSelectors.isAuthenticated(state)) {
        dispatch(logout())
      }

      dispatch(clearInvitation())
      dispatch(replace('/login'))
      dispatch(notifyError(new ModalError(error, options)))
      dispatch(fetchInvitationFailure(error))

      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    const handleResponse = ({ invite }) => {
      if (!invite || !invite.isInvitationValid) {
        handleError({ error: new AppError('Invalid Invitation') })
        return
      }

      const { email, isNewUser: _isNewUser } = invite

      // Inverted logic form BE
      isNewUser = !_isNewUser

      const invitation = {
        ...invite,
        token: invitationToken,
        isNewUser,
      }

      let isAuthenticated = sessionSelectors.isAuthenticated(state)
      const isLoggedUser = !isEmpty(user) && some(user.profiles, { email })

      if (isAuthenticated && (isNewUser || !isLoggedUser)) {
        isAuthenticated = false
        Promise.all([dispatch(logout()), dispatch(fetchInvitationSuccess(invitation))])
      } else {
        dispatch(fetchInvitationSuccess(invitation))
      }

      if (isNewUser) {
        dispatch(replace('/signup'))
      } else {
        dispatch(replace('/login'))
        if ((invite.status === PENDING_PRO_MEMBER || invite.status === PENDING_LIMITED_MEMBER) && !isAuthenticated) {
          dispatch(notifyWarning({ label: 'invitationAccountExist' }, { delay: 20 }))
        }
      }
    }

    api.invites.get(invitationToken, handleResponse, handleError)
  }

  thunk.type = actionTypes.TRIGGER_GET_INVITATION

  return thunk
}

export default getInvitation
