import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/network/constants'
import api from 'api'

// Actions

export const fetchReceivedRequestsFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_RECEIVED_REQUESTS_FAILURE,
})

export const fetchReceivedRequestsRequest = pageIndex => ({
  payload: pageIndex,
  type: actionTypes.FETCH_RECEIVED_REQUESTS_REQUEST,
})

export const fetchReceivedRequestsSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_RECEIVED_REQUESTS_SUCCESS,
})

// Thunks

export const fetchReceivedRequests = (pageIndex = 1) => {
  const thunk = (dispatch, getState) => {
    const accountId = sessionSelectors.accountId(getState())

    dispatch(fetchReceivedRequestsRequest(pageIndex))

    const handleResponse = response => dispatch(fetchReceivedRequestsSuccess(response))

    const handleError = ({ error }) => {
      dispatch(fetchReceivedRequestsFailure(error))
      throw error
    }

    return api.connectionRequests.get(
      {
        accountId,
        list: 'received',
        pageIndex,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_RECEIVED_REQUESTS

  return thunk
}

export default fetchReceivedRequests
