import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import * as workshopsSelectors from 'app_modules/workshops/selectors'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/workshops/constants'
import api from 'api'

// Actions

export const fetchUpdateWorkshopFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_UPDATE_WORKSHOP_FAILURE,
})

export const fetchUpdateWorkshopRequest = () => ({
  type: actionTypes.FETCH_UPDATE_WORKSHOP_REQUEST,
})

export const fetchUpdateWorkshopSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_UPDATE_WORKSHOP_SUCCESS,
})

// Thunks

export const fetchUpdateWorkshop = (data, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const state = getState()
    const accountId = sessionSelectors.accountId(state)
    const workshopId = workshopsSelectors.currentWorkshop(state).workshop.id

    dispatch(fetchUpdateWorkshopRequest())

    const handleResponse = response => {
      dispatch(fetchUpdateWorkshopSuccess(response))
      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchUpdateWorkshopFailure(error))
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'fetchUpdateWorkshop' })))
      }
      if (onError) {
        onError(error)
      } else {
        throw error
      }
    }

    return api.workshops.updateWorkshop(
      {
        accountId,
        workshopId,
        ...data,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_UPDATE_WORKSHOP

  return thunk
}

export default fetchUpdateWorkshop
