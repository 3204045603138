import { CircularProgress, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N, OWNERS } from 'constants/props'
import { StyledSelect } from 'generics/StyledFormComponents'
import Mask from 'generics/Mask'
import Translation from 'generics/Translation'

import styles from './FormFilterOwners.scss'
import useFormFilterOwners from './useFormFilterOwners'

/**
 * FormFilterOwners Component
 */
export const FormFilterOwners = ({ i18n, isLoading, onChange, owners }) => {
  const initialValues = { ownerFilter: 0 }
  const { handleChange, values } = useFormFilterOwners({ i18n, initialValues, onChange })

  const completeChange = value => {
    handleChange('ownerFilter', value)
    onChange(value)
  }

  const renderOwners = () => {
    const items = [
      <MenuItem
        key={0}
        // eslint-disable-next-line react/jsx-curly-brace-presence
        value={0}
      >
        All
      </MenuItem>,
    ]

    if (owners && owners.length) {
      items.push(
        ...owners.map(owner => (
          <MenuItem key={owner.id} value={owner.id}>
            {`${owner.firstName} ${owner.lastName}`}
          </MenuItem>
        )),
      )
    }

    return items
  }

  return (
    <>
      <form className={styles.form}>
        <StyledSelect
          className={styles['text-field']}
          label={i18n.pageTokenAdministration.ownerChange.filterByOwnerLabel}
          name="ownerFilter"
          onChange={e => completeChange(e.target?.value)}
          value={values.ownerFilter}
        >
          {renderOwners()}
        </StyledSelect>
      </form>
      <Mask className={styles.mask} open={isLoading}>
        <CircularProgress className={styles.loader} size={25} thickness={2} />
      </Mask>
    </>
  )
}

FormFilterOwners.propTypes = {
  i18n: I18N.isRequired,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  owners: OWNERS.isRequired,
}

FormFilterOwners.defaultProps = {
  isLoading: false,
}

export default Translation(FormFilterOwners, ['pageTokenAdministration', 'generics'])
