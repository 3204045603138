import { Col, Container, Row } from 'reactstrap'
import classnames from 'classnames'
import DocumentTitle from 'react-document-title'
import PropTypes from 'prop-types'
import React from 'react'

import { ACTIONS, I18N, USER } from 'constants/props'
import { Card } from 'generics/Card'
import { StyledButton } from 'generics/StyledFormComponents'
import Invite from 'classes/Invite'
import Translation from 'generics/Translation'

import FormSendPersonalsInvitation from './components/FormSendPersonalsInvitation/FormSendPersonalsInvitation'
import Result from './components/Result/Result'
import styles from './PageRelationships.scss'
import useRelationships from './useRelationships'

const { PERSONALS_URL, PUBLIC_PATH } = process.env
const IMG_PATH = `${window.location.origin}${PUBLIC_PATH}news/phone.png`

const PageRelationships = ({ accountId, i18n, isSSOAccount, notificationActions, user, userActions }) => {
  const { selfInvitation } = user

  const {
    email,
    emailError,
    hasPersonalAccount,
    isInviteAccepted,
    isEditing,
    isFetching,
    onChangeEmail,
    onClearError,
    onCopyToken,
    onResend,
    onSubmit,
    ssoToken,
  } = useRelationships({ accountId, i18n, notificationActions, selfInvitation, userActions })

  const descriptions = [i18n.news.descriptions.initial]

  if (hasPersonalAccount || isInviteAccepted) {
    descriptions.push(i18n.news.descriptions.completed)
  } else if (isEditing) {
    descriptions.push(i18n.news.descriptions.editing)
  }

  return (
    <DocumentTitle title="Simpli5® - Relationships Page">
      <article className={styles.page}>
        <Container>
          <Row>
            <Col>
              <Card barClassName={styles.bar} className={styles.card} isFetching={isFetching}>
                <Container>
                  <Row>
                    <Col className={styles.column} xs="12" md="8">
                      <h1>{i18n.news.title}</h1>
                      <div className={styles.description}>
                        {descriptions.map(description => (
                          <p key={description}>{description}</p>
                        ))}
                      </div>
                      {(hasPersonalAccount || isInviteAccepted) && (
                        <StyledButton
                          className={styles['login-btn']}
                          color="primary"
                          target="_blank"
                          title={i18n.news.form.loginBtn}
                          href={PERSONALS_URL}
                          id="main"
                        >
                          {i18n.news.form.loginBtn}
                        </StyledButton>
                      )}
                      {isEditing && (
                        <FormSendPersonalsInvitation
                          formAriaLabel={i18n.news.title}
                          initialValues={email ? { email } : null}
                          error={emailError}
                          isSubmitting={isFetching}
                          onChange={onClearError}
                          onSubmit={onSubmit}
                        />
                      )}
                      {!isEditing && !hasPersonalAccount && (
                        <Result
                          invite={new Invite(selfInvitation.invite)}
                          isSSO={isSSOAccount}
                          onChangeEmail={onChangeEmail}
                          onCopyToken={onCopyToken}
                          onResend={onResend}
                          ssoToken={ssoToken}
                        />
                      )}
                    </Col>
                    <Col className={classnames(styles.column, styles.column__centered)} xs="12" md="4">
                      <img src={IMG_PATH} alt={i18n.news.personalsAppName} />
                    </Col>
                  </Row>
                </Container>
              </Card>
            </Col>
          </Row>
        </Container>
      </article>
    </DocumentTitle>
  )
}

PageRelationships.propTypes = {
  accountId: PropTypes.number.isRequired,
  i18n: I18N.isRequired,
  isSSOAccount: PropTypes.bool,
  notificationActions: ACTIONS.isRequired,
  user: USER.isRequired,
  userActions: ACTIONS.isRequired,
}

PageRelationships.defaultProps = {
  isSSOAccount: false,
}

export default Translation(PageRelationships, ['news'])
