import { CircularProgress } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { pickHTMLProps } from 'pick-react-known-prop'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import Mask from 'generics/Mask'

import { CHILDREN } from '../../../constants/props'
import styles from './ListDnD.scss'

const ListDnD = ({
  accepts,
  canDrop,
  children,
  className,
  componentRefProp,
  disabled,
  emptyMessage,
  isDragging,
  isFetching,
  isOver,
  ...rest
}) => {
  const listRef = useRef(null)

  useEffect(() => {
    if (componentRefProp) {
      componentRefProp(listRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRefProp])

  const isActive = canDrop && isOver

  const childrenContent = !isEmpty(children) ? children : <span>{emptyMessage}</span>

  return (
    <div
      className={classnames(
        styles.list,
        { [styles['is-fetching']]: isFetching },
        { [styles['is-empty']]: isEmpty(children) },
        { [styles['can-drop']]: canDrop },
        { [styles['is-active']]: isActive },
        className,
      )}
      ref={listRef}
      {...pickHTMLProps(rest)}
    >
      {childrenContent}
      <Mask className={styles.mask} open={isFetching || disabled}>
        {isFetching && <CircularProgress className={styles.loader} size={60} thickness={7} />}
      </Mask>
    </div>
  )
}

ListDnD.propTypes = {
  accepts: PropTypes.string,
  canDrop: PropTypes.bool,
  children: CHILDREN,
  className: PropTypes.string,
  componentRefProp: PropTypes.func,
  disabled: PropTypes.bool,
  emptyMessage: PropTypes.string,
  isDragging: PropTypes.bool,
  isFetching: PropTypes.bool,
  isOver: PropTypes.bool,
}

ListDnD.defaultProps = {
  accepts: '',
  canDrop: false,
  children: undefined,
  className: null,
  componentRefProp: undefined,
  disabled: false,
  emptyMessage: undefined,
  isDragging: false,
  isFetching: false,
  isOver: false,
}

export default ListDnD
