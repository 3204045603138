import DocumentTitle from 'react-document-title'
import React from 'react'

import styles from './PageNotFound.scss'

const PageNotFound = () => (
  <DocumentTitle title="Simpli5® - Not Found Page">
    <div className={styles.main}>
      <h2>404 Page not found</h2>
    </div>
  </DocumentTitle>
)

export default PageNotFound
