import { Col, Row } from 'reactstrap'
import { LinearProgress } from '@material-ui/core'
import { sprintf } from 'sprintf-js'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { I18N } from 'constants/props'
import Translation from 'generics/Translation'

import styles from './ProgressBar.scss'

/**
 * @class ProgressBar
 * @description Progress Bar Component
 */
const ProgressBar = ({ className, completedOfText, hidePercentage, i18n, max, value = 0, testAttributePercentage, testAttributeCompleted }) => {
  const percentage = value > 0 ? (value * 100).toFixed(1) / max : 0
  const completedPercentage = !hidePercentage ? (
    <Col xs="6">
      <span className={styles.percentage} data-test={testAttributePercentage} >{sprintf(i18n.generics.completedPercentage, percentage)}</span>
    </Col>
  ) : null

  const completedOf = completedOfText ? (
    <Col xs="6">
      <span className={styles['completed-of']} data-test={testAttributeCompleted} >{sprintf(completedOfText, value, max)}</span>
    </Col>
  ) : null

  return (
    <div className={classNames(styles.content, className)}>
      <LinearProgress className={styles['linear-progress']} color="primary" value={percentage} variant="determinate" />
      <Row>
        {completedPercentage}
        {completedOf}
      </Row>
    </div>
  )
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  completedOfText: PropTypes.string,
  hidePercentage: PropTypes.bool,
  i18n: I18N.isRequired,
  max: PropTypes.number,
  value: PropTypes.number,
  testAttributePercentage: PropTypes.string,
  testAttributeCompleted: PropTypes.string,
}

ProgressBar.defaultProps = {
  className: null,
  completedOfText: null,
  hidePercentage: false,
  max: 0,
  value: null,
  testAttributePercentage: null,
  testAttributeCompleted: null,
}

export default Translation(ProgressBar, ['generics'])
