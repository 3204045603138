import actionTypes from 'app_modules/network/constants'
import api from 'api'

// Actions

export const fetchNetworkDetailsFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_NETWORK_DETAILS_FAILURE,
})

export const fetchNetworkDetailsSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_NETWORK_DETAILS_SUCCESS,
})

export const fetchNetworkDetailsRequest = profileId => ({
  type: actionTypes.FETCH_NETWORK_DETAILS_REQUEST,
  payload: profileId,
})

// Thunks

export const fetchNetworkDetails = (accountId, profileId) => {
  const thunk = dispatch => {
    dispatch(fetchNetworkDetailsRequest(profileId))

    const handleResponse = response => dispatch(fetchNetworkDetailsSuccess(response))

    const handleError = ({ error }) => dispatch(fetchNetworkDetailsFailure(error))

    return api.profiles.getNetworkDetails({ accountId, profileId }, handleResponse, handleError)
  }

  thunk.type = actionTypes.FETCH_NETWORK_DETAILS

  return thunk
}

export default fetchNetworkDetails
