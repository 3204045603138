import { merge } from 'lodash'

import ApiEndpoint from 'classes/api/ApiEndpoint.class'

/**
 * @class ApiController
 * @description Creates an Api Controller with all the endpoints request features
 * */
export default class ApiController {
  constructor(options) {
    const { api, controller } = options

    const instances = {}
    const endpoints = {}

    if (controller) {
      Object.keys(controller).forEach(endpoint => {
        instances[endpoint] = new ApiEndpoint({
          api,
          ...controller[endpoint],
        })
        endpoints[endpoint] = function request() {
          /**
           * Handles external API requests (e.g., Sensai AI endpoints)
           * When useExternalRequest flag is true:
           * - Uses different base URL (defined in controller config)
           * - Applies external API specific headers
           * - Handles external authentication
           * - Uses separate error handling for external services
           * @see app/api/controllers/sensai.controller.js for implementation example
           */
          if (controller[endpoint].useExternalRequest) {
            return instances[endpoint].externalRequest(...arguments)
          }
          return instances[endpoint].request(...arguments)
        }
      })
    }

    merge(this, { instances }, endpoints)
  }
}
