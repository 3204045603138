import { sprintf } from 'sprintf-js'
import classnames from 'classnames'
import pluralize from 'pluralize'
import PropTypes from 'prop-types'
import React from 'react'
import Translation from 'generics/Translation'

import { BUNDLE, I18N } from 'constants/props'
import { keyIndex } from 'helpers'
import { StyledButton } from 'generics/StyledFormComponents'
import Icon from 'generics/Icon'
import ListItemFlags from 'generics/ListItemFlags'
import ListItemActions from 'generics/ListItemActions'

import styles from './BundlesListItem.scss'

const BundlesListItem = ({ actions, bundle, className, onManageBundle, i18n }) => {
  const { i18NBundle: item, sharingMode, sharingModeLocked, teams } = bundle

  const {
    modulesCountLabel,
    sharedWithEntireNetworkLabel,
    teamsCountSharedLabel,
    teamSubject,
    moduleSubject,
  } = i18n.pageAdministration.bundlesListItem

  const modulesLabel = sprintf(modulesCountLabel, {
    modulesCount: item.moduleKeys.length,
    subject: pluralize(moduleSubject, item.moduleKeys.length),
  })

  const bundleFlags = [
    {
      className: styles.flags,
      iconName: sharingModeLocked ? 'lock' : null,
      label:
        sharingMode === 'shared'
          ? sharedWithEntireNetworkLabel
          : sprintf(teamsCountSharedLabel, {
              teamsCount: teams.length,
              subject: pluralize(teamSubject, teams.length),
            }),
    },
  ]

  return (
    <li className={classnames(styles.item, className)}>
      <Icon.Stroke7 className={styles.icon} name="box1" />
      <div className={styles['name-container']}>
        <StyledButton
          classes={{ root: styles['name-link'], label: styles['name-link__label'] }}
          color="default"
          onClick={() => {
            onManageBundle(bundle.i18NBundle.key)
          }}
          title={item.title}
          variant="text"
        >
          {item.title}
        </StyledButton>
        <span className={styles.description} title={modulesLabel}>
          {modulesLabel}
        </span>
        <ListItemFlags className={styles.flags} flags={keyIndex(bundleFlags)} />
      </div>
      <ListItemActions actions={actions} className={styles.actions} />
    </li>
  )
}

BundlesListItem.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.node),
  // FIXME
  // eslint-disable-next-line react/no-typos
  bundle: BUNDLE.isRequired,
  className: PropTypes.string,
  onManageBundle: PropTypes.func.isRequired,
  i18n: I18N.isRequired,
}

BundlesListItem.defaultProps = {
  actions: null,
  className: null,
}

export default Translation(BundlesListItem, ['pageAdministration'])
