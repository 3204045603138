import { pickHTMLProps } from 'pick-react-known-prop'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import { balloonShape } from 'constants/charts'
import { DYNAMICS_LABELS } from 'constants/index'
import { I18N, PEOPLE, TEAM } from 'props'
import Avatar from 'generics/Avatar'
import d3 from 'vendor/d3'
import Svg from 'generics/Svg'
import Translation from 'generics/Translation'

import styles from './SpiderGraph.scss'
import useSpiderGraph from './useSpiderGraph'

const MAXIMUM_PROFILES_LEFT_LIST = 6

const SVG_COORDINATES = {
  dynamics: [
    () => ({
      x: 0,
      y: 111,
    }),
    isOnTop => ({
      x: 0,
      y: isOnTop ? 2.6 : 5.5,
    }),
    isOnTop => ({
      x: 100,
      y: isOnTop ? 2.6 : 5.5,
    }),
    () => ({
      x: 100,
      y: 111,
    }),
  ],
  energies: [
    {
      x: 0,
      y: 114,
    },
    {
      x: 0,
      y: 5.65,
    },
    {
      x: 100,
      y: 5.65,
    },
    {
      x: 100,
      y: 114,
    },
  ],
  quadrants: [
    [-1, 1],
    [-1, -1],
    [1, -1],
    [1, 1],
  ],
  offset: [50, 57],
}

const mapScore = d3.scaleLinear().domain([0, 14]).range([7.5, 45])

export const SpiderGraph = props => {
  const {
    elementRefs,
    handleOnMouseEnter,
    handleOnMouseLeave,
    MAXIMUM_PROFILES_ALLOWED,
    profileIndexToHighlight,
    profiles,
  } = useSpiderGraph(props)

  const { container, nodeElement } = elementRefs

  const { className, i18n, isTeamAverageInclude, team, thumbnailClassName, ...rest } = props

  const svgProfilesGroup = []
    .concat(profiles)
    .reverse()
    .map(profile => {
      const { _isTeam, assessmentScores, id, index, name } = profile

      const path = d3.path()

      const { lineTo, moveTo } = path

      assessmentScores.forEach((score, scoreIndex) => {
        const point = SVG_COORDINATES.quadrants[scoreIndex].map(
          (sign, signIndex) => sign * mapScore(score) + SVG_COORDINATES.offset[signIndex],
        )

        if (scoreIndex === 0) {
          moveTo.apply(path, point)
        } else {
          lineTo.apply(path, point)
        }
      })

      path.closePath()

      return (
        <g key={id}>
          <path
            className={classnames(
              styles['spider-graph__profile'],
              styles[`spider-graph__profile--${_isTeam ? MAXIMUM_PROFILES_ALLOWED : index}`],
              { [styles['spider-graph__profile--is-team']]: _isTeam },
              { [styles['spider-graph__profile--highlight']]: index === profileIndexToHighlight },
              'spider-graph__profile',
              `spider-graph__profile--${_isTeam ? MAXIMUM_PROFILES_ALLOWED : index}`,
              { 'spider-graph__profile--is-team': _isTeam },
            )}
            d={path.toString()}
            onMouseEnter={() => {
              handleOnMouseEnter(index)
            }}
            onMouseLeave={() => {
              handleOnMouseLeave()
            }}
          />
          <title>{name}</title>
        </g>
      )
    })

  const svgLegendsTextsGroup = DYNAMICS_LABELS.map((dynamic, index) => {
    const energy = team.energies[index]
    const isRight = index > 1
    const isTop = index > 0 && index < 3
    const transformPoint = SVG_COORDINATES.dynamics[index](isTop && isTeamAverageInclude)

    return (
      <Fragment key={dynamic}>
        <text
          className={classnames(
            styles['spider-graph__dynamic'],
            { [styles['spider-graph__dynamic--is-right']]: isRight },
            'spider-graph__dynamic',
            { 'spider-graph__dynamic--is-right': isRight },
          )}
          key={dynamic}
          transform={`matrix(1 0 0 1 ${transformPoint.x} ${transformPoint.y})`}
        >
          <tspan>{`${index + 1}.${dynamic}`}</tspan>
          <title>{dynamic}</title>
        </text>

        {isTeamAverageInclude && (
          <text
            className={classnames(
              styles['spider-graph__energy'],
              { [styles['spider-graph__energy--is-right']]: isRight },
              'spider-graph__energy',
              { 'spider-graph__energy--is-right': isRight },
            )}
            key={energy}
            transform={`matrix(1 0 0 1 ${SVG_COORDINATES.energies[index].x} ${SVG_COORDINATES.energies[index].y})`}
          >
            <tspan>{energy}</tspan>
            <title>{energy}</title>
          </text>
        )}
      </Fragment>
    )
  })

  const profilesListItems = profiles.map(profile => {
    const { _isTeam, id, index, name } = profile

    const legendLabel = _isTeam ? i18n.pageSpiderGraph.label.teamAverage : name

    return (
      <li
        className={classnames(
          styles.list__li,
          { [styles[`list__li--${_isTeam ? MAXIMUM_PROFILES_ALLOWED : index}`]]: index === profileIndexToHighlight },
          'list__li',
          { [`list__li--${_isTeam ? MAXIMUM_PROFILES_ALLOWED : index}`]: index === profileIndexToHighlight },
        )}
        key={id}
        onMouseEnter={() => {
          handleOnMouseEnter(index)
        }}
        onMouseLeave={() => {
          handleOnMouseLeave()
        }}
        title={legendLabel}
      >
        <svg className={classnames(styles.list__svg, 'list__svg')} viewBox="0 0 100 120">
          <g className={classnames(styles.svg_1, 'svg_1')} transform="translate(50, 120) rotate(-135)">
            <path
              className={classnames(
                styles.list__path,
                styles[`list__path--${_isTeam ? MAXIMUM_PROFILES_ALLOWED : index}`],
                { [styles['list__path--highlight']]: index === profileIndexToHighlight },
                { [styles['list__path--is-team']]: _isTeam },
                'list__path',
                `list__path--${_isTeam ? MAXIMUM_PROFILES_ALLOWED : index}`,
                { 'list__path--is-team': _isTeam },
              )}
              d={balloonShape(44)}
            />
          </g>
        </svg>

        <Avatar
          className={classnames(styles.list__avatar, 'list__avatar', {
            [styles['list__avatar--thumbnail']]: thumbnailClassName,
          })}
          profile={profile}
        />

        <div className={classnames(styles.list__name, 'list__name')}>
          <span>{legendLabel}</span>
        </div>
      </li>
    )
  })

  const leftListItems = !thumbnailClassName ? profilesListItems.slice(0, MAXIMUM_PROFILES_LEFT_LIST) : profilesListItems

  const rightListItems = !thumbnailClassName ? profilesListItems.slice(MAXIMUM_PROFILES_LEFT_LIST) : []

  return (
    <div
      className={classnames(styles['spider-graph'], className, 'spider-graph')}
      ref={container}
      {...pickHTMLProps(rest)}
    >
      <div
        className={classnames(styles['spider-graph__graph'], 'spider-graph__graph', thumbnailClassName)}
        ref={nodeElement}
      >
        <Svg
          className={classnames(styles['spider-graph__svg'], 'spider-graph__svg')}
          vector-effect="non-scaling-stroke"
          viewBox="0 0 100 114"
        >
          <g>
            <rect
              className={classnames(
                styles.ref__band,
                styles['ref__band--is-solid'],
                styles['ref__band--is-outer'],
                'ref__band',
                'ref__band--is-solid',
                'ref__band--is-outer',
              )}
              height="100"
              width="100"
              y="7"
            />
            <rect className={classnames(styles.ref__band, 'ref__band')} height="85" width="85" x="7.5" y="14.5" />
            <rect
              className={classnames(
                styles.ref__band,
                styles['ref__band--is-solid'],
                'ref__band',
                'ref__band--is-solid',
              )}
              height="58"
              width="58"
              x="21"
              y="28"
            />
            <rect className={classnames(styles.ref__band, 'ref__band')} height="21" width="21" x="39.5" y="46.5" />
            <line className={classnames(styles.ref__line, 'ref__line')} x1="0" x2="100" y1="7" y2="107" />
            <line className={classnames(styles.ref__line, 'ref__line')} x1="100" x2="0" y1="7" y2="107" />
          </g>

          <g>{svgProfilesGroup}</g>

          <g>{svgLegendsTextsGroup}</g>
        </Svg>
      </div>
      <ul
        className={classnames(styles.list, styles['list--left'], 'list', 'list--left', {
          [styles['list--left__thumbnail']]: thumbnailClassName,
        })}
      >
        {leftListItems}
      </ul>
      <ul
        className={classnames(styles.list, styles['list--right'], 'list', 'list--right', {
          [styles['list--right__thumbnail']]: thumbnailClassName,
        })}
      >
        {rightListItems}
      </ul>
    </div>
  )
}

SpiderGraph.propTypes = {
  className: PropTypes.string,
  i18n: I18N.isRequired,
  isTeamAverageInclude: PropTypes.bool,
  profiles: PEOPLE,
  team: TEAM,
  thumbnailClassName: PropTypes.string,
}

SpiderGraph.defaultProps = {
  className: null,
  isTeamAverageInclude: false,
  profiles: [],
  team: null,
  thumbnailClassName: '',
}

export default Translation(SpiderGraph, ['pageSpiderGraph'])
