import { BarError } from 'classes/errors'
import { notifyError } from 'app_modules/notifications/actions'
import * as groupsSelectors from 'app_modules/groups/selectors'
import * as sessionSelectors from 'app_modules/session/selectors'
import actionTypes from 'app_modules/groups/constants'
import api from 'api'

// Actions

export const fetchUpdateGroupInvitationsFailure = error => ({
  payload: error,
  type: actionTypes.FETCH_UPDATE_GROUP_INVITATIONS_FAILURE,
})

export const fetchUpdateGroupInvitationsRequest = () => ({
  type: actionTypes.FETCH_UPDATE_GROUP_INVITATIONS_REQUEST,
})

export const fetchUpdateGroupInvitationsSuccess = response => ({
  payload: response,
  type: actionTypes.FETCH_UPDATE_GROUP_INVITATIONS_SUCCESS,
})

// Thunks

export const fetchUpdateGroupInvitations = (data, onSuccess, onError) => {
  const thunk = (dispatch, getState) => {
    const state = getState()
    const accountId = sessionSelectors.accountId(state)
    const { id: groupId } = groupsSelectors.currentGroup(state).group

    dispatch(fetchUpdateGroupInvitationsRequest())

    const handleResponse = response => {
      dispatch(fetchUpdateGroupInvitationsSuccess(response))

      if (onSuccess) {
        onSuccess(response)
      }
    }

    const handleError = ({ error, isConnectionError }) => {
      dispatch(fetchUpdateGroupInvitationsFailure(error))
      if (!isConnectionError) {
        dispatch(notifyError(new BarError({ ...error, label: 'fetchUpdateGroupInvitations' })))
      }

      if (onError) {
        onError(error)
      }
    }

    return api.groups.updateGroupInvitations(
      {
        accountId,
        groupId,
        ...data,
      },
      handleResponse,
      handleError,
    )
  }

  thunk.type = actionTypes.FETCH_UPDATE_GROUP_INVITATIONS

  return thunk
}

export default fetchUpdateGroupInvitations
