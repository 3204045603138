/* eslint-disable import/prefer-default-export */
import d3 from 'vendor/d3'

/**
 * Represents a balloon path definition.
 * @param  {number} radius - The radius of the balloon shape.
 * @returns {string} The path definition
 */
export const balloonShape = radius => {
  const path = d3.path()
  path.moveTo(0, 0)
  path.lineTo(radius, 0)
  path.arc(radius, radius, radius, -0.5 * Math.PI, Math.PI)
  path.closePath()
  return path.toString()
}
