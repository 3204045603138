import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { ITEM_FLAGS } from 'constants/props'
import Icon from 'generics/Icon'
import styles from './ListItemFlags.scss'

const ListItemFlags = props => {
  const { className, flags } = props

  const flagList = flags.map(flag => {
    const { className: flagClassName, iconName, id, label } = flag

    return (
      <li className={styles.option} key={id}>
        {iconName && <Icon.Stroke7 className={classnames(styles['flag-icon'], flagClassName)} name={iconName} />}
        {label && <span className={classnames(styles['option-label'], flagClassName)}>{label}</span>}
      </li>
    )
  })

  return <ul className={classnames(styles.options, className)}>{flagList}</ul>
}

ListItemFlags.propTypes = {
  className: PropTypes.string,
  flags: ITEM_FLAGS.isRequired,
}

ListItemFlags.defaultProps = {
  className: null,
}

export default ListItemFlags
