import PropTypes from 'prop-types'
import React from 'react'

import { ACTIONS, I18N } from 'constants/props'
import FormAssignTokens from '../FormAssignTokens/FormAssignTokens'
import styles from './AssignTokens.scss'
import useAssignTokens from './useAssignTokens'

const AssignTokens = ({
  accountId,
  accountName,
  i18n,
  isAdmin,
  notificationActions,
  onFetchNetworkDetails,
  profileId,
}) => {
  const { allOwners, assignTokens, owners, setOwners } = useAssignTokens({
    accountId,
    accountName,
    i18n,
    isAdmin,
    notificationActions,
    onFetchNetworkDetails,
    profileId,
  })

  return (
    <div className={styles.assignTokensContainer}>
      <p className={styles.description}>{i18n.pageTokenAdministration.assignTokens.description}</p>
      <FormAssignTokens
        accountId={accountId}
        accountName={accountName}
        allOwners={allOwners}
        isAdmin={isAdmin}
        onSubmit={assignTokens}
        owners={owners}
        setOwners={setOwners}
        resetOnSubmit
      />
    </div>
  )
}

AssignTokens.propTypes = {
  accountId: PropTypes.number.isRequired,
  accountName: PropTypes.string.isRequired,
  i18n: I18N.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  notificationActions: ACTIONS.isRequired,
  onFetchNetworkDetails: PropTypes.func.isRequired,
  profileId: PropTypes.number.isRequired,
}

export default AssignTokens
